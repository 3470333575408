export interface CustomerDocs {
  _id?: string;
  id?: string;
  code: string;
  createdAt: string;
  email: string;
  invoicePeriod: string;
  paymentPeriod: number;
  legalId: string;
  legalName: string;
  phone: string;
  publicName: string;
  updatedAt: string;
  vatNumber: string;
  mainLocation?: { geometry: { coordinates: number[] } };
  _organizationDeck?: { _id: string; nextDeliveryDate: string };
}

export interface ContactData {
  email: string;
  subject: string;
  message: string;
  token: string;
  password: string;
}

export enum CustomerStatus {
  IN_REVIEW = 'in-review',
  ACTIVE = 'active',
  ON_WATCH = 'on-watch',
  BLOCKED = 'blocked',
  ARCHIVED = 'archived',
}
