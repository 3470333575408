import { Marker } from '@vis.gl/react-google-maps';
function drawRoundedRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

function drawStroked(ctx, text, x, y) {
  ctx.font = 'normal 13px Roboto';
  ctx.fillStyle = '#616161E5';

  const textWidth = 38;
  const textHeight = 23;
  const borderRadius = 5;

  // Draw rounded rectangle
  drawRoundedRect(ctx, x, y - textHeight, textWidth, textHeight, borderRadius);
  ctx.fill();

  const metrics = ctx.measureText(text);
  const textX = x + (textWidth - metrics.width) / 2;
  const textY = y - (textHeight - 10) / 2;

  ctx.fillStyle = 'white';
  ctx.fillText(text, textX, textY);
}

export function CustomTooltip({ text, coordinates }) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = 50;
  canvas.height = 30;

  drawStroked(ctx, text, 0, 30);

  const dataUrl = canvas.toDataURL();

  return (
    <Marker
      position={{ lat: coordinates[1], lng: coordinates[0] }}
      icon={{
        url: dataUrl,
        anchor: new google.maps.Point(25, 15),
        scaledSize: new google.maps.Size(50, 30),
      }}
      zIndex={301}
    />
  );
}
