import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { geocodeByPlaceId } from '@dylmye/mui-google-places-autocomplete';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';

import { formatGeocode, formatGouv } from '../../../utils/googlePlace';

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export function LocationInput({
  defaultValue,
  label,
  size,
  onSelect,
  style = null,
  disabled = false,
}: {
  defaultValue?: any;
  label: string;
  size: 'small' | 'medium';
  onSelect: (value: any) => void;
  style?: any;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<any | null>({
    description: defaultValue?.name || defaultValue?.formattedAddress || '',
    structured_formatting: {
      main_text: defaultValue?.name || defaultValue?.formattedAddress || '',
    },
  });
  const [inputValue, setInputValue] = React.useState(defaultValue);

  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  const fetchRequest = React.useMemo(
    () =>
      debounce(
        (request: any, callback: (results?: readonly PlaceType[]) => void) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (inputValue?.name === '') {
      return;
    }

    fetchRequest(
      { input: inputValue, types: ['geocode'] },
      async (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          // @ts-ignore
          if (!results || results?.length < 2) {
            const resGouv = await fetch(
              `https://api-adresse.data.gouv.fr/search/?q=${inputValue}&limit=10`,
            );

            const dataGouv = await resGouv.json();

            newOptions = [
              ...newOptions,
              ...dataGouv?.features.map(feature => formatGouv(feature)),
            ];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchRequest]);

  const handleChangeLocation = async value => {
    if (value?.place_id) {
      const places = await geocodeByPlaceId(value.place_id);

      setValue(value);

      if (!isEmpty(places)) {
        const geoPlace = formatGeocode(places[0]);

        onSelect(geoPlace);
      }
    } else if (value?.gouvId) {
      setValue(value);
      onSelect(value);
    }
  };

  useEffect(() => {
    setValue({
      description: defaultValue?.name || defaultValue?.formattedAddress || '',
      structured_formatting: {
        main_text: defaultValue?.name || defaultValue?.formattedAddress || '',
      },
    });
  }, [defaultValue]);

  return (
    <Autocomplete
      id="google-place-input"
      getOptionLabel={option =>
        typeof option === 'string' ? option : option.description
      }
      disabled={disabled}
      style={style}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={t('locationInput.noResults')}
      onChange={async (event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        await handleChangeLocation(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField {...params} size={size} label={label} fullWidth />
      )}
      renderOption={(props, option) => {
        if (!option.structured_formatting && option.shortAddress) {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  {option.shortAddress}
                  <Typography variant="body2" color="text.secondary">
                    {option.city} {option.zipCode}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }

        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
