import { IconButton, LinearProgress, Typography } from '@mui/material';
import If from 'app/components/If';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
export function AcceptedFiles({
  files,
  handleDeleteFile,
  statusFile,
  hideProgressBar,
  fileProgress,
  disabled = false,
}) {
  return (
    <>
      {files.map(file => (
        <FilesListWrapper key={file.name}>
          <UploadFileIcon
            color="primary"
            sx={{ width: '28px', height: '28px', mr: '16px' }}
          />
          <div
            className="container"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Typography
              fontSize="1rem"
              fontWeight="400"
              sx={{
                minWidth: '300px',
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {file.name}
            </Typography>
            <div
              className="detailWrapper"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography>{(file.size / 1024).toFixed(2)}kb</Typography>
              <Typography sx={{ ml: '8px' }}>•</Typography>
              <Typography sx={{ ml: '8px' }}>
                {statusFile[file.name]}
              </Typography>
            </div>
            {hideProgressBar[file.name] === false && (
              <LinearProgress
                variant="determinate"
                value={fileProgress[file.name]}
                sx={{ width: '200px', mt: '4px' }}
              />
            )}
          </div>
          <If condition={!disabled}>
            <IconButton
              sx={{ ml: 'auto' }}
              onClick={() => handleDeleteFile(file.name)}
            >
              <CloseIcon color="action" />
            </IconButton>
          </If>
        </FilesListWrapper>
      ))}
    </>
  );
}

const FilesListWrapper = styled.div`
  width: 100%;
  height: 72px;
  padding: 16px 16px 0px 16px;
  display: flex;
  align-items: center;
`;
