import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { LegacyRef, useState } from 'react';
import { SelectorRenderOption } from './SelectorRenderOption';
import { themes } from 'styles/theme/themes';
import { Plus } from '@phosphor-icons/react';
import styled from 'styled-components';
import { usePreferredAgent } from 'hooks/PreferredAgent/usePreferredAgent';

interface SelectorComponentProps {
  user: any;
  loadingUpdate: any;
  handleSelectClick: any;
  isSelected: boolean;
  editProduct: any;
  UserProps: any;
  type: any;
  onChange: any;
  roundtripId: any;
  selectedRef: LegacyRef<HTMLDivElement> | undefined;
  minimal?: boolean;
}

function SelectorComponent({
  user,
  loadingUpdate,
  handleSelectClick,
  isSelected,
  editProduct,
  UserProps,
  type,
  onChange,
  roundtripId,
  selectedRef,
  minimal,
}: SelectorComponentProps) {
  const { t } = useTranslation();

  const { preferredAgent } = usePreferredAgent({ user });

  const displaySelected =
    !!preferredAgent?.fullName && !isSelected
      ? {
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          padding: minimal ? 0 : '22px 16px',
        }
      : { display: 'none' };

  const displayAdd =
    !preferredAgent?.fullName && !isSelected
      ? {
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          padding: minimal ? 0 : '0 16px',
        }
      : { display: 'none' };

  const addButton = {
    justifyContent: 'flex-start',
    color: themes?.default?.iconColor,
    border: `0.5px solid ${themes?.default?.gainsboro2}`,
    padding: '2px 6px !important',
    borderRadius: '20px',
    alignItems: 'center',
    gap: '2px',
    textTransform: 'none',
    fontSize: '0.625rem',
    lineHeight: '9px',
    fontWeight: '400',
    maxWidth: '47px',
    minWidth: '47px',
    maxHeight: '20px',
    minHeight: '20px',
    '&:hover': {
      backgroundColor: themes?.default?.gainsboro2,
    },
  };

  const [open, setOpen] = useState(true);
  return (
    <>
      <div
        ref={selectedRef}
        style={{
          width: '100%',
          minHeight: minimal ? '20px' : '40px',
          cursor: 'text',
        }}
        onClick={handleSelectClick}
      >
        <div style={displayAdd}>
          <Button size="small" sx={addButton}>
            <Plus size={9} color={themes?.default?.iconColor} />
            {t('common.add')}
          </Button>
        </div>
        <div style={displaySelected} id={`${roundtripId}${type}`}>
          {preferredAgent?.fullName}
        </div>
        {isSelected && (
          <Autocomplete
            disabled={editProduct}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            style={{ flex: 1, border: 'none' }}
            {...UserProps}
            autoComplete={false}
            onChange={onChange}
            isOptionEqualToValue={(
              option: { _id: string },
              value: { key: string },
            ) => {
              return option?.['_id'] === value?.key;
            }}
            defaultValue={{
              key: user?.['_id'],
              fullName: preferredAgent?.fullName,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '0',
                padding: '0',
              },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            PaperComponent={({ children }) => (
              <Paper style={{ padding: '4px 0', margin: '10px 0' }}>
                {children}
              </Paper>
            )}
            renderInput={params => (
              <TextField
                {...params}
                name={'vehicle'}
                size="small"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: '0.875rem',
                    alignItems: 'center',
                    padding: minimal ? '0 65px 0 0' : 'auto',
                  },
                }}
              />
            )}
            renderOption={(props, option) => {
              return (
                <SelectorRenderOption
                  props={props}
                  type="user"
                  option={option}
                  minimal={minimal}
                  isRipper={type === 'ripper'}
                />
              );
            }}
            getOptionDisabled={(option_: any) => {
              if (option_?.onLeave) {
                return true;
              }
              return type === 'ripper' ? !!option_?.roundtripCode : null;
            }}
          />
        )}
      </div>
    </>
  );
}

export default SelectorComponent;
