import { removeUndefined } from '../../../utils/dto'

const cacheAgentRole = (response: any) => {
  if (!response) return;
  return {
    _id: response._id,
    key: response.key,
    localeName: response.localeName,
  };
};

const cacheAgent = (response: any) => {
  if (!response) return;
  return Object.entries(response).reduce((acc:any, [key, agent]:any) => {
    acc[key] = { _role: cacheAgentRole(agent._role) };
    return acc;
  }, {});
};

const userCacheDto = (response: any) => {
  if (!response) return;
  return {
    agents: cacheAgent(response.agents),
  };
};

const getUserDto = (response: any) => {
  if (!response) return;
  return {
    firstName: response.firstName,
    lastName: response.lastName,
    cache: userCacheDto(response.cache),
    _currentOrganization: response._currentOrganization,
  };
};

const leaveRequestsObjDto = (response: any) => {
  if (!response) return;

  return {
    _id: response._id,
    differenceInDays: response.differenceInDays,
    startDate: response.startDate,
    endDate: response.endDate,
    createdAt: response.createdAt,
    description: response.description,
    status: response.status,
    _leaveRequestType: response._leaveRequestType,
    documentFile: response.documentFile,
    _user: getUserDto(response._user), // obj
  };
};

const leaveRequestsDocDto = (response: any) => {
  if (!response) return;
  return response.map((res: any) => {
    return leaveRequestsObjDto(res);
  });
};

export const getLeaveRequestsDto = (response: any) => {
  return removeUndefined({
    totalDocs: response.totalDocs,
    limit: response.limit,
    page: response.page,
    totalPages: response.totalPages,
    pagingCounter: response.pagingCounter,
    hasPrevPage: response.hasPrevPage,
    hasNextPage: response.hasNextPage,
    prevPage: response.prevPage,
    nextPage: response.nextPage,
    facets: response.facets,
    docs: leaveRequestsDocDto(response.docs),
  });
};
