import styled from 'styled-components';
import NoOrder from 'assets/img/empty-data.svg';
import { IconButtonComponent } from 'app/components/IconButtonComponent';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Dayjs } from 'dayjs';
import AddDialog from '../RightBar/components/add_dialog';
import { Background, LoadingLayer, RelativeSection } from '../RightBar';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
interface Props {
  dateValue: Dayjs | null;
  begin?: string;
  end?: string;
  orders: any;
  dateBegin?: any;
  requestAddRoundTripData: any;
  clusterId: string;
  isLoading: boolean;
  isFetching?: boolean;
  userContext: AgentCategories;
}
export const RightBarNoData: React.FC<Props> = ({
  dateValue,
  begin,
  end,
  orders,
  dateBegin,
  requestAddRoundTripData,
  clusterId,
  isLoading,
  userContext,
}) => {
  const { t } = useTranslation();
  const [openAdd, setOpenAdd] = useState(false);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [isLoading]);

  const handleClickOpen = () => {
    setOpenAdd(true);
  };
  if (showLoading)
    return (
      <RelativeSection>
        <LoadingLayer style={{ top: '50%' }}>
          <LoadingPulse />
        </LoadingLayer>
      </RelativeSection>
    );
  return (
    <Background>
      <AddDialog
        clusterId={clusterId}
        open={openAdd}
        setOpen={setOpenAdd}
        orders={orders}
        requestAddRoundTripData={requestAddRoundTripData}
        userContext={userContext}
      />
      <CenterDiv>
        <NoDataComponents
          Icon={NoOrder}
          open={openAdd}
          handleClick={() => handleClickOpen()}
          text={t('no_orders_yet')}
          IconButton={<></>}
          textButton={t('orders.add_orders')}
        />
      </CenterDiv>
    </Background>
  );
};

const CenterDiv = styled.div`
  width: max-content;
  display: flex !important;
  flex-direction: column !important;
  img {
    margin: 0 auto;
  }
  width: 100%;
  justify-content: center;
  height: 100%;
`;
export const Text = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 14px;
  text-align: center;
`;
// const Button = styled.button``;
