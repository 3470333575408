import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import PortalIcon from '@mui/icons-material/Api';
import {
  SquaresFour,
  ImagesSquare,
  ShoppingBagOpen,
  ClipboardText,
  CurrencyCircleDollar,
  PhoneCall,
  PresentationChart,
  Star,
  Invoice,
} from '@phosphor-icons/react';

import { MainNavItem, ScrollableSection, OptionsWrapper } from '../helpers';
import {
  CUSTOMER,
  DASHBOARD_ALL,
  ORDORIA,
  ORDORIA_CUSTOMER,
  REPORTS,
  REPORTS_ALL,
} from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { checkUserAcl } from 'common/helpers/acl';
import { Icon } from 'app/components/Icon';
import styled from 'styled-components';
import { isActiveRoute } from '../index';
import { IconWrapper } from '../../../../styles/components/muitable/TableViewPopover';
import IfUserAcl from '../../IfUserAcl';
import { MenuTitleWrapper } from '../Components';

const dashboardAll = `${ORDORIA}${DASHBOARD_ALL}`;
const reportAll = `${ORDORIA}${REPORTS_ALL}`;

export default function OrdoriaMenu({ theme, mode, authUser }) {
  const { t } = useTranslation();
  const location = useLocation();

  const isDashboardAll = location.pathname === dashboardAll;
  const isReportAll = location.pathname === reportAll;
  const isMinify = mode === 'minify';
  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>([0]);

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex(prevIndices => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter(i => i !== index);
      } else {
        return [index];
      }
    });
  };

  if (isDashboardAll || isReportAll) {
    return (
      <OptionsWrapper>
        <MenuTitleWrapper
          isMinify={isMinify}
          onClick={() => handleRowToggle(0)}
          openSectionIndex={openSectionIndex}
          theme={theme}
          title={t('nav.mainNav.title1')}
          index={0}
        >
          <MainNavItem
            to={dashboardAll}
            text={t('nav.mainNav.ordoria.dashboard')}
            mode={mode}
            icon={
              <Icon
                icon={<SquaresFour />}
                color={
                  location.pathname === dashboardAll
                    ? theme.primaryActiveColor
                    : themes?.default?.black54
                }
                size={20}
              />
            }
          />

          <MainNavItem
            to={reportAll}
            text={t('nav.mainNav.reports')}
            mode={mode}
            icon={
              <IconWrapper isActive={isActiveRoute(location, reportAll)}>
                <PresentationChart />
              </IconWrapper>
            }
          />
        </MenuTitleWrapper>
      </OptionsWrapper>
    );
  }

  return (
    <ScrollableSection>
      {checkUserAcl(authUser, ['manageCustomers']) && (
        <>
          <OptionsWrapper>
            <MenuTitleWrapper
              isMinify={isMinify}
              onClick={() => handleRowToggle(0)}
              openSectionIndex={openSectionIndex}
              theme={theme}
              title={t('nav.mainNav.title1')}
              index={0}
            >
              <MainNavItem
                to={ORDORIA}
                text={t('nav.mainNav.ordoria.dashboard')}
                mode={mode}
                icon={
                  <Icon
                    icon={<SquaresFour />}
                    color={
                      location.pathname === '/ordoria'
                        ? theme.primaryActiveColor
                        : themes?.default?.black54
                    }
                    size={20}
                  />
                }
              />

              <MainNavItem
                to="/ordoria/catalog"
                text={t('nav.mainNav.ordoria.catalog')}
                mode={mode}
                icon={
                  <Icon
                    icon={<ImagesSquare />}
                    color={
                      location.pathname === '/ordoria/catalog'
                        ? theme.primaryActiveColor
                        : themes?.default?.black54
                    }
                    size={20}
                  />
                }
              />

              <MainNavItem
                to="/ordoria/orders"
                text={t('nav.mainNav.ordoria.orders')}
                mode={mode}
                icon={
                  <Icon
                    icon={<ShoppingBagOpen />}
                    color={
                      location.pathname === '/ordoria/orders'
                        ? theme.primaryActiveColor
                        : themes?.default?.black54
                    }
                    size={20}
                  />
                }
              />

              <IfUserAcl acl={['reports']}>
                <MainNavItem
                  to={`/ordoria${REPORTS}`}
                  text={t('nav.mainNav.reports')}
                  mode={mode}
                  icon={
                    <IconWrapper isActive={isActiveRoute(location, REPORTS)}>
                      <PresentationChart
                        color={
                          location.pathname === '/ordoria/reports'
                            ? theme.primaryActiveColor
                            : themes?.default?.black54
                        }
                      />
                    </IconWrapper>
                  }
                />
              </IfUserAcl>
            </MenuTitleWrapper>
          </OptionsWrapper>

          <OptionsWrapper>
            <MenuTitleWrapper
              isMinify={isMinify}
              onClick={() => handleRowToggle(1)}
              openSectionIndex={openSectionIndex}
              theme={theme}
              title={t('nav.mainNav.title2')}
              index={1}
            >
              <MainNavItem
                to={ORDORIA_CUSTOMER}
                text={t('nav.mainNav.customers')}
                mode={mode}
                icon={
                  <IconWrapper isActive={isActiveRoute(location, CUSTOMER)}>
                    <Star
                      color={
                        location.pathname === '/ordoria/customers'
                          ? theme.primaryActiveColor
                          : themes?.default?.black54
                      }
                    />
                  </IconWrapper>
                }
              />

              <MainNavItem
                to={'/ordoria/callplan'}
                text={t('nav.mainNav.ordoria.callplan')}
                mode={mode}
                icon={
                  <PhoneCall
                    color={
                      location.pathname === '/ordoria/callplan'
                        ? theme.primaryActiveColor
                        : themes?.default?.black54
                    }
                  />
                }
              />
            </MenuTitleWrapper>
          </OptionsWrapper>
          <MenuTitleWrapper
            isMinify={isMinify}
            onClick={() => handleRowToggle(3)}
            openSectionIndex={openSectionIndex}
            theme={theme}
            title={t('nav.mainNav.checkDocuments')}
            index={3}
          >
            <MainNavItem
              to="/ordoria/notes"
              text={t('nav.mainNav.ordoria.claims')}
              mode={mode}
              icon={
                <Icon
                  icon={<ClipboardText />}
                  color={
                    location.pathname === '/ordoria/notes'
                      ? theme.primaryActiveColor
                      : themes?.default?.black54
                  }
                  size={20}
                />
              }
            />
            <MainNavItem
              to={'/ordoria/quotations'}
              text={t('nav.mainNav.ordoria.quotations')}
              mode={mode}
              icon={
                <Icon
                  icon={<CurrencyCircleDollar />}
                  color={
                    location.pathname === '/ordoria/quotations'
                      ? theme.primaryActiveColor
                      : themes?.default?.black54
                  }
                  size={20}
                />
              }
            />
            <MainNavItem
              to={'/ordoria/transations'}
              text={t('nav.mainNav.ordoria.transactions')}
              mode={mode}
              icon={
                <Icon
                  icon={<Invoice />}
                  color={
                    location.pathname === '/ordoria/transations'
                      ? theme.primaryActiveColor
                      : themes?.default?.black54
                  }
                  size={20}
                />
              }
            />
          </MenuTitleWrapper>
        </>
      )}

      {authUser?.currentAgent?._customer && (
        <MainNavItem
          to={ORDORIA}
          text={t('nav.mainNav.ordoria.portal')}
          mode={mode}
          icon={
            <PortalIcon
              sx={{
                color:
                  location.pathname === ORDORIA
                    ? theme.primaryActiveColor
                    : themes?.default?.black54,
              }}
            />
          }
        />
      )}
    </ScrollableSection>
  );
}
