import { use } from 'i18next';
import api from './api';
import { AddPlaceEntrance } from '../types/Place';
import { timeZoneDate } from '../utils/dates';
import roundtripApis from './roundtripApi';
import { roundtripPlace } from '../utils/Roundtrips/place';
export const placeApis = api.injectEndpoints({
  endpoints: builder => ({
    getPlaces: builder.query<any, string>({
      query: urlQuery => ({
        url: `/places?${urlQuery}`,
      }),
      providesTags: ['Places'],
    }),
    addPlaceEntrance: builder.mutation<any, AddPlaceEntrance>({
      query: body => ({
        url: `/places/${body.id}/access`,
        method: 'POST',
        body: body.data,
      }),
      invalidatesTags: [],
      async onQueryStarted(
        { id, date, ...patch },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: updatedPlace } = await queryFulfilled;
          const patchResult = dispatch(
            roundtripApis.util.updateQueryData(
              'getRoundtripByAgent',
              timeZoneDate(date),
              draft => {
                const updatedData1 = {
                  ...draft,
                  stops: draft.stops.map(stop => {
                    if (roundtripPlace(stop)._id === id) {
                      if (stop?._order?._deck?.access) {
                        return {
                          ...stop,
                          _order: {
                            ...stop._order,
                            _deck: {
                              ...stop._order._deck,
                              access: updatedPlace.access,
                            },
                          },
                        };
                      }
                      return { ...stop };
                    }
                    return stop;
                  }),
                };
                return updatedData1;
              },
            ),
          );
        } catch {}
      },
    }),
    deletePlaceEntrance: builder.mutation<any, AddPlaceEntrance>({
      query: body => ({
        url: `/places/${body.id}/access/${body.entranceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [],
      async onQueryStarted(
        { id, date, ...patch },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: updatedPlace } = await queryFulfilled;
          const patchResult = dispatch(
            roundtripApis.util.updateQueryData(
              'getRoundtripByAgent',
              timeZoneDate(date),
              draft => {
                const updatedData1 = {
                  ...draft,
                  stops: draft.stops.map(stop => {
                    if (roundtripPlace(stop)._id === id) {
                      if (stop?._order?._deck?.access) {
                        return {
                          ...stop,
                          _order: {
                            ...stop._order,
                            _deck: {
                              ...stop._order._deck,
                              access: updatedPlace.access,
                            },
                          },
                        };
                      }
                      return { ...stop };
                    }
                    return stop;
                  }),
                };
                return updatedData1;
              },
            ),
          );
        } catch {}
      },
    }),
    editPlaceEntrance: builder.mutation<any, AddPlaceEntrance>({
      query: body => ({
        url: `/places/${body.id}/access/${body.entranceId}`,
        method: 'PATCH',
        body: body.data,
      }),
      invalidatesTags: [],
      async onQueryStarted(
        { id, date, ...patch },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: updatedPlace } = await queryFulfilled;
          const patchResult = dispatch(
            roundtripApis.util.updateQueryData(
              'getRoundtripByAgent',
              timeZoneDate(date),
              draft => {
                const updatedData1 = {
                  ...draft,
                  stops: draft.stops.map(stop => {
                    if (roundtripPlace(stop)._id === id) {
                      if (stop?._order?._deck?.access) {
                        return {
                          ...stop,
                          _order: {
                            ...stop._order,
                            _deck: {
                              ...stop._order._deck,
                              access: updatedPlace.access,
                            },
                          },
                        };
                      }
                      return { ...stop };
                    }
                    return stop;
                  }),
                };
                return updatedData1;
              },
            ),
          );
        } catch {}
      },
    }),
    getCustomerPlaces: builder.query<any, any>({
      query: (urlQuery: any) => ({
        url: `/places/customer/${urlQuery.id}${urlQuery.query}`,
      }),
      providesTags: ['Places'],
    }),
    addBranch: builder.mutation<void, any>({
      query: ({ body }) => ({
        url: `/places/add-branch`,
        body: body,
        method: 'POST',
      }),
    }),
    deleteBranch: builder.mutation<void, any>({
      query: ({ id }) => ({
        url: `/places/${id}`,
        method: 'DELETE',
      }),
    }),
    updateBranchStatus: builder.mutation<void, any>({
      query: ({ id, status }) => ({
        url: `/places/${id}/update-status`,
        method: 'PUT',
        body: { status },
      }),
    }),
  }),
});
export const {
  useAddBranchMutation,
  useGetCustomerPlacesQuery,
  useGetPlacesQuery,
  useLazyGetPlacesQuery,
  useAddPlaceEntranceMutation,
  useDeletePlaceEntranceMutation,
  useEditPlaceEntranceMutation,
  useDeleteBranchMutation,
  useUpdateBranchStatusMutation,
} = placeApis;
export default placeApis;