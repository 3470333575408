import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormActions,
  FormSection,
  FormWrapper,
} from '../../../../../../../components/Form/styles';
import FormTextField from '../../../../../../../components/Form/TextField';
import { Button } from '@mui/material';
import { themes } from '../../../../../../../../styles/theme/themes';
import { SubmitButton } from '../../../../../../Accessories/Components/AccessoryForm/Components/SubmitButton';
import React from 'react';
import { useManagedContext } from '../../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import {
  useAddDepartmentMutation,
  useEditDepartmentMutation,
} from '../../../../../../../../common/services/companyDepartmentsApi';
import { useToaster } from '../../../../../../../../hooks/useToaster';
import { UserAutoComplete } from '../../../../../../Accessories/Components/AccessoryForm/Components/AssigneeAutoComplete/Components/UserAutoComplete';

interface DepartmentFormInterface {
  departmentName: string;
  employee: string;
}
export default function DepartmentForm() {
  const departmentsContext = useManagedContext('departmentsContext');
  const { editMode, selectedDepartment } = departmentsContext;
  const methods = useForm<DepartmentFormInterface>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      departmentName: editMode ? selectedDepartment?.name : '',
      employee: editMode ? selectedDepartment?.managerId : '',
    },
  });
  const toaster = useToaster();
  const {
    control,
    watch,
    formState: { errors },
    setError,
  } = methods;

  const departmentName = watch('departmentName')?.trim();
  const manager = watch('employee');
  const [editDepartment] = useEditDepartmentMutation();
  const [addDepartment] = useAddDepartmentMutation();
  const onEditFormSubmit = async () => {
    try {
      if (editMode) {
        await editDepartment({
          _id: selectedDepartment?.id!,
          name: departmentName,
          _manager: manager,
        }).unwrap();
      } else {
        await addDepartment({
          name: departmentName,
          _manager: manager,
        }).unwrap();
      }
      departmentsContext.updateDataWithFunction(prev => {
        prev.openDrawer = false;
        prev.editMode = false;
        prev.selectedDepartment = null;
      });
      toaster(
        3000,
        'success',
        editMode
          ? t('department.edited.success')
          : t('department.added.success'),
      );
    } catch (error: any) {
      if ((error as any).data?.message) {
        toaster(3000, 'error', t((error as any).data.message!));
        if (
          (error as any).data.message ===
          'company-department.department-already-exist'
        ) {
          setError('departmentName', {
            type: 'manual',
            message: t((error as any).data.message),
          });
        }
      } else {
        toaster(3000, 'error', t('server_error_message'));
      }
    }
  };
  const { t } = useTranslation();
  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        onEditFormSubmit();
      }}
    >
      <FormWrapper>
        <FormSection>
          <FormTextField
            name="departmentName"
            label={t(
              'settings.companyProfile.departments.add.textfields.departmentName',
            )}
            control={control}
            defaultValue={selectedDepartment?.name}
            rules={{
              required: t('department.name.required.message'),
              minLength: {
                value: 3,
                message: t('department.name.required.message'),
              },
              pattern: {
                value: /^(?!\s*$)[a-zA-Z\s]+$/,
                message: t('department.name.letters.message'),
              },
            }}
          />
          <UserAutoComplete
            title={t('Add_Client_Manager')}
            control={control}
            assignee={{
              _id: selectedDepartment?.managerId,
              fullName: selectedDepartment?.manager,
            }}
          />
        </FormSection>
      </FormWrapper>
      <FormActions>
        <Button
          disableElevation
          sx={{ color: themes.default.black60 }}
          onClick={() => {
            departmentsContext.updateDataWithFunction(prev => {
              prev.openDrawer = false;
              prev.editMode = false;
              prev.selectedDepartment = null;
            });
          }}
        >
          {t('common.buttons.cancel')}
        </Button>
        <SubmitButton
          loading={false}
          onSubmit={onEditFormSubmit}
          disabled={
            Object.keys(errors).length > 0 ||
            (departmentName === selectedDepartment?.name &&
              manager === selectedDepartment?.managerId) ||
            !manager ||
            !departmentName
          }
          isEdit={editMode}
        />
      </FormActions>
    </Form>
  );
}
