import { Alert, Portal } from '@mui/material';
import styled from 'styled-components';
import { LoadingPulse } from '../LoadingPulse';
import { useTranslation } from 'react-i18next';
import If from '../If';
import { useSelector } from 'react-redux';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { selectMainMenuSize } from 'app/slices/auth/selectors';

interface LoadingAlertProps {
  condition: boolean;
  message: string;
}
export const LoadingAlert = ({ condition, message }: LoadingAlertProps) => {
  const { t } = useTranslation();
  const zoomedIn = useSelector(selectIsZoomedIn);
  const mainMenuSize = useSelector(selectMainMenuSize);
  const isMinify = mainMenuSize === 'minify';

  return (
    <If condition={condition}>
      <Portal>
        <LoadingAlertContainer zoomedIn={!!zoomedIn} isMinify={isMinify}>
          <Alert variant="standard" severity="info">
            <AlertContent>
              <div>{t(message)}</div>
              <LoaderContainer>
                <LoadingPulse />
              </LoaderContainer>
            </AlertContent>
          </Alert>
        </LoadingAlertContainer>
      </Portal>
    </If>
  );
};

interface LoadingAlertContainerProps {
  zoomedIn: boolean;
  isMinify: boolean;
}

const LoadingAlertContainer = styled('div')<LoadingAlertContainerProps>`
  position: absolute;
  top: ${props =>
    !props.zoomedIn
      ? `calc(${props.theme.topBarHeight} + ${props.theme.tabsBarHeight} + 5px) !important`
      : `calc(${props.theme.tabsBarHeight} + 5px) !important`};
  left: ${props =>
    !props.zoomedIn
      ? props.isMinify
        ? `${props.theme.snackBarLeftPositionMin} !important`
        : `${props.theme.snackBarLeftPosition} !important`
      : '50%'};
  transform: translateX(-50%);
`;

const AlertContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LoaderContainer = styled.div`
  width: 50px;
  margin-left: 16px;
`;
