import React, { useState, useEffect } from 'react';

interface ShortcutProps {
  shortcut: {
    key1: string;
    key2?: string;
    action: {
      callback: (param?: any) => void;
      eventName: string;
    };
  };
}
/**
 * @deprecated this is replaced by bindShortcut which uses mousetrap library to handle keybinds which results
 * in cleaner code and use experience
 * you can import bindShortcut from app/pages/RoundTrips/components/KeyboardShortcuts/functions
 */
export const useCreateShortcut = ({ shortcut }: ShortcutProps) => {
  useEffect(() => {
    const handleEvent = e => {
      /* --------------------------------------- handle keyboard keys --------------------------------------- */
      // console.log("pressed: ", e.key);
      if (
        shortcut.action.eventName === 'keydown' ||
        shortcut.action.eventName === 'keyup'
      ) {
        const isMultipleKeys = shortcut.key2 !== undefined ? true : false;
        const nums = ['1', '2', '3', '4'];

        if (
          (e.metaKey || e.ctrlKey) &&
          shortcut.key2 == 'switch' &&
          nums.includes(e.key)
        ) {
          e.preventDefault();
          e.preventDefault();
          shortcut.action.callback(e.key);
        } else if (!isMultipleKeys && shortcut.key1 === e.key) {
          /* -------------------- single key -------------------- */
          switch (shortcut.key1) {
            case 'Escape':
              shortcut.action.callback(e);
              break;
            case 'Enter':
              shortcut.action.callback();
              break;
            case '0':
              shortcut.action.callback();
              break;
            case 'ArrowLeft':
              shortcut.action.callback();
              break;
            case 'ArrowRight':
              shortcut.action.callback();
              break;
            case 'ArrowUp':
              shortcut.action.callback();
              break;
            case 'ArrowDown':
              shortcut.action.callback();
              break;
            case '+':
              shortcut.action.callback();
              break;
            case '-':
              shortcut.action.callback();
              break;
            default:
              break;
          }
          /* ------------------ multiple keys ------------------- */
        } else if (isMultipleKeys && e.key === shortcut.key2) {
          /* ------------------ ctrl/cmd + key ------------------- */
          if (shortcut.key1 === 'ctrl' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            switch (shortcut.key2) {
              case 'o':
                e.preventDefault();
                shortcut.action.callback();
                break;

              case 'p':
                e.preventDefault();
                shortcut.action.callback();
                break;

              case 'r':
                e.preventDefault();
                shortcut.action.callback();
                break;

              case 's':
                e.preventDefault();
                shortcut.action.callback();
                break;

              default:
                break;
            }
            /* ---------------- shift + key ------------------- */
          } else if (shortcut.key1 === 'shift' && e.shiftKey) {
            return;
          }
          /* ------------------ ctrl/cmd + num ------------------- */
        } else if (
          isMultipleKeys &&
          shortcut.key1 === 'ctrl' &&
          (e.ctrlKey || e.metaKey)
        ) {
          const nums = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
          if (nums.includes(e.key)) {
            e.preventDefault();
            shortcut.action.callback(e.key);
          }
        }
        /* --------------------------------------- handle mouse click --------------------------------------- */
      } else if (e.type === 'mouseup') {
        /* --------------- shitf + left click ---------------- */
        if (e.button === 0 && e.shiftKey) {
          shortcut.action.callback();
          /* ---------------- ctrl/cmd + left click ---------------- */
        } else if (e.button === 0 && (e.ctrlKey || e.metaKey)) {
          shortcut.action.callback();
          /* ------------------- left click -------------------- */
        } else {
          shortcut.action.callback();
        }
      }
    };

    if (shortcut?.key1) {
      document.body.addEventListener(shortcut.action.eventName, handleEvent);
    }

    return () => {
      document.body.removeEventListener(shortcut.action.eventName, handleEvent);
    };
  }, [shortcut]);
};
