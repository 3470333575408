import { TableCell, TableRow } from '@mui/material';
import { A } from 'app/components/A';
import If from 'app/components/If';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Checkbox } from 'app/components/CustomCheckbox';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const RegularTableFunction = ({
  stableSort,
  rows,
  getComparator,
  order,
  orderBy,
  isSelected,
  handleClick,
  selected,
  setSelected,
  headCells,
  mapRowId = null,
  rowsDatas = [],
  handleEditTrigger = (obj: any) => {},
  ordoria = false,
  displayCheckbox = true,
}) => {
  const theme = useSelector(selectTheme);
  const navigate = useNavigate();

  const [isHoveringCheckBox, setIsHoveringCheckBox] = useState<string | null>(
    null,
  );

  const handleEditRow = (id: string) => {
    const obj = rowsDatas?.find((row: any) => row._id === id);
    handleEditTrigger(obj);
  };

  let tablesData: any;
  tablesData = rows.map((row, index) => {
    if (!row.data) return null;
    const isItemSelected = isSelected(row.data.id.value);
    const labelId = `enhanced-table-checkbox-${index}`;

    let highlightedRow = {};

    if (mapRowId === row.data.id.value) {
      highlightedRow = {
        backgroundColor: theme.lightBlueActive,
        borderTop: `2px solid ${theme.primaryActiveColor}`,
        borderBottom: `2px solid ${theme.primaryActiveColor}`,
      };
    }

    const stickyStyles = {
      position: 'sticky',
      left: 0,
      zIndex: 2,
    };

    const uuid = uuidv4();

    return (
      <RegularTableRow
        hover
        onClick={event => {
          if (!!displayCheckbox) {
            handleClick(event, row.data.id.value, selected, setSelected);
          }
        }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={uuid}
        selected={isItemSelected}
        sx={{ ...highlightedRow, display: 'table-row' }}
        // onMouseOver={() => setIsHoveringCheckBox(row?.data?.id?.value)}
        // onMouseOut={() => setIsHoveringCheckBox(null)}
      >
        <TableCell
          padding="checkbox"
          sx={{
            display: 'table-cell',
            alignItems: 'center',
            minWidth: 'auto',
            ...stickyStyles,
          }}
        >
          <If
            condition={!row?.data?.customCheck?.display || isItemSelected}
            otherwise={
              <CustomCheckedCheckboxContainer displayCheckbox={displayCheckbox}>
                <div>{row?.data?.customCheck?.value}</div>
                <div>
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    sx={{
                      height: 'fit-content',
                    }}
                  />
                </div>
              </CustomCheckedCheckboxContainer>
            }
          >
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
              sx={{
                height: 'fit-content',
                visibility: !!displayCheckbox ? 'block' : 'hidden',
              }}
            />
          </If>
        </TableCell>
        {headCells.map(headCell => {
          let value = <>{row?.data?.[headCell.id]?.value}</>;
          if (headCell.isEditTrigger) {
            value = (
              <A
                role="button"
                className="link"
                onClick={() => {
                  if (ordoria) navigate(`${row.data.id.value}/details`);
                  else handleEditRow(row.data.id.value);
                }}
              >
                {row?.data?.[headCell.id]?.value}
              </A>
            );
          }
          let styles = {};
          if (headCell.isSticky) {
            styles = {
              ...stickyStyles,
              left: headCell.stickyLeftPosition,
              right: headCell.stickyRightPosition,
            };
          }
          if (headCell.disableRow) return null;
          return headCell.disablePadding ? (
            <TableCell
              onClick={e => {
                e.stopPropagation();
              }}
              key={`${row?.data?.id?.value} - ${headCell?.id}`}
              padding="none"
              align="left"
              sx={{
                flex: 1,
                minWidth: headCell.minWidth || 0,
                maxWidth: headCell.maxWidth || 'unset',
                overflow: 'hidden',
                ...styles,
              }}
            >
              {value}
            </TableCell>
          ) : (
            <TableCell
              onClick={e => {
                e.stopPropagation();
              }}
              key={`${row?.data?.id?.value} - ${headCell?.id}`}
              align="left"
              sx={{
                flex: 1,
                minWidth: headCell.minWidth || 0,
                maxWidth: headCell.maxWidth || 'unset',
                overflow: 'hidden',
                paddingLeft: headCell.paddingLeft,
                paddingRight: headCell.paddingRight,
                ...styles,
              }}
            >
              {value}
            </TableCell>
          );
        })}
      </RegularTableRow>
    );
  });
  return tablesData;
};

const RegularTableRow = styled(TableRow)`
  height: 40px;
  overflow: hidden;
  & td {
    max-height: 40px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: ${props => props.theme.stickyTableCelTranslucent};
    white-space: nowrap !important;
    text-overflow: ellipsis !important;

    .MuiChip-label {
      font-size: 0.875rem;
    }
  }

  &:hover td {
    background-color: ${props => props.theme.grey} !important;
  }
`;

const CustomCheckedCheckboxContainer = styled.div<{
  displayCheckbox?: boolean;
}>`
  .MuiButtonBase-root {
    padding: 0;
  }
  & > div:nth-child(2) {
    display: none;
  }

  ${props =>
    props.displayCheckbox &&
    `
    &:hover {
      & > div:nth-child(1) {
        display: none;
      }
      & > div:nth-child(2) {
        display: block;
      }
    }
  `}
`;
