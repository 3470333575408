import api from './api';

export const appApi = api.injectEndpoints({
  endpoints: builder => ({
    getApplications: builder.query<any, string>({
      query: urlQuery => ({
        url: `/app/configuration${urlQuery}`,
      }),
      providesTags: ['Applications'],
    }),
  }),
});

export const { useGetApplicationsQuery } = appApi;

export default appApi;
