import * as React from 'react';
import styled from 'styled-components';
import { Parked } from 'app/components/RoundTrips/RoundTripData/statuses';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import { Icon } from 'app/components/Icon';
import { GasPump, UserCircle, Wrench } from '@phosphor-icons/react';
import If from 'app/components/If';
import { StopCount } from 'common/types/Roundtrip';
import { FarParkedCount } from 'app/pages/AddRoundTrips/components/FarParkedCount';

interface Props {
  roundtripStatus: string;
  stats?: any;
  tooltipDisabled?: boolean;
  stopCount: StopCount;
  farDeliveredOrdersCount?: number;
  minimal?: boolean;
}

export const Stops: React.FC<Props> = ({
  roundtripStatus,
  stats,
  tooltipDisabled,
  stopCount,
  farDeliveredOrdersCount = 0,
  minimal,
}) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  const delivered = React.useMemo(() => {
    const uneditableStatus = [
      'pending',
      'planned',
      'loaded',
      'on-way',
      'paused',
      'canceled',
    ];
    if (uneditableStatus.includes(roundtripStatus)) {
      return false;
    } else {
      return true;
    }
  }, [roundtripStatus]);

  const completedCount =
    stopCount?.order?.completed +
    stopCount?.fuel?.completed +
    stopCount?.visit?.completed +
    stopCount?.garage?.completed || 0;
  const totalCount =
    stopCount?.order?.total +
    stopCount?.fuel?.total +
    stopCount?.visit?.total +
    stopCount?.garage?.total || 0;

  return (
    <StopsWraper minimal={!!minimal}>
      <div className="stop_icon">
        {stats?.stops === 0 ? (
          <div className="no_stop">{t('no_stops')}</div>
        ) : (
          <>
            <If condition={!!stopCount?.order?.total}>
              <div className="count-section">
                <Icon
                  icon={<UserCircle />}
                  color={theme?.primaryActiveColor}
                  tooltip={t('order')}
                />
                <div className="stop-count">
                  {stopCount?.order?.completed}/{stopCount?.order?.total}
                </div>
                {farDeliveredOrdersCount > 0 && delivered && (
                  <div style={{ margin: '0 4px' }}>
                    <FarParkedCount count={farDeliveredOrdersCount} />
                  </div>
                )}
              </div>
            </If>
            <If condition={!!stopCount?.fuel?.total}>
              <div className="count-section">
                <Icon
                  icon={<GasPump />}
                  color={theme?.deepPurple}
                  tooltip={t('common.fuel')}
                />
                <div className="stop-count">
                  {stopCount?.fuel?.completed}/{stopCount?.fuel?.total}
                </div>
              </div>
            </If>

            <If condition={!!stopCount?.garage?.total}>
              <div className="count-section">
                <Icon
                  icon={<Wrench />}
                  color={theme?.teal300}
                  tooltip={t('common.maintenance')}
                />
                <div className="stop-count">
                  {stopCount?.garage?.completed}/{stopCount?.garage?.total}
                </div>
              </div>
            </If>
          </>
        )}
      </div>
      {!minimal && (
        <ManagedTooltip
          disabled={tooltipDisabled}
          title={
            roundtripStatus === Parked
              ? '100%'
              : completedCount
                ? Math.round((completedCount / totalCount) * 100) + '%'
                : '0%'
          }
        >
          <div>
            <div
              className={
                'progress ' + (roundtripStatus === Parked ? 'green' : 'blue')
              }
            >
              <div className="container_progress">
                <div
                  className="color"
                  style={{
                    background:
                      roundtripStatus === Parked
                        ? themes?.default?.greenStatus
                        : `linear-gradient(270deg, ${themes?.default?.summerSky} 0%, ${theme?.primaryActiveColor} 100%)`,
                    width:
                      roundtripStatus === Parked
                        ? '100%'
                        : completedCount
                          ? Math.round((completedCount / totalCount) * 100) + '%'
                          : '0%',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </ManagedTooltip>
      )}
    </StopsWraper>
  );
};

export const StopsWraper = styled.div<{ minimal }>`
  min-width: ${props => (props.minimal ? '' : '145px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  .no_stop {
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.01rem;
  }
  .stop_icon {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  progress {
    background-color: ${themes?.default?.veryLightGrey2};
    height: 4px;
  }
  .container_progress {
    width: 130px;
    height: 6px;
    border-radius: 100px;
    background: ${themes?.default?.veryLightGrey2};
  }
  .color {
    height: 6px;
    border-radius: 100px;
  }
  .count-section {
    display: flex;
    align-items: center;
    gap: 2px;
  }
  .stop-count {
    padding-top: 1px;
    color: ${props => props.theme.black60};
    font-size: 0.625rem;
  }
`;
