import { OrderStatuses } from 'app/pages/Orders/data/statuses';
import dayjs from 'dayjs';
import i18next from 'i18next';
import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
export const getOrderFilterCount = filters => {
  return getFilterCount(filters);
};

export const getFilterCount = filters => {
  const dateCount = filters?.begin && filters?.end ? 1 : 0;
  const quantityCount =
    filters?.quantity?.minQuantity || filters?.quantity?.maxQuantity ? 1 : 0;
  const totalTTCCount = filters?.minTotal || filters?.maxTotal ? 1 : 0;
  const productCount =
    filters?.lines?.minProducts || filters?.lines?.maxProducts ? 1 : 0;
  const costCount = filters?.cost?.min || filters?.cost?.max ? 1 : 0;
  const unitsCount = filters?.units?.min || filters?.units?.max ? 1 : 0;

  const fillingRateCount =
    filters?.fillingRate?.min || filters?.fillingRate?.max ? 1 : 0;
  const showArchivedCount = filters?.showArchived ? 1 : 0;

  const withDeletedCount = filters?.withDeleted ? 1 : 0;
  const isArchivedCount = filters?.isArchived ? 1 : 0;
  const filterCount = getCheckedFilterCount(filters);
  const createdAtCount = filters?.createdAt ? 1 : 0;
  const isLockedCount = filters?.isLocked ? 1 : 0;

  return (
    totalTTCCount +
    quantityCount +
    productCount +
    dateCount +
    fillingRateCount +
    unitsCount +
    withDeletedCount +
    costCount +
    filterCount +
    showArchivedCount +
    isArchivedCount +
    createdAtCount +
    isLockedCount
  );
};

export const getRtFilterCount = filters => {
  const dateCount = filters?.begin && filters?.end ? 1 : 0;
  const statusCount = filters?.status?.length;
  const weightCount =
    filters?.weight?.minWeight || filters?.weight?.maxWeight ? 1 : 0;
  const noVehicle = filters?.noVehicle ? 1 : 0;
  const noDriver = filters?.noDriver ? 1 : 0;
  const noRipper = filters?.noRipper ? 1 : 0;
  const noStops = filters?.noStops ? 1 : 0;

  return (
    statusCount +
    weightCount +
    dateCount +
    noVehicle +
    noDriver +
    noRipper +
    noStops
  );
};

export const getCallPlanFilterCount = filters => {
  const filterCount = getCheckedFilterCount(filters);
  const hasNote = filters?.hasNote ? 1 : 0;

  return filterCount + hasNote;
};

export const getRtOrderFilterCount = filters => {
  const assignedCount = filters?.assigned?.length;
  const priorityCount = filters?.priority?.length;
  const orderStatusCount = filters?.orderStatus?.length;
  const clientStatusCount = filters?.clientStatus?.length;
  const deliveryTypeCount = filters?.deliveryType?.length;
  const truckTypeCount = filters?.truckType?.length;

  return (
    assignedCount +
    priorityCount +
    orderStatusCount +
    clientStatusCount +
    deliveryTypeCount +
    truckTypeCount
  );
};

export const getOrderStatusesToShow = (orderStatusesToShow: string[]) => {
  return _.intersectionWith(
    OrderStatuses,
    orderStatusesToShow,
    (a, b) => a.status === b,
  );
};

export const getCheckedFilterCount = filters => {
  const filterCount = Object.keys(filters)?.reduce((count, key) => {
    if (Array.isArray(filters[key])) {
      return (
        count +
        filters[key]?.filter(item => typeof item.value === 'string')?.length
      );
    }

    return count;
  }, 0);

  return filterCount;
};

export const formatFilters = (filters: any) => {
  const formattedFilters: { key: string; value: string; label: string }[] = [];

  if (filters?.begin && filters?.end) {
    formattedFilters.push({
      key: 'date',
      value: `${filters?.begin} - ${filters?.end}`,
      label: `${dayjs(filters?.begin)?.format('DD/MM/YYYY')} - ${dayjs(
        filters?.end,
      )?.format('DD/MM/YYYY')}`,
    });
  }
  if (filters?.quantity?.minQuantity || filters?.quantity?.maxQuantity) {
    formattedFilters.push({
      key: 'quantity',
      value: `${filters?.quantity?.minQuantity} - ${filters?.quantity?.maxQuantity}`,
      label: `${i18next.t('common.quantity')} ${
        filters?.quantity?.minQuantity
      } - ${filters?.quantity?.maxQuantity}`,
    });
  }
  if (filters?.minTotal || filters?.maxTotal) {
    formattedFilters.push({
      key: 'totalTTC',
      value: `${filters?.minTotal} - ${filters?.maxTotal}`,
      label: `${i18next.t('total_ttc')} ${filters?.minTotal} - ${
        filters?.maxTotal
      }`,
    });
  }
  if (filters?.lines?.minProducts || filters?.lines?.maxProducts) {
    formattedFilters.push({
      key: 'lines',
      value: `${filters?.lines?.minProducts} - ${filters?.lines?.maxProducts}`,
      label: `${i18next.t('product_lines')} ${filters?.lines?.minProducts} - ${
        filters?.lines?.maxProducts
      }`,
    });
  }
  if (filters?.cost?.min || filters?.cost?.max) {
    formattedFilters.push({
      key: 'cost',
      value: `${filters?.cost?.min} - ${filters?.cost?.max}`,
      label: `${i18next.t('accessories.cost')} ${filters?.cost?.min} - ${
        filters?.cost?.max
      }`,
    });
  }
  if (filters?.fillingRate?.min || filters?.fillingRate?.max) {
    formattedFilters.push({
      key: 'fillingRate',
      value: `${filters?.fillingRate?.min} - ${filters?.fillingRate?.max}`,
      label: `${i18next.t('storage.filling_rate')} ${
        filters?.fillingRate?.min
      } - ${filters?.fillingRate?.max}`,
    });
  }
  if (filters?.units?.min || filters?.units?.max) {
    formattedFilters.push({
      key: 'units',
      value: `${filters?.units?.min} - ${filters?.units?.max}`,
      label: `${i18next.t('storage.units')} ${filters?.units?.min} - ${
        filters?.units?.max
      }`,
    });
  }
  if (filters?.showArchived) {
    formattedFilters.push({
      key: 'showArchived',
      value: filters?.showArchived,
      label: i18next.t('storage.location.show_archived'),
    });
  }
  if (filters?.withDeleted) {
    formattedFilters.push({
      key: 'withDeleted',
      value: filters?.withDeleted,
      label: i18next.t('orders.staus.deleted'),
    });
  }
  if (filters?.hasNote) {
    formattedFilters.push({
      key: 'hasNote',
      value: filters?.hasNote,
      label: i18next.t('callplan.filters.hasNote'),
    });
  }
  if (filters?.meMode) {
    formattedFilters.push({
      key: 'meMode',
      value: filters?.meMode,
      label: i18next.t('callplan.filters.meMode'),
    });
  }
  if (filters?.formChecked) {
    for (const [key, value] of Object.entries(filters.formChecked)) {
      if (value) {
        formattedFilters.push({
          key: key,
          value: filters?.formChecked[key],
          label: i18next.t(`callplan.customize.${key}`),
        });
      }
    }
  }

  if (filters?.type?.top1 && filters?.type?.top2) {
    Object.keys(filters.type).map(key => {
      if (filters.type[key]) {
        formattedFilters.push({
          key: 'type',
          value: filters.type[key],
          label: i18next.t(`report.filters.${filters.type[key]}`),
        });
      }
    });
  }

  if (filters?.isArchived) {
    formattedFilters.push({
      key: 'isArchived',
      value: filters?.isArchived,
      label: i18next.t('archived'),
    });
  }

  if (filters?.isLocked) {
    formattedFilters.push({
      key: 'isLocked',
      value: filters?.isLokced,
      label: i18next.t('disabled'),
    });
  }

  if (filters?.createdAt) {
    formattedFilters.push({
      key: 'createdAt',
      value: filters?.createdAt,
      label: `${i18next.t('common.createdAt')}: ${dayjs(
        filters?.createdAt,
      ).format('MMMM DD, YYYY')}`,
    });
  }

  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      value?.map(v => {
        formattedFilters.push({ key, value: v.value, label: v.label });
      });
    }
  }

  return formattedFilters;
};

export const handleRemoveFilter = (key: string, value: string, setFilters) => {
  switch (key) {
    case 'date':
      setFilters(prevState => ({
        ...prevState,
        begin: null,
        end: null,
      }));
      break;
    case 'totalTTC':
      setFilters(prevState => ({
        ...prevState,
        minTotal: '',
        maxTotal: '',
      }));
      break;
    case 'quantity':
      setFilters(prevState => ({
        ...prevState,
        quantity: { minQuantity: 0, maxQuantity: null },
      }));
      break;
    case 'lines':
      setFilters(prevState => ({
        ...prevState,
        lines: { minProducts: 0, maxProducts: null },
      }));
      break;
    case 'withDeleted':
      setFilters(prevState => ({
        ...prevState,
        withDeleted: false,
      }));
      break;
    case 'cost':
      setFilters(prevState => ({
        ...prevState,
        cost: { min: 0, max: null },
      }));
      break;
    case 'fillingRate':
      setFilters(prevState => ({
        ...prevState,
        fillingRate: { min: 0, max: null },
      }));
      break;

    case 'units':
      setFilters(prevState => ({
        ...prevState,
        units: { min: 0, max: null },
      }));
      break;

    case 'showArchived':
      setFilters(prevState => ({
        ...prevState,
        showArchived: false,
      }));
      break;

    case 'showMinimumToOrder':
      setFilters(prevState => ({
        ...prevState,
        formChecked: {
          ...prevState.formChecked,
          showMinimumToOrder: false,
        },
      }));
      break;
    case 'showBoxesAmount':
      setFilters(prevState => ({
        ...prevState,
        formChecked: {
          ...prevState.formChecked,
          showBoxesAmount: false,
        },
      }));
      break;
    case 'showWeightAmount':
      setFilters(prevState => ({
        ...prevState,
        formChecked: {
          ...prevState.formChecked,
          showWeightAmount: false,
        },
      }));
      break;
    case 'hasNote':
      setFilters(prevState => ({
        ...prevState,
        hasNote: false,
      }));
      break;
    case 'isArchived':
      setFilters(prevState => ({
        ...prevState,
        isArchived: false,
      }));
      break;
    case 'isLocked':
      setFilters(prevState => ({
        ...prevState,
        isLocked: false,
      }));
      break;
    case 'createdAt':
      setFilters(prevState => ({
        ...prevState,
        createdAt: null,
      }));
      break;
    default:
      setFilters(prevState => ({
        ...prevState,
        [key]: prevState[key]?.filter(item => item.value !== value),
      }));
      break;
  }
};

export const setFilterCheckedValues = (
  filters,
  setCheckedValues: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >,
) => {
  setCheckedValues({});

  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      value?.map(v => {
        setCheckedValues(prevState => ({
          ...prevState,
          [v.label]: true,
        }));
      });
    }
  }
};

export const handleCheckboxFilterChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  label: string,
  filterKey: string,
  checkedValues: Record<string, boolean>,
  setCheckedValues: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >,
  setFilters,
) => {
  const value = event.target.value;
  if (event.target.checked) {
    setFilters(prevState => ({
      ...prevState,
      [filterKey]: [...prevState[filterKey], { value, label }],
    }));
    setCheckedValues({ ...checkedValues, [label]: true });
  } else {
    setFilters(prevState => ({
      ...prevState,
      [filterKey]: prevState[filterKey].filter(item => item.value !== value),
    }));
    setCheckedValues({ ...checkedValues, [label]: false });
  }
};

export const getMostRecentItem = (items: any[], prop: string) => {
  let maxItem = null;

  for (const item of items) {
    if (!item) continue;

    if (
      (item?.[prop] && !maxItem?.[prop]) ||
      (maxItem && item?.[prop] && dayjs(item[prop]) > dayjs(maxItem[prop]))
    ) {
      maxItem = item;
    }
  }

  return maxItem;
};
export const handleSelectFilterChange = (
  filterKey: string,
  options: { value: string; label: string }[],
  setFilters,
) => {
  setFilters(prevState => ({
    ...prevState,
    [filterKey]: options,
  }));
};

export const formatKm = (distance: number): string => {
  if (!distance || distance > 10000000) {
    return '';
  }

  const d = parseInt((Math.round((distance || 0) * 100) / 100).toFixed(0));

  return Intl.NumberFormat('fr-FR', {}).format(d);
};

/**
 * @deprecated this function is deprecated and should not used, please use toggleHeadCellsValue instead
 */
export const toggleHeaderValue = (
  id: string,
  disableRow: boolean,
  canChangeView: boolean,
  setHeadCells: Dispatch<SetStateAction<any>>,
  hidden?: boolean,
) => {
  setHeadCells(prevHeads => {
    const newHeads = prevHeads?.map(head => {
      if (head?.id === id) {
        return { ...head, disableRow, canChangeView, hidden };
      }
      return head;
    });
    return newHeads;
  });
};

export const toggleHeadCellsValue = ({
  headCells,
  setHeadCells,
}: {
  headCells: any[];
  setHeadCells: Dispatch<SetStateAction<any>>;
}) => {
  setHeadCells(prevHeads => {
    const newHeads = prevHeads?.map(head => {
      const foundHead = headCells.find(headCell => headCell.id === head.id);
      if (foundHead) {
        return {
          ...head,
          ...foundHead,
        };
      } else {
        return head;
      }
    });
    return newHeads;
  });
};

export const stringAvatar = (name: string) => {
  if (name.indexOf(' ') === -1) {
    return `${name[0]}${name[1]}`;
  }

  const names = name.split(' ');

  return `${names[0]?.[0] || names[1]?.[0]}${names[1]?.[0] || names[2]?.[0]}`;
};

export const getAddressLabel = (location: {
  city: string;
  zipCode: string;
  shortAddress: string;
  name?: string;
}) => {
  if (!location) return '';
  const address = location.name
    ? location.name
    : `${location.zipCode ? location.zipCode + ', ' : ''}${
        location.city ? location.city + ', ' : ''
      }France${location.shortAddress ? ', ' + location.shortAddress : ''}`;

  return address;
};

export const getIsRoundtripOverLoaded = ({ vehicle, capacity }) => {
  const hasVehicle = !!vehicle;
  const currentCapacity = capacity || 0;
  const maxCapacity = vehicle?.capacity?.supportUnit || 0;

  return hasVehicle && currentCapacity > maxCapacity;
};

export const getRoundtripUserAndVehicleInfo = ({ agents, vehicle, role }) => {
  const user = agents?.find(data => data?._role?.key === role)?._user?.fullName;
  return { missingUser: !user, hasVehicle: !!vehicle };
};

export const validateInputNumberKeyDown = event => {
  if (event.key === 'e' || event.key === 'E') {
    event.preventDefault();
  }
};

export const validateInputNumberPaste = event => {
  const clipboardData = event.clipboardData.getData('Text');
  if (clipboardData.includes('e') || clipboardData.includes('E')) {
    event.preventDefault();
  }
};

export const haveSameValues = (obj1, obj2) => {
  const commonKeys = _.intersection(_.keys(obj1), _.keys(obj2));
  const values1 = _.pick(obj1, commonKeys);
  const values2 = _.pick(obj2, commonKeys);
  return _.isEqual(values1, values2);
};

export const downloadFile = (url: string, fileName: string) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error downloading the file:', error);
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.click();
    });
};

export const viewFile = (url: string) => {
  const link = document.createElement('a');
  link.href = `https://docs.google.com/viewer?url=${url}`;
  link.target = '_blank';
  link.click();
  link.remove();
};

export const getBusinessDaysCount = (start, end) => {
  let count = 0;
  let current = dayjs(start);

  while (current.isBefore(end) || current.isSame(end, 'day')) {
    const dayOfWeek = current.day();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // Exclude Sunday (0) and Saturday (6)
      count++;
    }
    current = current.add(1, 'day');
  }

  return count;
};
