import { useModal } from 'app/components/Dialog/hooks';
import { useGrid } from 'app/components/Grid/hooks';
import { useToaster } from 'hooks/useToaster';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import { useApiCalls } from '../hooks/useApiCalls';
import i18next from 'i18next';

interface ContextProps {
  drawerOpen: boolean;
  setDrawerOpen: StateSetter<boolean>;
  selectedRoleId: string;
  setSelectedRoleId: StateSetter<string>;
  onAddClick: () => void;
  onExportClick: () => void;
  handleDelete: (id: string) => void;
  handleEdit: (id: string) => void;
  handleCloseDrawer: () => void;
  handleBulkDelete: () => void;
  handleChangeStatus: (id: string, status: boolean) => void;
}

export const RoleContext = createContext<ContextProps | undefined>(undefined);

export const RoleProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { exportRows, rows: roles, selected, setSelected } = useGrid();
  const { openModal, closeModal, setActionDisabled } = useModal();
  const { deleteRole, bulkDelete, changeStatus, isSubmitting } = useApiCalls();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState('');

  const { t } = useTranslation();
  const toast = useToaster();

  useEffect(() => {
    setActionDisabled(!!isSubmitting);
  }, [isSubmitting, setActionDisabled]);

  const onAddClick = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const onExportClick = useCallback(() => {
    exportRows(``, `export_roles`);
  }, [exportRows]);

  const handleEdit = useCallback(
    (id: string) => {
      setSelectedRoleId(id);
      setDrawerOpen(true);
    },
    [setDrawerOpen, setSelectedRoleId],
  );

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedRoleId('');
  }, [setDrawerOpen, setSelectedRoleId]);

  const handleDelete = useCallback(
    (id: string) => {
      const name = roles?.find(role => role?._id === id)?.localeName?.[
        i18next.language || 'en'
      ];
      openModal({
        title: t(`role.delete`, {
          count: 1,
        }),
        content: t(`role.delete_confirmation`, {
          name: name,
          count: 1,
        }),
        action: {
          actionText: t('delete'),
          actionCallback: async () => {
            try {
              await deleteRole(id);
              toast(
                5000,
                'success',
                t('roles.deleted', {
                  count: selected?.length,
                }),
              );
            } catch (e) {}
            closeModal();
          },
          buttonProps: {
            sx: { background: themes?.default?.Cinnabar },
            color: 'error',
          },
        },
        cancel: true,
      });
    },
    [closeModal, openModal, roles, t, toast, deleteRole, selected?.length],
  );

  const handleBulkDelete = useCallback(() => {
    const name =
      roles?.find(role => role?._id === selected?.[0])?.localeName?.[
        i18next.language || 'en'
      ] || '';
    openModal({
      title: t(`role.delete`, {
        count: selected?.length,
      }),
      content: t(`role.delete_confirmation`, {
        count: selected?.length,
        name,
      }),
      action: {
        actionText: t('delete'),
        actionCallback: async () => {
          try {
            const resp: { deletedRole: number; notdeletedRole: number } =
              await bulkDelete();
            setSelected([]);
            if (!resp?.notdeletedRole && !!resp.deletedRole) {
              toast(
                5000,
                'success',
                t('roles.deleted', { count: selected?.length }),
              );
            } else if (!!resp?.deletedRole) {
              toast(
                10000,
                'warning',
                t('roles.bulk-deleted', {
                  notDeleted: resp?.notdeletedRole,
                  deleted: resp?.deletedRole,
                }),
              );
            } else {
              toast(10000, 'error', t('roles.bulk-deleted-failed'));
            }
          } catch (e) {}
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
          disabled: isSubmitting,
        },
      },
      cancel: true,
    });
  }, [
    closeModal,
    openModal,
    t,
    toast,
    bulkDelete,
    selected,
    setSelected,
    isSubmitting,
    roles,
  ]);

  const handleChangeStatus = (id: string, status: boolean) => {
    if (status) {
      handleActivate(id);
    } else {
      handleDeactivate(id);
    }
  };

  const handleActivate = useCallback(
    (id: string) => {
      const name = roles?.find(role => role?._id === id)?.localeName?.[
        i18next.language || 'en'
      ];
      openModal({
        title: t(`role.activate`, { name }),
        content: t(`role.activate_confirmation`, {
          name: name,
        }),
        action: {
          actionText: t('activate'),
          actionCallback: async () => {
            try {
              await changeStatus(id, true);
              toast(5000, 'success', t('roles.activated'));
            } catch (e) {}
            closeModal();
          },
          buttonProps: {
            color: 'success',
          },
        },
        cancel: true,
      });
    },
    [closeModal, openModal, roles, t, toast, changeStatus],
  );

  const handleDeactivate = useCallback(
    (id: string) => {
      const name = roles?.find(role => role?._id === id)?.localeName?.[
        i18next.language || 'en'
      ];
      openModal({
        title: t(`role.deactivate`, { name }),
        content: t(`role.deactivate_confirmation`, {
          name: name,
        }),
        action: {
          actionText: t('deactivate'),
          actionCallback: async () => {
            try {
              await changeStatus(id, false);
              toast(5000, 'success', t('roles.deactivated'));
            } catch (e) {}
            closeModal();
          },
          buttonProps: {
            sx: { background: themes?.default?.Cinnabar },
            color: 'error',
          },
        },
        cancel: true,
      });
    },
    [closeModal, openModal, roles, t, toast, changeStatus],
  );

  return (
    <>
      <RoleContext.Provider
        value={{
          drawerOpen,
          setDrawerOpen,
          selectedRoleId,
          setSelectedRoleId,
          onAddClick,
          onExportClick,
          handleDelete,
          handleEdit,
          handleCloseDrawer,
          handleBulkDelete,
          handleChangeStatus,
        }}
      >
        {children}
      </RoleContext.Provider>
    </>
  );
};
