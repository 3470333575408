import i18next from 'i18next';
import _ from 'lodash';
import { BranchStatuses } from '../data/BranchStatuses';
import moment from 'moment';

export const haveSameValues = (obj1, obj2) => {
  const commonKeys = _.intersection(_.keys(obj1), _.keys(obj2));
  const values1 = _.pick(obj1, commonKeys);
  const values2 = _.pick(obj2, commonKeys);
  return _.isEqual(values1, values2);
};
export const downloadFile = (url: string, fileName: string) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error downloading the file:', error);
    });
};
export const getLocaleName = (roles, id) => {
  return roles?.find(role => role?._id === id)?.localeName?.[
    i18next.language || 'en'
  ];
};
export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const omitEmptyValues = (obj, exceptions: any = []) => {
  return _.omitBy(obj, (value, key) => {
    if (exceptions.includes(key)) {
      return false;
    }

    if (_.isArray(value)) {
      return value.every(v => v === '');
    }

    return (
      _.isNil(value) ||
      value === '' ||
      value?.name === '' ||
      (Array.isArray(value) && value.length === 0) ||
      value === 'Invalid date'
    );
  });
};
export const capitalizeFirstLetterOfFirstThreeInEachWord = (
  str: string | null,
): string => {
  if (!str) return '';

  const words = str.split(' ');
  const transformedWords = words.map(word => {
    if (word.length < 3) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      const firstThree = word.slice(0, 3).toLowerCase();
      return firstThree.charAt(0).toUpperCase() + firstThree.slice(1);
    }
  });
  return transformedWords.join(' ');
};

export const getBranchStatusesToShow = (branchStatusesToShow: string[]) => {
  return _.intersectionWith(
    BranchStatuses,
    branchStatusesToShow,
    (a: any, b) => a.status === b,
  );
};

export const formatTimeSlotFrom = (timeSlot: string | undefined) => {
  return moment()
    .set({
      hour: moment(timeSlot ?? '08:00', 'HH:mm').hour(),
      minute: moment(timeSlot ?? '00:00', 'HH:mm').minute(),
    })
    .format('YYYY-MM-DD HH:mm');
};

export const formatTimeSlotTo = (timeSlot: string | undefined) => {
  return moment()
    .set({
      hour: moment(timeSlot ?? '17:00', 'HH:mm').hour(),
      minute: moment(timeSlot ?? '00:00', 'HH:mm').minute(),
    })
    .format('YYYY-MM-DD HH:mm');
};

export const formatTime = time => moment(time.$d).format('HH:mm');
