import React, { useCallback, useEffect, useState, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectConfiguration } from 'common/store/organization/selectors';
import If from 'app/components/If';
import { TableCell } from '@material-ui/core';

interface SupportSelectorProps {
  value?: any;
  supportUnits?: any;
  supportArrayCopy: any;
  setSupportArrayCopy: any;
  canEdit: boolean;
  AIAEstColVisible?: boolean;
  estimatedColVisible?: boolean;
  deliveredColVisible?: boolean;
  collectedColVisible?: boolean;
  loadedColVisible?: boolean;
  unloadedColVisible?: boolean;
  loadingUpdate?: boolean;
  setLoadingUpdate?: any;
}

function SupportSelector({
  value,
  supportUnits,
  supportArrayCopy,
  setSupportArrayCopy,
  canEdit,
  AIAEstColVisible,
  estimatedColVisible,
  deliveredColVisible,
  collectedColVisible,
  loadedColVisible,
  unloadedColVisible,
  loadingUpdate,
  setLoadingUpdate,
}: SupportSelectorProps) {
  const [supportValue, setSupportValue] = useState(value?.quantity || 0);

  useEffect(() => {
    const foundIndex = supportArrayCopy?.findIndex(
      support => support?._supportUnit?._id === value?._supportUnit?._id,
    );
    const newSupportArrayCopy = [...supportArrayCopy];

    if (foundIndex !== -1) {
      newSupportArrayCopy[foundIndex] = {
        ...newSupportArrayCopy[foundIndex],
        quantity: supportValue,
      };
      setSupportArrayCopy(newSupportArrayCopy);
    }
  }, [supportValue]);

  const handleChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.value) {
      setSupportValue(0);
      return;
    }
    setLoadingUpdate && setLoadingUpdate(true);
    let newValue = parseInt(event.target.value);

    if (isNaN(newValue)) {
      newValue = 0;
    } else if (newValue === supportValue) {
      setLoadingUpdate(false);
      return;
    } else if (newValue < 0) {
      newValue = 0;
    }
    setSupportValue(newValue);
  };

  const configurationSelector = useSelector(selectConfiguration);
  const supportUnitsList = configurationSelector?.supportUnitsList;

  const getListForOption = useCallback(
    (optionId: string) => {
      return (
        supportUnitsList.filter(
          obj1 =>
            !supportArrayCopy.some(
              obj2 => obj1._id === obj2._supportUnit?._id,
            ) || obj1._id === optionId,
        ) || []
      );
    },
    [supportUnitsList, supportArrayCopy],
  );

  const configurationProps = {
    getOptionLabel: (option: any) => {
      return option?.name || '';
    },
  };

  const collectedSupportUnits = useMemo(() => {
    const result = supportUnitsList?.map(support => {
      const collectedSupport = supportUnits?.collected?.find(
        su => su?._supportUnit?._id === support._id,
      );
      if (collectedSupport) {
        return collectedSupport;
      }
      return {
        _supportUnit: support,
        quantity: 0,
      };
    });
    return result?.filter(
      unit => unit?._supportUnit?._id === value?._supportUnit?._id,
    )[0];
  }, [JSON.stringify(supportUnits?.collected), supportUnitsList]);

  const deliveredSupportUnits = useMemo(() => {
    const result = supportUnitsList?.map(support => {
      const deliveredSupport = supportUnits?.delivered?.find(
        su => su?._supportUnit?._id === support._id,
      );
      if (deliveredSupport) {
        return deliveredSupport;
      }
      return {
        _supportUnit: support,
        quantity: 0,
      };
    });
    return result?.filter(
      unit => unit?._supportUnit?._id === value?._supportUnit?._id,
    )[0];
  }, [JSON.stringify(supportUnits?.delivered), supportUnitsList]);

  const loadedSupportUnits = useMemo(() => {
    const result = supportUnitsList?.map(support => {
      const loadedSupport = supportUnits?.loaded?.find(
        su => su?._supportUnit?._id === support._id,
      );
      if (loadedSupport) {
        return loadedSupport;
      }
      return {
        _supportUnit: support,
        quantity: 0,
      };
    });
    return result?.filter(
      unit => unit?._supportUnit?._id === value?._supportUnit?._id,
    )[0];
  }, [JSON.stringify(supportUnits?.loaded), supportUnitsList]);

  const unloadedSupportUnits = useMemo(() => {
    const result = supportUnitsList?.map(support => {
      const unloadedSupport = supportUnits?.unloaded?.find(
        su => su?._supportUnit === support._id,
      );
      if (unloadedSupport) {
        return unloadedSupport;
      }
      return {
        _supportUnit: support,
        quantity: 0,
      };
    });
    return result?.filter(
      unit => unit?._supportUnit === value?._supportUnit?._id,
    )[0];
  }, [JSON.stringify(supportUnits?.unloaded), supportUnitsList]);

  return (
    <If
      condition={canEdit}
      otherwise={
        <>
          <TableCell>
            <p style={{ marginRight: 8 }} className="title">
              {value?._supportUnit?.name || ''}
            </p>
          </TableCell>
          {AIAEstColVisible && (
            <TableCell>
              <p className="value">{value?.AIAEst?.quantity || '-'}</p>
            </TableCell>
          )}
          {estimatedColVisible && (
            <TableCell>
              <p className="value">{value?.quantity || '-'}</p>
            </TableCell>
          )}
          {loadedColVisible && (
            <TableCell>
              <p className="value">
                {loadedSupportUnits?.quantity === 0
                  ? '-'
                  : loadedSupportUnits?.quantity}
              </p>
            </TableCell>
          )}
          {deliveredColVisible && (
            <TableCell>
              <p className="value">
                {deliveredSupportUnits?.quantity === 0
                  ? '-'
                  : deliveredSupportUnits?.quantity}
              </p>
            </TableCell>
          )}
          {collectedColVisible && (
            <TableCell>
              <p className="value">
                {collectedSupportUnits?.quantity === 0
                  ? '-'
                  : collectedSupportUnits?.quantity}
              </p>
            </TableCell>
          )}
          {unloadedColVisible && (
            <TableCell>
              <p className="value">
                {unloadedSupportUnits?.quantity === 0
                  ? '-'
                  : unloadedSupportUnits?.quantity}
              </p>
            </TableCell>
          )}
        </>
      }
    >
      <TableCell>
        <p className="title">{value?._supportUnit?.name || ''}</p>
      </TableCell>
      {AIAEstColVisible && (
        <TableCell>
          <p className="value">{value?.AIAEst?.quantity || '-'}</p>
        </TableCell>
      )}
      <TableCell
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 30,
        }}
      >
        <TextField
          defaultValue={value?.quantity ?? 0}
          onChange={handleChangeTextField}
          disabled={loadingUpdate}
          inputProps={{
            min: 0,
            style: { textAlign: 'center', padding: ' 2px 1px' },
          }}
          name="numberformat"
          id="formatted-numberformat-input"
          variant="outlined"
          sx={{
            width: '20px',
            textAlign: 'center',
            margin: '0 !important',
            '& .MuiFormControl-root': {
              margin: '0 !important',
            },
          }}
        />
      </TableCell>
      {loadedColVisible && (
        <TableCell>
          <p className="value">
            {loadedSupportUnits?.quantity === 0
              ? '-'
              : loadedSupportUnits?.quantity}
          </p>
        </TableCell>
      )}
      {deliveredColVisible && (
        <TableCell>
          <p className="value">
            {deliveredSupportUnits?.quantity === 0
              ? '-'
              : deliveredSupportUnits?.quantity}
          </p>
        </TableCell>
      )}
      {collectedColVisible && (
        <TableCell>
          <p className="value">
            {collectedSupportUnits?.quantity === 0
              ? '-'
              : collectedSupportUnits?.quantity}
          </p>
        </TableCell>
      )}
    </If>
  );
}

export default SupportSelector;

export const Dropdown = styled.div`
  align-items: center;
  justify-content: space-between;
  .MuiButtonBase-root {
    padding-top: 1px;
  }
  width: 220px;
  display: flex;
  gap: 30px;
`;
