import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

interface CustomDatePickerProps {
  width?: string;
}

export const CustomDatePicker = styled.div<CustomDatePickerProps>(props => ({
  flex: 1,
  '& .custom-date-picker': {
    width: props.width || '200px',
    marginBottom: 'unset !important',
    '& .MuiInputBase-root': {
      height: 24,
      width: '200px',
      borderRadius: '4px',
      '&:before': {
        borderBottom: 'unset !important',
      },
      '&:after': {
        borderBottom: 'unset !important',
      },
    },
    '& input': {
      height: '30px',
      width: '200px',
      fontSize: '0.75rem',
      paddingTop: '2px',
      paddingBottom: '0',
    },
    '& svg': {
      cursor: 'pointer',
    },
    '& .MuiFormControl-root': {
      flex: 1,
    },
  },
}));
