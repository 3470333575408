import { colorRoundtripStatus } from 'app/components/RoundTrips/RoundTripData/StatusColors';
import {
  Driver,
  RoundtripStatus,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { PopoverStatus } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { useStatusActions } from 'app/pages/AddRoundTrips/data/RoundTripData/components/hook/useStatusActions';
import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ManagedTooltip } from './ManagedTooltip';
import { useRoundtripUpdateStatusMutation } from 'common/services/roundtripApi';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';
import { IconButton, Stack } from '@mui/material';
import { RocketLaunch, SpinnerGap } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { delayTime } from 'common/utils/Roundtrips/startAt';

interface Props {
  roundtrip: any;
  functionRoundTripStatus?: any;
  notEdit?: boolean;
  tooltipDisabled?: boolean;
  onClickRoundtrip?: Function;
  minimal?: boolean;
  expanded?: boolean;
  drawer?: boolean;
  details?: boolean;
  truckStatusIcon?: JSX.Element;
}

export const StatusPopover: React.FC<Props> = ({
  roundtrip,
  functionRoundTripStatus,
  notEdit = false,
  tooltipDisabled,
  onClickRoundtrip,
  minimal = false,
  expanded = false,
  drawer = false,
  details = false,
  truckStatusIcon = <></>,
  ...props
}) => {
  const { t } = useTranslation();
  const [roundTripStatus] = useRoundtripUpdateStatusMutation();

  const [status, setStatus] = useState<any[]>([]);
  const handleClickRoundtrip = () => {
    if (typeof onClickRoundtrip === 'function') {
      onClickRoundtrip(roundtrip._id);
    }
  };
  useStatusActions(
    setStatus,
    roundtrip,
    functionRoundTripStatus,
    roundTripStatus,
  );
  const isPulsing = PULSING_STATUSES.includes(roundtrip.status);
  function dateToLocalISO(date) {
    const off = date.getTimezoneOffset();
    return new Date(date.getTime() - off * 60 * 1000).toISOString();
  }

  const [displayTime, setDisplayTime] = useState('');

  const startState: { started: boolean; delayed: boolean } | null =
    useMemo(() => {
      if (roundtrip && roundtrip?.startAt) {
        const rtStartedAt = new Date(roundtrip.startAt);
        const timeString = rtStartedAt.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        });
        setDisplayTime(timeString);
        if (
          new Date(roundtrip.defaultStartAt).getTime() <
          new Date(dateToLocalISO(new Date())).getTime() -
            delayTime(roundtrip) * 60 * 1000
        ) {
          return { started: true, delayed: true };
        } else {
          return { started: true, delayed: false };
        }
      } else {
        if (
          new Date(roundtrip.defaultStartAt).getTime() <
          new Date(dateToLocalISO(new Date())).getTime() -
            delayTime(roundtrip) * 60 * 1000
        ) {
          return { started: false, delayed: true };
        } else {
          return { started: false, delayed: false };
        }
      }
    }, [roundtrip]);

  return (
    <Stack
      sx={{ width: '100%' }}
      direction="row"
      alignItems="center"
      gap={!!minimal ? 0 : '8px'}
    >
      <Wrapper
        minimal={!!minimal}
        expanded={!!expanded}
        drawer={!!drawer}
        details={!!details}
      >
        <RoundtripTitle className="rtCode">
          <span>
            {notEdit ? (
              <ManagedTooltip
                disabled={tooltipDisabled}
                title={t(`roundtrips.status.${roundtrip.status}`)}
              >
                <IconButton
                  sx={{
                    padding: '4px',
                  }}
                >
                  {isPulsing ? (
                    <PulsingIndicator
                      status={roundtrip.status}
                      baseboxShadow={PULSING_COLORS[roundtrip.status].boxShadow}
                      animationBoxShadow={
                        PULSING_COLORS[roundtrip.status].animationBoxShadow
                      }
                    />
                  ) : (
                    <StatusIndicator status={roundtrip.status} />
                  )}
                </IconButton>
              </ManagedTooltip>
            ) : (
              <PopoverStatus
                status={roundtrip.status}
                id={roundtrip.id}
                handleAction={async (e, action, id) => {}}
                condition1={!roundtrip._vehicle?.model}
                condition2={
                  roundtrip?.agents?.find(data => data?._role?.key === Driver)
                    ?._user
                }
                tooltipDisabled={tooltipDisabled}
                actions={status}
              />
            )}
          </span>
          <If
            condition={!!minimal}
            otherwise={
              <RoundtripCode onClick={handleClickRoundtrip}>
                {roundtrip.code}~{roundtrip.internalCode}
              </RoundtripCode>
            }
          >
            <MinimalCodeWrapper details={details}>
              {roundtrip.code}
            </MinimalCodeWrapper>
          </If>
        </RoundtripTitle>
        {(!minimal || drawer) && (
          <RoundtripStatusContainer narrow={!!drawer} details={!!details}>
            <StatusIconWrapper>
              {startState?.started && !startState?.delayed ? (
                <ManagedTooltip
                  title={t('roundtrip.started', { time: displayTime })}
                >
                  <RocketLaunch
                    size={12}
                    color={themes?.default?.fruitSaladGreen}
                  />
                </ManagedTooltip>
              ) : startState?.started && startState?.delayed ? (
                <ManagedTooltip
                  title={t('roundtrip.started.delayed', { time: displayTime })}
                >
                  <RocketLaunch size={12} color={themes?.default?.redA700} />
                </ManagedTooltip>
              ) : !startState?.started && !startState?.delayed ? (
                <ManagedTooltip title={t('roundtrip.not_started')}>
                  <SpinnerGap size={12} color={themes?.default?.iconColor} />
                </ManagedTooltip>
              ) : !startState?.started && startState?.delayed ? (
                <ManagedTooltip title={t('roundtrip.not_started.delayed')}>
                  <SpinnerGap size={12} color={themes?.default?.redA700} />
                </ManagedTooltip>
              ) : null}
            </StatusIconWrapper>
            <RoundtripTime>
              {startState?.started
                ? displayTime
                : t('not_started').toLowerCase()}
            </RoundtripTime>
            {truckStatusIcon}
          </RoundtripStatusContainer>
        )}
      </Wrapper>
    </Stack>
  );
};

export const FlexDiv = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const Wrapper = styled.div<{
  minimal: boolean;
  expanded: boolean;
  drawer: boolean;
  details?: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: ${props =>
    props.details
      ? 'column'
      : props.expanded || props.drawer
        ? 'row'
        : 'column'};
  justify-content: ${props =>
    props.expanded || props.drawer ? 'space-between' : ''};
`;

const RoundtripTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const RoundtripStatusContainer = styled.div<{
  narrow: boolean;
  details?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: ${props => (props.details ? '4px' : '21.8px')};
`;

const StatusIconWrapper = styled.span`
  height: 12px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RoundtripTime = styled.span`
  font-size: 10px;
  line-height: 11.72px;
  color: ${themes?.default?.nobel};
`;

interface PulsingProps {
  baseboxShadow: string;
  animationBoxShadow: string;
}

export const StatusIndicator = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50px;
  background: ${(props: { status }) => colorRoundtripStatus(props.status)};
`;

export const PULSING_COLORS = {
  loading: {
    boxShadow: 'rgba(41, 182, 246, 0.5)',
    animationBoxShadow: 'rgba(41, 182, 246, 0.2)',
  },
  unloading: {
    boxShadow: 'rgba(41, 182, 246, 0.5)',
    animationBoxShadow: 'rgba(41, 182, 246, 0.2)',
  },
};

export const PULSING_STATUSES = [
  RoundtripStatus.Loading,
  RoundtripStatus.Unloading,
];

export const PulsingIndicator = styled(StatusIndicator)<PulsingProps>`
  box-shadow: 0 0 0 0 ${props => props.baseboxShadow};
  animation: pulse 1.5s infinite;
  cursor: pointer;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${props => props.animationBoxShadow};
    }
    50% {
      box-shadow: 0 0 0 5px ${props => props.animationBoxShadow};
    }
    100% {
      box-shadow: 0 0 0 0 ${props => props.animationBoxShadow};
    }
  }
`;

export const RoundtripCode = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;

const MinimalCodeWrapper = styled.div<{ details?: boolean }>`
  padding: ${props => (props.details ? '0' : '4px 0 4px 4px')};
`;
