interface HeadCell {
  id: string;
  label: string;
  disablePadding: boolean;
  numeric: boolean;
  disableSort?: boolean;
  disableRow?: boolean;
  canChangeView?: boolean;
  filter: boolean;
  key: string | null;
  sortField?: string;
  sortValue?: string;
}

export const warehousesHeadCellsConst: HeadCell[] = [
  {
    id: 'name',
    label: 'warehouse.heads.name',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'warehouse.heads.name',
    sortValue: 'name',
  },
  {
    id: 'initials',
    label: 'warehouse.heads.initials',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'warehouse.heads.initials',
    sortValue: 'shortName',
  },
  {
    id: 'location',
    label: 'warehouse.heads.location',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'warehouse.heads.location',
    sortValue: 'location',
  },
  {
    id: 'capacity',
    label: 'warehouse.heads.capacity',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
  },
  {
    id: 'manager',
    label: 'warehouse.heads.manager',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
  },
  {
    id: 'contact',
    label: 'warehouse.heads.contact',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    sortField: 'warehouse.heads.contact',
    sortValue: 'phone',
  },
  {
    id: 'operatingHours',
    label: 'warehouse.heads.hours',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    sortField: 'warehouse.heads.hours',
    sortValue: 'hours',
  },
  {
    id: 'status',
    label: 'warehouse.heads.status',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
  },
];
