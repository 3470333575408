import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SetStateAction, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';
import { FileMagnifyingGlass } from '@phosphor-icons/react';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../../styles/theme/slice/selectors';
interface TableDocProps {
  treated: boolean;
  data: any;
  setDoc: SetStateAction<any>;
  setDocCgv: SetStateAction<any>;
  handleVerifyDocument: Function;
  handleOpenRefuseModal: Function;
}

export function TableDoc({
  treated,
  data,
  setDoc,
  setDocCgv,
  handleVerifyDocument,
  handleOpenRefuseModal,
}: TableDocProps) {
  const [open, setOpen] = useState<boolean[]>(
    new Array(data?.length).fill(false),
  );
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  return (
    <>
      <Grid
        sx={{
          m: '10px 20px',
        }}
      >
        <TableContainer>
          <Table
            size="small"
            sx={{
              backgroundColor: 'white',
            }}
          >
            <TableHead
              sx={{
                backgroundColor: '#F4F4F4',
                height: '10px',
              }}
            >
              <TableRow>
                <TableCell rowSpan={1} />
                <TableCell align="left">
                  {t('checkDocument.clientName').toUpperCase()}
                </TableCell>
                <TableCell align="left">
                  {t('checkDocument.clientCode').toUpperCase()}
                </TableCell>
                <TableCell>INPI</TableCell>
                <TableCell>COMMERCIAL</TableCell>
                <TableCell
                  sx={{
                    width: '40%',
                  }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.docs?.map((doc, index) => {
                const hasSepa = doc.documents.some(
                  d => d.type === 'sepa' && !d.isVerified,
                );

                const sepaDocument = doc.documents.find(
                  d => d.type === 'sepa' && !d.isVerified,
                );

                return (
                  <>
                    <TableRow
                      key={index}
                      onClick={() => {
                        const updatedOpen = [...open];
                        updatedOpen[index] = !updatedOpen[index];
                        setOpen(updatedOpen);
                      }}
                      sx={{
                        ':hover': {
                          backgroundColor: '#F8F8F8',
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <TableCell rowSpan={1}>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            const updatedOpen = [...open];
                            updatedOpen[index] = !updatedOpen[index];
                            setOpen(updatedOpen);
                          }}
                        >
                          {open[index] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            display: 'inline',
                            ':hover': {
                              textDecoration: 'underline',
                              color: '#2A59FF',
                            },
                          }}
                        >
                          {doc?.publicName}
                        </Typography>
                        {sepaDocument && (
                          <Tooltip
                            title={
                              sepaDocument.isRevoked
                                ? t('checkDocument.sepaRevoked')
                                : t('checkDocument.hasSepa')
                            }
                          >
                            <WarningIcon
                              sx={{
                                color: sepaDocument.isRevoked
                                  ? theme.red
                                  : theme.orange600,
                                marginTop: '-2px',
                                marginLeft: '4px',
                              }}
                            />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          color={!treated ? 'warning' : 'primary'}
                          label={doc?.code}
                          sx={{
                            width: '80px',
                            fontWeight: '500',
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            window.open(
                              `https://annuaire-entreprises.data.gouv.fr/etablissement/${doc.legalId}`,
                            );
                          }}
                        >
                          <FileMagnifyingGlass />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Typography>{doc?._salesman?.fullName}</Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                        sx={{
                          ':hover': {
                            backgroundColor: '#F8F8F8',
                            cursor: 'pointer',
                          },
                        }}
                      >
                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                          <Box
                            sx={{ margin: 1, marginTop: 2, marginBottom: 3 }}
                          >
                            <Table
                              size="small"
                              aria-label="purchases"
                              sx={{
                                width: '95%',
                                margin: '0 auto',
                                mt: 3,
                              }}
                            >
                              <TableHead>
                                <TableRow
                                  sx={{
                                    backgroundColor: '#F4F4F4',
                                  }}
                                >
                                  <TableCell>
                                    {t('warehouse.heads.name').toUpperCase()}
                                  </TableCell>
                                  <If condition={!treated}>
                                    <TableCell align="center">
                                      {t('endTrip.action').toUpperCase()}
                                    </TableCell>
                                  </If>
                                  <TableCell>
                                    {t('checkDocument.submitted').toUpperCase()}
                                  </TableCell>
                                  <TableCell>
                                    {t('checkDocument.treated').toUpperCase()}
                                  </TableCell>
                                  <TableCell>
                                    {t('common.labels.status').toUpperCase()}
                                  </TableCell>
                                  <TableCell>
                                    {t('checkDoc.refused_reason').toUpperCase()}
                                  </TableCell>
                                  <TableCell>
                                    {t('checkDocument.submittedBy')} /{' '}
                                    {t('checkDocument.validated')}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {doc.documents
                                  .slice()
                                  .sort((a, b) => {
                                    const order = [
                                      'kbis',
                                      'cni',
                                      'sepa',
                                      'rib',
                                    ];
                                    return (
                                      order.indexOf(a.type) -
                                      order.indexOf(b.type)
                                    );
                                  })
                                  .map(document => (
                                    <TableRow key={document.id}>
                                      <TableCell>
                                        {document.type === 'sepa' && (
                                          <Button
                                            variant="outlined"
                                            sx={{ marginRight: 5 }}
                                            onClick={() => setDoc(document)}
                                          >
                                            SEPA
                                          </Button>
                                        )}
                                        {document.type === 'rib' && (
                                          <Button
                                            variant="outlined"
                                            sx={{ marginRight: 5 }}
                                            onClick={() => setDoc(document)}
                                          >
                                            RIB
                                          </Button>
                                        )}
                                        {document.type === 'kbis' && (
                                          <Button
                                            variant="outlined"
                                            sx={{ marginRight: 5 }}
                                            onClick={() => setDoc(document)}
                                          >
                                            KBIS
                                          </Button>
                                        )}
                                        {document.type === 'cni' && (
                                          <Button
                                            variant="outlined"
                                            sx={{ marginRight: 5 }}
                                            onClick={() => setDoc(document)}
                                          >
                                            ID
                                          </Button>
                                        )}
                                      </TableCell>
                                      <If condition={!treated}>
                                        <TableCell
                                          sx={{
                                            p: 0,
                                          }}
                                        >
                                          <Button
                                            color="success"
                                            sx={{ pt: 0 }}
                                            onClick={() =>
                                              handleVerifyDocument(
                                                doc._id,
                                                document._id,
                                                'accept',
                                              )
                                            }
                                          >
                                            <DoneIcon />
                                          </Button>
                                          <Button
                                            color="error"
                                            sx={{ pt: 0 }}
                                            onClick={() =>
                                              handleOpenRefuseModal(
                                                doc._id,
                                                document._id,
                                              )
                                            }
                                          >
                                            <CloseIcon />
                                          </Button>
                                        </TableCell>
                                      </If>
                                      <TableCell>
                                        <Typography
                                          variant="body1"
                                          color="text.secondary"
                                          sx={{ marginRight: 2 }}
                                        >
                                          {moment(document.uploadedAt).format(
                                            'DD/MM/YYYY HH:mm:ss',
                                          )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="body1"
                                          color="text.secondary"
                                          sx={{ marginRight: 2 }}
                                        >
                                          {document.processedAt
                                            ? moment(
                                                document.processedAt,
                                              )?.format('DD/MM/YYYY HH:mm:ss')
                                            : 'Non traité'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        {document.isVerified === true ? (
                                          <Chip
                                            size="small"
                                            color="success"
                                            sx={{
                                              marginLeft: 'auto',
                                              width: '80px',
                                            }}
                                            label={'Vérifié'}
                                          />
                                        ) : (
                                          <Chip
                                            size="small"
                                            color="error"
                                            sx={{
                                              marginLeft: 'auto',
                                              width: '80px',
                                            }}
                                            label={'Non Vérifié'}
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="body1"
                                          color="text.secondary"
                                          sx={{ marginRight: 2 }}
                                        >
                                          {document?.reason || ''}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography>
                                          {document?._uploadedBy?.fullName
                                            ? document?._uploadedBy?.fullName
                                            : '-'}{' '}
                                          /
                                          {document?._processedBy?.fullName
                                            ? document?._processedBy?.fullName
                                            : '-'}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                <TableRow>
                                  <TableCell>
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        width: '64px',
                                      }}
                                      onClick={() =>
                                        setDocCgv(doc._organization.termsUrl)
                                      }
                                    >
                                      CGV
                                    </Button>
                                  </TableCell>
                                  <TableCell />
                                  <If condition={!treated}>
                                    <TableCell />
                                  </If>
                                  <TableCell>
                                    <Typography
                                      variant="body1"
                                      color="text.secondary"
                                    >
                                      {doc.termsAcceptedAt
                                        ? moment(doc.termsAcceptedAt)?.format(
                                            'DD/MM/YYYY HH:mm:ss',
                                          )
                                        : 'Non traité'}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {doc.termsAcceptedAt ? (
                                      <Chip
                                        size="small"
                                        color="success"
                                        sx={{ width: '80px' }}
                                        label={'Vérifié'}
                                      />
                                    ) : (
                                      <Chip
                                        size="small"
                                        color="error"
                                        sx={{ width: '80px' }}
                                        label={'Non Vérifié'}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell />
                                  <TableCell />
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
