export interface LanguageAvailable {
  code: string;
  en: { label: string; variant: string };
  fr: { label: string; variant: string };
}

export const LANGUAGES_AVAILABLE = [
  {
    code: 'fr',
    en: { label: 'Français', variant: 'France' },
    fr: { label: 'Français', variant: 'France' },
  },
  {
    code: 'en',
    en: { label: 'English', variant: 'United States' },
    fr: { label: 'English', variant: 'United States' },
  },
];

export const ENV = {
  API_URL: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : process.env.NODE_ENV === 'development'
      ? 'https://api-dev.bamptee.com'
      : 'https://api.bamptee.com',
  WS_URL: process.env.REACT_APP_WS_URL
    ? process.env.REACT_APP_WS_URL
    : process.env.NODE_ENV === 'development'
      ? 'wss://ws-dev.bamptee.com'
      : 'wss://ws.bamptee.com',
  CHAT_WS_URL: process.env.REACT_APP_CHAT_WS_URL
    ? process.env.REACT_APP_CHAT_WS_URL
    : process.env.NODE_ENV === 'development'
      ? 'wss://api-dev.bamptee.com'
      : 'wss://api.bamptee.com',

  REACT_APP_GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
  REACT_APP_GOOGLE_MAP_ID: process.env.REACT_APP_GOOGLE_MAP_ID || '',
};
