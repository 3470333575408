import { useSelector } from 'react-redux';
import { selectTheme } from '../../../../../../../../styles/theme/slice/selectors';
import { useManagedContext } from '../../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { DrawerComponent } from '../../../../../../../components/DrawerComponent';
import { Stack } from '@mui/material';
import { InfoCard } from '../../../../../../../components/InfoCard';
import { Icon } from '../../../../../../../components/Icon';
import { CalendarBlank, User } from '@phosphor-icons/react';
import DepartmentForm from '../DepartmentForm';
import React from 'react';
import If from '../../../../../../../components/If';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

export function DepartmentsDrawer() {
  const theme = useSelector(selectTheme);
  const departmentsContext = useManagedContext('departmentsContext');
  const { t } = useTranslation();
  return (
    <DrawerComponent
      title={`${
        departmentsContext.editMode
          ? t('settings.companyProfile.departments.edit.title')
          : t('settings.companyProfile.departments.add.title')
      }`}
      open={departmentsContext.openDrawer}
      handleClose={() => {
        departmentsContext.updateDataWithFunction(prev => {
          prev.openDrawer = false;
          prev.editMode = false;
          prev.selectedDepartment = null;
        });
      }}
    >
      <If condition={departmentsContext.editMode}>
        <Stack direction="row" gap={4} padding={4}>
          <InfoCard
            title={t('common.createdAt')}
            value={moment(
              departmentsContext.selectedDepartment?.createdAt,
            ).format('MM/DD/YYYY, hh:mm A')}
            iconBg={theme.primaryLight1}
            icon={
              <Icon icon={<CalendarBlank />} color={theme.primaryActiveColor} />
            }
          />
          <InfoCard
            title={t('created_by')}
            value={departmentsContext.selectedDepartment?.manager}
            iconBg={theme.primaryLight1}
            icon={<Icon icon={<User />} color={theme.primaryActiveColor} />}
          />
        </Stack>
      </If>
      <DepartmentForm />
    </DrawerComponent>
  );
}
