import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { CustomIconButton } from 'app/components/Chat/components/CustomIconButton';
import { SidebarSimple, Browsers, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { StateSetter } from 'types';
import { Popover } from '@mui/material';

interface NotificationToolbarProps {
  handleCloseChat: () => void;
  viewMode: 'sidebar' | 'modal';
  setViewMode: StateSetter<'sidebar' | 'modal'>;
}

export const NotificationToolbar: React.FC<NotificationToolbarProps> = ({
  handleCloseChat,
  viewMode,
  setViewMode,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const popoverOpen = useMemo(() => {
    return !!anchorEl;
  }, [anchorEl]);

  function handleOpenPopover(e) {
    setAnchorEl(e.currentTarget || e.target);
  }

  function handleClosePopover() {
    setAnchorEl(null);
  }

  function handleChangeViewMode(mode: 'sidebar' | 'modal') {
    setViewMode(mode);
    handleClosePopover();
  }

  return (
    <Wrapper isOnSide={viewMode === 'sidebar'}>
      <div
        style={{
          borderRadius: '20px 20px 0 0',
        }}
      >
        <p className="title">{t('modules.notifications')}</p>
        <div className="actions">
          <CustomIconButton
            icon={
              viewMode === 'sidebar' ? (
                <SidebarSimple weight="bold" />
              ) : (
                <Browsers weight="bold" />
              )
            }
            iconStyles={
              viewMode === 'sidebar' ? { transform: 'rotate(180deg)' } : {}
            }
            isDropdown={true}
            size={16}
            baseSize={34}
            square={true}
            onClick={e => handleOpenPopover(e)}
          />
          <CustomIconButton
            icon={<X weight="bold" />}
            size={16}
            baseSize={34}
            square={true}
            onClick={handleCloseChat}
          />
        </div>
      </div>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <ViewModesWrapper>
          <CustomIconButton
            icon={<SidebarSimple weight="bold" />}
            iconStyles={{ transform: 'rotate(180deg)' }}
            size={16}
            baseSize={32}
            square={true}
            active={viewMode === 'sidebar'}
            onClick={() => handleChangeViewMode('sidebar')}
          />
          <CustomIconButton
            icon={<Browsers weight="bold" />}
            size={16}
            baseSize={32}
            square={true}
            active={viewMode === 'modal'}
            onClick={() => handleChangeViewMode('modal')}
          />
        </ViewModesWrapper>
      </Popover>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isOnSide?: boolean }>`
  background: ${themes?.default?.accordionWhiteBg};
  height: 32px;
  display: flex;
  border-radius: ${props =>
    props.isOnSide ? '18px 0 0 0' : '18px 18px 0px 0px'};

  & > div {
    flex: 1;
    padding: ${props => (props.isOnSide ? '16px 0 16px 24px' : '16px 24px')};
    border-radius: ${props =>
      props.isOnSide ? '18px 0 0 0' : '18px 18px 0px 0px'} !important;
    background-image: linear-gradient(
      55deg,
      rgba(255, 0, 255, 0.16) -14.9%,
      rgba(176, 30, 255, 0.16) 10.07%,
      rgba(17, 89, 255, 0.16) 93.5%
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    margin: 0;
    font-size: 1.14rem;
    font-weight: 500;
    line-height: 18.4px;
    text-align: left;
    color: ${themes?.default?.textBlack};
  }
`;

const ViewModesWrapper = styled.div`
  height: 32px;
  border-radius: 4px;
  display: flex;
`;
