import { createContext, useState, useReducer, useEffect, useMemo } from 'react';
import {
  ADD_AREA_MODAL,
  DELETE_AREA_MODAL,
  ADD_STORAGE_LOCATION_MODAL,
  ARCHIVE_LOCATION_MODAL,
} from './Consts';
import { AddUpdateArea } from './Components/Areas/AddUpdateArea';
import { DeleteArea } from './Components/Areas/DeleteArea';
import { AddStorageLocation } from './Components/Locations/AddStorageLocation/AddStorageLocation';
import { ArchiveLocation } from './Components/Locations/ArchiveLocation/ArchiveLocation';

import { selectAuthUser } from '../../../common//store/auth/selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  FormState,
  TabType,
  StorageLocationFormState,
  Action,
  StoeageFormAction,
} from './Components/types';
import { useApiCalls } from './Components/hooks/useApiCalls';
import { useAbility } from 'hooks/Abilities';

const initialState: FormState = {
  id: '',
  decks: [],
  name: '',
  _type: null,
  aisles: [],
};

export const StorageLocationContext = createContext({
  setModalToShow: (modalToShow: string) => {},
  setLoading: (loading: boolean) => {},
  setFormUpdatesHaChange: (formUpdateHasChanges: boolean) => {},
  setAddLocationErrors: (validationAddLocationErrors: any) => {},
  formUpdateHasChanges: false,
  dispatch: (data: Action) => {},
  dispatchStorageLocationForm: (data: StoeageFormAction) => {},
  setIsDisabled: (isDisabled: boolean) => {},
  closeModal: () => {},
  currentOrganization: {} as any,
  isDisabled: true,
  selectedDeckChange: '',
  setSelectedDeckChange: (selectedDeckChange: string) => {},
  initialState: {} as any,
  updateFormData: (field: keyof FormState, value: any) => {},
  currentSelectedArea: {} as any,
  setCurrentSelectedArea: (currentSelectedArea: any) => {},
  currentLocation: {} as any,
  setCurrentLocation: (currentLocation: any) => {},
  currentTabAction: {} as any,
  setCurrentTabAction: (currentTabAction: any) => {},
  filterStatus: '',
  setFilterStatus: (filterStatus: string) => {},
  tabsData: [] as TabType[],
  setTabs: (tabsData: any) => {},
  storageLocationForm: {} as any,
  resetAddLocationForm: () => {},
  validationAddLocationErrors: {} as any,
  canEdit: false,
  updateStorageFormData: (
    field: keyof StorageLocationFormState,
    value: any,
  ) => {},
});

const formReducer = (
  state: FormState | StorageLocationFormState,
  action: Action | StoeageFormAction,
): FormState | StorageLocationFormState => {
  switch (action.type) {
    case 'SET_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      return state;
  }
};

export const StorageLocationProvider = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [validationErrors, setErrors] = useState({});
  const [validationAddLocationErrors, setAddLocationErrors] = useState({});
  const [currentSelectedArea, setCurrentSelectedArea] = useState({} as any);
  const [currentTabAction, setCurrentTabAction] = useState({});
  const [currentLocation, setCurrentLocation] = useState({} as any);
  const [selectedDeckChange, setSelectedDeckChange] = useState('');
  const [formUpdateHasChanges, setFormUpdatesHaChange] = useState(false);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const authUser = useSelector(selectAuthUser);
  const currentOrganization = authUser?._currentOrganization;
  const { t } = useTranslation();

  const [formData, dispatch] = useReducer(formReducer, initialState);
  const ability = useAbility();

  const [modalToShow, setModalToShow] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [tabsData, setTabs] = useState<TabType[]>([]);

  const closeModal = () => {
    setModalToShow('');
  };

  const { refetchAreas } = useApiCalls();

  const initialSrotageLocationState: StorageLocationFormState = {
    id: '',
    _area: currentSelectedArea?._id,
    _type: currentSelectedArea?._type,
    _deck: '',
    _aisle: '',
    _level: '',
    _location: '',
    note: '',
    width: 0,
    height: 0,
    length: 0,
    units: 1,
    status: 'active',
  };
  const [storageLocationForm, dispatchStorageLocationForm] = useReducer(
    formReducer,
    initialSrotageLocationState,
  );

  const validate = (newFormData, field) => {
    let errors: any = { ...validationErrors };
    let hasError = false;
    switch (field) {
      case 'name':
        if (!newFormData[field].trim()) {
          errors = {
            ...validationErrors,
            [field]: t('name_is_required'),
          };
        } else {
          errors[field] = null;
        }
        break;
      case '_type':
        if (!newFormData[field]) {
          errors = {
            ...validationErrors,
            [field]: t('storage.validations.type_is_required'),
          };
        } else {
          errors[field] = null;
        }
        break;
      case 'aisles':
      case 'decks':
        if (newFormData[field]?.length == 0) {
          const errorMsg =
            field == 'aisles'
              ? 'storage.validations.aisles_is_required'
              : 'storage.validations.warehouse_is_required';
          errors = {
            ...validationErrors,
            [field]: t(errorMsg),
          };
        } else {
          errors[field] = null;
        }
        break;
    }

    for (var key in newFormData) {
      if (key != 'id') {
        if (
          key == 'name' &&
          (!newFormData[key].trim() || newFormData[key] == '')
        ) {
          hasError = true;
        } else if (key == '_type' && !newFormData[key]) {
          hasError = true;
        } else if (
          (key == 'aisles' || key == 'decks') &&
          newFormData[field]?.length == 0
        ) {
          hasError = true;
        }
      }
    }

    hasError ? setIsDisabled(true) : setIsDisabled(false);
    setErrors(errors);
  };

  const canEdit = useMemo(() => {
    return !!ability.can('canedit', 'StorageLocation');
  }, [ability]);

  const updateFormData = (field: keyof FormState, value: any) => {
    const newFormData = {
      ...formData,
      [field]: value,
    };

    dispatch({ type: 'SET_FIELD', field, value });
    validate(newFormData, field);
  };

  const updateStorageFormData = (
    field: keyof StorageLocationFormState,
    value: any,
  ) => {
    const newFormData = {
      ...formData,
      [field]: value,
    };
    dispatchStorageLocationForm({ type: 'SET_FIELD', field, value });
    validateAddlocation(newFormData, field);
  };

  const resetAddLocationForm = () => {
    Object.keys(initialSrotageLocationState).forEach(key => {
      const value = initialSrotageLocationState[key];
      const fieldData = key as keyof StorageLocationFormState;
      dispatchStorageLocationForm({
        type: 'SET_FIELD',
        field: fieldData,
        value: value,
      });
    });
    setAddLocationErrors({});
  };

  const validateAddlocation = (newFormData, field) => {
    let errors: any = { ...validationAddLocationErrors };
    let hasError = false;
    switch (field) {
      case '_deck':
        if (!newFormData[field]) {
          errors = {
            ...validationAddLocationErrors,
            [field]: t('storage.validations.warehouse_is_required'),
          };
        } else {
          errors[field] = null;
        }
        break;
      case '_type':
        if (!newFormData[field]) {
          errors = {
            ...validationAddLocationErrors,
            [field]: t('storage.validations.type_is_required'),
          };
        } else {
          errors[field] = null;
        }
        break;
      case '_aisle':
        if (!newFormData[field]) {
          console.log('ok', newFormData[field]);
          errors = {
            ...validationAddLocationErrors,
            [field]: t('storage.validations.aisles_is_required'),
          };
        } else {
          console.log('ok', newFormData[field]);

          errors[field] = null;
        }
        break;
    }
    setAddLocationErrors(errors);
  };

  useEffect(() => {
    //setCurrentSelectedArea({});

    refetchAreas();
  }, [currentOrganization]);

  return (
    <StorageLocationContext.Provider
      value={{
        setModalToShow,
        dispatch,
        setLoading,
        setIsDisabled,
        closeModal,
        currentOrganization,
        isDisabled,
        initialState,
        updateFormData,
        currentSelectedArea,
        setCurrentSelectedArea,
        currentTabAction,
        setCurrentTabAction,
        setFilterStatus,
        filterStatus,
        tabsData,
        setTabs,
        storageLocationForm,
        currentLocation,
        setCurrentLocation,
        resetAddLocationForm,
        updateStorageFormData,
        dispatchStorageLocationForm,
        selectedDeckChange,
        setSelectedDeckChange,
        validationAddLocationErrors,
        setFormUpdatesHaChange,
        formUpdateHasChanges,
        setAddLocationErrors,
        canEdit,
      }}
    >
      {children}
      {modalToShow == ADD_AREA_MODAL && (
        <AddUpdateArea
          updateFormData={updateFormData}
          formData={formData}
          validationErrors={validationErrors}
          setErrors={setErrors}
        />
      )}
      {modalToShow == DELETE_AREA_MODAL && (
        <DeleteArea currentTabAction={currentTabAction} />
      )}
      {modalToShow == ADD_STORAGE_LOCATION_MODAL && (
        <AddStorageLocation
          handleClose={closeModal}
          storageLocationForm={storageLocationForm}
          updateStorageFormData={updateStorageFormData}
          validationAddLocationErrors={validationAddLocationErrors}
          resetAddLocationForm={resetAddLocationForm}
          currentSelectedArea={currentSelectedArea}
          setAddLocationErrors={setAddLocationErrors}
        />
      )}
      {modalToShow == ARCHIVE_LOCATION_MODAL && <ArchiveLocation />}
    </StorageLocationContext.Provider>
  );
};
