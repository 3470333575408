import { DotsThree } from '@phosphor-icons/react';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import { useMemo, useState } from 'react';
import { PopoverEl } from 'app/components/Popover';
import { themes } from 'styles/theme/themes';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';
import { TemplateSaveModal } from '../TemplateSaveModal';
import { useAbility } from 'hooks/Abilities';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { Icon } from 'app/components/Icon';

interface ActionsMenuProps {
  handleSaveTemplate: (formData: any) => Promise<void>;
  handlePrint: () => void;
  printDeliveryNote: () => void;
  printPreparationNote: () => void;
  deck: any;
}

export const ActionsMenu = ({
  handleSaveTemplate,
  handlePrint,
  printDeliveryNote,
  printPreparationNote,
  deck,
}: ActionsMenuProps) => {
  const ability = useAbility();
  const { t } = useTranslation();
  const [openTemplatesModal, setOpenTemplatesModal] = useState<boolean>(false);

  const handleOpenSaveAsTemplate = () => {
    setOpenTemplatesModal(true);
  };

  const handleCloseSaveAsTemplate = () => {
    setOpenTemplatesModal(false);
  };

  const theme = useSelector(selectTheme);

  const actions = useMemo(() => {
    const allowedActions: any = [
      {
        action: 'template',
        element: (
          <PopoverEl
            redStatus={theme.redStatus}
            onClick={handleOpenSaveAsTemplate}
            style={{
              paddingLeft: themes?.default?.popoverLabelPadding,
              width: '100%',
              paddingRight: `${
                parseInt(themes?.default?.popoverLabelPadding.split('px')[0]) *
                2
              }px`,
            }}
          >
            <Typography sx={{ fontSize: '0.8125rem' }}>
              {t('roundtrip.templates.popover_title.save_as_template')}
            </Typography>
          </PopoverEl>
        ),
      },
    ];

    if (ability.can('canexport', 'roundtrips')) {
      allowedActions.push({
        action: 'print',
        nested: true,
        actions: [
          {
            action: 'print-roadmap',
            element: (
              <PopoverEl
                redStatus={theme.redStatus}
                onClick={() => handlePrint()}
                style={{
                  width: '100%',
                }}
              >
                <div className="popover-item">
                  {t('common.buttons.print_roadmap')}
                </div>
              </PopoverEl>
            ),
          },
          {
            action: 'print-deliveryNotes',
            element: (
              <PopoverEl
                redStatus={theme.redStatus}
                onClick={() => printDeliveryNote()}
                style={{ width: '100%' }}
              >
                <div className="popover-item">
                  {t('common.buttons.print_delivery_notes')}
                </div>
              </PopoverEl>
            ),
          },
          {
            action: 'print-preparationNotes',
            element: (
              <PopoverEl
                redStatus={theme.redStatus}
                onClick={() => printPreparationNote()}
                style={{ width: '100%' }}
              >
                <div className="popover-item">
                  {t('print.preparation-note-button')}
                </div>
              </PopoverEl>
            ),
          },
        ],
        element: (
          <PopoverEl
            redStatus={theme.redStatus}
            style={{
              paddingLeft: themes?.default?.popoverLabelPadding,
              width: '100%',
            }}
          >
            <Typography sx={{ fontSize: '0.8125rem' }}>
              {t('common.buttons.print')}
            </Typography>
          </PopoverEl>
        ),
      });
    }
    return allowedActions;
  }, [t, ability]);

  return (
    <div
      style={{ width: '100%' }}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <NestedMenuComponent id={''} actions={actions} />
      <If condition={openTemplatesModal}>
        <TemplateSaveModal
          open={openTemplatesModal}
          handleClose={handleCloseSaveAsTemplate}
          onSaveTemplate={handleSaveTemplate}
          deck={deck}
        />
      </If>
    </div>
  );
};
