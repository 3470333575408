import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Can } from 'hooks/Abilities/context';
import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  ButtonBase,
} from '@mui/material';
import { handlesetAnchorFunction } from 'functions/setAnchorFunction';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { ArrowSquareRight, CaretDown, X } from '@phosphor-icons/react';
import { ArrowIconWrapper } from '../OnSelectActions';
import { StateSetter } from 'types';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import AssignedRoundtripsPopover from 'app/pages/RoundTrips/components/Map/components/AssigneClientOrderPopover';
import { AddClientOrderPopover } from 'app/pages/RoundTrips/components/Map/components/AddClientOrderPopover';

interface Props {
  selected: any[];
  setSelected: StateSetter<any[]>;
  roundtrips: any[];
  handleClearSelection: () => void;
  setAddModalOpen?: Function;
  selectedItemsCount: number;
  fullStopsToAssign: any[];
}

export const OnMapSelectActions: React.FC<Props> = ({
  selected = [],
  setSelected = () => {},
  roundtrips = [],
  handleClearSelection = () => {},
  setAddModalOpen = () => {},
  selectedItemsCount = 0,
  fullStopsToAssign = [],
}) => {
  /* ------------------------------ Use States ------------------------------ */
  const [assignMenuAnchorEl, setAssignMenuAnchorEl] = useState<any>(null);
  const [openRoundtrip, setOpenRoundtrip] = useState<boolean>(false);
  const [openRoundtripAnchorEl, setOpenRoundtripAnchorEl] = useState<any>(null);

  /* ------------------------------- Variables ------------------------------ */
  // const iconSize = themes.default?.iconSizeMedium;
  const { t } = useTranslation();
  const dividerSx = {
    height: '12px',
    color: themes.default.lightGrey3,
    width: '1px',
  };
  const theme = useSelector(selectTheme);

  /* ------------------------------- Use Memos ------------------------------ */
  const assignMenuOpen = useMemo(() => {
    return Boolean(assignMenuAnchorEl);
  }, []);

  /* ------------------------------- Functions ------------------------------ */

  /* ------------------------------------------------------------------------ */
  return (
    <Wrapper>
      <ButtonBase
        onClick={handleClearSelection}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          padding: '2px 4px',
        }}
      >
        <Typography
          color={theme?.primary}
          fontWeight={500}
          lineHeight={'24px'}
          fontSize={'0.85rem'}
          letterSpacing={'0.4px'}
        >
          {t('selected_count', { count: selected?.length || 0 })}
        </Typography>
        <Icon icon={<X weight="bold" />} size={12} color={theme?.primary} />
      </ButtonBase>
      <Can I="canedit" a="orders">
        <VerticalDivider sx={{ ...dividerSx }} />
      </Can>
      <Can I="canedit" a="orders">
        <Tooltip title={t('orders.add_to_roundtrip')}>
          <Stack
            direction={'row'}
            gap={'2px'}
            alignItems={'center'}
            marginRight={'10px'}
            position={'relative'}
          >
            <IconButton
              sx={{
                color: themes?.default?.textColorPrimary,
                marginRight: '10px',
              }}
              aria-controls={
                assignMenuOpen ? 'demo-positioned-menu' : undefined
              }
              aria-haspopup="true"
              aria-expanded={assignMenuOpen ? 'true' : undefined}
              onClick={e => {
                handlesetAnchorFunction(e, setAssignMenuAnchorEl);
              }}
            >
              <Icon icon={<ArrowSquareRight />} size={16} />
            </IconButton>
            <ArrowIconWrapper>
              <Icon icon={<CaretDown />} weight="fill" size={'12px'} />
            </ArrowIconWrapper>
          </Stack>
        </Tooltip>
      </Can>
      {Boolean(assignMenuAnchorEl) && (
        <AssignedRoundtripsPopover
          open={Boolean(assignMenuAnchorEl)}
          setOpenAddRoundtrip={setOpenRoundtrip}
          setAddModalOpen={setAddModalOpen}
          handleMenuClose={() => setAssignMenuAnchorEl(null)}
          handleClearSelection={() => handleClearSelection()}
          roundtripsCount={roundtrips?.length || 0}
          selectedItemsCount={selectedItemsCount}
          anchorEl={assignMenuAnchorEl}
          position={{
            x: 'right',
            y: 37,
          }}
          leftNestedMenuPosition={true}
          setOpenRoundtripAnchorEl={setOpenRoundtripAnchorEl}
        />
      )}
      {openRoundtrip && (
        <AddClientOrderPopover
          openAddRoundtrip={openRoundtrip}
          setOpenAddRoundtrip={setOpenRoundtrip}
          roundtrips={roundtrips}
          selectedFullOrders={fullStopsToAssign}
          handleUnselectAllOrders={handleClearSelection}
          anchorEl={openRoundtripAnchorEl}
          position={{ x: -463, y: 'top' }}
          closeOnMouseOut={true}
          handleCloseClientOrderPopover={() => setAssignMenuAnchorEl(null)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 2px;
  gap: 12px;
`;
