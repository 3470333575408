import styled from 'styled-components';
import If from '../../If';
import { Collapse, IconButton, Badge } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const MenuTitleWrapper = ({
  isMinify,
  onClick,
  openSectionIndex,
  theme,
  title,
  index,
  children,
}) => {
  return (
    <>
      <If condition={!isMinify}>
        <TitleWrapper onClick={onClick}>
          <Title textColorSecondary={theme.textColorSecondary}>{title}</Title>
          <IconButton
            sx={{
              ml: 'auto',
              mr: '12px',
              ':hover': {
                transform: 'scale(1.1)',
                transition: 'all 100ms ease-in',
              },
            }}
          >
            {openSectionIndex.includes(index) ? (
              <KeyboardArrowDownIcon color="action" />
            ) : (
              <KeyboardArrowRightIcon color="action" />
            )}
          </IconButton>
        </TitleWrapper>
      </If>
      <Collapse
        in={openSectionIndex.includes(index) || isMinify}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </>
  );
};

const Title = styled.p<{ textColorSecondary: string }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.813em;
  line-height: 15px;
  text-transform: uppercase;
  margin-left: 19px;
  color: ${props => props?.textColorSecondary};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`;
