import { Stack, Typography } from '@mui/material';
import React, { useMemo, useState, useTransition } from 'react';
import { Details } from './components/Details';
import { useTranslation } from 'react-i18next';
import { Departments } from './components/Departments';
import { Warehouses } from './components/Warehouses';
import { CompanyStructure } from './components/CompanyStructure';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import If from 'app/components/If';
import { ContentWrapper } from '../Components/styles';
import { TabsMenu } from '../../../components/TabsMenu';

export function CompanyProfile() {
  const { t } = useTranslation();
  const [, startTransition] = useTransition();
  const can = usePermission('companysettings');
  const canedit = can(Permission.EDIT);
  const [currentTabIndex, setCurrentTabIndex] = useState(canedit ? '0' : '2');

  const handleTabChange = (_e, tabIndex) => {
    startTransition(() => {
      setCurrentTabIndex(tabIndex);
    });
  };
  const tabsArray = useMemo(() => {
    return [
      {
        label: <p>{t('settings.companyProfile.tab.details')}</p>,
        value: '0',
      },
      {
        label: <p>{t('settings.companyProfile.tab.departments')}</p>,
        value: '1',
      },
      {
        label: <p>{t('settings.companyProfile.tab.companyStructure')}</p>,
        value: '2',
      },
      {
        label: <p>{t('settings.companyProfile.tab.warehouses')}</p>,
        value: '3',
      },
    ];
  }, [t]);
  if (!can(Permission.VIEW)) return null;

  return (
    <>
      <ContentWrapper>
        <Stack direction="row" gap={10}>
          <Stack justifyContent="center">
            <Typography fontSize="1rem" fontWeight="500" alignItems="center">
              {t('settings.companyProfile.title')}
            </Typography>
          </Stack>
          <TabsMenu
            tabs={tabsArray}
            selectedTab={currentTabIndex}
            handleOnSwitchTab={handleTabChange}
          />
        </Stack>
        <div id="toolbar" />
      </ContentWrapper>
      <If
        condition={canedit}
        otherwise={
          <>
            {currentTabIndex === '0' && <Departments />}
            {currentTabIndex === '1' && <CompanyStructure />}
            {currentTabIndex === '2' && <Warehouses />}
          </>
        }
      >
        {currentTabIndex === '0' && <Details />}
        {currentTabIndex === '1' && <Departments />}
        {currentTabIndex === '2' && <CompanyStructure />}
        {currentTabIndex === '3' && <Warehouses />}
      </If>
    </>
  );
}
