import { AccountSettingsNav } from 'app/pages/Settings/SettingsNav';
import { toolsActions } from 'app/slices/tools';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

export function Settings() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toolsActions.setZoomIn({ status: true }));
  }, []);

  return (
    <>
      <AccountSettingsNav />

      <Wrapper>
        <Outlet />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  width: ${props => `calc(100% - ${props.theme.settingsNavWidth})`};
  height: ${props => `calc(100vh - ${props.theme.topBarHeight})`};
  overflow: auto;
  background: #fff;
  top: ${props => props.theme.topBarHeight};
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  width: 720px;
  height: 98px;
  margin-top: 26px;
  margin-left: 23px;
`;
