import { Popover } from '@mui/material';
import FilterIcon from 'app/components/FilterComponents/components/FilterIcon';
import { useFilterState } from 'app/components/FilterComponents/hooks/useFilterState';
import { FilterContainer } from 'app/components/FilterComponents/styles';
import { useGrid } from 'app/components/Grid/hooks';

interface FilterProps {
  filterComponent: JSX.Element;
  disabled?: boolean;
}

export const GridFilters = ({ filterComponent, disabled }: FilterProps) => {
  const { filters } = useGrid();
  const { filterOn, handleCloseFilter, handleOpenFilter, filtersCount } =
    useFilterState({ filters });
  const openFilter = Boolean(filterOn);
  const id = openFilter ? 'filter' : undefined;
  return (
    <FilterIcon
      filterOn={filterOn}
      filtersCount={filtersCount}
      handleOpenFilter={handleOpenFilter}
      disabled={disabled}
      filterComponent={
        <Popover
          id={id}
          open={openFilter}
          anchorEl={filterOn}
          onClose={handleCloseFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <FilterContainer>{filterComponent}</FilterContainer>
        </Popover>
      }
    />
  );
};
