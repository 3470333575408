import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { roundtripActions } from 'common/store/roundtrips';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectEditingRoundtripUser } from 'common/store/roundtrips/selectors';
import { useSelectors } from './hook/useSelector';
import SelectorComponent from './components/SelectorComponent';
import { UserPropsObj } from './components/UserPropsObj';
import {
  Driver,
  Ripper,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { useToaster } from 'hooks/useToaster';
import _ from 'lodash';

interface DriverSelectorProps {
  user: any;
  users: any;
  addFuction: any;
  roundTripCopy: any;
  updateRoundtripData: any;
  roundtripId: any;
  driverAgentId: any;
  ripperAgentId: any;
  schedule?: Function;
  clusterId?: string;
  runningVehicle?: boolean;
  clusterStatus?: string;
}

const DriverSelector = React.memo(
  function DriverSelector({
    user,
    users,
    addFuction,
    roundTripCopy,
    updateRoundtripData,
    roundtripId,
    driverAgentId,
    ripperAgentId,
    schedule,
    clusterId,
    runningVehicle,
    clusterStatus,
  }: DriverSelectorProps) {
    const dispatch = useDispatch();
    const toast = useToaster();

    const [selectedDriver, setSelectedDriver] = useState(user);
    const selectedRef = useRef(null);

    useEffect(() => {
      setSelectedDriver(user);
    }, [user]);

    let {
      handleSelectClick,
      handleSelectLeave,
      isSelected,
      loadingUpdate,
      editProduct,
      setLoadingUpdate,
      setIsSelected,
    } = useSelectors(
      selectedRef,
      roundtripActions,
      selectEditingRoundtripUser,
      users,
      roundtripId,
      Driver,
    );
    const UserProps = UserPropsObj(users);

    return (
      <SelectorComponent
        selectedRef={selectedRef}
        user={selectedDriver}
        handleSelectClick={() => {
          setIsSelected(true);
          handleSelectClick();
        }}
        loadingUpdate={loadingUpdate}
        isSelected={isSelected}
        editProduct={editProduct}
        UserProps={UserProps}
        type={Driver}
        onChange={async (e, value) => {
          //hide condition
          // if (clusterStatus === 'active' && runningVehicle) {
          //   toast(5000, 'error', 'alerts.roundtrips.prevent_clear');
          //   return;
          // }
          setSelectedDriver(value);
          setLoadingUpdate(true);
          dispatch(roundtripActions.setEditingRoundtripUser(true));
          try {
            await addFuction({
              id: roundTripCopy.id,
              data: {
                agents: [
                  {
                    _user: value?.['_id'],
                    _role: driverAgentId,
                    userkey: Driver,
                  },
                  {
                    _user: roundTripCopy?.agents?.find(
                      data => data?._role?.key === 'ripper',
                    )?._user?.id,
                    _role: ripperAgentId,
                    userkey: Ripper,
                  },
                ],
              },
              UpdateRoundtripData: updateRoundtripData,
            });
          } catch (e) {
            setSelectedDriver(user);
          }
          setLoadingUpdate(false);
          dispatch(roundtripActions.setEditingRoundtripUser(false));
          handleSelectLeave();
        }}
        roundtripId={roundtripId}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.driverAgentId === nextProps.driverAgentId &&
      prevProps.ripperAgentId === nextProps.ripperAgentId &&
      _.isEqual(prevProps.user, nextProps.user) &&
      _.isEqual(prevProps.users, nextProps.users) &&
      prevProps.runningVehicle === nextProps.runningVehicle &&
      prevProps.clusterStatus === nextProps.clusterStatus
    );
  },
);

export default DriverSelector;
export const LoadingPulseWrapper = styled.div`
  padding-left: 27px;
  padding-top: 12px;
`;
