import { useGrid } from 'app/components/Grid/hooks';
import { useContext, useMemo, useEffect, useState } from 'react';
import { useQueryParams } from 'hooks/useQueryParams';
import { useQueryArray } from 'common/hooks/useQueryArray';
import { useParams } from 'react-router-dom';

import {
  useGetLocationsByAreaIdQuery,
  useGetLocationsStatsByAreaIdQuery,
  useGetAisleLevelsByAisleIdQuery,
  useGetLevelLocationsByLevelIdQuery,
  useGetTypeAislesQuery,
  useCreateStorageLocationMutation,
  useGetOrganizationsAreasQuery,
  useGetAreaAislesQuery,
  useGetLocationInfoQuery,
  useUpdateStorageLocationMutation,
} from 'common/services/storageLocationApi';
import { StorageLocationContext } from '../../StorageLocationProvider';
import { useGetWarehousesQuery } from 'common/services/organizationApi';

export const useApiCalls = () => {
  const {
    currentSelectedArea,
    filterStatus,
    setFilterStatus,
    storageLocationForm,
    selectedDeckChange,
  } = useContext(StorageLocationContext);
  const [Warehouses, setWarehouses]: any = useState();
  const {
    page,
    rowsPerPage,
    debouncedSearchTerm,
    order,
    orderBy,
    debouncedFilters,
    selected,
  } = useGrid();
  const params = useParams();

  const { data: warehousesData } = useGetWarehousesQuery(
    useQueryParams({
      status: 'active',
    }),
  );

  const { data: locationDetails, refetch: refetchLocationDetails } =
    useGetLocationInfoQuery(params?.id || '', {
      skip: !params?.id,
    });

  const { data: aisles, isLoading: isLoadingTypeAisles } =
    useGetTypeAislesQuery(currentSelectedArea?._type, {
      skip: !currentSelectedArea?._type,
    });
  const [statusParams, setStatusParams] = useState('');
  const [skipAisle, setSkipAisle] = useState(false);
  const [skipLevel, setSkipLevel] = useState(false);

  const [selectedDecks, setSelectedDecks]: any = useState([]);
  const [createStorageLocation] = useCreateStorageLocationMutation();
  const [updateStorageLocation] = useUpdateStorageLocationMutation();

  const commonQueryParams = {
    _area: currentSelectedArea?._id,
    offset: 0,
    showArchived: !!debouncedFilters?.showArchived,
    text: debouncedSearchTerm,
    sort: order + (!!orderBy ? orderBy : 'name'),
    statuses: debouncedFilters?.statuses?.map(filter => {
      return filter.value;
    }),
    _aisles: debouncedFilters?._aisles?.map(filter => {
      return filter.label;
    }),
    _locations: debouncedFilters?._locations?.map(filter => {
      return filter.label;
    }),
    _levels: debouncedFilters?._levels?.map(filter => {
      return filter.label;
    }),
    units: debouncedFilters?.units
      ? JSON.stringify({
          min: debouncedFilters?.units?.min || 0,
          max: debouncedFilters?.units?.max || 1000,
        })
      : undefined,
    fillingRate: debouncedFilters?.fillingRate
      ? JSON.stringify({
          min: debouncedFilters?.fillingRate?.min || 0,
          max: debouncedFilters?.fillingRate?.max || 1000,
        })
      : undefined,
  };

  const queryParams = useQueryArray({
    ...commonQueryParams,
    page,
    limit: rowsPerPage,
  });

  const exportQueryParams = useQueryArray(commonQueryParams);

  const { data: areaAisles } = useGetAreaAislesQuery(currentSelectedArea?._id, {
    skip: !currentSelectedArea?._id,
  });

  useEffect(() => {
    let statuses = '';
    if (currentSelectedArea) {
      if (filterStatus != '') {
        statuses = '&' + filterStatus + '=true';
      }
      setStatusParams(statuses);
      setFilterStatus(filterStatus);
    }
  }, [filterStatus]);

  useEffect(() => {
    if (warehousesData?.docs) {
      const formattedData: any = [];
      for (let i = 0; i < warehousesData.docs.length; i++) {
        formattedData.push({
          label: warehousesData.docs[i].name,
          _id: warehousesData.docs[i]._id,
        });
      }

      setWarehouses(formattedData);
    }
  }, [warehousesData]);

  const {
    data: areaStorageLocations,
    isLoading: isLoadingLocations,
    isFetching: isFetchingLocations,
    refetch: refetchLocations,
  } = useGetLocationsByAreaIdQuery(queryParams + statusParams, {
    skip: !currentSelectedArea?._id,
  });

  const aisleLevelParams = useQueryArray({
    _storageAisle: storageLocationForm?._aisle,
  });

  const levelLocationsParams = useQueryArray({
    _storageLevel: storageLocationForm?._level,
  });

  const { data: aisleLevels } = useGetAisleLevelsByAisleIdQuery(
    skipAisle ? '' : aisleLevelParams,

    { skip: !skipAisle && !storageLocationForm?._aisle },
  );
  const { data: levelLocations } = useGetLevelLocationsByLevelIdQuery(
    skipLevel ? '' : levelLocationsParams,
    { skip: !skipLevel && !storageLocationForm?._level },
  );
  const deckQuery = useQueryArray({
    _deck: selectedDeckChange,
  });
  const areaQueryParams = selectedDeckChange ? deckQuery : '';
  const {
    data: areaList,
    isFetching: isFetchingArea,
    isLoading: isLoadingArea,
    refetch: refetchAreas,
  } = useGetOrganizationsAreasQuery(areaQueryParams);

  const { data: areaStats, refetch: refetchStats } =
    useGetLocationsStatsByAreaIdQuery(
      useQueryParams({
        _area: currentSelectedArea?._id,
      }),
      { skip: !currentSelectedArea?._id },
    );

  const isSubmitting = useMemo(() => {
    return false;
  }, []);

  return {
    isLoadingLocations,
    isSubmitting,
    areaStorageLocations,
    areaStats,
    refetchLocations,
    isFetchingLocations,
    Warehouses,
    selectedDecks,
    setSelectedDecks,
    aisles,
    aisleLevels,
    levelLocations,
    createStorageLocation,
    areaList,
    isFetchingArea,
    isLoadingArea,
    refetchAreas,
    refetchStats,
    setSkipAisle,
    setSkipLevel,
    areaAisles,
    exportQueryParams,
    locationDetails,
    updateStorageLocation,
    refetchLocationDetails,
  };
};
