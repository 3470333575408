import {
  Button,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';
import { AddRoundtripWraper } from 'app/components/RoundTrips/styles/styles';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { themes } from 'styles/theme/themes';
import { StatusIndicator } from 'app/components/TableComponent/helpers/Components/StatusPopover';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { useSearchParams } from 'react-router-dom';
import { useAddStopRoundtripMutation } from 'common/services/roundtripApi';
import { useToaster } from 'hooks/useToaster';
import _ from 'lodash';
import { RoundtripsType } from 'app/pages/RoundTrips/data/types';

interface Props {
  openAddRoundtrip: any;
  setOpenAddRoundtrip: any;
  roundtrips: any;
  selectedFullOrders: any;
  handleUnselectAllOrders?: () => void;
  position?: any;
  anchorEl?: any;
  closeOnMouseOut?: boolean;
  handleCloseClientOrderPopover: Function;
}

export const AddClientOrderPopover: React.FC<Props> = ({
  openAddRoundtrip,
  setOpenAddRoundtrip,
  roundtrips,
  selectedFullOrders,
  handleUnselectAllOrders = () => {},
  position,
  anchorEl,
  closeOnMouseOut,
  handleCloseClientOrderPopover,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramUserContext =
    searchParams.get('userContext') == RoundtripsType.Sales
      ? AgentCategories.Sales
      : AgentCategories.Logistic;
  const [userContext, setUserContext] = useState<AgentCategories>(
    paramUserContext || AgentCategories.Logistic,
  );

  const [accordionStatus, setAccordionStatus] = useState<
    { key: string; value: boolean[] }[]
  >([{ key: '', value: [] }]);

  const toast = useToaster();

  const [searchOn, setSearchOn] = useState<boolean>(false);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [searchAddRoundtrip, setSearchAddRoundtrip] = useState<string>('');
  const [addStopRoundtrip] = useAddStopRoundtripMutation();

  const handleSearchAddRoundtripChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchAddRoundtrip(event.target.value);
  };

  const handleAssignToTrip = async roundtrip => {
    const formattedOrders = selectedFullOrders?.map(order => {
      const id = order?._order?._id ?? order?._id;
      return {
        _id: id,
        type: order?.type,
        _order: id,
        _place: order?._deck?._id,
      };
    });

    // remove duplicates
    const uniqueFormattedOrders = Array.from(new Set(formattedOrders));

    await addStopRoundtrip({
      id: roundtrip?.id,
      data: {
        stops: uniqueFormattedOrders,
      },
    }).unwrap();
    toast(5000, 'success', 'alerts.orders.assign_success');
    handleCloseClientOrderPopover();
    handleUnselectAllOrders();
  };

  const handleMenuClose = () => {
    if (mouseOver && closeOnMouseOut) {
      setOpenAddRoundtrip(false);
      setMouseOver(false);
    }
  };

  return (
    <Popover
      id={'add-roundtrip'}
      open={openAddRoundtrip}
      onClose={() => {
        setOpenAddRoundtrip(false);
      }}
      anchorOrigin={{
        vertical: position.y || 'center',
        horizontal: position.x + 209 || 'center',
      }}
      anchorEl={anchorEl ? anchorEl : undefined}
    >
      <AddRoundtripWraper
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={handleMenuClose}
      >
        <TextField
          size="small"
          variant="outlined"
          onChange={handleSearchAddRoundtripChange}
          value={searchAddRoundtrip}
          placeholder={t('search_trips')}
          sx={{
            marginLeft: '5px',
            width: '184px',
            marginTop: '10px',
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="search"
                  component="label"
                  onClick={() => {
                    setSearchOn(false);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="roundtrips">
          {roundtrips.map((roundtrip, index) => {
            if (
              roundtrip?.stops?.some(stop =>
                selectedFullOrders.some(
                  order => order._id === stop?._order?._id,
                ),
              )
            ) {
              return <></>;
            } else
              return (
                <Button
                  key={roundtrip?.id}
                  sx={{
                    display: 'flex',
                    gap: '17px',
                    color: themes?.default?.black,
                  }}
                  className="roundtrip_item"
                  onClick={() => handleAssignToTrip(roundtrip)}
                >
                  <div className="roundtrip_code">
                    <StatusIndicator status={roundtrip.status} />
                    {roundtrip.code}~{roundtrip.internalCode}
                  </div>
                  <div>
                    {roundtrip.countStops} Stops -{' '}
                    {roundtrip?.['_vehicle']?.model}
                  </div>
                </Button>
              );
          })}
        </div>
      </AddRoundtripWraper>
    </Popover>
  );
};
