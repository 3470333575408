import { useContext, useMemo } from 'react';
import { contextRegistry } from './index';

export function useManagedContext(contextName: string) {
  const contextEntry = useMemo(() => {
    const entry = contextRegistry.get(contextName);
    if (!entry) {
      throw new Error(`Context with name '${contextName}' not found.`);
    }
    return entry;
  }, [contextName]);

  const { context, valueType } = contextEntry;

  const contextValue = useContext(context);
  if (!contextValue) {
    throw new Error(
      `Context value for '${contextName}' is undefined. Ensure the context is provided.`,
    );
  }

  return contextValue as typeof valueType;
}
