import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'common/store/app';
import { selectOrdersGridPreferences } from 'common/store/app/selectors';
import { useState } from 'react';
import { useGetHeadCells } from 'app/components/TableComponent/helpers/functions';
import { selectTableColumnsConfig } from 'common/store/app/selectors';
import { cloneDeep } from 'lodash';
import { useHeadCells } from 'app/components/TableComponent/hooks/useHeadCells';

interface Data {
  priority: { value: JSX.Element; sort?: string };
  warehouse: { value: JSX.Element; sort?: string };
  _id: { value: number | string };
  code: { value: JSX.Element; sort?: string };
  comment: { value: JSX.Element; sort?: string };
  countLines: { value: JSX.Element; sort?: number };
  createdAt: { value: JSX.Element; sort?: string };
  deleted: { value: JSX.Element };
  deliveryDate: { value: JSX.Element; sort?: string };
  origin: { value: JSX.Element; sort?: string };
  product: { value: JSX.Element };
  payment: { value: JSX.Element };
  notes: { value: JSX.Element };
  status: { value: JSX.Element; sort?: string };
  supportUnits: { value: JSX.Element };
  supportUnitsRequestedCapacity: { value: JSX.Element; sort?: number };
  totalWeight: { value: JSX.Element; sort?: number };
  totalAmount: { value: JSX.Element; sort?: number };
  totalAmountTax: { value: JSX.Element; sort?: number };
  caKg: { value: JSX.Element; sort?: number };
  updatedAt: { value: JSX.Element; sort?: string };
  _customer: { value: JSX.Element };
  customerCode: { value: JSX.Element };
  roundtripCode: { value: JSX.Element };
  roundtripPosition: { value: JSX.Element };
  salesman: { value: JSX.Element };
  createdBy: { value: JSX.Element };
  validatedBy: { value: JSX.Element };
  amountRequested: { value: JSX.Element };
  outstandingBalance: { value: JSX.Element };
  customer_code: { value: JSX.Element };
  customer_name: { value: JSX.Element };
  address: { value: JSX.Element };
  quantity: { value: JSX.Element };
  weight: { value: JSX.Element };
  support_unit: { value: JSX.Element };
  delivery_date: { value: JSX.Element };
  action: { value: JSX.Element };
}

interface HeadCell {
  id: keyof Data;
  label: string;
  disablePadding: boolean;
  numeric: boolean;
  disableSort?: boolean;
  disableRow?: boolean;
  canChangeView?: boolean;
  filter: boolean;
  key: string | null;
  minWidth?: any;
  maxWidth?: any;
  isSticky?: boolean;
  stickyLeftPosition?: number;
  stickyRightPosition?: number;
  isEditTrigger?: boolean;
}

const defaultOrdersHeadCellsConst: HeadCell[] = [
  {
    id: 'code',
    label: '',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 0,
    maxWidth: 0,
    isSticky: true,
    stickyLeftPosition: 48,
  },
  {
    id: 'customerCode',
    label: 'order',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    disableSort: true,
    isSticky: true,
    stickyLeftPosition: 88,
    isEditTrigger: true,
  },
  {
    id: 'warehouse',
    label: 'warehouse',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'customer_code',
    label: 'product.heads.code',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'customer_name',
    label: 'customer',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    minWidth: 200,
    maxWidth: 200,
    disableSort: true,
  },
  {
    id: 'address',
    label: 'common.address',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    minWidth: 200,
    maxWidth: 200,
    disableSort: true,
  },
  {
    id: 'createdBy',
    label: 'roundtrip.created_by',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    minWidth: 200,
    maxWidth: 200,
    disableSort: true,
  },
  {
    id: 'quantity',
    label: 'common.quantity',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'countLines',
    label: 'product_lines',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'weight',
    label: 'weight_kg',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 135,
    minWidth: 135,
    disableSort: true,
  },
  {
    id: 'support_unit',
    label: 'roundtrip.support_units',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 145,
    minWidth: 145,
    disableSort: true,
  },
  {
    id: 'delivery_date',
    label: 'clients.orderDetails.deliveryDate',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 150,
    minWidth: 150,
    disableSort: true,
  },
  {
    id: 'roundtripCode',
    label: 'roundtrip_code',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    minWidth: 150,
    maxWidth: 150,
    disableSort: true,
  },
  {
    id: 'roundtripPosition',
    label: 'position',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'priority',
    label: 'common.priority',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'salesman',
    label: 'customer.salesman',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'supportUnitsRequestedCapacity',
    label: 'support_requested_capacity',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
    minWidth: 260,
    maxWidth: 260,
  },
  {
    id: 'amountRequested',
    label: 'amount_requested',
    numeric: true,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'outstandingBalance',
    label: 'outstanding_balance',
    numeric: true,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'totalAmount',
    label: 'total_price',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 135,
    minWidth: 135,
    disableSort: true,
  },
  {
    id: 'totalAmountTax',
    label: 'total_ttc',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 145,
    minWidth: 145,
    disableSort: true,
  },
  {
    id: 'caKg',
    label: 'ca_kg',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    disableSort: true,
  },
  {
    id: 'updatedAt',
    label: 'updated_at',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 150,
    minWidth: 150,
    disableSort: true,
  },
  {
    id: 'payment',
    label: 'common.payment',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 120,
    minWidth: 120,
    disableSort: true,
  },
  {
    id: 'notes',
    label: 'common.notes',
    numeric: false,
    disablePadding: false,
    disableRow: true,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 125,
    minWidth: 125,
    disableSort: true,
  },
  {
    id: 'status',
    label: 'warehouse.heads.status',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 150,
    minWidth: 150,
    disableSort: true,
    isSticky: true,
    stickyRightPosition: 50,
  },
  {
    id: 'action',
    label: '',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: true,
    filter: false,
    key: null,
    maxWidth: 'fit-content',
    minWidth: 50,
    disableSort: true,
    isSticky: true,
    stickyRightPosition: 0,
  },
];

export const useOrderHeadCells = () => {
  const ordersHeadCellsConst = useGetHeadCells({
    defaultHeadCellsConst: defaultOrdersHeadCellsConst,
    parentAppName: 'bianta',
    moduleName: 'orders',
  });

  const [ordersHeadCellsConst_, setOrdersHeadCellsConst_] =
    useState(ordersHeadCellsConst);
  const dispatch = useDispatch();

  const tableColumnsConfig = useSelector(selectTableColumnsConfig);
  const enableColumns: string[] = useSelector(selectOrdersGridPreferences);
  const headCells: HeadCell[] = ordersHeadCellsConst_.map(headCell => {
    if (enableColumns.includes(headCell.id) !== true) {
      headCell.disableRow = true;
    } else {
      headCell.disableRow = false;
    }

    return headCell;
  });

  const updateHeadCells: any = (
    newHeadCells: HeadCell[],
    reorder?: boolean,
  ) => {
    if (reorder) {
      setOrdersHeadCellsConst_(newHeadCells);
    }
    const newHeadCellsFiltered = newHeadCells.filter(
      headCell => headCell.disableRow !== true,
    );

    // if (JSON.stringify(newHeadCellsFiltered) !== JSON.stringify(headCells)) {
    dispatch(
      appActions.setOrderGridPreferences({
        columns: newHeadCells
          .filter(c => c.disableRow !== true)
          .map(headCell => headCell.id),
      }),
    );

    let tableColumnsConfigCopy = cloneDeep(tableColumnsConfig);
    tableColumnsConfigCopy.bianta = tableColumnsConfigCopy.bianta || {};
    tableColumnsConfigCopy.bianta.orders = newHeadCells?.map(headCell => {
      return { id: headCell.id };
    });
    dispatch(appActions.setTableColumnsConfig(tableColumnsConfigCopy));
  };

  return [headCells, updateHeadCells];
};
