import { Stack } from '@mui/material';
import { GasPump, UserCircle, Wrench } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import { useTranslation } from 'react-i18next';
import { MetricWrapper } from '../../../MetricItem';
import styled from 'styled-components';
import If from 'app/components/If';
import { themes } from 'styles/theme/themes';
import { FarParkedCount } from 'app/pages/AddRoundTrips/components/FarParkedCount';

interface MetricItemProps {
  stopCount: {
    totalOrders: number;
    totalOrdersCompleted: number;
    totalFuel: number;
    totalGarage: number;
  };
  minimized?: boolean;
  farDeliveredOrdersCount?: number;
}
export const StopMetrics = ({
  stopCount,
  minimized = false,
  farDeliveredOrdersCount = 0,
}: MetricItemProps) => {
  const { t } = useTranslation();
  const flexDirection = minimized ? 'row-reverse' : 'row';
  const plusIcon = minimized ? '' : '+';

  return (
    <MetricWrapper noBorder={!!minimized}>
      <ManagedTooltip
        normalCursor
        title={`${`${stopCount?.totalOrders}`} ${t('assigned_orders')}`}
      >
        <Stack direction={flexDirection} gap={'2px'} alignItems={'center'}>
          <Icon icon={<UserCircle />} color={themes?.default?.primary} />
          <ValueWrapper>
            {`${stopCount.totalOrdersCompleted}/${stopCount?.totalOrders}`}
          </ValueWrapper>
          {farDeliveredOrdersCount > 0 && (
            <div style={{ margin: '0 4px' }}>
              <FarParkedCount count={farDeliveredOrdersCount} />
            </div>
          )}
        </Stack>
      </ManagedTooltip>

      <If condition={!!stopCount?.totalFuel}>
        <ManagedTooltip
          normalCursor
          title={`${`${stopCount?.totalFuel}`} ${t('assigned_fuel')}`}
        >
          <Stack direction={flexDirection} gap={'2px'} alignItems={'center'}>
            <Icon icon={<GasPump />} color={themes?.default?.deepPurple} />
            <ValueWrapper>
              {plusIcon}
              {stopCount?.totalFuel}
            </ValueWrapper>
          </Stack>
        </ManagedTooltip>
      </If>

      <If condition={!!stopCount?.totalGarage}>
        <ManagedTooltip
          normalCursor
          title={`${`${stopCount?.totalGarage}`} ${t('assigned_garage')}`}
        >
          <Stack direction={flexDirection} gap={'2px'} alignItems={'center'}>
            <Icon icon={<Wrench />} color={themes?.default?.teal300} />
            <ValueWrapper>
              {plusIcon}
              {stopCount?.totalGarage}
            </ValueWrapper>
          </Stack>
        </ManagedTooltip>
      </If>
    </MetricWrapper>
  );
};

const ValueWrapper = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: ${props => props.theme.black60};
  line-height: 10px;
`;
