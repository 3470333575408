import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { toolsActions } from 'app/slices/tools';
import { Fade } from '@mui/material';
import { themes } from 'styles/theme/themes';
import Truck from 'assets/img/roundTrips/truck.svg';
import If from '../If';
import { defaultMapStyle } from 'styles/mapStyles';
import { GreenPulse } from '../RoundtripTruckIcon';
import TruckIcon from '../TruckIcon';
import { GoogleMapComponent } from 'app/components/GoogleMapComponent';
import { Marker, AdvancedMarker } from '@vis.gl/react-google-maps';

interface MapPopoverProps {
  latitude: any;
  longitude: any;
  open: boolean;
  handleClose: Function;
  editMode?: boolean;
  editMap?: boolean;
  setSelectedStopCopy?: any;
  isTruck?: boolean;
  driverLat?: any;
  driverLong?: any;
  vehicle?: any;
}

const MapAddClient = ({
  latitude,
  longitude,
  open,
  handleClose,
  editMode,
  editMap,
  setSelectedStopCopy,
  isTruck,
  driverLat,
  driverLong,
  vehicle,
}: MapPopoverProps) => {
  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [center, setCenter] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [driverPosition, setDriverPosition] = useState({
    lat: driverLat,
    lng: driverLong,
  });

  const containerStyle = {
    height: '391px',
    width: '100%',
    transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
    borderRadius: '4px',
  };

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? '';

  useEffect(() => {
    if (latitude && longitude) {
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (driverLat && driverLong) {
      setDriverPosition({
        lat: driverLat,
        lng: driverLong,
      });
    }
  }, [driverLat, driverLong]);

  return (
    <Fade in={open}>
      <MapWrapper fullScreen={fullScreen} editMode={editMode}>
        <GoogleMapComponent
          containerStyle={containerStyle}
          disableDefaultUI={true}
          defaultCenter={center}
          defaultZoom={15}
          onMapClick={e => {
            if (editMap) {
              setCenter({ lat: e?.latLng?.lat(), lng: e?.latLng?.lng() });
              setSelectedStopCopy([e?.latLng?.lat(), e?.latLng?.lng()]);
            }
          }}
        >
          <>
            {/* <FullScreenButton
              fullScreen={fullScreen}
              onClick={handleFullscreen}
            >
              {fullScreen ? <ZoomInMapIcon /> : <ZoomOutMapIcon />}
            </FullScreenButton> */}
            {/* <CloseButton onClick={handleCloseWrapper}>
              <CloseIcon />
            </CloseButton> */}
            {/* <StreetViewButton
              streetView={streetViewVisible}
              onClick={toggleStreetView}
            >
              <MyLocationIcon />
            </StreetViewButton> */}
            <If condition={!isTruck}>
              <Marker position={center} />
            </If>
            <If
              condition={
                (isTruck && center?.lat && center?.lng) ||
                (!isTruck && driverPosition?.lat && driverPosition?.lng)
              }
            >
              <AdvancedMarker position={isTruck ? center : driverPosition}>
                {/* TODO: check other modules for the usage of this component */}
                {/* This is only fixed in vehicle table */}
                {/* maybe create a new component and use it instead of this one */}
                {vehicle ? (
                  <TruckIcon
                    vehicle={false}
                    running={vehicle?.engineRunning}
                    driver={false}
                    notTracked={!vehicle?.isTracked}
                    iconStyle={{
                      icon: { height: '30px', width: '30px' },
                      img: { height: '15px', width: '15px' },
                    }}
                    status={vehicle.status}
                  />
                ) : (
                  <GreenPulse style={{ height: '30px', width: '30px' }}>
                    <img
                      src={Truck}
                      style={{ height: '15px', width: '15px' }}
                    />
                  </GreenPulse>
                )}
              </AdvancedMarker>
            </If>
          </>
        </GoogleMapComponent>
      </MapWrapper>
    </Fade>
  );
};

export default MapAddClient;

const MapButton = styled.div(props => ({
  position: 'absolute',
  right: '51px',
  top: '15px',
  cursor: 'pointer',
  color: props.theme.icon,
  padding: '5px',
  backgroundColor: 'rgb(255,255,255)',
  borderRadius: '4px',
  zIndex: '1500',
  boxShadow: '1px 1px 4px 1px rgba(0, 0, 0, 0.25)',
}));

interface FullscreenButtonProps {
  fullScreen?: boolean;
}

const CloseButton = styled(MapButton)(props => ({
  right: '15px',
}));

interface StreetViewButtonProps {
  streetView?: boolean;
}
interface MapProps {
  fullScreen?: boolean;
  editMode?: boolean;
}

// const MapWrapper = styled.div<MapProps>`
//   position: fixed;
//   right: ${props => (props.fullScreen ? '0' : '17px')};
//   z-index: 2100;
//   bottom: ${props =>
//     props.fullScreen ? '0' : props.editMode ? '40px' : '22px'};
//   box-shadow: 0px 5px 30px 0px ${themes?.default?.suvaGrey}40;
//   border-radius: 4px;
// `;
const MapWrapper = styled.div<MapProps>`
  display: flex; /* Use flexbox to position elements */
  flex-direction: column; /* Stack the children vertically */
  width: ${props =>
    props.fullScreen
      ? '100vw'
      : '100%'}; /* Adjust width to 651px based on fullScreen prop */
  height: ${props =>
    props.fullScreen
      ? '100vh'
      : '178px'}; /* Adjust height to 178px based on fullScreen prop */
  z-index: 2100;
  border-radius: 4px;
  margin-right: 17px; /* Add margin to the right side */
  margin-bottom: ${props =>
    props.fullScreen
      ? '0'
      : props.editMode
        ? '40px'
        : '0px'}; /* Adjust bottom margin based on props */
`;