import { IconButton } from '@mui/material';
import { DotsThree } from '@phosphor-icons/react';
import { useMenu } from 'app/components/CustomMenu/hooks/useMenu';
import { MenuOption } from 'app/components/CustomMenu/types';
import { Icon } from 'app/components/Icon';
import { themes } from 'styles/theme/themes';

interface Props {
  options: MenuOption[];
  disabled?: boolean;
}
export interface GridAction {
  label: string;
  action: () => void;
  isDelete?: boolean;
  show?: boolean;
}

export const GridActionMenu = ({ options, disabled }: Props) => {
  const { openMenu, Menu } = useMenu(options);

  return (
    <>
      <IconButton
        onClick={event => {
          event?.stopPropagation();
          openMenu(event);
        }}
        disabled={!!disabled}
        sx={{
          cursor: !!disabled ? 'not-allowed !important' : 'pointer',
          pointerEvents: 'auto !important',
          padding: 0,
        }}
        disableRipple
      >
        <Icon
          icon={<DotsThree weight="bold" />}
          size={themes.default?.dotsIconSize}
        />
      </IconButton>

      {Menu}
    </>
  );
};
