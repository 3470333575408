import React, { useMemo, useEffect, useState } from 'react';

interface Props {
  bounds: any;
  fillColor: string;
  fillOpacity: number;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
  clickable?: boolean;
  map: any;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  onMouseUp?: Function;
  onClick?: Function;
  visible?: boolean;
}

export const RectangleComponent: React.FC<Props> = ({
  bounds,
  fillColor,
  fillOpacity,
  strokeColor,
  strokeOpacity,
  strokeWeight,
  clickable = false,
  map,
  onMouseEnter,
  onMouseLeave,
  onMouseUp,
  onClick,
  visible = true,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    if (!visible) return;

    const rectangle = new window.google.maps.Rectangle({
      bounds,
      fillColor,
      fillOpacity,
      strokeColor,
      strokeOpacity,
      strokeWeight,
      clickable: clickable,
      draggable: false,
      editable: false,
      zIndex: 2,
      map: map,
    });

    const handleMouseOver = () => {
      if (!isMouseOver) {
        setIsMouseOver(true);
        onMouseEnter && onMouseEnter();
      }
    };

    const handleMouseOut = () => {
      if (isMouseOver) {
        setIsMouseOver(false);
        onMouseLeave && onMouseLeave();
      }
    };

    if (onMouseEnter || onMouseLeave) {
      rectangle.addListener('mouseover', handleMouseOver);
      rectangle.addListener('mouseout', handleMouseOut);
    }

    if (onMouseUp) {
      rectangle.addListener('mouseup', onMouseUp);
    }

    if (onClick) {
      rectangle.addListener('click', onClick);
    }

    return () => {
      rectangle.setMap(null);
    };
  }, [
    bounds,
    fillColor,
    fillOpacity,
    strokeColor,
    strokeOpacity,
    strokeWeight,
    map,
    onMouseEnter,
    onMouseLeave,
    onClick,
    visible,
    isMouseOver,
  ]);

  return null;
};
