import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { OrganizationState } from './types';
import organizationApi from '../../services/organizationApi';

export const initialState: OrganizationState = {
  organization: null,
  activities: {
    countNewConversations: 0,
  },
  decks: [],
  deckCount: 0,
  roles: [],
  productFamilies: [],
  contactAddresses: [],
  emergencyStopTypes: [],
  supportUnits: [],
  personalStopReasons: [],
  parkNumbers: [],
  orderStatus: [],
  customerStatus: [],
  customerTypes: [],
  creditScores: [],
  deliveryRoles: [],
  orderStatusLogistic: [],
  fuelTypes: [],
  vehicleCategories: [],
  roundtripCodes: [],
  activityCodes: [],
  agentGroups: [],
  toolsLicences: [],
  agentContracts: [],
  supportUnitsList: [],
  selectedWarehouse: '',
  leaveRequestTypes: [],
  connectors: [],
  applicationScopePrerequisites: [],
  companyDepartments: [],
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setConfiguration: (
      state,
      {
        payload: {
          organization,
          decks,
          roles,
          deliveryRoles,
          productFamilies,
          contactAddresses,
          supportUnits,
          emergencyStopTypes,
          personalStopReasons,
          parkNumbers,
          orderStatus,
          customerStatus,
          customerTypes,
          creditScores,
          orderStatusLogistic,
          fuelTypes,
          vehicleCategories,
          roundtripCodes,
          agentGroups,
          toolsLicences,
          agentContracts,
          supportUnitsList,
          deckCount,
          leaveRequestTypes,
          connectors,
          applicationScopePrerequisites,
          companyDepartments,
        },
      }: PayloadAction<OrganizationState>,
    ) => {
      state.organization = organization;
      state.decks = decks;
      state.roles = roles;
      state.deliveryRoles = deliveryRoles;
      state.productFamilies = productFamilies;
      state.contactAddresses = contactAddresses;
      state.supportUnits = supportUnits;
      state.emergencyStopTypes = emergencyStopTypes;
      state.personalStopReasons = personalStopReasons;
      state.parkNumbers = parkNumbers;
      state.orderStatus = orderStatus;
      state.customerStatus = customerStatus;
      state.customerTypes = customerTypes;
      state.creditScores = creditScores;
      state.orderStatusLogistic = orderStatusLogistic;
      state.fuelTypes = fuelTypes;
      state.vehicleCategories = vehicleCategories;
      state.roundtripCodes = roundtripCodes;
      state.agentGroups = agentGroups;
      state.toolsLicences = toolsLicences;
      state.agentContracts = agentContracts;
      state.supportUnitsList = supportUnitsList;
      state.deckCount = deckCount;
      state.leaveRequestTypes = leaveRequestTypes;
      state.connectors = connectors;
      state.applicationScopePrerequisites = applicationScopePrerequisites;
      state.companyDepartments = companyDepartments;
    },
    updateSelectedWarehouse: (state, { payload }: PayloadAction<any>) => {
      state.selectedWarehouse = payload;
    },
    setOrganization: (state, { payload }: PayloadAction<any>) => {
      state.organization = payload;
    },
  },
  extraReducers: builder => {
    // @ts-ignore
    builder
      .addMatcher(
        organizationApi.endpoints.getConfiguration.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const {
              organization,
              decks,
              roles,
              deliveryRoles,
              productFamilies,
              contactAddresses,
              supportUnits,
              emergencyStopTypes,
              personalStopReasons,
              parkNumbers,
              orderStatus,
              customerStatus,
              customerTypes,
              creditScores,
              orderStatusLogistic,
              fuelTypes,
              vehicleCategories,
              roundtripCodes,
              activityCodes,
              agentGroups,
              toolsLicences,
              agentContracts,
              supportUnitsList,
              deckCount,
              leaveRequestTypes,
              connectors,
              applicationScopePrerequisites,
              companyDepartments,
            } = response.payload;

            state.organization = organization;
            state.decks = decks;
            state.roles = roles;
            state.deliveryRoles = deliveryRoles;
            state.productFamilies = productFamilies;
            state.contactAddresses = contactAddresses;
            state.supportUnits = supportUnits;
            state.emergencyStopTypes = emergencyStopTypes;
            state.personalStopReasons = personalStopReasons;
            state.parkNumbers = parkNumbers;
            state.customerStatus = customerStatus;
            state.orderStatus = orderStatus;
            state.customerTypes = customerTypes;
            state.creditScores = creditScores;
            state.orderStatusLogistic = orderStatusLogistic;
            state.fuelTypes = fuelTypes;
            state.vehicleCategories = vehicleCategories;
            state.roundtripCodes = roundtripCodes;
            state.activityCodes = activityCodes;
            state.agentGroups = agentGroups;
            state.toolsLicences = toolsLicences;
            state.agentContracts = agentContracts;
            state.supportUnitsList = supportUnitsList;
            state.deckCount = deckCount;
            state.leaveRequestTypes = leaveRequestTypes;
            state.connectors = connectors;
            state.applicationScopePrerequisites = applicationScopePrerequisites;
            state.companyDepartments = companyDepartments;
          }

          return state;
        },
      )
      .addMatcher(
        organizationApi.endpoints.getOrganizationActivities.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const activities = response.payload;

            state.activities = activities;
          }

          return state;
        },
      );
  },
});

const organizationReducer = slice.reducer;

export const { actions: organizationActions } = slice;

export default organizationReducer;
