import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { StateSetter } from 'types';
import { themes } from 'styles/theme/themes';
import { CustomDatePicker } from './styles';
import { CalendarBlank } from '@phosphor-icons/react';
import { Icon } from '../Icon';
import { useHighlightedDay } from './hooks/useHighlightDay';

export interface DatePickerProps {
  dateValue: any;
  setDateValue: StateSetter<Dayjs | null>;
  inputFormat?: string;
  textFieldProps?: TextFieldProps;
  disablePast?: boolean;
  showDots?: boolean;
}

const Search = styled(TextField)({
  marginBottom: 'unset',
});

const today = dayjs().startOf('day');

export default function DropdownDatePicker({
  dateValue = today,
  setDateValue,
  inputFormat = 'DD/MM/YYYY',
  textFieldProps,
  disablePast = true,
  showDots = false,
}: DatePickerProps) {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const { i18n } = useTranslation();

  const { HightLightedDay, onMonthChange } = useHighlightedDay({
    dateValue,
    showDots,
  });

  return (
    <CustomDatePicker>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        <DatePicker
          disablePast={disablePast}
          inputFormat={inputFormat}
          value={dateValue}
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          onOpen={() => setDatePickerOpen(true)}
          onChange={newValue => {
            setDateValue(newValue);
          }}
          InputAdornmentProps={{ position: 'start' }}
          components={{
            OpenPickerIcon: DateRangeIcon,
          }}
          renderDay={(date, selectedDays, pickersDayProps) =>
            HightLightedDay({
              date,
              pickersDayProps,
            })
          }
          onMonthChange={onMonthChange}
          className="custom-date-picker"
          renderInput={params => (
            <Search
              hiddenLabel
              size="small"
              onClick={() => setDatePickerOpen(true)}
              {...params}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => setDatePickerOpen(true)}
                  >
                    <Icon
                      icon={<CalendarBlank />}
                      color={
                        dateValue?.isAfter(today.startOf('day'), 'day')
                          ? themes?.default?.summerSky
                          : dateValue?.isBefore(today.startOf('day'), 'day')
                            ? themes?.default?.darkGray
                            : themes?.default?.greenStatus
                      }
                    />
                  </InputAdornment>
                ),
              }}
              {...textFieldProps}
            />
          )}
        />
      </LocalizationProvider>
    </CustomDatePicker>
  );
}
