import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { themes } from 'styles/theme/themes';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function OrderPriority({ priority }) {
  const { t } = useTranslation();
  const iconSx = { height: '1.25rem', width: '1.25rem' };
  return (
    <div className="data" style={{ maxWidth: '70px' }}>
      {priority === 'high' ? (
        <Tooltip title={t('common.high')}>
          <KeyboardDoubleArrowUpIcon
            sx={{ ...iconSx, color: themes?.default?.redStatus }}
          />
        </Tooltip>
      ) : priority === 'medium' ? (
        <Tooltip title={t('common.medium')}>
          <DragHandleIcon
            sx={{ ...iconSx, color: themes?.default?.darkTangerine }}
          />
        </Tooltip>
      ) : (
        <Tooltip title={t('common.low')}>
          <KeyboardDoubleArrowDownIcon
            sx={{ ...iconSx, color: themes?.default?.greenStatus }}
          />
        </Tooltip>
      )}
    </div>
  );
}
