import {
  Department,
  DepartmentResponseDto,
} from '../../types/CompanyDepartments';

export const processDepartments = (response: any) => {
  const deps = response.docs.map((department: any) => {
    return {
      id: department._id,
      name: department.name,
      manager: department.cache?._manager.name,
      organization: department._organization,
      deleted: department.deleted,
      managerId: department._manager,
    };
  }) as Department[];
  const totalDocs = response.totalDocs;
  return { departments: deps, totalDocs } as DepartmentResponseDto;
};
