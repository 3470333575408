import { removeUndefined } from '../../../utils/dto';

const roundtripByAgentVehicle = (response: any) => {
  if (!response) return;
  return {
    fuelType: response.fuelType,
    _id: response._id,
    capacity: response.capacity,
    odometer: response.odometer,
    unloadparking: response.unloadparking,
    lastPosition: response.lastPosition,
  };
};

const productDto = (response: any) => {
  if (!response) return;
  return {
    _id: response._id,
    _product: response._product,
    quantity: response.quantity,
  };
};

const productsDto = (response: any) => {
  if (!response) return;
  return response.map((product: any) => {
    return productDto(product);
  });
};

const orderCacheDto = (response: any) => {
  if (!response) return;
  return {
    _deck: response._deck,
  };
};

const orderCustomer = (response: any) => {
  if (!response) return;
  return {
    publicName: response.publicName,
    code: response.code,
    phone: response.phone,
    _salesman: response._salesman,
  };
};

const stopOrderDto = (response: any) => {
  if (!response) return;
  return {
    supportUnitsList: response.supportUnitsList,
    _id: response._id,
    code: response.code,
    updatedStatusAt: response.updatedStatusAt,
    amountRequested: response.amountRequested,
    type: response.type,
    status: response.status,
    date: response.date,
    _organization: response._organization,
    _deck: response._deck,
    cache: orderCacheDto(response.cache),
    products: productsDto(response.products),
    _customer: orderCustomer(response._customer),
    totalWeight: response.totalWeight,
    deliveryComment: response.deliveryComment,
    phoneBooks: response.phoneBooks,
    deliveryInfo: response.deliveryInfo,
  };
};

const stopResponse = (response: any) => {
  if (!response) return;
  return {
    _id: response._id,
    id: response.id,
    type: response.type,
    status: response.status,
    updatedStatusAt: response.updatedStatusAt,
    fuelQuantity: response.fuelQuantity,
    _order: stopOrderDto(response._order),
    documents: response.documents,
    _place: response._place,
    visitStopDetails: response.visitStopDetails,
  };
};

const getRoundtripStops = (response: any) => {
  if (!response) return;
  return response.map((stop: any) => {
    return stopResponse(stop);
  });
};

export const getRoundtripByAgentDto = (response: any) => {
  return removeUndefined({
    _id: response._id,
    code: response.code,
    internalCode: response.internalCode,
    date: response.date,
    _cluster: response._cluster,
    _startPlace: response._startPlace,
    _endPlace: response._endPlace,
    _vehicle: roundtripByAgentVehicle(response._vehicle),
    status: response.status,
    defaultStartAt: response.defaultStartAt,
    defaultRoundtripDelayTime: response.defaultRoundtripDelayTime,
    stops: getRoundtripStops(response.stops),
    cache: response.cache,
    startAt: response.startAt,
    countOrdersLoaded: response.countOrdersLoaded,
    countOrders: response.countOrders,
    stopCount: response.stopCount,
    countStops: response.countStops,
    supportUnitsList: response.supportUnitsList,
    supportUnits: response.supportUnits,
    id: response.id,
  });
};
