import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import {
  Button,
  Grid,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

import { useCreatePaymentMutation } from 'common/services/payments';

export default function DocItem({ document, onOpenDocument, paymentEnable }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');
  const [createPayment, { isLoading: paymentIsLoading }] =
    useCreatePaymentMutation();

  const handlePay = async document => {
    console.log(document);
    const res = await createPayment({
      okUrl: `${window.location.origin}/ordoria?payment=ok`,
      koUrl: `${window.location.origin}/ordoria?payment=ko`,
      fileId: document._id,
    }).unwrap();

    console.log(res);

    if (res?.paymentUrl) {
      window.open(res.paymentUrl, '_blank');
    }
  };

  return (
    <Card
      key={document.id}
      style={{
        marginBottom: 16,
        height: isMobile ? 'auto' : 61,
        width: isMobile ? '100%' : '1000px',
      }}
    >
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={7}>
            <ContentWrapper>
              {document.metadata.type === 'invoice' ? (
                <Chip
                  label={`${t(`customer.documentTypes.${document.metadata.type}`)}`}
                  sx={{
                    backgroundColor: themes?.default?.green30,
                    color: themes?.default?.accordionWhiteBg,
                    fontSize: '0.875rem',
                  }}
                />
              ) : document.metadata.type === 'delivery' ? (
                <Chip
                  label={`${t(`customer.documentTypes.${document.metadata.type}`)}`}
                  sx={{
                    backgroundColor: themes?.default?.egyptianBlue,
                    color: themes?.default?.accordionWhiteBg,
                    fontSize: '0.875rem',
                  }}
                />
              ) : (
                <Chip
                  label={`${t(`customer.documentTypes.${document.metadata.type}`)}`}
                  sx={{
                    backgroundColor: themes.default.carmine,
                    color: themes?.default?.accordionWhiteBg,
                    fontSize: '0.875rem',
                  }}
                />
              )}
              <Typography
                sx={{ marginLeft: '20px', marginRight: '20px' }}
                color="text.secondary"
                fontSize="1rem"
              >
                {dayjs(document.metadata?.date || document.createdAt).format(
                  'L',
                )}
              </Typography>

              {document.viewAt ? (
                <ViewAtWrapper>
                  <Tooltip
                    title={t('customerPortal.documents.viewed', {
                      date: dayjs(document.viewAt).format('LLL'),
                    })}
                  >
                    <Typography
                      fontSize="1rem"
                      fontWeight="500"
                      color="primary"
                    >
                      {document.name}
                    </Typography>
                  </Tooltip>
                </ViewAtWrapper>
              ) : (
                <Typography fontSize="1rem" fontWeight="500">
                  {document.name}
                </Typography>
              )}
            </ContentWrapper>
          </Grid>
          <Grid item xs={12} md={5}>
            <ButtonWrapper>
              {paymentEnable &&
                document.metadata.type === 'invoice' &&
                document._payment?.status !== 'success' && (
                  <>
                    {paymentIsLoading ? (
                      <LoadingButton
                        variant="contained"
                        size="small"
                        sx={{ height: '30px', marginLeft: '16px' }}
                      >
                        {t('customerPortal.documents.payOnlineInProgress')}
                      </LoadingButton>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => handlePay(document)}
                        size="small"
                        sx={{ height: '30px', marginLeft: '16px' }}
                      >
                        {t('customerPortal.documents.payOnline')}
                      </Button>
                    )}
                  </>
                )}

              {document._payment?.status === 'success' && (
                <Tooltip
                  title={`Payée en ligne le ${window.dayjs(document._payment?.statusUpdatedAt || document._payment?.updatedAt).format('LLL')}`}
                >
                  <Chip label="Payée" color="success" variant="outlined" />
                </Tooltip>
              )}

              <Button
                onClick={() => onOpenDocument(document)}
                size="small"
                sx={{ height: '30px', marginLeft: '16px' }}
              >
                <VisibilityIcon sx={{ marginRight: '3px' }} />
                PDF
              </Button>
            </ButtonWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    justify-content: flex-start;
  }
`;

const ViewAtWrapper = styled.div`
  display: flex;
  align-items: center;
`;
