import { Alert, IconButton, Stack, Typography } from '@mui/material';
import { BellRinging, ReadCvLogo, X } from '@phosphor-icons/react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { themes } from 'styles/theme/themes';
import { Icon } from '../Icon';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useRef } from 'react';
import { MAILER_EVENTS } from './action';
import { OrderStatus, RoundtripStatus } from 'common/utils/enum';
import If from '../If';
import { StopStatusUpdated } from './components/StopStatusUpdated';

interface Props {
  onClose: () => void;
  content: React.ReactNode | any;
  url?: { path: string; options?: NavigateOptions };
  activityName?: string;
}

export const Notification = ({
  onClose,
  content,
  url,
  activityName,
}: Props) => {
  const navigate = useNavigate();
  const displayed = content || <></>;
  const time = useRef(dayjs().format('HH:mm'));
  const status = content.props.status;

  const handleIconColor = () => {
    const colorMap = {
      [MAILER_EVENTS.ORDER_STATUS_UPDATED]: {
        [OrderStatus.CANCELED]: themes?.default?.chipRedBg,
        [OrderStatus.DELIVERED]: themes?.default?.fruitSaladGreen,
      },
      [MAILER_EVENTS.ROUNDTRIP_STATUS_UPDATED]: {
        [RoundtripStatus.CANCELED]: themes?.default?.chipRedBg,
        [RoundtripStatus.COMPLETED]: themes?.default?.fruitSaladGreen,
      },
    };
    return activityName
      ? colorMap?.[activityName]?.[status] || themes?.default?.primary
      : themes?.default?.primary;
  };

  return (
    <Wrapper>
      <Alert
        variant="outlined"
        onClose={onClose}
        icon={<BellRinging size={28} color={handleIconColor()} />}
        action={
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClose}
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
              }}
            >
              <Icon icon={<X />} />
            </IconButton>
            <If
              condition={
                activityName !== MAILER_EVENTS.ROUNDTRIP_STATUS_UPDATED &&
                activityName !== MAILER_EVENTS.ORDER_STATUS_UPDATED
              }
            >
              <Typography
                fontSize={'0.85rem'}
                color={themes?.default?.textColorSecondary}
              >
                {time.current}
              </Typography>
            </If>
          </>
        }
        onClick={() => {
          if (!url) {
            return;
          }
          onClose();
          navigate(url.path, { ...url.options });
        }}
        sx={{
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '4px',
          boxShadow: `0px 24px 48px -12px ${themes.default.notificationBoxShadow}`,
          alignItems: 'center',
          cursor: 'pointer',
          paddingRight: '40px',
          minWidth: '352px',
          height: '54px',
          background: themes?.default?.lightBlueBg,
          flex: 1,
        }}
      >
        <Stack direction={'row'} justifyContent={'space-between'} flex={1}>
          {displayed}
        </Stack>
      </Alert>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${props => props.theme.gradiantBlueBg};
  padding: 1px;
  border-radius: 4px;
`;
