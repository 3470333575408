import api from './api';

export const paymentsApi = api.injectEndpoints({
  endpoints: builder => ({
    getPayments: builder.query<any, string>({
      query: urlParams => ({
        url: `/payments${urlParams}`,
      }),
      providesTags: ['Payments'],
    }),
    createPayment: builder.mutation<any, any>({
      query: body => ({
        url: `/payments`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetPaymentsQuery, useCreatePaymentMutation } = paymentsApi;

export default paymentsApi;
