import { Box, Chip, Radio, Typography } from '@mui/material';
import { themes } from 'styles/theme/themes';

export const ChipWithRadio = ({
  value,
  label,
  selectedValue,
  disabled = false,
}) => {
  const checked = selectedValue === value;
  return (
    <Chip
      label={
        <Box display="flex" alignItems="center" marginRight="12px">
          <Radio
            value={value}
            checked={checked}
            sx={{
              p: 0,
              mr: '4px',
              color: checked
                ? themes?.default?.primaryActiveColor
                : themes?.default?.chipsBorderGrey,
              '&.Mui-checked': {
                color: checked
                  ? themes?.default?.primaryActiveColor
                  : themes?.default?.chipsBorderGrey,
              },
            }}
            disabled={disabled}
          />
          <Typography fontWeight={checked ? '500' : '400'} fontSize="1rem">
            {label}
          </Typography>
        </Box>
      }
      variant="outlined"
      style={{
        backgroundColor: checked ? themes?.default?.primaryLight1 : 'white',
        borderColor: checked
          ? themes?.default?.strokePrimary
          : themes?.default?.chipsBorderGrey,
        color: checked
          ? themes?.default?.primaryActiveColor
          : themes?.default?.textBlack,
        marginLeft: '12px',
      }}
      size="medium"
    />
  );
};
