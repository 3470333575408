import { toolsActions } from 'app/slices/tools';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

export const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const pdfUrl = `${process.env.PUBLIC_URL}/pdf/privacy-policy.pdf`;

  useEffect(() => {
    dispatch(toolsActions.setZoomIn({ status: true }));
    return () => {
      dispatch(toolsActions.setZoomIn({ status: false }));
    };
  });
  return (
    <Wrapper>
      <h1>PRIVACY POLICY</h1>

      <p>
        By connecting to the platform and/or downloading the application, you
        acknowledge that you have read, understood and accepted, without
        limitation or reservation, the personal data protection policy
        (hereinafter the "Privacy Policy").
      </p>

      <p>
        The purpose of this Privacy Policy is to inform Data subjects, and in
        particular Clients, Users and Prospective customers, on the collection
        and processing of their personal data when using the platform and/or the
        application.
      </p>

      <p>
        The Privacy Policy also aims at informing Data subjects of the rights
        and freedoms they may assert with regard to the use made of their
        personal data, and describes the measures implemented to protect these
        rights.
      </p>

      <p>
        The processing of personal data is carried out in accordance with the
        applicable regulations, in particular Regulation (EU) 2016/679 on the
        protection of individuals with regard to the processing of personal data
        and on the free movement of such data (hereinafter "GDPR"), French Law
        no. 78-17 on information technology, files and freedoms and the
        recommendations of the French Data Protection Authority (CNIL).
      </p>

      <p>Information relating to data processing is presented as follows:</p>

      <ol>
        <li>Data controller</li>
        <li>Data subjects whose personal data are collected and processed</li>
        <li>Categories of personal data processed (non-exhaustive list)</li>
        <li>
          Information on data processing (purpose, legal basis and retention
          period)
        </li>
        <li>Recipients of the personal data</li>
        <li>Security and confidentiality of personal data</li>
        <li>Data subject rights and requests relating to data protection</li>
      </ol>

      <h2>1. Data controller</h2>

      <p>
        BAMPTEE SAS, located at 128 rue de la Boétie, 75008 Paris, France,
        registered in the Paris Trade and Companies Register under SIREN number
        984 837 179, acts as data controller for certain data processing
        operations and as data processor for other data processing operations.
      </p>

      <p>
        For the processing of personal data defined in article 4.1, BAMPTEE acts
        as data controller.
      </p>

      <p>
        For the processing of personal data defined in article 4.2, BAMPTEE acts
        as a processor for the Client prescribing the application used.
      </p>

      <h2>2. Data subjects whose personal data are collected and processed</h2>

      <p>
        The Data subjects whose personal data are collected and processed are
        those defined below:
      </p>

      <ul>
        <li>
          <strong>Client:</strong> any natural person or legal entity having
          concluded a contract with BAMPTEE to access the services offered by
          BAMPTEE;
        </li>
        <li>
          <strong>Beneficiary users (clients who receive deliveries):</strong>{' '}
          any natural person or legal entity having a contractual relationship
          with the Client and benefiting from the services offered by BAMPTEE
          via an access given by the Client;
        </li>
        <li>
          <strong>Users:</strong> natural person using the application;
        </li>
        <li>
          <strong>Data subjects:</strong> any identified or identifiable natural
          person, and in particular Clients and Users.
        </li>
      </ul>

      <h2>3. Categories of personal data processed (non-exhaustive list)</h2>

      <p>BAMPTEE processes the following personal data:</p>

      <ul>
        <li>
          <strong>The Client's personal data:</strong> surname, first name,
          contact details, etc.
        </li>
        <li>
          <strong>
            Personal data relating to the Users of the application:
          </strong>
          surname and first name of the driver, car registration number plate,
          location, number of kilometres travelled, loading status, delivery
          status, addresses, journey history, etc.
        </li>
        <li>
          <strong>
            Personal data relating to Beneficiary Users (clients who receive
            deliveries):
          </strong>
          surname, first name, email address, order information, delivery
          details, logistics, invoicing data, etc.
        </li>
      </ul>

      <h2>
        4. Information on data processing (purpose, legal basis and retention
        period)
      </h2>

      <h3>4.1 Data processing for which BAMPTEE acts as data controller</h3>

      <table>
        <tr>
          <th>Purpose of the processing</th>
          <th>Legal basis</th>
          <th>Data retention*</th>
        </tr>
        <tr>
          <td>Managing the commercial relationship with the Client</td>
          <td>Performance of the contract</td>
          <td>
            Duration of the contractual relationship and 5 years intermediate
            archiving
          </td>
        </tr>
        <tr>
          <td>Application security control (connection data management)</td>
          <td>Legal obligation</td>
          <td>12 months</td>
        </tr>
        <tr>
          <td>Managing commercial prospecting</td>
          <td>Legitimate interest / Consent</td>
          <td>
            Until consent is withdrawn or 3 years from the date or the last
            contract from the prospect
          </td>
        </tr>
        <tr>
          <td>Billing/accounting</td>
          <td>Legal obligation</td>
          <td>10 years</td>
        </tr>
      </table>

      <p>
        * The duration of the active database corresponds to the period during
        which the data is used before being permanently deleted or archived
        during the limitation period applicable in civil, commercial and tax
        matters. When personal data is kept in an archive, it is no longer used
        by BAMPTEE for the purposes determined but is of administrative interest
        to BAMPTEE (for example, for the management of a possible litigation) or
        must be kept to meet a legal obligation. The data may then be consulted
        on an ad hoc basis by specifically authorised persons.
      </p>

      <h3>4.2 Data processing for which BAMPTEE acts as data processor</h3>

      <p>
        BAMPTEE processes personal data in accordance with the Client's
        instructions and according to the functionalities of the platform and/or
        application used by the Client. BAMPTEE may therefore carry out the
        processing operations relating to the following:
      </p>

      <ul>
        <li>Management of access and connection to the application</li>
        <li>Delivery tracking (geolocation)</li>
        <li>Data hosting</li>
        <li>Invoicing and complaints service</li>
      </ul>

      <p>
        The personal data processed by BAMPTEE in the name and on behalf of the
        Client is kept for a period that complies with legal and regulatory
        provisions and does not exceed that decided by the Client in the context
        of its use of the platform and the application.
      </p>

      <h2>5. Recipients of the personal data</h2>

      <p>
        Personal data is processed by BAMPTEE's employees. The information may
        also be transmitted to certain 'Service Providers' (any natural person
        who has entered into a service provision contract with BAMPTEE) in order
        to enable BAMPTEE to provide the services offered. These Service
        Providers act on the instructions of BAMPTEE, and BAMPTEE ensures that
        the data transmitted is processed solely within the framework of the
        services offered.
      </p>

      <h2>6. Security and confidentiality of personal data</h2>

      <p>
        Personal data is hosted within the European Union. BAMPTEE implements
        appropriate measures in order to guarantee a level of protection adapted
        to the risk in terms of confidentiality, integrity, availability, and
        resilience of systems and, in particular, in order to prevent personal
        data from being falsified, damaged, or communicated to unauthorised
        third parties.
      </p>

      <h2>7. Data subject rights and requests relating to data protection</h2>

      <p>
        In accordance with the regulations in force, any Data subject may
        contact BAMPTEE, whose contact details are below, in order to exercise:
      </p>

      <ul>
        <li>
          <strong>the right of access:</strong> to ask BAMPTEE whether it holds
          data concerning him or her, and to request communication in order to
          verify the content;
        </li>
        <li>
          <strong>the right to rectification:</strong> this involves the
          rectification of inaccurate or incomplete information concerning a
          Data Subject. This prevents an organisation from using or circulating
          incorrect information on the data subject;
        </li>
        <li>
          <strong>the right to erasure (if applicable):</strong> the right to
          ask BAMPTEE to erase personal data concerning Data Subjects;
        </li>
        <li>
          <strong>
            the right to restriction of processing (if applicable):
          </strong>
          the right to ask BAMPTEE to temporarily freeze the use of certain
          data;
        </li>
        <li>
          <strong>the right to data portability (if applicable):</strong> the
          possibility of recovering part of their data in an open and
          machine-readable format;
        </li>
        <li>
          <strong>
            the right to object to the processing of data (if applicable):
          </strong>
          the possibility for any Data Subject to object at any time to BAMPTEE
          using some of their data for a specific purpose. This concerns in
          particular the right to object to any commercial canvassing or to be
          contacted by BAMPTEE, by email or by SMS/MMS for other similar
          products or services.
        </li>
      </ul>

      <p>
        Any Data Subject may lodge a complaint with the CNIL for any difficulty
        relating to the processing of his/her data:
        https://www.cnil.fr/fr/plaintes.
      </p>

      <p>
        For any request concerning personal data, please send an email to the
        following address: contact@bamptee.com.
      </p>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  line-height: 1.6;
  max-width: 800px;
  margin: auto;
  padding: 20px;

  ul {
    padding-left: 20px;
  }

  th,
  td {
    border: 1px solid;
    padding: 10px;
  }
`;
