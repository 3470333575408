import api from './api';

export const storageLocationApi = api.injectEndpoints({
  endpoints: builder => ({
    getOrganizationsAreas: builder.query<any, string>({
      query: urlParams => ({
        url: `/storage-area${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Areas'],
    }),
    getTypeAisles: builder.query<any, string>({
      query: typeId => ({
        url: `/storage-aisle/options/create?_storageType=${typeId}`,
        method: 'GET',
      }),
      providesTags: ['Areas'],
    }),
    getTypeList: builder.query<any, string>({
      query: urlParams => ({
        url: `/storage-location-type/options`,
        method: 'GET',
      }),
      providesTags: ['Areas'],
    }),
    addArea: builder.mutation<any, any>({
      query: body => ({
        url: `/storage-area/`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    deleteArea: builder.mutation<any, { areaId: string }>({
      query: ({ areaId }) => ({
        url: `/storage-area/${areaId}`,
        method: 'DELETE',
      }),
    }),
    renameArea: builder.mutation<any, { areaId: string; newName: string }>({
      query: ({ areaId, newName }) => ({
        url: `/storage-area/${areaId}/rename`,
        method: 'PATCH',
        body: { name: newName },
      }),
    }),

    getLocationsByAreaId: builder.query<any, string>({
      query: urlParams => ({
        url: `/storage-location${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Areas'],
    }),
    getLocationsStatsByAreaId: builder.query<any, string>({
      query: urlParams => ({
        url: `/storage-location/stats${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Areas'],
    }),
    getAisleLevelsByAisleId: builder.query<any, string>({
      query: urlParams => ({
        url: `/storage-level/options${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Levels'],
    }),
    getLevelLocationsByLevelId: builder.query<any, string>({
      query: urlParams => ({
        url: `/storage-level-location/options${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['locations'],
    }),
    createStorageLocation: builder.mutation<any, any>({
      query: body => ({
        url: `/storage-location`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Locations'],
    }),
    getAreaAisles: builder.query<any, string>({
      query: areaId => ({
        url: `/storage-aisle/options?_storageArea=${areaId}`,
        method: 'GET',
      }),
      providesTags: ['Areas'],
    }),
    archiveLocation: builder.mutation<any, { locationsIds: string[] }>({
      query: ({ locationsIds }) => ({
        url: `/storage-location/archive`,
        method: 'PATCH',
        body: { _locations: locationsIds },
      }),
    }),
    getLocationInfo: builder.query<any, string>({
      query: locationId => ({
        url: `/storage-location/${locationId}`,
        method: 'GET',
      }),
      providesTags: ['Locations'],
    }),
    updateStorageLocation: builder.mutation<any, { locationId; body }>({
      query: ({ locationId, body }) => ({
        url: `/storage-location/${locationId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Locations'],
    }),
  }),
});

export const {
  useGetOrganizationsAreasQuery,
  useAddAreaMutation,
  useGetTypeAislesQuery,
  useGetTypeListQuery,
  useDeleteAreaMutation,
  useRenameAreaMutation,
  useGetLocationsByAreaIdQuery,
  useGetLocationsStatsByAreaIdQuery,
  useGetAisleLevelsByAisleIdQuery,
  useGetLevelLocationsByLevelIdQuery,
  useCreateStorageLocationMutation,
  useGetAreaAislesQuery,
  useArchiveLocationMutation,
  useGetLocationInfoQuery,
  useUpdateStorageLocationMutation,
} = storageLocationApi;

export default storageLocationApi;
