import api from './api';

export const activityApis = api.injectEndpoints({
  endpoints: builder => ({
    getActivities: builder.query<any, string>({
      query: urlQuery => ({
        url: `/activities?${urlQuery}`,
      }),
      providesTags: ['Activities'],
    }),
    activitiesRead: builder.mutation<any, { activityIds: string[] }>({
      query: ({ activityIds }) => ({
        url: `/activities/read`,
        method: 'POST',
        body: { activityIds },
      }),
      invalidatesTags: ['Activities'],
    }),
  }),
});

export const { useGetActivitiesQuery, useActivitiesReadMutation } =
  activityApis;

export default activityApis;
