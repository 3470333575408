import { SxProps, Theme, TableCell, TableCellProps } from '@mui/material';
import { useGrid } from '../../hooks';
import { useMemo } from 'react';
import If from 'app/components/If';
import { Column } from '../../types';
import { themes } from 'styles/theme/themes';

interface Props extends TableCellProps {
  columnId?: string;
  children?: React.ReactNode;
}
export const GridTableCell = ({ columnId, children, ...props }: Props) => {
  const { columns, columnWidths, withCheckbox } = useGrid();

  const column: Column | undefined = useMemo(() => {
    return columns.find(c => c.id === columnId);
  }, [columns, columnId]);

  const cellStyle: SxProps<Theme> = useMemo(() => {
    const columnStyle = column?.cellStyle || {};

    let left = 0;
    if (column?.sticky === 'left') {
      const columnIndex = columns.findIndex(c => c.id === columnId);
      if (columnIndex > 0) {
        left = columns
          .slice(0, columnIndex)
          .reduce((sum, col) => sum + (columnWidths[col.id] || 0), 0);
      }
    }

    if (withCheckbox) {
      left += themes.default.gridCheckboxWidth;
    }

    return {
      ...props.sx,
      ...columnStyle,
      position: column?.sticky ? 'sticky' : 'static',
      left: column?.sticky === 'left' ? `${left}px` : undefined,
    } as SxProps<Theme>;
  }, [
    column?.cellStyle,
    column?.sticky,
    columnId,
    columnWidths,
    columns,
    props.sx,
    withCheckbox,
  ]);

  return (
    <If condition={!!column && !column?.disableRow} otherwise={<></>}>
      <TableCell {...props} sx={cellStyle}>
        {children}
      </TableCell>
    </If>
  );
};
