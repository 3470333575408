import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import { SquaresFour, Users, PresentationChart } from '@phosphor-icons/react';

import { MainNavItem, ScrollableSection, OptionsWrapper } from '../helpers';
import { DASHBOARD_ALL, IAM, REPORTS_ALL } from '../../../../utils/routes';
import { themes } from '../../../../styles/theme/themes';
import { checkUserAcl } from 'common/helpers/acl';
import { Icon } from 'app/components/Icon';
import styled from 'styled-components';
import { isActiveRoute } from '../index';
import { IconWrapper } from '../../../../styles/components/muitable/TableViewPopover';
import { MenuTitleWrapper } from '../Components';

const dashboardAll = `${IAM}${DASHBOARD_ALL}`;
const reportAll = `${IAM}${REPORTS_ALL}`;

export default function IAMMenu({ theme, mode, authUser }) {
  const { t } = useTranslation();
  const location = useLocation();
  const isDashboardAll = location.pathname === dashboardAll;
  const isReportAll = location.pathname === reportAll;
  const isMinify = mode === 'minify';
  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>([0]);

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex(prevIndices => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter(i => i !== index);
      } else {
        return [index];
      }
    });
  };

  if (isDashboardAll || isReportAll) {
    return (
      <OptionsWrapper>
        <MainNavItem
          to={dashboardAll}
          text={t('nav.mainNav.dashboard')}
          mode={mode}
          icon={
            <Icon
              icon={<SquaresFour />}
              color={
                location.pathname === dashboardAll
                  ? theme.primaryActiveColor
                  : themes?.default?.black54
              }
              size={20}
            />
          }
        />

        <MainNavItem
          to={reportAll}
          text={t('nav.mainNav.reports')}
          mode={mode}
          icon={
            <IconWrapper isActive={isActiveRoute(location, reportAll)}>
              <PresentationChart />
            </IconWrapper>
          }
        />
      </OptionsWrapper>
    );
  }

  return (
    <ScrollableSection>
      {checkUserAcl(authUser, ['manageCustomers']) && (
        <>
          <OptionsWrapper>
            <MenuTitleWrapper
              isMinify={isMinify}
              onClick={() => handleRowToggle(0)}
              openSectionIndex={openSectionIndex}
              theme={theme}
              title={t('nav.mainNav.title2')}
              index={0}
            >
              <MainNavItem
                to={'/iam/employees'}
                text={t('nav.mainNav.employees')}
                mode={mode}
                icon={
                  <Icon
                    icon={<Users />}
                    color={
                      location.pathname === '/iam/employees'
                        ? theme.primaryActiveColor
                        : themes?.default?.black54
                    }
                    size={20}
                  />
                }
              />
            </MenuTitleWrapper>
          </OptionsWrapper>
        </>
      )}
    </ScrollableSection>
  );
}
