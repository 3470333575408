import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Popover,
  SxProps,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  CircularProgress,
} from '@mui/material';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'common/hooks/useDebounce';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import { useUpdateOrderSupportUnitsMutation } from 'common/services/orderApi';
import { Icon } from 'app/components/Icon';
import { Info, Sparkle } from '@phosphor-icons/react';
import SupportSelector from './components/SupportSelector';
import { selectConfiguration } from 'common/store/organization/selectors';
import { useLazyGetOrdersIdQuery } from 'common/services/orderApi';

interface Props {
  widthButton?: any;
  supportUnits: any;
  canEdit: boolean;
  updateOrderFunction?: any;
  stopId?: string;
  isOverLoaded?: boolean;
  tooltipDisabled?: boolean;
  refreshData?: () => void;
  displayOnHover?: boolean;
  disableButton?: boolean;
  updateOrderCache?: Function;
  roundtripId?: string;
  buttonSx?: SxProps;
  roundtripStatus?: string;
}

export function SupportUnitsPopover({
  widthButton,
  supportUnits,
  canEdit,
  updateOrderFunction,
  stopId,
  isOverLoaded,
  tooltipDisabled,
  refreshData,
  displayOnHover,
  disableButton,
  updateOrderCache,
  roundtripId,
  buttonSx,
  roundtripStatus,
}: Props) {
  const { t } = useTranslation();

  const [UpdateOrders] = useUpdateOrderSupportUnitsMutation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [supportUnits_, setSupportUnits_]: any = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const open = Boolean(anchorEl);
  const configurationSelector = useSelector(selectConfiguration);
  const supportUnitsList = configurationSelector?.supportUnitsList;

  const [triggerGetOrder, { data: fullOrder }] = useLazyGetOrdersIdQuery();

  useEffect(() => {
    if (open && stopId) {
      triggerGetOrder(stopId);
    }
  }, [open, stopId, triggerGetOrder, JSON.stringify(supportUnits)]);

  useEffect(() => {
    var allLoadedSupportUnits = supportUnitsList?.map(sp => {
      var quantity = 0;
      fullOrder?.supportUnitListLocations?.map(loadedSp => {
        if (loadedSp?._supportUnit?._id === sp?._id) {
          quantity++;
        }
      });
      if (quantity > 0) return { _supportUnit: sp, quantity: quantity };
    });

    allLoadedSupportUnits = allLoadedSupportUnits?.filter(
      unit => unit !== undefined,
    );

    const result = {
      ...fullOrder?.supportUnitsList,
      loaded: allLoadedSupportUnits,
    };
    if (
      !result?.planned &&
      !result?.delivered &&
      !result?.collected &&
      result?.loaded?.length === 0
    ) {
      setSupportUnits_(null);
    } else {
      setSupportUnits_(result);
    }
  }, [
    supportUnitsList,
    JSON.stringify(supportUnits),
    JSON.stringify(fullOrder),
  ]);

  const displayedSupportUnits = useMemo(() => {
    return supportUnitsList?.map(support => {
      const existingSupport = supportUnits?.planned?.find(
        su => su?._supportUnit?._id === support._id,
      );
      if (existingSupport) {
        return existingSupport;
      }
      return {
        _supportUnit: support,
        quantity: 0,
      };
    });
  }, [supportUnitsList, JSON.stringify(supportUnits)]);

  const disabled = !!tooltipDisabled;
  const [list, setList] = useState<any>(displayedSupportUnits || []);

  const [supportArrayCopy, setSupportArrayCopy] = useState<any>(
    displayedSupportUnits || [],
  );
  const debouncedSupportArray = useDebounce(supportArrayCopy, 500);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    handleUpdate();
  }, [JSON.stringify(debouncedSupportArray)]);

  const handleUpdate = async () => {
    if (typeof updateOrderFunction === 'function' && canEdit && open) {
      await updateOrderFunction(
        stopId,
        {
          supportUnitsList: {
            planned: debouncedSupportArray?.filter(su => {
              return su._supportUnit;
            }),
            delivered: supportUnits_
              ? supportUnits_?.delivered
              : supportUnits?.delivered || [],
            collected: supportUnits_
              ? supportUnits_?.collected
              : supportUnits?.collected || [],
          },
        },
        UpdateOrders,
      );
      setList(debouncedSupportArray);
      refreshData && refreshData();
      setLoadingUpdate(false);
    }
  };
  const [supportList, setSupportList] = useState<any>();
  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disableButton) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    !loadingUpdate && setAnchorEl(null);
  };

  useEffect(() => {
    if (!canEdit || !open) {
      setList(displayedSupportUnits || []);
      setSupportArrayCopy(displayedSupportUnits || []);
    }
  }, [displayedSupportUnits, canEdit]);

  const AIAEstColVisible = useMemo(() => {
    return supportUnits_?.AIAEst?.length
      ? supportUnits_?.AIAEst?.length > 0 &&
          supportUnits_?.AIAEst?.some(su => su?.quantity > 0)
      : supportUnits?.AIAEst?.length > 0 &&
          supportUnits?.AIAEst?.some(su => su?.quantity > 0);
  }, [
    JSON.stringify(supportUnits_?.AIAEst),
    JSON.stringify(supportUnits?.AIAEst),
  ]);

  const estimatedColVisible = useMemo(() => {
    if (canEdit) {
      return true;
    } else {
      return supportUnits_?.planned?.length
        ? supportUnits_?.planned?.length > 0 &&
            supportUnits_?.planned?.some(su => su?.quantity > 0)
        : supportUnits?.planned?.length > 0 &&
            supportUnits?.planned?.some(su => su?.quantity > 0);
    }
  }, [
    JSON.stringify(supportUnits_?.planned),
    JSON.stringify(supportUnits?.planned),
  ]);

  const deliveredColVisible = useMemo(() => {
    if (stopId && !['delivered'].includes(fullOrder?.status) && fullOrder) {
      return false;
    } else if (
      roundtripStatus &&
      ['pending', 'planned', 'loaded'].includes(roundtripStatus)
    ) {
      return false;
    } else
      return supportUnits_?.delivered?.length
        ? supportUnits_?.delivered?.length > 0 &&
            supportUnits_?.delivered?.some(su => su?.quantity > 0)
        : supportUnits?.delivered?.length > 0 &&
            supportUnits?.delivered?.some(su => su?.quantity > 0);
  }, [
    JSON.stringify(supportUnits_?.delivered),
    JSON.stringify(supportUnits?.delivered),
    JSON.stringify(fullOrder),
  ]);

  const collectedColVisible = useMemo(() => {
    return supportUnits_?.collected?.length
      ? supportUnits_?.collected?.length > 0 &&
          supportUnits_?.collected?.some(su => su?.quantity > 0)
      : supportUnits?.collected?.length > 0 &&
          supportUnits?.collected?.some(su => su?.quantity > 0);
  }, [
    JSON.stringify(supportUnits_?.collected),
    JSON.stringify(supportUnits?.collected),
  ]);

  const loadedColVisible = useMemo(() => {
    return supportUnits_?.loaded?.length
      ? supportUnits_?.loaded?.length > 0 &&
          supportUnits_?.loaded?.some(su => su?.quantity > 0)
      : supportUnits?.loaded?.length > 0 &&
          supportUnits?.loaded?.some(su => su?.quantity > 0);
  }, [
    JSON.stringify(supportUnits_),
    JSON.stringify(supportUnits),
    roundtripStatus,
  ]);

  const unloadedColVisible = useMemo(() => {
    return (
      supportUnits?.unloaded?.length > 0 &&
      supportUnits?.unloaded?.some(su => su?.quantity > 0)
    );
  }, [supportUnits_, JSON.stringify(supportUnits?.unloaded)]);

  useEffect(() => {
    if (!displayedSupportUnits?.length) {
      return;
    }
    let displayedList = [...list];
    if (!canEdit) {
      if (
        AIAEstColVisible ||
        estimatedColVisible ||
        deliveredColVisible ||
        collectedColVisible ||
        loadedColVisible
      ) {
        displayedList = [...list];
      } else {
        displayedList = list?.filter(support => !!support?.quantity);
      }
    }
    setSupportList(
      displayedList?.map((key, index) => (
        <>
          <StyledTableRow key={index}>
            <SupportSelector
              canEdit={canEdit}
              supportArrayCopy={supportArrayCopy}
              setSupportArrayCopy={setSupportArrayCopy}
              value={key}
              supportUnits={supportUnits_ ? supportUnits_ : supportUnits}
              AIAEstColVisible={AIAEstColVisible}
              estimatedColVisible={estimatedColVisible}
              deliveredColVisible={deliveredColVisible}
              collectedColVisible={collectedColVisible}
              loadedColVisible={loadedColVisible}
              unloadedColVisible={unloadedColVisible}
              loadingUpdate={loadingUpdate}
              setLoadingUpdate={setLoadingUpdate}
            />
          </StyledTableRow>
          {index !== displayedList?.length - 1 && displayedList?.length > 1 && (
            <TableRow>
              <TableCell>
                <HorizentalLine />
              </TableCell>
              <TableCell>
                <HorizentalLine />
              </TableCell>
              <TableCell>
                <HorizentalLine />
              </TableCell>
              <TableCell>
                <HorizentalLine />
              </TableCell>
              <TableCell>
                <HorizentalLine />
              </TableCell>
              <TableCell>
                <HorizentalLine />
              </TableCell>
              <TableCell>
                <HorizentalLine />
              </TableCell>
            </TableRow>
          )}
        </>
      )),
    );
  }, [
    displayedSupportUnits,
    supportList,
    list,
    supportArrayCopy,
    AIAEstColVisible,
    estimatedColVisible,
    deliveredColVisible,
    collectedColVisible,
    loadedColVisible,
    canEdit,
    JSON.stringify(supportUnits_),
    JSON.stringify(supportUnits),
  ]);
  const theme = useSelector(selectTheme);
  const iconColor = isOverLoaded
    ? theme?.redStatus
    : anchorEl !== null
      ? theme?.primaryActiveColor
      : themes?.default?.textColorSecondary;
  const tooltip = isOverLoaded
    ? t('roundtrips.support-unit.capacity-exceeded')
    : t('view_support_units');

  useEffect(() => {
    updateOrderCache &&
      updateOrderCache(stopId, roundtripId, {
        supportUnitsList: supportArrayCopy,
      }).then(() => {
        setTimeout(() => {
          setLoadingUpdate(false);
        }, 500);
      });
  }, [JSON.stringify(supportArrayCopy)]);

  return (
    <>
      <ManagedTooltip title={tooltip} disabled={disabled}>
        <Button
          onClick={!displayOnHover ? handleButtonClick : () => {}}
          onMouseEnter={displayOnHover ? handleButtonClick : () => {}}
          sx={{
            width: widthButton + 'px',
            maxWidth: widthButton + 'px',
            minWidth: widthButton + 'px',
            ...buttonSx,
          }}
        >
          <Info
            color={iconColor}
            size={13}
            style={{ marginTop: '3px', marginBottom: '3px' }}
          />
        </Button>
      </ManagedTooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopupClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ '& .MuiPaper-root': { overflowY: 'hidden' } }}
      >
        <Wrapper onMouseLeave={displayOnHover ? handlePopupClose : () => {}}>
          <PopoverTitle>{t('home.support_units')}</PopoverTitle>
          {loadingUpdate && (
            <ProgressContainer>
              <CircularProgress size={16} color="primary" />
            </ProgressContainer>
          )}
          {supportList?.length > 0 ||
          AIAEstColVisible ||
          estimatedColVisible ||
          deliveredColVisible ||
          collectedColVisible ||
          loadedColVisible ||
          unloadedColVisible ? (
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                },
                '& .MuiTableCell-root': {
                  padding: 0,
                  border: 'none !important',
                },
              }}
            >
              <TableHead
                sx={{
                  '& p': {
                    margin: 0,
                    padding: '0 12px 0 0',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    fontSize: '0.625rem',
                    fontWeight: 400,
                    lineHeight: '13.5px',
                    textAlign: 'center',
                    color: themes?.default?.black,
                  },
                }}
              >
                <TableRow>
                  <TableCell>
                    <p style={{ width: 69 }}></p>
                  </TableCell>
                  {AIAEstColVisible && (
                    <TableCell>
                      <p>
                        AIA Est.
                        <div
                          style={{
                            padding: '0 0 2px 0',
                          }}
                        >
                          <Icon
                            icon={<Sparkle />}
                            size={12}
                            color={themes?.default?.strokePrimary}
                          />
                        </div>
                      </p>
                    </TableCell>
                  )}
                  {estimatedColVisible && (
                    <TableCell>
                      <p>Planner Est.</p>
                    </TableCell>
                  )}
                  {loadedColVisible && (
                    <TableCell>
                      <p>{t('orders.status.loaded')}</p>
                    </TableCell>
                  )}
                  {deliveredColVisible && (
                    <TableCell>
                      <p>{t('orders.status.delivered')}</p>
                    </TableCell>
                  )}
                  {collectedColVisible && (
                    <TableCell>
                      <p>{t('confirmDelivery.collected')}</p>
                    </TableCell>
                  )}
                  {unloadedColVisible && (
                    <TableCell>
                      <p>{t('orders.status.unloaded')}</p>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>{supportList}</TableBody>
            </Table>
          ) : (
            <NoSupportAttached>
              {' '}
              {t('no_support_units_attached')}{' '}
            </NoSupportAttached>
          )}
        </Wrapper>
      </Popover>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px;
  max-width: 453px;
`;

const PopoverTitle = styled.p`
  margin: 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 18.59px;
  letter-spacing: 0.17px;
  color: ${themes?.default?.textColorPrimary};
`;

const StyledTableRow = styled(TableRow)`
  p {
    margin: 0 !important;
  }

  .title {
    opacity: 0.7;
    font-size: 0.75rem;
    letter-spacing: 0.15px;
    color: ${themes?.default?.textBlack} !important;
    padding: 6px 0;
    line-height: 18px;
  }

  .value {
    font-size: 0.75rem;
    line-height: 18px;
    letter-spacing: 0.15px;
    padding: 6px 12px 6px 0;
    color: ${themes?.default?.black};
    text-align: center;
  }
`;

const HorizentalLine = styled.div`
  width: 100%;
  height: 1px !important;
  background-image: linear-gradient(
    to bottom,
    ${themes?.default?.textColorSecondary} 0%,
    ${themes?.default?.textColorSecondary} 51%,
    transparent 51%
  );
`;

const NoSupportAttached = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 1rem;
`;

const ProgressContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;
