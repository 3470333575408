import { useContext, useState } from 'react';
import { useArchiveLocationMutation } from 'common/services/storageLocationApi';
import { useApiCalls } from '../../hooks/useApiCalls';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StorageLocationContext } from '../../../StorageLocationProvider';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';
import styled from 'styled-components';
import { useGrid } from 'app/components/Grid/hooks';

export const ArchiveLocation = () => {
  const { refetchLocations, areaStorageLocations, refetchStats } =
    useApiCalls();

  const { closeModal, currentLocation } = useContext(StorageLocationContext);
  const { t } = useTranslation();
  const [archiveLocationMutation] = useArchiveLocationMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToaster();
  const { selected } = useGrid();

  const archiveArea = async () => {
    try {
      await archiveLocationMutation({
        locationsIds: selected.length > 0 ? selected : [currentLocation._id],
      }).unwrap();
      toast(
        5000,
        'success',
        selected.length == 1 || selected.length == 0
          ? t('nav.mainNav.storage_location') +
              ' ' +
              t('common.archived.success') +
              '.'
          : selected.length +
              ' ' +
              t('storage.locations.main_title') +
              ' ' +
              t('common.archived.success') +
              '.',
      );
    } catch (e) {}
    closeModal();
    refetchStats();
    refetchLocations();
  };
  const currentSelectedLocation =
    selected.length == 1
      ? areaStorageLocations?.docs?.find(
          location => location?._id === selected[0],
        )
      : selected.length == 0
      ? currentLocation
      : '';

  return (
    <Dialog
      open={true}
      fullWidth
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <strong>{t('storage.locations.archive_modal_title')}</strong>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
      <DialogContent sx={{ p: 2 }} dividers>
        <>
          {selected?.length == 1 || selected.length == 0 ? (
            <>
              {t(`storage.locations.archive_modal_content`) +
                ' ' +
                currentSelectedLocation.code +
                '?'}
            </>
          ) : (
            <>
              {t(`storage.locations.x_content_archive_model`) +
                ' ' +
                selected.length +
                ' ' +
                t(`storage.locations.main_title`) +
                '?'}
            </>
          )}
        </>
      </DialogContent>
      <DialogActions style={{ marginBottom: '22px', marginRight: '14px' }}>
        <Button onClick={closeModal} color="inherit">
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={archiveArea}
          disabled={false}
          variant="contained"
          autoFocus
          sx={{
            height: '33px',
            width: '88px',
          }}
          color="error"
        >
          {t('messages.archive')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const LoadingWrapper = styled.div`
  margin: 100px 300px;
  text-align: center;
`;
