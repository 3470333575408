import { useModal } from 'app/components/Dialog/hooks';
import { useGrid } from 'app/components/Grid/hooks';
import { useToaster } from 'hooks/useToaster';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import { useApiCalls } from '../Components/hooks/useApiCalls';
import i18next from 'i18next';

interface ContextProps {
  drawerOpen: boolean;
  setDrawerOpen: StateSetter<boolean>;
  selectedRoleId: string;
  setSelectedRoleId: StateSetter<string>;
  handleDelete: (id: string) => void;
  handleEdit: (id: string) => void;
  handleCloseDrawer: () => void;
  handleBulkDelete: () => void;
}

export const LocationContext = createContext<ContextProps | undefined>(
  undefined,
);

export const StorageProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { rows: locations, selected, setSelected } = useGrid();
  const { openModal, closeModal, setActionDisabled } = useModal();
  const { isSubmitting } = useApiCalls();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState('');

  const { t } = useTranslation();
  const toast = useToaster();

  useEffect(() => {
    setActionDisabled(!!isSubmitting);
  }, [isSubmitting, setActionDisabled]);

  const handleEdit = useCallback(
    (id: string) => {
      setSelectedRoleId(id);
      setDrawerOpen(true);
    },
    [setDrawerOpen, setSelectedRoleId],
  );

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedRoleId('');
  }, [setDrawerOpen, setSelectedRoleId]);

  const handleDelete = useCallback(
    (id: string) => {
      const name = locations?.find(role => role?._id === id)?.localeName?.[
        i18next.language || 'en'
      ];
      openModal({
        title: t(`common.buttons.dialogue_delete`, {
          count: 1,
        }),
        content: t(`role.delete_confirmation`, {
          name: name,
          count: 1,
        }),
        action: {
          actionText: t('delete'),
          actionCallback: async () => {
            try {
              //TODO:: ADD DELETE CALL
              toast(
                5000,
                'success',
                t('roles.deleted', {
                  count: selected?.length,
                }),
              );
            } catch (e) {}
            closeModal();
          },
          buttonProps: {
            sx: { background: themes?.default?.Cinnabar },
            color: 'error',
          },
        },
        cancel: true,
      });
    },
    [closeModal, openModal, locations, t, toast, selected?.length],
  );

  const handleBulkDelete = useCallback(() => {
    const name =
      locations?.find(role => role?._id === selected?.[0])?.localeName?.[
        i18next.language || 'en'
      ] || '';
    openModal({
      title: t(`role.delete`, {
        count: selected?.length,
      }),
      content: t(`role.delete_confirmation`, {
        count: selected?.length,
        name,
      }),
      action: {
        actionText: t('delete'),
        actionCallback: async () => {
          try {
            //TODO:: ADD BULK DELETE
          } catch (e) {}
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
          disabled: isSubmitting,
        },
      },
      cancel: true,
    });
  }, [
    closeModal,
    openModal,
    t,
    toast,
    selected,
    setSelected,
    isSubmitting,
    locations,
  ]);

  return (
    <>
      <LocationContext.Provider
        value={{
          drawerOpen,
          setDrawerOpen,
          selectedRoleId,
          setSelectedRoleId,
          handleDelete,
          handleEdit,
          handleCloseDrawer,
          handleBulkDelete,
        }}
      >
        {children}
      </LocationContext.Provider>
    </>
  );
};
