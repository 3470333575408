import { useModal } from 'app/components/Dialog/hooks';
import { useGrid } from 'app/components/Grid/hooks';
import { useToaster } from 'hooks/useToaster';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { StateSetter } from 'types';
import { getLocaleName } from '../helper/helpers';
import { useApiCalls } from '../hooks/useApiCalls';
import { useParams } from 'react-router-dom';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';

interface ContextProps {
  drawerOpen: boolean;
  setDrawerOpen: StateSetter<boolean>;
  selectedBranchId: string;
  setSelectedBranchId: StateSetter<string>;
  onAddClick: () => void;
  onExportClick: () => void;
  handleDelete: (customerId: string, branchId: string) => void;
  handleEdit: (id: string) => void;
  handleCloseDrawer: () => void;
  handleBulkDelete: () => void;
  handleChangeStatus: (
    customerId: string,
    branchId: string,
    status: string,
  ) => void;
  filters: any;
  setFilters: StateSetter<any>;
  canEdit?: boolean;
}

export const PlaceContext = createContext<ContextProps | undefined>(undefined);
export const PlaceProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const can = usePermission('customers');
  const canEdit = can(Permission.EDIT);

  const { exportRows, rows: roles, selected, setSelected } = useGrid();
  const { openModal, closeModal, setActionDisabled } = useModal();
  const { id } = useParams<Record<string, string>>();

  const { deleteBranch, bulkDelete, changeStatus, isSubmitting, refetch } =
    useApiCalls(id || '');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState('');
  const [filters, setFilters] = useState<any>({});
  const { t } = useTranslation();
  const toast = useToaster();

  useEffect(() => {
    setActionDisabled(!!isSubmitting);
  }, [isSubmitting, setActionDisabled]);

  const onAddClick = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const onExportClick = useCallback(() => {
    exportRows(``, `export_roles`);
  }, [exportRows]);

  const handleEdit = useCallback(
    (id: string) => {
      setSelectedBranchId(id);
      setDrawerOpen(true);
    },
    [setDrawerOpen, setSelectedBranchId],
  );

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedBranchId('');
  }, [setDrawerOpen, setSelectedBranchId]);

  const handleDelete = useCallback(
    (customerId: string, branchId: string) => {
      const name = getLocaleName(roles, selected?.[0]);
      openModal({
        title: t(`client.deleteBranch`, {
          count: 1,
        }),
        content: t(`role.delete_confirmation`, {
          name: name,
          count: 1,
        }),
        action: {
          actionText: t('delete'),
          actionCallback: async () => {
            try {
              await deleteBranch({
                customerId: customerId,
                branchId: branchId,
              });
              toast(5000, 'success', t('branch.delete_one'));
            } catch (e) {}
            closeModal();
            refetch();
          },
          buttonProps: {
            sx: { background: themes?.default?.Cinnabar },
            color: 'error',
          },
        },
        cancel: true,
      });
    },
    [closeModal, openModal, roles, t, toast, deleteBranch, selected?.length],
  );

  const handleBulkDelete = useCallback(() => {
    const name = getLocaleName(roles, selected?.[0]);
    openModal({
      title: t(`role.delete`, {
        count: selected?.length,
      }),
      content: t(`role.delete_confirmation`, {
        count: selected?.length,
        name,
      }),
      action: {
        actionText: t('delete'),
        actionCallback: async () => {
          try {
            const resp: { deletedRole: number; notdeletedRole: number } =
              await bulkDelete();
            setSelected([]);
            if (!resp?.notdeletedRole && !!resp.deletedRole) {
              toast(
                5000,
                'success',
                t('roles.deleted', { count: selected?.length }),
              );
            } else if (!!resp?.deletedRole) {
              toast(
                10000,
                'warning',
                t('roles.bulk-deleted', {
                  notDeleted: resp?.notdeletedRole,
                  deleted: resp?.deletedRole,
                }),
              );
            } else {
              toast(10000, 'error', t('roles.bulk-deleted-failed'));
            }
          } catch (e) {}
          closeModal();
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
          disabled: isSubmitting,
        },
      },
      cancel: true,
    });
  }, [
    closeModal,
    openModal,
    t,
    toast,
    bulkDelete,
    selected,
    setSelected,
    isSubmitting,
    roles,
  ]);

  const handleChangeStatus = useCallback(
    async (customerId: string, branchId: string, status: string) => {
      try {
        await changeStatus(customerId, branchId, status);
        refetch();
        toast(5000, 'success', t('roles.activated'));
      } catch (e) {}
    },
    [closeModal, openModal, roles, t, toast, changeStatus],
  );

  return (
    <>
      <PlaceContext.Provider
        value={{
          drawerOpen,
          setDrawerOpen,
          selectedBranchId,
          setSelectedBranchId,
          onAddClick,
          onExportClick,
          handleDelete,
          handleEdit,
          handleCloseDrawer,
          handleBulkDelete,
          handleChangeStatus,
          filters,
          setFilters,
          canEdit,
        }}
      >
        {children}
      </PlaceContext.Provider>
    </>
  );
};
