import api from './api';

export const callsApi = api.injectEndpoints({
  endpoints: builder => ({
    getStatus: builder.query<any, string>({
      query: () => ({
        url: `calls/status`,
      }),
      providesTags: ['Calls'],
    }),
    getCalls: builder.query<any, any>({
      query: ({ urlParams }) => ({
        url: `calls?${urlParams}`,
      }),
      providesTags: ['Calls'],
    }),
    getCallsPerStatus: builder.query<any, any>({
      query: ({ statusId, urlParams }) => ({
        url: `calls/byStatus/${statusId}${urlParams}`,
      }),
      providesTags: ['Calls'],
    }),
    patchCallStatus: builder.mutation<
      any,
      {
        id: string;
        statusId: string;
        recallBeginTime: any;
        recallEndTime: any;
        orderId: any;
      }
    >({
      query: ({ id, statusId, recallBeginTime, recallEndTime, orderId }) => ({
        url: `calls/${id}/status`,
        method: 'PATCH',
        body: { statusId, recallBeginTime, recallEndTime, orderId },
      }),
      invalidatesTags: ['Calls'],
    }),
    deleteCall: builder.mutation<any, any>({
      query: id => ({
        url: `calls/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Calls'],
    }),
    getCallActivities: builder.query<any, any>({
      query: id => ({
        url: `calls/${id}/activities`,
        method: 'GET',
      }),
    }),
    getCallsNotes: builder.query<any, any>({
      query: id => ({
        url: `calls/${id}/notes`,
        method: 'GET',
      }),
    }),
    postCallNote: builder.mutation<any, any>({
      query: ({ id, content, isPrimary }) => ({
        url: `calls/${id}/notes`,
        method: 'POST',
        body: { content, isPrimary },
      }),
      invalidatesTags: ['Calls'],
    }),
    getCall: builder.query<any, any>({
      query: id => ({
        url: `calls/${id}`,
        method: 'GET',
      }),
    }),
    createVoiceCall: builder.mutation<any, any>({
      query: ({ id, callId, agentId, phone }) => ({
        url: 'calls/voice',
        method: 'POST',
        body: { id, callId, agentId, phone },
      }),
    }),
    createVoiceJwt: builder.mutation<any, any>({
      query: () => ({
        url: 'calls/voice-jwt',
        method: 'POST',
        body: { },
      }),
    }),
  }),
});

export const {
  useGetStatusQuery,
  useGetCallsQuery,
  useLazyGetCallsQuery,
  useLazyGetStatusQuery,
  useGetCallsPerStatusQuery,
  useLazyGetCallsPerStatusQuery,
  usePatchCallStatusMutation,
  useDeleteCallMutation,
  useGetCallActivitiesQuery,
  useLazyGetCallActivitiesQuery,
  useGetCallsNotesQuery,
  useLazyGetCallsNotesQuery,
  usePostCallNoteMutation,
  useGetCallQuery,
  useCreateVoiceCallMutation,
  useCreateVoiceJwtMutation,
} = callsApi;

export default callsApi;
