import { Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from 'styled-components';
import { useRef, useEffect, useMemo } from 'react';
import {
  formatFilters,
  getFilterCount,
  handleRemoveFilter,
} from 'app/helpers/helpers';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';

interface FilterChipsProps {
  filters: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
}

export const FilterChips = ({ filters, setFilters }: FilterChipsProps) => {
  const theme = useSelector(selectTheme);
  const filterCount = getFilterCount(filters);

  const containerRef = useRef<any>(null);
  const startX = useRef(0);
  const scrollLeft = useRef(0);
  const isDragging = useRef(false);
  const handleMouseDown = e => {
    isDragging.current = true;
    startX.current = e.pageX - containerRef.current.offsetLeft;
    scrollLeft.current = containerRef?.current.scrollLeft;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };
  const handleMouseMove = e => {
    if (!isDragging.current) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX.current) * 2;
    containerRef.current.scrollLeft = scrollLeft.current - walk;
  };
  const handleMouseUp = () => {
    isDragging.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };
  useEffect(() => {
    if (containerRef.current) {
      if (filterCount) {
        containerRef.current.style.cursor = 'grab';
      } else {
        containerRef.current.style.cursor = 'default';
      }
    }
  }, [filterCount]);
  const formattedFilters = useMemo(() => {
    return formatFilters(filters);
  }, [filters]);

  if (!filterCount) {
    return null;
  }

  return (
    <FilterChipsContainer ref={containerRef} onMouseDown={handleMouseDown}>
      {formattedFilters?.map(filter => {
        return (
          <Chip
            size="small"
            label={filter.label}
            deleteIcon={
              <CancelIcon onMouseDown={event => event.stopPropagation()} />
            }
            onDelete={() => {
              handleRemoveFilter(filter.key, filter.value, setFilters);
            }}
            sx={{
              color: theme.primaryActiveColor,
              background: theme.primaryBackgroundColor,
              cursor: 'grab',
            }}
          />
        );
      })}
    </FilterChipsContainer>
  );
};

const FilterChipsContainer = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  gap: 8px;
  overflow-y: hidden;
  overflow-x: scroll;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;

  /* WebKit */
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }

  /* Firefox */
  &::-moz-scrollbar-track {
    background-color: transparent !important;
  }
  &::-moz-scrollbar-thumb {
    background-color: transparent !important;
  }

  & span {
    font-size: 0.75rem;
  }
`;
