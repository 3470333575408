import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Icon } from 'app/components/Icon';
import CarbonFootprintIcon from 'assets/img/Orders/CarbonFootprint.svg';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

interface Props {
  carbonEmission: number;
}

export const CarbonFootprint: React.FC<Props> = ({ carbonEmission }) => {
  const { t } = useTranslation();
  return (
    <>
      {carbonEmission && carbonEmission > 0 && (
        <Tooltip title={t('carbon_footprint')}>
          <Wrapper>
            <Icon icon={<img src={CarbonFootprintIcon} alt="" />} size={16} />
            <p>{carbonEmission.toFixed(2)}Kg</p>
          </Wrapper>
        </Tooltip>
      )}
    </>
  );
};

const Wrapper = styled.div`
  height: 16px;
  min-width: 50px;
  padding: 1px 6px;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  background: ${themes?.default?.lightBlueBg};

  p {
    margin: 0;
    padding: 2px 0 0 0;
    font-size: 0.5625rem;
    font-weight: 500;
    letter-spacing: 0.15px;
  }
`;
