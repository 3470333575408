import {
  Box,
  SxProps,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectTheme } from 'styles/theme/slice/selectors';
import { EXCEL, GRID, REGULAR, REOPTIMIZE, TRACK } from '../constants';
import { CSSProperties } from '@mui/styles';
import { CheckboxLabelWrapper } from '../styles';
import { TimeLineComponent } from './Components/TimeLineComponent';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { checkedTable, cloneSelectedArray } from './functions';
import { ReOptimizeHeader } from 'app/pages/AddRoundTrips/components/TableComponent/helpers/reoptimizeHeader';
import { TableCellComponent } from './Components/TableCellComponent';
import { ExelExist } from './Components/ExelExist';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';
import { Checkbox } from 'app/components/CustomCheckbox';
import { CaretUp, CaretDown } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { themes } from 'styles/theme/themes';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';

export const handleSelectAllClick = (
  event: React.ChangeEvent<HTMLInputElement>,
  type,
  rows,
  selected,
  setSelected,
) => {
  if (type === REGULAR) {
    if (event.target.checked) {
      const newSelected = rows.map(n => n.data.id.value);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
    return;
  }
  if (type === TRACK || type === GRID) {
    let selectedCopy = cloneSelectedArray(selected);
    let i;
    for (i = 0; i < rows.length; i++) {
      const key = rows[i].key;
      if (rows[i].data.length !== selectedCopy[key].length) break;
    }
    const tableKeys = Object.keys(selectedCopy);
    if (i >= rows.length) {
      for (i = 0; i < tableKeys.length; i++) {
        selectedCopy[tableKeys[i]] = [];
      }
      setSelected(selectedCopy);
    } else {
      for (let i = 0; i < rows.length; i++) {
        selectedCopy[rows[i].key] = [];
        for (let j = 0; j < rows[i].data.length; j++) {
          selectedCopy[rows[i].key].push(rows[i].data[j].data.id.value);
        }
      }
      setSelected(selectedCopy);
    }
  }
  if (type === EXCEL) {
    let selectedCopy = cloneSelectedArray(selected);
    let i;
    for (i = 0; i < rows.length; i++) {
      const key = rows[i].key;
      if (rows[i].data.length !== selectedCopy[key].length) break;
    }
    const tableKeys = Object.keys(selectedCopy);
    if (i >= rows.length) {
      for (i = 0; i < tableKeys.length; i++) {
        selectedCopy[tableKeys[i]] = [];
      }
      setSelected(selectedCopy);
    } else {
      for (let i = 0; i < rows.length; i++) {
        selectedCopy[rows[i].key] = [];
        for (let j = 0; j < rows[i].data.length; j++) {
          selectedCopy[rows[i].key].push(rows[i].data[j].data.id.value);
        }
      }
      setSelected(selectedCopy);
    }
  }
};

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectColumn?: any;
  setSelected?: any;
  order: any;
  orderBy: string;
  rowCount: number;
  isLoading: boolean;
  type?: string;
  headCells: any;
  setHeadCells?: any;
  selected: any;
  rows: any;
  group: string;
  isSelected: any;
  selectedRowIndex: number;
  selectedResult?: number;
  setSelectedResult?: React.Dispatch<React.SetStateAction<number>>;
  selectedRoundTrip?: number;
  emptyCellStyle?: any;
  checkBox?: boolean;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isLoading,
    headCells,
    setHeadCells,
    selected,
    rows,
    type,
    onSelectColumn,
    setSelected,
    group,
    isSelected,
    selectedRowIndex,
    selectedResult,
    selectedRoundTrip,
    setSelectedResult,
    emptyCellStyle,
    checkBox,
  } = props;
  const { t } = useTranslation();

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const theme = useSelector(selectTheme);

  const [filteredHeadCells, setFilteredHeadCells] = useState<any>(null);

  useEffect(() => {
    setFilteredHeadCells(() => {
      return headCells.filter(headCell => !headCell.disableRow);
    });
  }, [headCells]);

  if (isLoading)
    return (
      <LoadingContainer>
        {' '}
        <LoadingPulse />{' '}
      </LoadingContainer>
    );

  let stickyCheckbox: SxProps<Theme> | undefined = {};

  stickyCheckbox = {
    zIndex: 1,
    backgroundColor: theme.roundtripGreyBg,
    left: 0,
    position: 'sticky',
  };
  if (type === TRACK) {
    stickyCheckbox = {
      ...stickyCheckbox,
      backgroundColor: theme.primaryLightO,
    };
  }

  if (type === REOPTIMIZE) {
    return (
      <ReOptimizeHeader
        selectedResult={selectedResult}
        setSelectedResult={setSelectedResult}
        rows={rows}
        selectedRoundTrip={selectedRoundTrip}
      />
    );
  }

  const getItemStyle = (
    isDragging,
    draggableStyle,
    styles,
    stickyStyles,
    headCell,
  ) => ({
    flex: isDragging ? '' : 1,
    minWidth: headCell.minWidth || 0,
    maxWidth: headCell.maxWidth,
    borderBottom: 'none',
    overflow: 'hidden',
    background: isDragging ? themes?.default?.primaryLight : '',
    borderRadius: isDragging ? '8px' : '',
    border: isDragging ? `1px solid ${themes?.default?.primary}` : '',
    ...styles,
    ...stickyStyles,
    ...draggableStyle,
  });

  const handleDragEnd = result => {
    if (!result?.destination) return;

    const srcIndex = result?.source?.index;
    const destIndex = result?.destination?.index;

    if (destIndex === srcIndex || destIndex === 0) return;

    const srcId = filteredHeadCells[srcIndex].id;
    const destId = filteredHeadCells[destIndex].id;

    const actualtSrcIndex = headCells.findIndex(
      headCell => headCell.id === srcId,
    );
    const actualtDestIndex = headCells.findIndex(
      headCell => headCell.id === destId,
    );

    const headCellsCopy = [...headCells];
    headCellsCopy.splice(actualtSrcIndex, 1);
    headCellsCopy.splice(actualtDestIndex, 0, headCells[actualtSrcIndex]);
    setHeadCells && setHeadCells(headCellsCopy, true);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <FixedTableHead
        sx={{
          backgroundColor: theme.roundtripsGrayBackGround,
        }}
      >
        <Droppable droppableId="head-cells-row" direction="horizontal">
          {(draoppableProvided, droppableSnapshot) => (
            <TableRow
              sx={{
                display:
                  type === TRACK || type === GRID || type === EXCEL
                    ? 'flex'
                    : 'table-row',
                borderBottom: `1px solid ${theme.grayBorder}`,
              }}
              {...draoppableProvided.droppableProps}
              ref={draoppableProvided.innerRef}
            >
              {!!(type === EXCEL && rows.length) && (
                <TableCellComponent
                  sx={{
                    ...stickyCheckbox,
                    left: '52px',
                  }}
                />
              )}

              <ExelExist
                type={type}
                EXCEL={EXCEL}
                rows={rows}
                selectedRowIndex={selectedRowIndex}
                isSelected={isSelected}
                group={group}
                orderBy={orderBy}
                order={order}
                onSelectColumn={onSelectColumn}
                selected={selected}
                setSelected={setSelected}
              />
              {!checkBox && (
                <TableCell
                  padding="checkbox"
                  sx={{
                    display:
                      type === TRACK || type === GRID || type === EXCEL
                        ? 'flex'
                        : 'table-cell',
                    alignItems: 'center',
                    minWidth: type === REGULAR ? 'auto' : '52px',
                    ...stickyCheckbox,
                    position: 'sticky',
                    left: '0px',
                    width:
                      emptyCellStyle?.width && type !== REGULAR
                        ? `${emptyCellStyle.width} !important`
                        : 'auto',
                    zIndex: 2,
                    background: theme.regularTableBg,
                  }}
                ></TableCell>
              )}

              {type !== EXCEL &&
                filteredHeadCells?.map((headCell, headCellIndex) => {
                  if (headCell.disableRow) return null;
                  let styles = {};
                  styles = { ...styles, textTransform: 'uppercase' };
                  if (headCell.center) {
                    styles = { display: 'flex', justifyContent: 'center' };
                  }
                  let stickyStyles = {};
                  if (headCell.isSticky) {
                    stickyStyles = {
                      position: 'sticky',
                      left: headCell.stickyLeftPosition,
                      right: headCell.stickyRightPosition,
                      zIndex: 2,
                      background: theme.regularTableBg,
                      marginLeft: headCell.marginLeft,
                    };
                  }

                  return (
                    <>
                      {checkBox &&
                      (headCell?.id === 'code' || headCell?.isCheckbox) ? (
                        <>
                          <TableCell
                            padding="checkbox"
                            sx={{
                              display:
                                type === TRACK ||
                                type === GRID ||
                                type === EXCEL
                                  ? 'flex'
                                  : 'table-cell',
                              alignItems: 'center',
                              minWidth: 'auto',
                              ...stickyCheckbox,
                              position: 'sticky',
                              left: '0px',
                              width:
                                emptyCellStyle?.width && type !== REGULAR
                                  ? `${emptyCellStyle.width} !important`
                                  : 'auto',
                            }}
                          >
                            <Checkbox
                              color="primary"
                              indeterminate={
                                numSelected > 0 && !(numSelected === rowCount)
                              }
                              checked={
                                type === REGULAR
                                  ? rowCount > 0 && numSelected === rowCount
                                  : checkedTable(selected, rows)
                              }
                              onChange={onSelectAllClick}
                              inputProps={{
                                'aria-label': 'select all desserts',
                              }}
                              sx={{
                                height: 'fit-content',
                              }}
                            />
                          </TableCell>
                          <TableCell
                            padding={headCell.label ? 'normal' : 'none'}
                            sx={{
                              display:
                                type === TRACK ||
                                type === GRID ||
                                type === EXCEL
                                  ? 'flex'
                                  : 'table-cell',
                              alignItems: 'center',
                              minWidth: headCell.minWidth,
                              ...stickyCheckbox,
                              position: 'sticky',
                              left: type === REGULAR ? '48px' : '0px',
                              width:
                                emptyCellStyle?.width && type !== REGULAR
                                  ? `${emptyCellStyle.width} !important`
                                  : 'auto',
                              zIndex: 2,
                              background: theme.regularTableBg,
                            }}
                          >
                            <If condition={headCell?.isCheckbox}>
                              {headCell.disableSort ? (
                                <CheckBoxLabel>
                                  {t(headCell.label)}
                                </CheckBoxLabel>
                              ) : (
                                <TableSortLabel
                                  active={true}
                                  direction={
                                    orderBy === headCell.sortField
                                      ? order === '-'
                                        ? 'desc'
                                        : 'asc'
                                      : 'asc'
                                  }
                                  onClick={createSortHandler(
                                    headCell.sortField,
                                  )}
                                  IconComponent={() => (
                                    <CustomSortIconWrapper>
                                      <div>
                                        <Icon
                                          icon={<CaretUp />}
                                          weight="bold"
                                          size={8}
                                          color={
                                            orderBy === headCell.sortField &&
                                            order === ''
                                              ? themes?.default?.black
                                              : `${themes?.default?.iconColor}99`
                                          }
                                        />
                                      </div>
                                      <div>
                                        <Icon
                                          icon={<CaretDown />}
                                          weight="bold"
                                          size={8}
                                          color={
                                            orderBy === headCell.sortField &&
                                            order === '-'
                                              ? themes?.default?.black
                                              : `${themes?.default?.iconColor}99`
                                          }
                                        />
                                      </div>
                                    </CustomSortIconWrapper>
                                  )}
                                >
                                  <CheckBoxLabel>
                                    {t(headCell.label)}
                                  </CheckBoxLabel>
                                  {orderBy === headCell.sortField ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {order === '-'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              )}
                            </If>
                          </TableCell>
                        </>
                      ) : headCell.isSticky ? (
                        <TableCell
                          key={headCell.id}
                          align="left"
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                          sortDirection={
                            orderBy === headCell.sortField ? order : false
                          }
                          sx={{
                            flex: 1,
                            minWidth: headCell.minWidth || 0,
                            maxWidth: headCell.maxWidth,
                            borderBottom: 'none',
                            overflow: 'hidden',
                            ...styles,
                            ...stickyStyles,
                          }}
                        >
                          {headCell.disableSort ? (
                            <span style={{ fontWeight: 500 }}>
                              {t(headCell.label)}
                            </span>
                          ) : (
                            <TableSortLabel
                              active={true}
                              direction={
                                orderBy === headCell.sortField
                                  ? order === '-'
                                    ? 'desc'
                                    : 'asc'
                                  : 'asc'
                              }
                              IconComponent={() => (
                                <CustomSortIconWrapper>
                                  <div>
                                    <Icon
                                      icon={<CaretUp />}
                                      weight="bold"
                                      size={8}
                                      color={
                                        orderBy === headCell.sortField &&
                                        order === ''
                                          ? themes?.default?.black
                                          : `${themes?.default?.iconColor}99`
                                      }
                                    />
                                  </div>
                                  <div>
                                    <Icon
                                      icon={<CaretDown />}
                                      weight="bold"
                                      size={8}
                                      color={
                                        orderBy === headCell.sortField &&
                                        order === '-'
                                          ? themes?.default?.black
                                          : `${themes?.default?.iconColor}99`
                                      }
                                    />
                                  </div>
                                </CustomSortIconWrapper>
                              )}
                              onClick={createSortHandler(headCell.sortField)}
                            >
                              <span style={{ fontWeight: 500 }}>
                                {t(headCell.label)}
                              </span>
                              {orderBy === headCell.sortField ? (
                                <Box component="span" sx={visuallyHidden}>
                                  {order === '-'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          )}
                        </TableCell>
                      ) : (
                        <Draggable
                          key={headCell.id}
                          index={headCellIndex}
                          draggableId={headCell.id}
                        >
                          {(provided, snapshot) => (
                            <TableCell
                              key={headCell.id}
                              align="left"
                              padding={
                                headCell.disablePadding ? 'none' : 'normal'
                              }
                              sortDirection={
                                orderBy === headCell.sortField ? order : false
                              }
                              sx={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                styles,
                                stickyStyles,
                                headCell,
                              )}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {headCell.disableSort ? (
                                <span style={{ fontWeight: 500 }}>
                                  {t(headCell.label)}
                                </span>
                              ) : (
                                <TableSortLabel
                                  active={true}
                                  direction={
                                    orderBy === headCell.sortField
                                      ? order === '-'
                                        ? 'desc'
                                        : 'asc'
                                      : 'asc'
                                  }
                                  IconComponent={() => (
                                    <CustomSortIconWrapper>
                                      <div>
                                        <Icon
                                          icon={<CaretUp />}
                                          weight="bold"
                                          size={8}
                                          color={
                                            orderBy === headCell.sortField &&
                                            order === ''
                                              ? themes?.default?.black
                                              : `${themes?.default?.iconColor}99`
                                          }
                                        />
                                      </div>
                                      <div>
                                        <Icon
                                          icon={<CaretDown />}
                                          weight="bold"
                                          size={8}
                                          color={
                                            orderBy === headCell.sortField &&
                                            order === '-'
                                              ? themes?.default?.black
                                              : `${themes?.default?.iconColor}99`
                                          }
                                        />
                                      </div>
                                    </CustomSortIconWrapper>
                                  )}
                                  onClick={createSortHandler(
                                    headCell.sortField,
                                  )}
                                >
                                  <span style={{ fontWeight: 500 }}>
                                    {t(headCell.label)}
                                  </span>
                                  {orderBy === headCell.sortField ? (
                                    <Box component="span" sx={visuallyHidden}>
                                      {order === '-'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                    </Box>
                                  ) : null}
                                </TableSortLabel>
                              )}
                            </TableCell>
                          )}
                        </Draggable>
                      )}
                    </>
                  );
                })}
              {type === TRACK && <TimeLineComponent />}
              {draoppableProvided.placeholder}
            </TableRow>
          )}
        </Droppable>
      </FixedTableHead>
    </DragDropContext>
  );
}

export const TimeLine = styled.div(props => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '0.625rem',
  '& .time': {
    minWidth: 60,
    '&:last-child': {
      minWidth: 'unset !important',
    },
  },
}));

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
`;

export const FixedTableHead = styled(TableHead)(props => ({
  position: 'sticky',
  top: 0,
  zIndex: 4,
}));

const CheckBoxLabel = styled.span`
  font-weight: 500;
  text-transform: uppercase;
`;

const CustomSortIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 16px;
  margin: 0 0 0 8px;
  position: relative;

  div {
    height: 6px;
    position: absolute;
    left: 0;
  }

  div:first-child {
    top: -7px;
  }

  div:last-child {
    bottom: 11px;
  }
`;
