import React from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import Skeleton from '@mui/material/Skeleton';

interface Props {
  length: number;
}

export const ContactCardSkeleton: React.FC<Props> = ({ length = 1 }) => {
  return (
    <>
      {Array.from({ length: length }).map((_, index) => (
        <ContactCardWrapper>
          <div style={{ position: 'relative' }}>
            <Skeleton
              animation="wave"
              variant="circular"
              width={32}
              height={32}
            />
            <UserStatusWrapper size={8} color={'#FFF'}>
              <Skeleton
                animation="wave"
                variant="circular"
                width={8}
                height={8}
              />
            </UserStatusWrapper>
          </div>
          <UserInfo>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={160}
              height={15}
            />
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={110}
              height={15}
            />
          </UserInfo>
        </ContactCardWrapper>
      ))}
    </>
  );
};

const ContactCardWrapper = styled.div`
  width: 100%;
  height: 63px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${themes?.default?.gainsboro2};
  display: flex;
  gap: 8px;
`;

const UserInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const UserStatusWrapper = styled.div<{ size: number; color: string }>`
  height: ${props => props.size}px !important;
  width: ${props => props.size}px !important;
  border-radius: ${props => props.size}px !important;
  outline: 1px solid ${themes?.default?.grey};
  background: ${props => props.color};
  position: absolute;
  bottom: 2px;
  right: 0;
`;
