import { Grid, InputAdornment } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import If from 'app/components/If';
import { FilterName, ToggleIcon } from '../../styles';
import { useMemo, useState } from 'react';
import { CustomDateRangePicker } from 'app/components/DatePicker/CustomDateRangePicker';
import { Icon } from 'app/components/Icon';
import { CalendarTodayOutlined } from '@mui/icons-material';

interface DateRangeFilterProps {
  openByDefault?: boolean;
  filterName?: string;
  dateValue: { begin: string | null; end: string | null };
  handleFilterChange: (date: {
    begin: string | null;
    end: string | null;
  }) => void;
}

const DateRangeFilter = ({
  openByDefault,
  dateValue,
  filterName,
  handleFilterChange,
}: DateRangeFilterProps) => {
  const [open, setOpen] = useState<boolean>(openByDefault || false);

  const isHighlighted = useMemo(() => {
    return !!(dateValue?.begin || dateValue?.end);
  }, [dateValue]);

  return (
    <>
      <div className="title_action" onClick={() => setOpen(!open)}>
        <FilterName isHighlighted={isHighlighted}>{filterName}</FilterName>
        {
          <ToggleIcon disableRipple>
            {open ? <RemoveIcon /> : <AddIcon />}
          </ToggleIcon>
        }
      </div>
      <If condition={open}>
        <Grid container spacing={0} sx={{ padding: '10px 0' }}>
          <CustomDateRangePicker
            dateValue={dateValue}
            setDateValue={handleFilterChange}
            textFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      icon={
                        <CalendarTodayOutlined
                          sx={{ width: '16px', height: '16px' }}
                        />
                      }
                    />
                  </InputAdornment>
                ),
              },
              sx: {
                '& input': {
                  width: '100% !important',
                },
                '& button': { width: 'auto' },
                '& .MuiInputBase-root': {
                  width: '100% !important',
                  height: 'auto !important',
                },
              },
              placeholder: filterName,
            }}
            width={'100%'}
          />
        </Grid>
      </If>
    </>
  );
};

export default DateRangeFilter;
