import { useGrid } from '../../../../../../../components/Grid/hooks';
import { usePermission } from '../../../../../../../../hooks/Abilities/usePermission';
import { useQueryParams } from '../../../../../../../../hooks/useQueryParams';
import {
  useDeleteDepartmentsMutation,
  useGetDepartmentsQuery,
} from '../../../../../../../../common/services/companyDepartmentsApi';
import React, { useEffect } from 'react';
import { Columns } from '../../data';
import { LoadingIndicator } from '../../../../../../../components/LoadingIndicator';
import { useManagedContext } from '../../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import DepartmentsList from '../DepartmentsList';
import { handleDeleteDepartments } from '../../helpers';
import { themes } from '../../../../../../../../styles/theme/themes';
import { GridToolbar } from '../../../../../../../components/Grid/components/GridToolbar';
import { useToaster } from '../../../../../../../../hooks/useToaster';
import { useModal } from '../../../../../../../components/Dialog/hooks';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import If from '../../../../../../../components/If';

export default function FunctionalDepartments() {
  const departmentsContext = useManagedContext('departmentsContext');
  const {
    setupGrid,
    page,
    rowsPerPage,
    order,
    debouncedSearchTerm,
    orderBy,
    setOrderBy,
  } = useGrid();
  const can = usePermission('departments');
  console.log('String(order + orderBy)', String(order + orderBy));
  const queryParams = useQueryParams({
    sort: String(order + orderBy) || 'name',
    limit: rowsPerPage,
    page: page,
    text: debouncedSearchTerm,
  });
  const { isLoading, data: departmentsResponse } =
    useGetDepartmentsQuery(queryParams);
  const toaster = useToaster();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  useEffect(() => {
    setupGrid({
      columns: Columns,
      rows: departmentsResponse?.departments,
      totalRows: departmentsResponse?.totalDocs || 0,
      // withCheckbox: can(Permission.EDIT) || can(Permission.EXPORT),
    });
  }, [setupGrid, departmentsResponse, orderBy, setOrderBy, can]);
  const [deleteDepartmentsMutation] = useDeleteDepartmentsMutation();

  function handleBulkDelete(selected: string[]) {
    openModal({
      title: t(`delete`),
      content: `${t(`common.dialog.delete.initial_title`)} ${selected.length} ${
        t('common.department') + (selected.length > 1 ? 's' : '')
      }?`,
      action: {
        actionText: t('delete'),
        actionCallback: () => {
          const ids = selected?.join(',');
          handleDeleteDepartments(
            ids,
            deleteDepartmentsMutation,
            closeModal,
            toaster,
            t,
          );
        },
        buttonProps: {
          sx: { background: themes?.default?.Cinnabar },
          color: 'error',
        },
      },
      cancel: true,
    });
  }

  return (
    <>
      {createPortal(
        <GridToolbar
          onAddClick={() => {
            departmentsContext.updateData('openDrawer', true);
          }}
          // onExportClick={() => {}}
          disabled={false}
          onBulkDelete={selected => {
            handleBulkDelete(selected);
          }}
          // TODO: check what todo with filters
          // filterComponent={<LeaveRequestFilter />}
          sx={{ pr: '0 !important', borderBottom: 'none' }}
        />,
        // @ts-ignore
        document.getElementById('toolbar'),
      )}
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <If condition={!isLoading} otherwise={<LoadingIndicator />}>
          <DepartmentsList departmentsResponse={departmentsResponse!} />;
        </If>
      </div>
    </>
  );
}
