export interface Params {
  [key: string]: any;
}

export function useQueryArray(params: Params) {
  const searchParams = new URLSearchParams();
  // Iterate over the object and append key-value pairs to searchParams
  for (var key in params) {
    if (Array.isArray(params[key])) {
      params[key].forEach(id => {
        const arrayKey = key + '[]';
        searchParams.append(arrayKey, id);
      });
    } else {
      if (params[key] || params[key] === 0 ) {
        searchParams.append(key, params[key]);
      }
    }
  }
  const queryString = searchParams.toString();
  return `?${queryString}`;
}
