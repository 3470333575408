import { useContext, useMemo, useState } from 'react';
import { LeftMenuContext } from '../LeftMenu';
import { RoundtripListItem } from './components/RoundtripListItem';
import { Stack } from '@mui/material';
import styled from 'styled-components';

export const RoundtripList = ({
  setSelectedRoundtripDetails,
  orders,
  setOrders,
  hoveringOverRt,
  setHoveringOverRt,
}) => {
  const { roundtrips, collapsed } = useContext(LeftMenuContext);

  const roundtripList = useMemo(() => {
    return (
      <Container collapsed={!!collapsed}>
        <Stack gap="12px" sx={{ padding: '0 4px 0 10px', margin: '5px 0' }}>
          {roundtrips?.map(rt => {
            return (
              <div style={{ position: 'relative' }}>
                <RoundtripListItem
                  key={rt._id}
                  roundtrip={rt}
                  onClick={e => {
                    setSelectedRoundtripDetails(rt);
                  }}
                  orders={orders}
                  setOrders={setOrders}
                  hoveringOverRt={hoveringOverRt}
                  setHoveringOverRt={setHoveringOverRt}
                />
              </div>
            );
          })}
        </Stack>
      </Container>
    );
  }, [collapsed, roundtrips, hoveringOverRt, orders]);

  return <>{roundtripList}</>;
};

const Container = styled.div<{ collapsed }>`
  position: relative;
  height: calc(
    100vh - ${props => props.theme.tableNavHeight} -
      ${props => props.theme.toolBarHeight} - 80px
  );

  overflow-y: hidden;
  overflow-x: hidden;

  @supports selector(::-webkit-scrollbar) {
    scrollbar-gutter: stable;
  }

  &:hover {
    overflow-y: overlay;
  }

  .truckIconWrapper {
    left: 228px !important;
  }
`;
