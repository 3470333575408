import { ROUNDTRIP_STATUS } from 'app/components/RoundTrips/RoundTripData/StatusColors';
import * as React from 'react';
import styled from 'styled-components';
import { RoundtripStatus } from './styles/RoundtripStatus';
import { useTranslation } from 'react-i18next';

interface Props {
  status: string;
  noLabel?: boolean;
  style?: React.CSSProperties;
}

export const Rstatus: React.FC<Props> = ({ status, noLabel, style }) => {
  const { t } = useTranslation();
  return (
    <RoundtripStatus style={{ ...style }}>
      {noLabel === true ? (
        <div className={status} />
      ) : (
        <div className={status} style={{ height: style?.height }}>
          {t(`roundtrips.status.${status}`)}
        </div>
      )}
    </RoundtripStatus>
  );
};
