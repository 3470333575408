import { Button, Popover } from '@mui/material';
import styled from 'styled-components';
import { useUpdateOrderMutation } from 'common/services/orderApi';
import { useTranslation } from 'react-i18next';
import { handleCloseAssignRoundTrip } from 'functions/setAnchorFunction';
import { useStopUpdateApiMutation } from 'common/services/roundtripApi';
import { updateOrderFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import {
  Canceled,
  Confirmed,
  Loaded,
  Pending,
  Prepared,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';
import { changeStatus } from './components/functions';

interface Props {
  addRoundTripOpen: any;
  setAddRoundTripOpen: any;
  roundtrip: any;
  order: any;
  deleteStop: Function;
  currentRoundtrip: any;
  accordionStatus?: any;
  setAccordionStatus?: (e) => void;
}

export const StatusChangePopover: React.FC<Props> = ({
  addRoundTripOpen,
  setAddRoundTripOpen,
  order,
  roundtrip,
}) => {
  const [UpdateOrders] = useUpdateOrderMutation();
  const { t } = useTranslation();
  const [uploadStop] = useStopUpdateApiMutation();

  const theme = useSelector(selectTheme);
  return (
    <>
      <Popover
        open={!!addRoundTripOpen}
        anchorEl={addRoundTripOpen}
        onClose={() => {
          handleCloseAssignRoundTrip(setAddRoundTripOpen);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <AddRoundtripWraper theme={theme}>
          <div
            className="roundtrips"
            onMouseLeave={() => {
              setAddRoundTripOpen(null);
            }}
          >
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                changeStatus(
                  'pending',
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="orange_circle circle"></div>
              <div>{t('orders.status.pending')}</div>
            </Button>
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                changeStatus(
                  Confirmed,
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="yellow_circle circle"></div>
              <div>{t('orders.status.to-be-prepared')}</div>
            </Button>
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                changeStatus(
                  Prepared,
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="purple_circle circle"></div>
              <div>{t('orders.status.prepared')}</div>
            </Button>
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                changeStatus(
                  Loaded,
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="blue_circle circle"></div>
              <div>{t('orders.status.loaded')}</div>
            </Button>
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                changeStatus(
                  'ready-for-delivery',
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="indigo_circle circle"></div>
              <div>{t('orders.status.ready-for-delivery')}</div>
            </Button>
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                changeStatus(
                  Pending,
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="orange_2_circle circle"></div>
              <div>{t('orders.status.on-the-road')}</div>
            </Button>
            {/* <Button
              sx={{ display: 'flex', gap: '17px', color: themes?.default?.black }}
              className="roundtrip_item"
              onClick={async () => {
                // prepared
                handleCloseAssignRoundTrip(setAddRoundTripOpen);

                uploadStop({
                  id: roundtrip?.id,

                  stopId: order?._id,
                  status: 'delivered',
                });
              }}
            >
              <div className="green_circle circle"></div>
              <div>{t('orders.status.delivered')}</div>
            </Button> */}
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                changeStatus(
                  'postponed',
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="grey_circle circle"></div>
              <div>{t('orders.status.postponed')}</div>
            </Button>
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                // canceled

                changeStatus(
                  Canceled,
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="circle red_circle"></div>
              <div>{t('orders.status.canceled')}</div>
            </Button>
            <Button
              sx={{
                display: 'flex',
                gap: '17px',
                color: themes?.default?.black,
              }}
              className="roundtrip_item"
              onClick={async () => {
                changeStatus(
                  'blocked',
                  handleCloseAssignRoundTrip,
                  setAddRoundTripOpen,
                  roundtrip,
                  uploadStop,
                  order,
                  updateOrderFunction,
                  UpdateOrders,
                );
              }}
            >
              <div className="circle brown_circle"></div>
              <div>{t('orders.status.blocked')}</div>
            </Button>
          </div>
        </AddRoundtripWraper>
      </Popover>
    </>
  );
};

export const AddRoundtripWraper = styled.div<{ theme: any }>`
  width: 100%;
  font-size: 0.857rem;
  .circle {
    border-radius: 100px;
    width: 0.7rem;
    height: 0.7rem;
  }
  .indigo_circle {
    background: ${themes?.default?.summerSky};
  }
  .grey_circle {
    background: ${themes?.default?.grey2};
  }
  .green_circle {
    background: ${themes?.default?.greenStatus};
  }
  .red_circle {
    background: ${themes?.default?.redStatus};
  }
  .blue_circle {
    background: ${props => props.theme?.primaryActiveColor};
  }
  .orange_circle {
    background: ${themes?.default?.safetyOrange};
  }
  .yellow_circle {
    background: ${themes?.default?.Saffron};
  }
  .purple_circle {
    background: ${themes?.default?.purple};
  }
  .orange_2_circle {
    background: ${themes.default.orangePeel};
  }
  .brown_circle {
    background: ${themes?.default?.brown};
  }
  .button_text {
    color: var(--text-color-primary, ${themes?.default?.textColorPrimary});
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: Roboto;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.01063rem;
  }
  button {
    width: 100%;
    justify-content: flex-start;
  }

  .roundtrip_code {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .roundtrip_item {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
export const TitleSorting = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  width: 117px;
`;
