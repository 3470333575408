import React, {
  createContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
  Context,
} from 'react';
import produce from 'immer';
import dot from 'dot-object';

interface Props<T extends object> {
  contextName: string;
  defaultValue: T;
  children: any;
}

export const contextRegistry = new Map<
  string,
  { context: Context<any>; valueType: any } // Use 'any' for flexibility
>();

export default function ManagedContext<T extends object>({
  contextName,
  defaultValue,
  children,
}: Props<T>) {
  const [data, setData] = useState(defaultValue || {});
  const contextRef = React.useRef<Context<any> | null>(null);
  type ContextValueType = T & {
    data: T;
    updateData: (key: string, value: any) => void;
    updateDataWithFunction: (func: (draft: T) => void) => void;
  };

  if (!contextRef.current) {
    contextRef.current = createContext<ContextValueType | null>(null);
  }

  const currentContext = contextRef.current;

  const updateData = useCallback(
    (key: string, value: any) => {
      if (!(key in defaultValue))
        throw new Error(`Key '${key}' not found in defaultValue`);
      setData((oldData: T) =>
        produce(oldData, (draft: any) => {
          dot.str(key, value, draft);
        }),
      );
    },
    [setData, defaultValue],
  );

  const updateDataWithFunction = useCallback(
    (func: (draft) => void) => {
      setData((oldData: T) =>
        produce(oldData, draft => {
          func(draft);
          Object.keys(draft).forEach(key => {
            if (!(key in defaultValue)) {
              throw new Error(`Key '${key}' not found in defaultValue`);
            }
          });
        }),
      );
    },
    [defaultValue],
  );

  const value = useMemo(
    () =>
      ({
        ...(data || {}),
        data,
        updateData,
        updateDataWithFunction,
      } as ContextValueType),
    [data, updateData, updateDataWithFunction],
  );

  useEffect(() => {
    return () => {
      contextRegistry.delete(contextName);
    };
  }, [contextName]);

  // Store the context and its type in the registry
  contextRegistry.set(contextName, {
    context: currentContext,
    valueType: value,
  });

  return (
    <currentContext.Provider value={value}>{children}</currentContext.Provider>
  );
}
