import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../../../styles/theme/slice/selectors';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  FooterWrapper,
  GeneralInfoField,
  GeneralInfoFieldContent,
  GeneralInfoFieldsWrapper,
  SectionWrapper,
} from '../styles';
import { FieldComponent } from 'app/components/FieldComponent';
import { Button } from '@mui/material';
import {
  useLazyGetCustomerQuery,
  usePatchDeckMutation,
  useUpdateCustomerMutation,
} from 'common/services/customerApi';

import { LocationInput } from 'app/components/LocationInput';

interface Props {
  onClose: () => void;
  onOk?: (customerId: string) => void;
  customer: any;
}

export const EditCustomer: React.FC<Props> = ({ customer, onClose, onOk }) => {
  const [getCustomer, { data: customerDetail }] = useLazyGetCustomerQuery();

  const [patchDeck] = usePatchDeckMutation();

  const methods = useForm({
    defaultValues: {
      code: customer?.code,
      publicName: customer?.publicName,
      legalName: customer?.legalName,
      email: customer?.email,
      vatNumber: customer?.vatNumber,
      invoicePeriod: customer?.invoicePeriod,
      phone: customer?.phone,
      locations: customerDetail?.decks?.map(deck => deck.location),
    },
  });

  const { control, watch } = methods;
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);
  const [locations, setLocations] = useState<any>([]);

  const [updateCustomer] = useUpdateCustomerMutation();
  const code = watch('code');
  const publicName = watch('publicName');
  const legalName = watch('legalName');
  const email = watch('email');
  const vatNumber = watch('vatNumber');
  const invoicePeriod = watch('invoicePeriod');
  const phone = watch('phone');

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (customer?._id) {
      getCustomer(customer._id);
    }
  }, [customer?._id]);

  const handleSetLocation = (index: number, location: any) => {
    const newLocations = [...locations];
    newLocations[index] = location;
    setLocations(newLocations);
  };

  // On click btn submit
  const onSubmit = async () => {
    try {
      // Call the first API function
      await updateCustomer({
        id: customer._id,
        code,
        publicName,
        email,
        vatNumber,
        invoicePeriod,
        phone,
      }).unwrap();

      // Call the second API function for each deck
      const promises = customerDetail.decks.map((deck, index) => {
        if (locations[index]) {
          return patchDeck({
            id: customerDetail._id,
            deckId: deck._id,
            location: locations[index],
          });
        }
      });

      await Promise.all(promises);

      // Continue with the rest of the function
      if (onOk) {
        onOk(customer._id);
      }

      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <EditCustomerTitle>
        <ArrowBackIcon
          sx={{ color: theme.icon, cursor: 'pointer' }}
          onClick={handleClose}
        />
        <div className="title">
          {t('customer.edit_customer.title_customer')}
        </div>
      </EditCustomerTitle>
      {customer && (
        <>
          <GeneralInfoFieldsWrapper>
            <GeneralInfoField>
              <GeneralInfoFieldContent>
                <div>{t('customer.edit_customer.created_on')}</div>
                <div>
                  {customerDetail
                    ? window.dayjs(customerDetail?.createdAt).format('LLL')
                    : null}
                </div>
              </GeneralInfoFieldContent>
            </GeneralInfoField>
            <GeneralInfoField>
              <GeneralInfoFieldContent>
                <div>{t('customer.edit_customer.updated_on')}</div>
                <div>
                  {customerDetail
                    ? window.dayjs(customerDetail?.updatedAt).format('LLL')
                    : null}
                </div>
              </GeneralInfoFieldContent>
            </GeneralInfoField>
          </GeneralInfoFieldsWrapper>
          <SectionWrapper>
            <div className="title-field">
              <div className="title">
                {t('customer.edit_customer.customer_info')}
              </div>
              <FieldComponent
                style={{ width: '393px' }}
                name="email"
                control={control}
                rules={{ required: true, minLength: 2 }}
                size="small"
                label="Email"
                defaultValue=""
                disabled={false}
                variant="outlined"
              />
            </div>
            <FieldComponent
              style={{ width: '187px' }}
              name="code"
              control={control}
              rules={{ required: true, minLength: 2 }}
              size="small"
              label="Code"
              defaultValue=""
              disabled={false}
              variant="outlined"
            />
            <FieldComponent
              style={{ width: '187px' }}
              name="vatNumber"
              control={control}
              rules={{ required: true, minLength: 2 }}
              size="small"
              label="VAT Number"
              defaultValue=""
              disabled={false}
              variant="outlined"
            />
            <FieldComponent
              style={{ width: '393px' }}
              name="publicName"
              control={control}
              rules={{ required: true, minLength: 2 }}
              size="small"
              label={t('customer.publicName')}
              defaultValue=""
              disabled={false}
              variant="outlined"
            />

            <FieldComponent
              style={{ width: '187px' }}
              name="phone"
              control={control}
              rules={{ required: true, minLength: 2 }}
              size="small"
              label="Phone"
              defaultValue=""
              disabled={false}
              variant="outlined"
            />
            <FieldComponent
              style={{ width: '187px' }}
              name="invoicePeriod"
              control={control}
              rules={{ required: true, minLength: 2 }}
              size="small"
              label="Invoice Period"
              defaultValue=""
              disabled={false}
              variant="outlined"
            />
            <div className="title-field" style={{ width: '393px' }}>
              <div className="title">
                {t('customer.edit_customer.customer_location')}
              </div>
              {customerDetail?.decks &&
                customerDetail.decks.map((deck: any, index) => (
                  <LocationInput
                    key={`locationInput${deck._id}}`}
                    label={deck.code}
                    defaultValue={deck?.location}
                    size="small"
                    //popupIcon={<LocationSearchingIcon color="primary" sx={{mr: '4px'}}/>}
                    onSelect={loc => handleSetLocation(index, loc)}
                  />
                ))}
            </div>
          </SectionWrapper>
          <FooterWrapper
            style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          >
            <div
              className="footer"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button onClick={handleClose} variant="outlined">
                {t('common.buttons.cancel')}
              </Button>
              <div className="action-buttons">
                <Button variant="contained" onClick={onSubmit}>
                  {t('common.buttons.save')}
                </Button>
              </div>
            </div>
          </FooterWrapper>
        </>
      )}
    </>
  );
};

const EditCustomerTitle = styled.div`
  display: flex;
  margin: 18px;
  & .title {
    margin-left: 20px;
    font-weight: 500;
  }
`;
