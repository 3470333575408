import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const TablePaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: flex-end;
`;

export interface ExpandedProps {
  expanded: boolean;
}

export const CheckboxLabelWrapper = styled.div(props => ({
  display: 'flex',
}));
export const SectionView = styled.div<ExpandedProps>(props => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  '& .dropdown-text': {
    color: props.expanded === true ? props.theme.primary : props.theme.text,
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .no_roundtrip': {
      background: themes?.default?.errorBackground,
      borderRadius: '100px',
      fontFamily: 'Roboto',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '135%',
      textAlign: 'center',
      padding: '5px 11px',
      letterSpacing: '0.4px',
      color: themes?.default?.errorMain,
    },
    '& .inactive_roundtrip': {
      background: themes.default.beige,
      borderRadius: '100px',
      fontFamily: 'Roboto',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '10px',
      textAlign: 'center',
      padding: '5px 11px',
      letterSpacing: '0.4px',
      color: themes?.default?.darkOrange,
      height: '22px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .warehouses_status': {
      position: 'sticky',
      left: '40px',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      '& .status': {
        display: 'flex',
        gap: '11px',
      },
      '& .circle': {
        width: '8px',
        height: '8px',
        background: 'green',
        borderRadius: '10px',
      },
      '& .green_status': {
        background: themes?.default?.greenStatus,
      },
      '& .orange_status': {
        background: themes?.default?.warningMain,
      },
      '& .red_status': {
        background: themes?.default?.redStatus,
      },
      '& .status_count': {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        background: `${themes?.default?.black}0A`,
        padding: '3.067px 3.833px',
        borderRadius: '3.067px',
      },
      '& .title_warehouse': {
        padding: '3px',
        fontSize: '1rem',
      },
    },
  },
  '& .stats-dropdown-text': {
    color: themes?.default?.accordionWhiteBg,
    fontSize: '0.875rem',
  },
  '& .full-width': {
    width: '100%',
  },
  width: '100%',
}));

export const TypesWrapper = styled.div(props => ({
  position: 'fixed',
  bottom: 0,
  zIndex: 1,
  display: 'flex',
  '& .accordion-el': {
    width: 77,
    height: 32,
    fontSize: '0.875rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:not(.selected)': {
      backgroundColor: props.theme.primaryLightO,
      color: props.theme.textColorPrimary,
    },
    '&:not(:first-child)': {
      borderLeft: `0.0.25rem solid ${props.theme.greyDisabled}`,
    },
  },
  '& .selected': {
    backgroundColor: props.theme.primaryActiveO,
    color: themes?.default?.accordionWhiteBg,
  },
}));

export const AccordionSummaryContent = styled.div(props => ({
  display: 'flex',
  height: 38,
  borderBottom: `0.063rem solid ${props.theme.borderGrey}`,
  alignItems: 'center',
  fontSize: '0.875rem',
  fontWeight: 700,
  width: '100%',
  cursor: 'pointer',
}));

export const StopTitle = styled.div(props => ({
  minWidth: 180,
  height: 120,
  background: props.theme.primaryActive,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '26px',
  fontWeight: 700,
  fontSize: '0.8125rem',
  borderBottom: `0.063rem solid ${props.theme.tableBorder}`,
  borderRight: `0.063rem solid ${props.theme.primaryLightO}`,
}));

export const GridAccordionSummaryWrapper = styled.div(props => ({
  borderBottom: `0.063rem solid ${props.theme.tableBorder}`,
  backgroundColor: props.theme.accordionWhiteBg,
  height: 40,
  borderRadius: 4,
  zIndex: 3,
  position: 'sticky',
  top: '35px',
}));

export const EmptyCenter = styled.div<{ top?: string }>`
  position: absolute;
  top: ${props =>
    props.top ? props.top : `calc(50% + ${themes.default.toolBarHeight})`};
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
