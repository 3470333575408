import { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { Can } from '@casl/react';
import styled from 'styled-components/macro';
import SwitchBtnIcon from '../../../../assets/img/Menu/SwitchBtn-icon.svg';
import { useAbility } from 'hooks/Abilities';

export const SystemIcon = ({
  access,
  companyLogoIcon,
  onClick,
  title,
  switchKey,
  hasBorder,
}) => {
  const ability = useAbility();

  const isMac = useMemo(() => {
    return navigator.platform.toLowerCase().indexOf('mac') >= 0;
  }, [navigator]);

  return (
    <Can I="access" a={access} ability={ability}>
      <Tooltip title={title} placement="right" onClick={onClick}>
        <MainSwitcherWrapper hasBorder={hasBorder}>
          <StyledSpan>
            <StyledLefttImg
              src={companyLogoIcon}
              style={{ cursor: 'pointer' }}
            />
          </StyledSpan>
          <StyledSpanRight hasGap={!isMac}>
            {isMac ? (
              <StyledRightImg src={SwitchBtnIcon} />
            ) : (
              <StyledKey>Ctrl</StyledKey>
            )}
            <StyledKey>{switchKey}</StyledKey>
          </StyledSpanRight>
        </MainSwitcherWrapper>
      </Tooltip>
    </Can>
  );
};
const StyledLefttImg = styled.img`
  margin-top: 2px;
  padding-left: 3px;
`;
const StyledSpanRight = styled.span<{ hasGap?: boolean }>`
  line-height: 27px;
  margin: 3px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  gap: ${props => (props.hasGap ? '4px' : '')};
`;
const StyledKey = styled.span`
  font-size: 12px;
`;
const StyledRightImg = styled.img`
  width: 17px;
  height: 17px;
`;
const StyledSpan = styled.span`
  line-height: 27px;
  margin: 3px;
  margin-right: 3px;
`;

const MainSwitcherWrapper = styled.div<{ hasBorder: boolean }>`
  width: 100%;
  padding: 1px;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 25px;

  border: ${props => (props.hasBorder ? 'solid 0.5 transparent' : '')};
  border-radius: 4px;
  background-image: ${props =>
    props.hasBorder
      ? 'linear-gradient(white, white),linear-gradient(67deg, #ff00ff, #b01eff, #1159ff)'
      : ''};
  background-origin: border-box;
  background-clip: content-box, border-box;

  &:hover {
    cursor: pointer;
  }
`;
