import styled from 'styled-components';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useGetConfigurationQuery } from 'commonDev/services/organizationApi';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import BampteeLogo from 'assets/img/Menu/MainBampteeLogo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { selectAuthUser } from 'common/store/auth/selectors';
import { toolsActions } from 'app/slices/tools';
import {
  ROLES_PERMISSIONS,
  SETTINGS_COMPANY_PROFILE,
  SETTINGS_CUSTOMIZATION,
  SETTINGS_LANGUAGE,
  SETTINGS_USERS,
  USERS,
} from 'utils/routes';
import { themes } from 'styles/theme/themes';
import { selectIsZoomedIn } from '../../../slices/tools/selectors';
import { useAbility } from 'hooks/Abilities';
import { BampteeApplications } from 'common/store/app';
import If from 'app/components/If';
import { useMenu } from 'app/components/CustomMenu/hooks/useMenu';
import { Icon } from 'app/components/Icon';
import { CaretDown, Check } from '@phosphor-icons/react';
import { useSwitchOrganizationMutation } from 'common/services/authApi';
import EllipsisText from 'app/components/EllipsisText';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';

export function AccountSettingsNav() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  const theme = useSelector(selectTheme);
  const authUser = useSelector(selectAuthUser);
  const location = useLocation();
  const dispatch = useDispatch();
  const zoomIn = useSelector(selectIsZoomedIn);
  const ability = useAbility();
  const [switchOrganization] = useSwitchOrganizationMutation();

  const [selectedOrganization, setSelectedOrganization] = useState(
    authUser?._currentOrganization,
  );

  const handleSwitchOrganization = useCallback(
    async organization => {
      setSelectedOrganization(organization);
      await switchOrganization({
        organizationId: organization?._id,
      }).unwrap();
    },
    [switchOrganization],
  );

  const options = useMemo(() => {
    const organizations = authUser?._organizations || [];
    return organizations?.map(org => {
      const isSelected = selectedOrganization?._id === org?._id;
      return {
        label: (
          <OrganizationLabel isActive={isSelected}>
            {org.name}
            {!!isSelected && <Check />}
          </OrganizationLabel>
        ),
        action: () => {
          handleSwitchOrganization(org);
        },
      };
    });
  }, [authUser, handleSwitchOrganization, selectedOrganization]);
  const {
    openMenu,
    Menu: OrganizationSelectMenu,
    isMenuOpen,
  } = useMenu(options);

  const canGoBack = useMemo(() => {
    return !!BampteeApplications.find(app => ability.can('access', app.id));
  }, [ability]);

  useEffect(() => {
    if (!zoomIn && location.pathname.includes('/settings')) {
      dispatch(toolsActions.setZoomIn({ status: true }));
    }
  }, [location.pathname]);

  const companyProfileCan = usePermission('companysettings')(Permission.VIEW);
  const rolesCan = usePermission('roles')(Permission.VIEW);
  console.log(rolesCan);
  const usersCan = usePermission('users')(Permission.VIEW);
  const languageCan = usePermission('language')(Permission.VIEW);
  const customizationCan = usePermission('customization')(Permission.VIEW);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  const goBack = () => {
    navigate('/');
    dispatch(toolsActions.setZoomIn({ status: false }));
  };

  useEffect(() => {
    setSelectedOrganization(authUser?._currentOrganization);
  }, [authUser?._currentOrganization]);

  return (
    <SettingsWrapper>
      <HeaderDrawerWrapper>
        <If condition={!!canGoBack}>
          <ArrowBackIcon
            color="action"
            sx={{ cursor: 'pointer', mr: '12px' }}
            onClick={goBack}
          />
        </If>
        <Stack
          direction={'row'}
          alignItems={'center'}
          onClick={event => openMenu(event)}
          sx={{ cursor: 'pointer' }}
        >
          <Typography fontSize="1.125rem" fontWeight="700">
            <EllipsisText text={selectedOrganization?.name} width={140} />
          </Typography>
          <IconButton>
            <Icon
              icon={<CaretDown />}
              weight="bold"
              size={12}
              color={themes?.default?.iconColor}
              style={{
                transitionDuration: '0.2s',
                transform: isMenuOpen ? 'rotate(180deg)' : '',
              }}
            />
          </IconButton>
        </Stack>
        {OrganizationSelectMenu}
      </HeaderDrawerWrapper>
      <ScrollMenuWrapper scrollBarColor={theme.scrollBarColor}>
        <Typography
          fontSize="0.813rem"
          fontWeight="500"
          color={themes?.default?.textColorSecondary}
          sx={{
            padding: '4px 22px 19px 16px',
            mt: '30px',
            textTransform: 'uppercase',
          }}
        >
          {t('settings.title')}
        </Typography>
        <List>
          {companyProfileCan && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={event => {
                  handleListItemClick(event, 0);
                  navigate(SETTINGS_COMPANY_PROFILE);
                }}
              >
                <ListItemText
                  primary={t('settings.companyProfile.title')}
                  color={theme.primaryActiveColor}
                  sx={{
                    ...(location.pathname === SETTINGS_COMPANY_PROFILE
                      ? {
                          color: 'primary.main',
                          '.MuiTypography-root': { fontWeight: '700' },
                        }
                      : { color: 'inherit' }),
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {rolesCan && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={event => {
                  handleListItemClick(event, 1);
                  navigate(ROLES_PERMISSIONS);
                }}
              >
                <ListItemText
                  primary={t('settings.companyProfile.roles')}
                  sx={{
                    ...(location.pathname === ROLES_PERMISSIONS
                      ? {
                          color: 'primary.main',
                          '.MuiTypography-root': { fontWeight: '700' },
                        }
                      : { color: 'inherit' }),
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {usersCan && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={event => {
                  handleListItemClick(event, 2);
                  navigate(SETTINGS_USERS);
                }}
              >
                <ListItemText
                  primary={t('settings.users.title')}
                  sx={{
                    ...(location.pathname?.includes(SETTINGS_USERS)
                      ? {
                          color: 'primary.main',
                          '.MuiTypography-root': { fontWeight: '700' },
                        }
                      : { color: 'inherit' }),
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {languageCan && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={event => {
                  handleListItemClick(event, 3);
                  navigate(SETTINGS_LANGUAGE);
                }}
              >
                <ListItemText
                  primary={t('settings.language.title')}
                  sx={{
                    ...(location.pathname === SETTINGS_LANGUAGE
                      ? {
                          color: 'primary.main',
                          '.MuiTypography-root': { fontWeight: '700' },
                        }
                      : { color: 'inherit' }),
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {/* <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 4)}>
              <ListItemText
                primary="Notifications"
                sx={{
                  ...(selectedIndex === 4
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem> */}
          {customizationCan && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={event => {
                  handleListItemClick(event, 5);
                  navigate(SETTINGS_CUSTOMIZATION);
                }}
              >
                <ListItemText
                  primary={t('settings.customization.title')}
                  sx={{
                    ...(location.pathname === SETTINGS_CUSTOMIZATION
                      ? {
                          color: 'primary.main',
                          '.MuiTypography-root': { fontWeight: '700' },
                        }
                      : { color: 'inherit' }),
                  }}
                />
              </ListItemButton>
            </ListItem>
          )}
          {/* <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 6)}>
              <ListItemText
                primary="Roundtrips Templates"
                sx={{
                  ...(selectedIndex === 6
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 7)}>
              <ListItemText
                primary="Mappings"
                sx={{
                  ...(selectedIndex === 7
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 8)}>
              <ListItemText
                primary="Integrations"
                sx={{
                  ...(selectedIndex === 8
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 9)}>
              <ListItemText
                primary="Security"
                sx={{
                  ...(selectedIndex === 9
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ borderBottom: `solid 1px ${themes?.default?.lightFill}` }}
          >
            <ListItemButton onClick={event => handleListItemClick(event, 10)}>
              <ListItemText
                primary="Activity logs"
                sx={{
                  ...(selectedIndex === 10
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem> */}
        </List>
        {/* <Typography
          fontSize="0.813rem"
          fontWeight="500"
          color={themes?.default?.textColorSecondary}
          sx={{ padding: '4px 22px 19px 19px', mt: '20px' }}
        >
          OTHER
        </Typography>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 11)}>
              <ListItemText
                primary="Help"
                sx={{
                  ...(selectedIndex === 11
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 12)}>
              <ListItemText
                primary="Contact"
                sx={{
                  ...(selectedIndex === 12
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 13)}>
              <ListItemText
                primary="Policies"
                sx={{
                  ...(selectedIndex === 13
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={event => handleListItemClick(event, 14)}>
              <ListItemText
                primary={<strong>Signout</strong>}
                sx={{
                  ...(selectedIndex === 14
                    ? {
                      color: 'primary.main',
                      '.MuiTypography-root': { fontWeight: '700' },
                    }
                    : { color: 'inherit' }),
                }}
              />
            </ListItemButton>
          </ListItem>
        </List> */}
        <FooterDrawerWrapper>
          <img src={BampteeLogo} width="17px" height="23px" />
          <Typography
            fontSize="0.688rem"
            fontWeight="400"
            sx={{ lineHeight: '23px', ml: '10px' }}
          >
            {t('powered_by_bamptee')}
          </Typography>
        </FooterDrawerWrapper>
      </ScrollMenuWrapper>
    </SettingsWrapper>
  );
}

const HeaderDrawerWrapper = styled.div`
  display: flex;
  width: 223.629px;
  height: 52px;
  padding: 12px 0px 16px 19px;
  align-items: center;
`;

const FooterDrawerWrapper = styled.div`
  display: flex;
  text-align: center;
  padding-top: 20px;
  justify-content: center;
  margin: 0 auto;
  //css to position the footer bottom of nav
  position: absolute;
  right: 0;
  left: 0;
  bottom: 16px;
`;

const SettingsWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 4;
  border-right: 1px solid var(--divider, ${themes?.default?.lightFill});
  background: ${themes?.default?.ghostWhite};
  box-shadow: 0px 3px 15px 0px rgba(139, 139, 139, 0.1);
`;

const ScrollMenuWrapper = styled.div<{ scrollBarColor: string }>`
  max-height: calc(100vh - 90px);
  overflow: hidden;

  &:hover {
    overflow: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${props => props?.scrollBarColor};
    }
  }
`;

const OrganizationLabel = styled.div<{ isActive?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: ${props => (props.isActive ? '700' : '400')};
  color: ${props => (props.isActive ? props.theme.primaryActiveColor : '')};
  width: 100%;
`;
