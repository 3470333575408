import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% + 8px);
  margin-left: -4px;
  z-index: 10;
`;

export const ImgWrapper = styled.div`
  border-radius: 8px;
  background: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1),
    2px 0px 8px 0px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
