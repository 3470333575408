import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../slices/auth/selectors';
import { useCheckQuery } from '../../../common/services/authApi';
import { useGetOrganizationActivitiesQuery } from '../../../common/services/organizationApi';
import useWindowFocus from 'use-window-focus';
import { BIANTA, LOGOUT, ORDERS_ACTIONS, ORDORIA, SIGNIN } from 'utils/routes';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';

function PrivateWrapper({ children }) {
  useCheckQuery();

  return <>{children}</>;
}
function PrivateRoute({ children }) {
  const windowFocused = useWindowFocus();
  const [needRefresh, setNeedRefresh] = useState(false);
  const authToken = useSelector(selectAuthToken);
  const { error, refetch } = useCheckQuery();
  const { refetch: refetchActivities } = useGetOrganizationActivitiesQuery();
  const { isCustomer } = useGetUserAcl();

  useEffect(() => {
    if (windowFocused === false) {
      setNeedRefresh(true);
    } else if (windowFocused === true && needRefresh === true) {
      refetch();
      refetchActivities();
      setNeedRefresh(false);
    }
  }, [windowFocused]);

  if (authToken === null) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={SIGNIN} />;
  } else if ((error as { status: number })?.status === 401) {
    return <Navigate to={LOGOUT} />;
  } else if (authToken === undefined) {
    return <></>;
  }

  // prevent customer role from biante routes, later to be replaced by actual permissions implementation
  // if (isCustomer() && !location?.pathname?.includes(ORDORIA)) {
  //   return <Navigate to={ORDERS_ACTIONS} />;
  // }

  return <PrivateWrapper>{children}</PrivateWrapper>;
}

export default PrivateRoute;
