import { MenuItem } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { PopOverElement } from '../Popover';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { PopOverElWrapper } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';

interface Props {
  action: any;
  handleItemClick: any;
  handleAction: any;
  handleActionclick: any;
  handleClose: any;
  id: any;
  leftPosition?: boolean;
}
export const NestedItemsComponent: React.FC<Props> = ({
  action,
  handleItemClick,
  handleAction,
  handleActionclick,
  handleClose,
  id,
  leftPosition,
}) => {
  const theme = useSelector(selectTheme);
  useEffect(() => {
    const elements = document.querySelectorAll(
      '.MuiPaper-root.MuiPaper-elevation.MuiPopover-paper.MuiMenu-paper',
    );
    const lastInstance = elements[elements.length - 1];
    lastInstance?.classList.add('nested-menu-item');
    if (leftPosition) {
      lastInstance?.classList.add('nested-menu-item-left');
    }
  }, []);
  return (
    <>
      {action?.actions?.[0] ? (
        action.actions.map((action2, index) => {
          if (action2?.element) {
            return (
              <MenuItem
                sx={{
                  padding: '0 !important',
                }}
              >
                <PopOverElement
                  primaryActiveColor={theme.primaryActiveColor}
                  className={action2?.seperator ? 'seperator' : ''}
                  style={{
                    height: action2?.seperator ? 'auto' : '',
                  }}
                  onClick={e => {
                    if (handleAction) handleAction(e, action2.action, id);
                    if (handleActionclick)
                      handleActionclick(e, handleClose, action2.action);

                    handleClose();
                  }}
                >
                  <PopOverElWrapper>{action2.element}</PopOverElWrapper>
                </PopOverElement>
              </MenuItem>
            );
          }
        })
      ) : (
        <>{action?.actions}</>
      )}
    </>
  );
};
