import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { DrawerComponent } from 'app/components/DrawerComponent';
import { generatePath, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { STORAGELOCATIONINFO } from 'utils/routes';

import {
  Button,
  FormControl,
  Autocomplete,
  TextField,
  Box,
  InputLabel,
  FormLabel,
  MenuItem,
  Select,
  Grid,
} from '@mui/material';
import { storageLocationStatuses } from '../../../Consts';
import InputAdornment from '@mui/material/InputAdornment';
import { useToaster } from 'hooks/useToaster';
import { useApiCalls } from '../../hooks/useApiCalls';
import { SelectOptionWrapper } from 'app/components/FilterComponents/styles';

import { SubmitButton } from './components/SubmitButton';
import {
  FormActions,
  FormWrapper,
  SectionTitle,
} from 'app/components/Form/styles';
interface DrawerProps {
  handleClose?: any;
  storageLocationForm: any;
  updateStorageFormData: any;
  validationAddLocationErrors: any;
  resetAddLocationForm: any;
  currentSelectedArea: any;
  setAddLocationErrors: any;
}
interface selectItem {
  _id: string;
  label: string;
}
export const AddStorageLocation: React.FC<DrawerProps> = ({
  handleClose,
  storageLocationForm,
  updateStorageFormData,
  validationAddLocationErrors,
  resetAddLocationForm,
  currentSelectedArea,
  setAddLocationErrors,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderOption = (props, field) => {
    return (
      <div {...props} style={{ opacity: 1 }}>
        <SelectOptionWrapper>
          <div>{field.label}</div>
        </SelectOptionWrapper>
      </div>
    );
  };

  const [selectedAisle, setSelectedAisles]: any = useState();
  const [selectedDecks, setSelectedDecks]: any = useState();
  const [disabled, setDisabled]: any = useState(true);
  const [selectedLevel, setSelectedLevel]: any = useState();
  const [selectedLocation, setSelectedLocation]: any = useState();
  const {
    Warehouses,
    areaAisles,
    aisleLevels,
    levelLocations,
    refetchLocations,
    refetchStats,
    createStorageLocation,
  } = useApiCalls();
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToaster();

  const onSubmit = async () => {
    const updatedPostData = _.omit(storageLocationForm, [
      'id',
      'height',
      'length',
      'width',
    ]);

    const body = {
      ...updatedPostData,
      _area: currentSelectedArea._id,
      _type: currentSelectedArea._type,
      units: parseInt(storageLocationForm.units),
      dimensions: {
        width: storageLocationForm.width
          ? parseInt(storageLocationForm.width)
          : 0,
        length: storageLocationForm.length
          ? parseInt(storageLocationForm.length)
          : 0,
        height: storageLocationForm.height
          ? parseInt(storageLocationForm.height)
          : 0,
      },
    };

    const resp = await createStorageLocation(body).unwrap();
    return resp;
  };

  const afterSubmit = options => {
    options?.close && handleClose();
    if (options?.newId) {
      const path = generatePath(STORAGELOCATIONINFO, { id: options?.newId });
      navigate(path);
    }
  };

  useEffect(() => {
    let error = false;
    const requiredData = _.omit(storageLocationForm, [
      'id',
      'height',
      'length',
      'width',
      'note',
      '_area',
      '_type',
    ]);
    for (var key in requiredData) {
      if (!requiredData[key] || requiredData[key] == '') {
        error = true;
      }
    }
    if (error) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [storageLocationForm]);

  const onFormSubmit = async options => {
    try {
      const resp = await onSubmit();

      options?.resetForm && resetAddLocationForm();
      afterSubmit({
        close: options?.closeForm,
        newId: options?.refetch ? resp?.storageLocation?._id : '',
      });
      toast(5000, 'success', `storage.location.add_success`);
      refetchStats();
      refetchLocations();
    } catch (err: any) {
      if (err.status == 400) {
        if (err.data?.validation) {
          err.data?.validation.forEach(errorItem => {
            setAddLocationErrors({
              ...validationAddLocationErrors,
              [errorItem.context?.key]: t(errorItem.message),
            });
          });
        }
      }
      setLoading(false);
    }
  };

  const handleAisleChange = (
    event: React.ChangeEvent<{}>,
    item: selectItem,
  ) => {
    setSelectedAisles(item);
    updateStorageFormData('_aisle', item?._id);
    if (!item || validationAddLocationErrors._aisle) {
      setSelectedLevel(null);
      setSelectedLocation(null);
      updateStorageFormData('_location', null);
      updateStorageFormData('_type', null);
    }
  };

  const handleLocationChange = (
    event: React.ChangeEvent<{}>,
    item: selectItem,
  ) => {
    setSelectedLocation(item);
    updateStorageFormData('_location', item?._id);
  };

  const handleLevelChange = (
    event: React.ChangeEvent<{}>,
    item: selectItem,
  ) => {
    setSelectedLevel(item);
    if (!item) {
      setSelectedLocation(null);
    }
    updateStorageFormData('_level', item?._id);
  };

  const handleLocationWarehouseChange = (
    event: React.ChangeEvent<{}>,
    item: selectItem,
  ) => {
    setSelectedDecks(item);
    updateStorageFormData('_deck', item?._id);
  };

  return (
    <DrawerComponent
      title={'storage.add_storage_location'}
      open={true}
      handleClose={handleClose}
    >
      <FormWrapper>
        <SectionTitle>{t('storage.overview')}</SectionTitle>
        <FormControl size="small" sx={{ width: '100%' }}>
          <Autocomplete
            size="small"
            options={Warehouses || []}
            getOptionLabel={warehouse => warehouse.label}
            onChange={handleLocationWarehouseChange}
            value={storageLocationForm._deck ? selectedDecks : null}
            renderInput={params => (
              <TextField
                {...params}
                label={t('warehouse')}
                placeholder={t('warehouse')}
                fullWidth
                error={validationAddLocationErrors._deck}
                helperText={validationAddLocationErrors._deck}
                required
                sx={{ marginBottom: '0 !important' }}
              />
            )}
            renderOption={renderOption}
          />
        </FormControl>
        <FormControl size="small" sx={{ width: '100%' }}>
          <Autocomplete
            size="small"
            options={areaAisles || []}
            value={storageLocationForm._aisle ? selectedAisle : null}
            getOptionLabel={option => option.label}
            onChange={handleAisleChange}
            renderInput={params => (
              <TextField
                {...params}
                label={t('common.aisles')}
                placeholder={t('common.aisles')}
                fullWidth
                required
                error={validationAddLocationErrors._aisle}
                helperText={validationAddLocationErrors._aisle}
                sx={{ marginBottom: '0 !important' }}
              />
            )}
            renderOption={renderOption}
          />
        </FormControl>
        <FormControl size="small" sx={{ width: '100%' }}>
          <Autocomplete
            size="small"
            options={aisleLevels || []}
            value={storageLocationForm._level ? selectedLevel : null}
            getOptionLabel={option => option.label}
            onChange={handleLevelChange}
            disabled={!storageLocationForm._aisle}
            renderInput={params => (
              <TextField
                {...params}
                label={t('storage.level')}
                placeholder={t('storage.level')}
                fullWidth
                required
                error={validationAddLocationErrors._level}
                helperText={validationAddLocationErrors._level}
                sx={{ marginBottom: '0 !important' }}
              />
            )}
            renderOption={renderOption}
          />
        </FormControl>
        <FormControl size="small" sx={{ width: '100%' }}>
          <Autocomplete
            size="small"
            options={levelLocations || []}
            value={storageLocationForm._location ? selectedLocation : null}
            getOptionLabel={option => option.label}
            onChange={handleLocationChange}
            disabled={
              !storageLocationForm._level || !storageLocationForm._aisle
            }
            renderInput={params => (
              <TextField
                {...params}
                label={t('storage.location')}
                placeholder={t('storage.location')}
                fullWidth
                required
                error={validationAddLocationErrors._location}
                helperText={validationAddLocationErrors._location}
                sx={{ marginBottom: '0 !important' }}
              />
            )}
            renderOption={renderOption}
          />
        </FormControl>
        <FormControl size="small" sx={{ width: '100%' }}>
          <InputLabel id="demo-simple-select-label">
            {t('common.labels.status')}
          </InputLabel>

          <Select
            labelId="demo-simple-select-label"
            value={storageLocationForm.status}
            required={true}
            error={validationAddLocationErrors.status}
            label={t('common.labels.status')}
            onChange={e => updateStorageFormData('status', e.target.value)}
          >
            {storageLocationStatuses.map(option => (
              <MenuItem value={option.key}>
                <Grid container>
                  <div
                    className="greenDot"
                    style={{
                      height: '8px',
                      width: '8px',

                      backgroundColor:
                        option.key == 'active'
                          ? themes?.default?.fruitSaladGreen
                          : themes?.default?.redA700,
                      borderRadius: '100%',
                      marginRight: 5,
                      marginTop: 6,
                    }}
                  ></div>
                  <span> {option.key}</span>
                </Grid>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" sx={{ width: '100%' }}>
          <TextField
            onChange={e => updateStorageFormData('note', e.target.value)}
            label={t('common.label.note')}
            variant="outlined"
            type="text"
            value={storageLocationForm.note}
            size="small"
            inputProps={{ maxLength: 40 }}
            sx={{ marginBottom: '0 !important' }}
            multiline
            rows={4}
          />
        </FormControl>
        <FormLabel
          sx={{ color: themes?.default?.black }}
          id="demo-controlled-radio-buttons-group"
        >
          {t('storage.dimensions')}
        </FormLabel>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <TextField
            label={t('common.width')}
            type="number"
            value={storageLocationForm.width}
            id="outlined-start-adornment"
            onChange={e => updateStorageFormData('width', e.target.value)}
            sx={{ mt: 1, width: '16ch' }}
            error={validationAddLocationErrors.width}
            helperText={validationAddLocationErrors.width}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
          />
          <TextField
            type="number"
            value={storageLocationForm.length}
            onChange={e => updateStorageFormData('length', e.target.value)}
            label={t('common.length')}
            sx={{ ml: 1, mt: 1, width: '16ch' }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
          />
          <TextField
            type="number"
            value={storageLocationForm.height}
            error={validationAddLocationErrors.height}
            helperText={validationAddLocationErrors.height}
            onChange={e => updateStorageFormData('height', e.target.value)}
            label={t('common.height')}
            sx={{ ml: 1, mt: 1, width: '16ch' }}
            InputProps={{
              inputProps: { min: 0 },
              endAdornment: <InputAdornment position="end">mm</InputAdornment>,
            }}
          />
        </Box>
        <FormLabel
          sx={{ color: themes?.default?.black }}
          id="demo-controlled-radio-buttons-group"
        >
          {t('storage.num_of_units')}
        </FormLabel>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <TextField
            type="number"
            required={true}
            onChange={e => updateStorageFormData('units', e.target.value)}
            value={storageLocationForm.units}
            label={t('storage.num_of_units')}
            sx={{ width: '16ch', mt: 1 }}
            error={validationAddLocationErrors.units}
            helperText={validationAddLocationErrors.units}
            InputProps={{
              inputProps: { min: 1 },
            }}
          />
        </Box>
      </FormWrapper>
      <FormActions style={{ marginTop: '30px' }}>
        <Button
          disableElevation
          sx={{ color: themes.default.black60 }}
          onClick={handleClose}
        >
          {t('common.buttons.cancel')}
        </Button>

        <SubmitButton
          loading={loading}
          onSubmit={onFormSubmit}
          disabled={disabled}
          isEdit={false}
          withOptions={true}
        />
      </FormActions>
    </DrawerComponent>
  );
};
