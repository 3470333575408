import { useSelector } from 'react-redux';
import { selectTheme } from '../../../styles/theme/slice/selectors';
import { t } from 'i18next';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import { QuestionMark, WarningAmberOutlined } from '@mui/icons-material';
import styled from 'styled-components';

interface OrderProductIconProps {
  missing: boolean;
  damaged: boolean;
}
export default function OrderProductIcon({
  missing,
  damaged,
}: OrderProductIconProps) {
  const theme = useSelector(selectTheme);

  const iconSx = { height: '1rem', width: '1rem' };
  let productIcon = <></>;

  let message = missing ? t('missing') : t('damaged');

  if (missing) {
    productIcon = (
      <QuestionMark
        sx={{
          ...iconSx,
          color: theme.darkOrange,
        }}
      />
    );
  } else if (damaged) {
    productIcon = (
      <WarningAmberOutlined
        sx={{
          ...iconSx,
          color: theme.Cinnabar,
        }}
      />
    );
  }

  return (
    <IconWrapper>
      <ManagedTooltip title={message}>{productIcon}</ManagedTooltip>
    </IconWrapper>
  );
}

const IconWrapper = styled.div`
  position: absolute;
`;
