import { Badge, TableCell, TableRow, Tooltip } from '@mui/material';
import styled from 'styled-components';
import EllipsisText from 'app/components/EllipsisText';
import OrderProductIcon from 'app/components/OrderProductIcon';
import If from 'app/components/If';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { Question, Warning } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';

const renderQuantity = (t, quantity, missingQuantity) => {
  if (missingQuantity > 0) {
    const total = quantity + missingQuantity;

    return (
      <Tooltip
        placement="bottom"
        arrow
        title={t('product.quantityHelper', { total, quantity })}
      >
        <span>
          {quantity}/{total}
        </span>
      </Tooltip>
    );
  }

  return <>{quantity}</>;
};

export function ProductTableRow({
  product,
  checkIfDamaged,
  isCustomer,
  heads,
  deckId,
}) {
  const { t } = useTranslation();
  const showOrderedQuantity = heads?.find(
    head => head?.id === 'quantity' && !head.disableRow,
  );
  const showLoadedQuantity = heads?.find(
    head => head?.id === 'loadedQuantity' && !head.disableRow,
  );
  const showDeliveredQuantity = heads?.find(
    head => head?.id === 'quantityDelivered' && !head.disableRow,
  );

  const stock =
    product?._product?.stockDecks?.find(sd => sd._deck === deckId)?.stock || 0;

  return (
    <TableRow key={product._id}>
      <ProductTableCell
        sx={{
          position: 'relative',
        }}
      >
        <OrderProductIcon
          missing={!!product.missingQuantity}
          damaged={checkIfDamaged(product._product.code)}
        />
        <span style={{ marginRight: '15px', marginLeft: '25px' }}>
          {product._product.code}
        </span>
      </ProductTableCell>
      <ProductTableCell>
        <EllipsisText width={350} text={product._product.name} />
        <SubtitleText>
          {product._product.boxingUnit} {`${t('orders.items')} / ${t('box')}`}
        </SubtitleText>
      </ProductTableCell>
      <ProductTableCell sx={{ color: stock === 0 ? 'red' : 'inherit' }}>
        {stock}
      </ProductTableCell>
      {/* <If
        condition={isCustomer === false}
        otherwise={<></>}
      >
        <ProductTableCell
          
          
        >
          {product._product.boxingUnit}
        </ProductTableCell>
      </If> */}
      {showOrderedQuantity && (
        <ProductTableCell>
          {renderQuantity(t, product.quantity, product.missingQuantity)}
        </ProductTableCell>
      )}
      {showLoadedQuantity && (
        <ProductTableCell>{product.quantityLoaded}</ProductTableCell>
      )}
      {showDeliveredQuantity && (
        <ProductTableCell>
          <ProductQuantitiesContainer>
            {product.quantityDelivered}
            {product?.damagedQuantity > 0 && (
              <Icon
                icon={<Warning />}
                color={themes?.default?.chipRedBg}
                size={16}
                tooltip={`${product.damagedQuantity} ${t('common.damaged')}`}
                tooltipWithArrow={true}
              />
            )}
            {product?.missingQuantity > 0 && (
              <Icon
                icon={<Question />}
                color={themes?.default?.orangePeel}
                size={16}
                tooltip={`${product.missingQuantity} ${t('common.missing')}`}
                tooltipWithArrow={true}
              />
            )}
          </ProductQuantitiesContainer>
        </ProductTableCell>
      )}
      {/* <TableCell  >
        3 <InfoOutlinedIcon sx={{ ml: 0.5, color: `${themes.default.black}8A` }} />
      </TableCell> */}
      <ProductTableCell>{`€ ${product.salePrice.toFixed(2)}`}</ProductTableCell>
      <ProductTableCell>
        {`€ ${(product.salePrice.toFixed(2) * product.quantity).toFixed(2)}`}
      </ProductTableCell>
      <If condition={!isCustomer} otherwise={<></>}>
        <ProductTableCell
          sx={{ padding: '6px 8px', fontWeight: '700', fontSize: '0.75rem' }}
          align="center"
        >
          {product.amount === 0 && ['free', 'sample'].includes(product.type) ? (
            <FreeDiscount>{t(`common.${product.type || 'free'}`)}</FreeDiscount>
          ) : product.discount ? (
            `${product.discount.toFixed(2)}%`
          ) : (
            '-'
          )}
        </ProductTableCell>
      </If>
      <ProductTableCell>{`€ ${product.amount.toFixed(2)}`}</ProductTableCell>
      {/* Hide notes */}
      {/* <TableCell  >
        <Tooltip title={t('roundtrips.tooltip.notes')}>
          <div
            className="stops-data stops-data-sm"
          >
            <IconButton
              onClick={() =>
                handleDisplayNote(product._id)
              }
            >
              <If condition={getCountNotes(product._product._id) > 0} otherwise={(<NoteOutlinedIcon />)}>
                <StyledBadge color="error" badgeContent={getCountNotes(product._product._id)?.toString()}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  overlap="circular"
                >
                  <NoteOutlinedIcon />
                </StyledBadge>
              </If>
            </IconButton>
          </div>
        </Tooltip>
      </TableCell> */}
    </TableRow>
  );
}

const ProductTableCell = styled(TableCell)`
  font-size: 0.75rem;
  padding: 6px 8px !important;
  text-align: left;
`;
const FreeDiscount = styled.span`
  color: ${props => props.theme.greenStatus};
`;
const SubtitleText = styled.div`
  color: ${props => props.theme.black}99;
  font-size: 0.625rem;
  font-weight: 500;
`;
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    display: 'block',
    padding: 0,
    paddingTop: '3.5px',
    background: theme.badgeRed,
    height: '16px',
    minWidth: '16px',
  },
}));

const ProductQuantitiesContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
`;
