import styled from 'styled-components';

export const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
export const FormWrapper = styled.div`
  padding: 16px;
  flex: 1;
  overflow-y: auto;
`;

export const FormSection = styled.div`
  position: relative;
`;

export const FormActions = styled.div`
  width: 100%;
  max-height: 70px;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 2;
  border-top: ${props => `1px solid ${props.theme.lightFill}`};
`;

export const SectionTitle = styled.div`
  font-size: 0.9375rem;
  font-weight: 500;
  padding-bottom: 15px;
  color: ${props => props.theme.black};
`;
