import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Item } from '../style';
import { IconButton } from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { useTranslation } from 'react-i18next';
import { vehicleCondition } from '../../../functions/function';
import StopIcon from '../../../../../../components/StopIcon';
import { themes } from 'styles/theme/themes';
import { StatusOrder } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import { Checkbox } from 'app/components/CustomCheckbox';

export default function ItemComponent({
  item,
  checked,
  handleChange,
  Added,
  uncheckById,
  itemIndex,
}) {
  const { t } = useTranslation();
  const time = item?._deck?.deliveryHours?.[0]?.begin
    ? `${item?._deck?.deliveryHours?.[0]?.begin} - ${item?._deck?.deliveryHours?.[0]?.end}`
    : '';

  return (
    <Item
      style={
        Added
          ? { alignItems: 'center' }
          : { display: 'flex', marginTop: itemIndex === 0 ? 0 : '' }
      }
    >
      {Added === false && (
        <div
          className="checkbox-group"
          style={{ paddingTop: '9px', height: 'auto' }}
        >
          <Checkbox
            checked={!!checked[item.id]}
            onChange={event => handleChange(event, item.id)}
          />
          <div className="data" style={{ maxWidth: '70px' }}>
            {item?.priority === 'high' ? (
              <KeyboardDoubleArrowDownIcon
                sx={{ color: themes?.default?.greenStatus }}
              />
            ) : item?.priority === 'medium' ? (
              <DragHandleIcon sx={{ color: themes?.default?.darkTangerine }} />
            ) : (
              <KeyboardDoubleArrowUpIcon
                sx={{ color: themes?.default?.redStatus }}
              />
            )}
          </div>
        </div>
      )}

      <div className="checkbox-group">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="data" style={{ maxWidth: '70px' }}>
            <StopIcon
              type={item.type}
              orderCategory={item.category}
              tooltipMessage={t(`common.${item?.type}`)}
            />
          </div>

          <span
            style={{
              fontWeight: 400,
              display: 'flex',
              alignItems: 'center',
              marginLeft: '7px',
            }}
          >
            <p className="code">{item?._customer?.code}</p>
            <div style={{ width: '100%', marginLeft: '5px' }}>
              <StatusOrder status={item?.status} />
            </div>
          </span>
          {time && (
            <div className="accordion-data time" style={{ maxWidth: '100px' }}>
              <div>{time}</div>
            </div>
          )}
        </div>
        <div className="data_Name_Location">
          {item?._customer?.publicName}, {item?._deck?.location?.shortAddress}
        </div>
        <div className="content gray_value_data">
          {(item?._deck?.vehicleCondition ||
            item?.cache?._deck?.vehicleCondition) && (
            <div className="gray_value">{vehicleCondition(item)}</div>
          )}
          <div className="gray_value">
            {item?.totalWeight?.toFixed(2) || 0} KG
          </div>
          <div className="gray_value">
            {0} {`m${'\u00B3'}`}
          </div>
          <div className="gray_value">{item?.countLines || 0} ITEMS</div>
          <div className="gray_value">
            {item?.supportUnitsRequestedCapacity || 0} Support Units
          </div>
          {/* <PersonPopover supportUnits={item?.supportUnits} t={t} /> */}
        </div>
      </div>
      {Added == true && (
        <div>
          <IconButton aria-label="delete" onClick={() => uncheckById(item?.id)}>
            <RemoveCircleOutlineOutlinedIcon />
          </IconButton>
        </div>
      )}
    </Item>
  );
}
