import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { TrashSimple, Eye } from '@phosphor-icons/react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import moment from 'moment';
import ModalPDFViewer from 'app/components/ModalPDFViewer';
import If from 'app/components/If';
import { useDeleteFileMutation } from 'common/services/files';
import { useToaster } from 'hooks/useToaster';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';

interface DownloadedFilesProps {
  name: string;
  droppedAt: any;
  docId: number;
  url: string;
  triggerVehicle: any;
  vehicleId: number;
  deleteOption?: boolean;
}
export const DownloadedFiles = ({
  name,
  droppedAt,
  docId,
  url,
  triggerVehicle,
  vehicleId,
  deleteOption = true,
}) => {
  const [open, setOpen] = React.useState(false);
  const toast = useToaster();
  const { t } = useTranslation();

  const [deleteFile] = useDeleteFileMutation();

  function deleteDoc() {
    if (deleteOption) {
      deleteFile(docId)
        .unwrap()
        .then(res => {
          triggerVehicle({
            id: vehicleId,
          });
          toast(3000, 'success', t('success.delete.info'));
        })
        .catch(err => {
          toast(3000, 'error', t('error.delete.info'));
        });
    }
  }

  return (
    <>
      <If condition={open}>
        <ModalPDFViewer
          open={open}
          url={url}
          fileName={name}
          onClose={() => setOpen(false)}
        />
      </If>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '10px 0',
          borderRadius: '5px',
          marginBottom: '10px',
          maxWidth: '100%',
        }}
      >
        <UploadFileIcon
          color="primary"
          sx={{
            marginRight: '10px',
          }}
        />
        <Grid flexDirection="column">
          <Typography
            fontSize="13px"
            fontWeight="400"
            onClick={() => setOpen(true)}
            sx={{
              marginRight: '10px',
              width: '450px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              '&:hover': {
                textDecoration: 'underline',
                cursor: 'pointer',
              },
            }}
          >
            {name}
          </Typography>
          <Typography color="#9E9E9E" fontSize="13px" fontWeight="400">
            {moment(droppedAt).format('MM/DD/YYYY hh:mm')}
          </Typography>
        </Grid>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton onClick={() => setOpen(true)}>
            <Eye />
          </IconButton>
          <If condition={deleteOption}>
            <IconButton onClick={deleteDoc}>
              <TrashSimple />
            </IconButton>
          </If>
        </div>
      </div>
    </>
  );
};
