import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import If from 'app/components/If';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

interface Props {
  loading: boolean;
  onSubmit: Function;
  disabled: boolean;
  isEdit: boolean;
  withOptions?: boolean;
}

export const BranchButton = ({
  loading,
  onSubmit,
  disabled,
  isEdit = false,
  withOptions = false,
}: Props) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <LoadingButton
        variant="contained"
        disableElevation
        loading
        loadingPosition="start"
        size="small"
      >
        {t(isEdit ? 'common.buttons.save' : 'common.buttons.add')}
      </LoadingButton>
    );
  }
  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        disableElevation
        sx={{
          marginLeft: '16px',
          borderRadius: isEdit || !withOptions ? '4px' : '4px 0 0 4px',
        }}
        onClick={() => onSubmit({ closeForm: true })}
      >
        {t(isEdit ? 'common.buttons.save' : 'common.buttons.add')}
      </Button>
      <If condition={!isEdit && withOptions}>
        <Button
          disabled={disabled}
          variant="contained"
          disableElevation
          size="small"
          sx={{
            minWidth: 0,
            borderRadius: '0 4px 4px 0',
            borderLeft: !disabled
              ? `0.5px solid ${themes.default.darkBlue}`
              : '',
            padding: 0,
          }}
        >
          <NestedMenuComponent
            id="submit-menu"
            iconAction={
              <IconWrapper>
                <Icon icon={<CaretDown />} color="white" />
              </IconWrapper>
            }
            iconOpen={
              <IconWrapper>
                <Icon icon={<CaretUp />} color="white" />
              </IconWrapper>
            }
            anchorOriginHorizontal={'right'}
            anchorOriginVertical={'top'}
            transformOriginVerical={'bottom'}
            actions={[
              {
                action: 'close',
                nested: false,
                onClick: async () => {
                  await onSubmit({ closeForm: true });
                },
                element: <PopoverText>{t('add_and_close')}</PopoverText>,
              },

              {
                action: 'another',
                nested: false,
                onClick: async () => {
                  await onSubmit({ resetForm: true });
                },
                element: (
                  <PopoverText>{t('add_and_start_another')}</PopoverText>
                ),
              },
            ]}
          />
        </Button>
      </If>
    </>
  );
};

const PopoverText = styled.div`
  padding-left: ${themes?.default?.popoverLabelPadding};
  font-size: 0.8125rem;
`;

const IconWrapper = styled.div`
  padding: 4px 10px;
`;
