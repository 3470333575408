import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Popover } from '@mui/material';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { Icon } from 'app/components/Icon';
import { Smiley } from '@phosphor-icons/react';

interface Props {
  onEmojiSelect: (e: any) => void;
  afterClose?: (e?: any) => void;
}

export const EmojiPopover: React.FC<Props> = ({
  onEmojiSelect,
  afterClose,
}) => {
  const { i18n } = useTranslation();
  const [emjoiPopoverAnchor, setEmjoiPopoverAnchor] = useState<any>(null);
  const handleClose = e => {
    setEmjoiPopoverAnchor(null);
    if (!!afterClose) {
      setTimeout(() => {
        afterClose(e);
      }, 50);
    }
  };
  return (
    <>
      <ButtonBase
        sx={{
          borderRadius: '50px',
          padding: '4px',
        }}
        onClick={e => setEmjoiPopoverAnchor(e.currentTarget)}
      >
        <Icon icon={<Smiley />} size={24} />
      </ButtonBase>

      <Popover
        open={!!emjoiPopoverAnchor}
        anchorEl={emjoiPopoverAnchor}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        onClose={e => handleClose(e)}
      >
        <div
          style={{
            position: 'relative',
            width: '352px',
            height: '435px',
            overflow: 'hidden',
          }}
        >
          <Picker
            data={data}
            onEmojiSelect={e => onEmojiSelect(e)}
            previewPosition={'none'}
            skinTonePosition={'none'}
            locale={i18n?.language || 'en'}
            theme={'light'}
          />
        </div>
      </Popover>
    </>
  );
};
