import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AuthState } from './types';
import authApi from '../../services/authApi';
// import authApi from '../../services/authApi';

export const initialState: AuthState = {
  user: null,
  token: null,
  sessionId: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, token, sessionId },
      }: PayloadAction<{ user: any; token: string; sessionId: string }>,
    ) => {
      state.user = user;
      state.token = token;
      state.sessionId = sessionId;
    },
    revokeCredentials: (state, { payload: {} }: PayloadAction<{}>) => {
      state.user = null;
      state.token = null;
      state.sessionId = null;
    },
    logout: state => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        authApi.endpoints.switchDefaultOrganization.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { user, token, sessionId } = response.payload;

            state.user = user;
            // state.token = token;
            state.sessionId = sessionId;
            state.v += 1;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.switchOrganization.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { user, token, sessionId } = response.payload;

            state.user = user;
            // state.token = token;
            state.sessionId = sessionId;
            state.v += 1;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.switchAgent.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { user, token, sessionId } = response.payload;

            state.user = user;
            // state.token = token;
            state.sessionId = sessionId;
            state.v += 1;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.updateMe.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            state.user = response.payload;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.check.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { user, token, sessionId } = response.payload;

            state.user = user;
            state.token = token;
            state.sessionId = sessionId;
            state.v += 1;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.me.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            state.user = response.payload;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.check.matchRejected,
        (state: any, response: any) => {
          if (
            response.payload?.status === 401 ||
            response.payload?.status === 400
          ) {
            state.user = null;
            state.token = null;
            state.sessionId = null;
          }

          return state;
        },
      );
  },
});

const authReducer = slice.reducer;

export const { actions: authActions } = slice;

export default authReducer;
