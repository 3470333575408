import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '@mui/material';
import { CustomIconButton } from 'app/components/Chat/components/CustomIconButton';
import { X } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleClose: Function;
  imgSrc: string;
}

export const FullScreenImageView: React.FC<Props> = ({
  open,
  handleClose,
  imgSrc,
}) => {
  const { t } = useTranslation();
  const [zoom, setZoom] = useState(100); // zoom in %
  const [selectedZoom, setSelectedZoom] = useState(150); // zoom in %

  const handleCloseModal = e => {
    handleClose(e);
    setZoom(100);
  };

  const handleZoom = e => {
    e.stopPropagation();
    if (zoom === selectedZoom) {
      setZoom(100);
    } else {
      setZoom(selectedZoom);
    }
  };

  return (
    <Modal open={open} onClose={e => handleCloseModal(e)}>
      <ImageViewWrapper onClick={e => handleCloseModal(e)} zoom={zoom}>
        <div className="actions">
          <CustomIconButton
            icon={<X />}
            iconColor={themes?.default?.accordionWhiteBg}
            size={28}
            tooltip={t('common.close')}
            onClick={e => handleCloseModal(e)}
          />
        </div>
        <img
          src={imgSrc}
          draggable="false"
          onClick={e => handleZoom(e)}
          alt=""
        />
      </ImageViewWrapper>
    </Modal>
  );
};

export const ImageViewWrapper = styled.div<{ zoom: number }>`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 30px;
  overflow: auto;

  .actions {
    position: absolute;
    top: 20px;
    right: 12px;
    display: flex;
    gap: 12px;
  }
  img {
    height: ${props => props.zoom}%;
    cursor: ${props => (props.zoom === 100 ? 'zoom-in' : 'zoom-out')};
    border-radius: 8px;
    transition: all 0.2s ease-in;
  }
`;
