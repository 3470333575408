import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import { AuthState } from './types';
import { MosaicNode } from 'react-mosaic-component';
import authApi from '../../../common/services/authApi';
export const initialState: AuthState = {
  user: null,
  token: null,
  sessionId: null,
  v: 0,
  mainMenuSize: 'full',
  dashboardConfiguration: {
    direction: 'row',
    first: uuidv4(),
    second: {
      direction: 'column',
      first: uuidv4(),
      second: uuidv4(),
    },
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, token, sessionId },
      }: PayloadAction<{ user: any; token: string; sessionId: string }>,
    ) => {
      state.user = user;
      state.token = token;
      state.sessionId = sessionId;
    },
    logout: state => {
      state.user = null;
      state.token = null;
    },
    setDashboardConfiguration: (
      state,
      { payload: { config } }: PayloadAction<{ config: MosaicNode<string> }>,
    ) => {
      state.dashboardConfiguration = config;
    },
    setMainMenuSize: (
      state,
      { payload: { size } }: PayloadAction<{ size: string }>,
    ) => {
      state.mainMenuSize = size;
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        authApi.endpoints.check.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { user, token, sessionId } = response.payload;

            state.user = user;
            state.token = token;
            state.sessionId = sessionId;
            state.v += 1;
          }
        },
      )
      .addMatcher(
        authApi.endpoints.me.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            state.user = response.payload;
          }
        },
      )
      .addMatcher(
        authApi.endpoints.check.matchRejected,
        (state: any, response: any) => {
          if (response.payload?.status === 401) {
            state.user = null;
            state.token = null;
            state.sessionId = null;
          }
        },
      )
      .addMatcher(
        authApi.endpoints.switchOrganization.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload) {
            const { user, token, sessionId } = response.payload;

            state.user = user;
            state.token = token;
            state.sessionId = sessionId;
          }
        },
      )
      .addMatcher(
        authApi.endpoints.switchAgent.matchFulfilled,
        (state: any, response: any) => {
          console.log('authApi.endpoints.switchAgent.matchFulfilled', response);
          if (response.payload) {
            const { user, token, sessionId } = response.payload;

            console.log(
              'authApi.endpoints.switchAgent.matchFulfilled',
              user.currentAgent?._customer,
            );
            state.user = user;
            // state.token = token;
            state.sessionId = sessionId;
            state.v += 1;
          }

          return state;
        },
      )
      .addMatcher(
        authApi.endpoints.updateMe.matchFulfilled,
        (state: any, response: any) => {
          if (response.payload?.email) {
            state.user = response.payload;
          }
        },
      );
  },
});

export const { actions: authActions } = slice;
export const authReducer = slice.reducer;

export const useAuthSlice = () => {
  // useInjectReducer({ key: slice.name, reducer: authReducer });
  return { actions: slice.actions };
};
