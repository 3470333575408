import { useMemo, useCallback } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { StatusPopover } from 'app/components/TableComponent/helpers/Components/StatusPopover';
import { LeftMenuContext } from '../../../../../LeftMenu';
import { useContext } from 'react';
import { ActionWrapper, RoundtripItemProps } from '../..';
import If from 'app/components/If';
import TruckIcon from 'app/components/TruckIcon';
import styled from 'styled-components';
import { MapPinLine, Ruler, Scales, UserCircle } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { themes } from 'styles/theme/themes';
import { PopoverAction } from 'app/pages/AddRoundTrips/data/RoundTripData/components/PopoverAction';
import { functionRoundTripStatus } from 'app/components/RoundTrips/function/ApiFunctions';
import { useTranslation } from 'react-i18next';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';
import { getIsRoundtripOverLoaded } from 'app/helpers/helpers';
import { SupportUnitsPopover } from 'app/components/SupportUnitsPopover';
import { StopMetrics } from 'app/pages/AddRoundTrips/components/Metrics/components/StopMetrics';
import { FarParkedCount } from 'app/pages/AddRoundTrips/components/FarParkedCount';
import { Stops } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StopsBar';

export const RoundtripExpanded = ({
  roundtrip,
  roundtripInfo,
  isHovered,
}: RoundtripItemProps) => {
  const {
    updateRoundtripStatus,
    viewMode,
    handleClickOpen,
    handleClickOpenDelete,
    setRoundtripEdit,
  } = useContext(LeftMenuContext);

  const { t } = useTranslation();

  const handleFarDeliveredOrdersCount = useCallback(() => {
    var count = 0;
    roundtrip?.stops?.map(order => {
      if (
        order?._order?.deliveryInfo?.distance &&
        order?._order?.deliveryInfo?.distance > 500
      ) {
        count++;
      }
    });
    return count;
  }, [roundtrip]);

  const farDeliveredOrdersCount = useMemo(() => {
    return handleFarDeliveredOrdersCount();
  }, [handleFarDeliveredOrdersCount]);

  return (
    <>
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        <StatusPopover
          roundtrip={roundtrip}
          functionRoundTripStatus={updateRoundtripStatus}
          notEdit={viewMode}
          expanded={true}
          truckStatusIcon={
            <If
              condition={
                roundtripInfo?.missingUser || !roundtripInfo?.hasVehicle
              }
            >
              <TruckIcon
                driver={!!roundtripInfo?.missingUser}
                vehicle={!roundtripInfo?.hasVehicle}
                running={roundtrip?._vehicle?.engineRunning}
                status={roundtrip?._vehicle?.status}
                style={{
                  position: 'relative',
                  paddingLeft: '4px',
                }}
                iconStyle={{
                  icon: { width: '0.75rem', height: '0.75rem' },
                  img: { width: '0.75rem', height: '0.75rem' },
                }}
              />
            </If>
          }
        />
      </Stack>

      <>
        <Stack direction={'row'} gap={'7px'} alignItems={'center'}>
          <MetricContainer>
            <MetricValue padding="3px 0 0 0">
              {!!roundtrip?._vehicle
                ? `${roundtrip?.supportUnitsRequestedCapacity || 0} / ${
                    roundtrip?._vehicle?.capacity?.supportUnit
                  } `
                : `${roundtrip?.supportUnitsRequestedCapacity || 0} `}
            </MetricValue>
            <div
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <SupportUnitsPopover
                canEdit={false}
                widthButton={16}
                supportUnits={{
                  ...roundtrip?.supportUnitsList,
                  unloaded: roundtrip?.supportUnitsListCollected,
                }}
                isOverLoaded={getIsRoundtripOverLoaded({
                  vehicle: roundtrip?._vehicle,
                  capacity: roundtrip?.supportUnitsRequestedCapacity,
                })}
                buttonSx={{ width: themes.default.iconSize, padding: 0 }}
              />
            </div>
          </MetricContainer>
          <MetricContainer>
            <Icon icon={<Scales />} tooltip={t('roundtrips.others.weight')} />
            <MetricValue>
              {roundtrip?.cache?.stats?.totalWeight?.toFixed(2)} KG
            </MetricValue>
          </MetricContainer>
          <MetricContainer>
            <Icon icon={<Ruler />} tooltip={t('roundtrips.tooltip.volume')} />
            <MetricValue>0 M3</MetricValue>
          </MetricContainer>
        </Stack>

        <Divider sx={{ borderColor: themes.default.whisper, width: '100%' }} />

        <Stack
          direction={'row'}
          gap={'6px'}
          width={'100%'}
          alignItems={'center'}
        >
          <TruckIcon
            driver={false}
            vehicle={false}
            running={roundtrip?._vehicle?.engineRunning}
            status={roundtrip?._vehicle?.status}
            style={{
              position: 'relative',
              paddingLeft: '4px',
            }}
          />

          <Stops
            stats={roundtrip.cache?.stats}
            roundtripStatus={roundtrip.status}
            stopCount={roundtrip.stopCount}
            farDeliveredOrdersCount={farDeliveredOrdersCount}
            minimal={true}
          />

          <If condition={!viewMode}>
            <ActionWrapper isHovered={isHovered}>
              <PopoverAction
                typeEdit={!viewMode}
                roundtrip={roundtrip}
                functionRoundTripStatus={functionRoundTripStatus}
                handleClickOpen={handleClickOpen}
                handleClickOpenDelete={handleClickOpenDelete}
                setRoundtripEdit={setRoundtripEdit}
                anchorOriginVertical={'top'}
                anchorOriginHorizontal={38}
                transformOriginVerical={'top'}
                transformOriginHorizontal={'left'}
              />
            </ActionWrapper>
          </If>
        </Stack>
      </>
    </>
  );
};

const MetricContainer = styled.div`
  display: flex;
  gap: 4px;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background: ${props => props.theme.grey4};
  font-size: 0.7rem;
  height: 20px;
`;

interface MetricValueProps {
  padding?: string;
}
const MetricValue = styled.div<MetricValueProps>`
  text-wrap: nowrap;
  padding: ${props => props.padding};
`;
