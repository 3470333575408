import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useEffect, useState, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  useGetCustomersQuery,
  useLazyGetCustomerQuery,
} from 'common/services/customerApi';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { TemplatesCards } from 'app/components/TemplatesCards';
import { filterItems } from 'functions/searchItemInList';
import { useLazyGetTemplatesOrderQuery } from 'common/services/templates';
import {
  useCreateOrderMutation,
  useLazyGetOrdersQuery,
} from 'common/services/orderApi';
import { ToastMessage } from 'app/components/Toast';
import { useNavigate } from 'react-router-dom';
import _without from 'lodash/without';
import CancelIcon from '@mui/icons-material/Cancel';
import { PickersDay } from '@mui/x-date-pickers';
import { useGetOrdersQuery } from 'common/services/orderApi';
import { useDebounce } from 'common/hooks/useDebounce';
import { useQueryParams } from 'hooks/useQueryParams';
import { usePatchCallStatusMutation } from 'common/services/calls';
import { CustomerStatus } from 'common/types/Customer';
interface CreateOrderDrawerSalesProps {
  closeDrawer: () => void;
  dateValue: any;
  setDateValue: any;
  setRadioValue: any;
  radioValue: any;
  setNextDeliveryDate: any;
  selectedCustomer?: any;
  type?: string;
  callId?: string;
  statusOrderedCallId?: string;
}
export function CreateOrderDrawerSales({
  closeDrawer,
  dateValue,
  setDateValue,
  setRadioValue,
  radioValue,
  setNextDeliveryDate,
  selectedCustomer,
  type,
  callId,
  statusOrderedCallId,
}: CreateOrderDrawerSalesProps) {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedBranchArray, setSelectedBranchArray] = useState<any>([]);
  const [selectedBranchId, setSelectedBranchId] = useState<any>([]);
  const [templateProducts, setTemplatesProducts] = useState<Array<Object>>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [searchText, setSearchText] = useState('');
  const authUser = useSelector(selectAuthUser);
  const [orderDeck, setOrderDeck] = useState<Array<any>>([
    { _id: '', deliveryDays: [] },
  ]);
  const [isNotTypicalDates, setIsNotTypicalDates] = useState<boolean[]>([]);
  const [selectedIdCustomer, setSelectedIdCustomer] = useState<any>(
    selectedCustomer ? selectedCustomer : '',
  );

  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [createdOrderData, setCreateOrderData] = useState({
    createdOrder: {
      amountRequested: 0,
      code: '',
      countLines: 0,
      countPackages: 0,
      countProducts: 0,
      createdAt: '',
      createdBy: '',
      deleted: false,
      deliveryDate: '',
      id: '',
      priority: '',
      products: [],
      status: '',
      supportUnitsRequestedCapacity: 0,
      totalAmount: 0,
      totalWeight: 0,
      totalAmountTax: 0,
      updatedAt: '',
      __v: 0,
      _id: '',
      _linkClusters: [],
      _organization: '',
    },
  });

  const navigate = useNavigate();

  const [triggergetCustomer, { data, isLoading: isLoadingDecks }] =
    useLazyGetCustomerQuery(selectedIdCustomer);

  const [customersSearch, setCustomersSearch] = useState('');
  const [branchesSearch, setBranchesSearch] = useState('');

  const debouncedCustomersSearch = useDebounce(customersSearch, 500);

  const commonCustomersQueryParams = {
    limit: 1000,
    ordoria: true,
    status: Object.values(CustomerStatus).filter(s => {
      return s !== CustomerStatus.ARCHIVED;
    }),
  };

  const customersQueryParams = useQueryParams({
    ...commonCustomersQueryParams,
    text: debouncedCustomersSearch,
  });

  const {
    data: customersData,
    isLoading: isLoadingCustomers,
    isFetching: isFetchingCustomers,
  } = useGetCustomersQuery(customersQueryParams.slice(1));
  const decks = data?.decks;

  const [dataCustomers, setDataCustomers] = useState<any>(null);

  useEffect(() => {
    if (!customersData) return;
    setDataCustomers(null);
    setTimeout(() => {
      setDataCustomers(customersData);
    }, 200);
  }, [customersData]);

  const decksId = decks?.map(deck => deck._id) || [];

  const handleDateValueChange = newValue => {
    setDateValue(newValue);
  };

  const handleSearchTextChange = e => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleTemplateClick = (templateId, products) => {
    setSelectedTemplate(templateId);
    setTemplatesProducts(products);
  };

  const handleOrderDeckChange = event => {
    setOrderDeck(event.target.value);
  };

  const [triggerGetOrder, { data: orders }] = useLazyGetOrdersQuery();
  const [orderOnSameDate, setOrderOnSameDate] = useState<boolean>(false);

  useEffect(() => {
    const formattedDateValue = moment(dateValue?.$d).format('YYYY/MM/DD');

    const orderQuery = `?&beginAt=${formattedDateValue}&endAt=${formattedDateValue}&status=${[
      'pending',
      'pending-updates',
      'pending-customer',
      'validated',
      'confirmed',
      'prepared',
    ]}&customerDeck=${selectedBranchId}`;

    if (selectedIdCustomer && dateValue && selectedBranchId.length > 0) {
      triggerGetOrder(orderQuery).then(res => {
        if (res?.data.docs.length > 0) {
          setOrderOnSameDate(true);
        } else {
          setOrderOnSameDate(false);
        }
      });
    }
  }, [dateValue, selectedBranchId]);

  useEffect(() => {
    const newIsNotTypicalDates = orderDeck.map((deck: any) => {
      if (selectedBranchArray.length > 0) {
        if (deck?._id?.length && dateValue) {
          const weekDayName = new Date(dateValue).toLocaleDateString('en-US', {
            weekday: 'long',
          });

          const deliveryDaysLower = deck.deliveryDays
            ? deck.deliveryDays.map((e: string) => e.toLowerCase())
            : [];

          if (deliveryDaysLower.includes(weekDayName.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    });

    if (selectedBranchArray.length === 0) {
      setIsNotTypicalDates([]);
    } else {
      setIsNotTypicalDates(newIsNotTypicalDates);
    }
  }, [orderDeck, dateValue, selectedBranchArray]);

  useEffect(() => {
    if (selectedBranchArray && selectedBranchArray.length > 0) {
      const ids = selectedBranchArray.map(branch => branch._id);
      setSelectedBranchId(ids);
    }
    if (selectedBranchArray.length > 0 === false) {
      setSelectedBranchId([]);
      setOrderOnSameDate(false);
    }
  }, [selectedBranchArray]);

  useEffect(() => {
    setIsNotTypicalDates([]);
    setSelectedBranchId([]);
    setSelectedBranchArray([]);
    setNextDeliveryDate(null);
  }, [selectedIdCustomer]);

  useEffect(() => {
    if (decks?.length === 1) {
      setSelectedBranchArray([decks[0]]);
      setNextDeliveryDate(decks[0].nextDeliveryDate);
    }
  }, [decks]);
  const [trigger, { data: templates }] = useLazyGetTemplatesOrderQuery();

  const commonTemplateQueryParams = {
    _customer: selectedIdCustomer || '',
  };
  const templateFetchQueryParams = useQueryParams({
    ...commonTemplateQueryParams,
  });

  useEffect(() => {
    if (selectedIdCustomer) {
      trigger(templateFetchQueryParams);
      triggergetCustomer(selectedIdCustomer);
    }
  }, [selectedIdCustomer]);

  const filteredTemplates = useMemo(() => {
    return filterItems(templates?.templates, searchText.trim(), ['name']);
  }, [templates, searchText]);

  const { t } = useTranslation();

  function handleCountMultipleBranches() {
    return selectedBranchArray.length - 1;
  }

  const [snackPack, setSnackPack] = useState<readonly ToastMessage[]>([]);
  const toast = (message: string, severity: ToastMessage['severity']) => {
    setSnackPack(prev => [
      ...prev,
      { message, key: new Date().getTime(), severity },
    ]);
  };

  let formattedDate: any;

  const [createOrderBtnDisabled, setCreateOrderBtnDisabled] =
    useState<boolean>(true);

  const handlecreateOrderBtnDisable = () => {
    if (
      dateValue === null ||
      radioValue === '' ||
      selectedBranchArray.length <= 0 ||
      selectedIdCustomer === ''
    ) {
      setCreateOrderBtnDisabled(true);
    } else {
      setCreateOrderBtnDisabled(false);
    }
  };

  useEffect(() => {
    handlecreateOrderBtnDisable();
  }, [dateValue, radioValue, selectedBranchArray, selectedIdCustomer]);

  const [createOrder] = useCreateOrderMutation();
  const [changeStatus] = usePatchCallStatusMutation();

  const handleCreateOrder = async () => {
    if (
      dateValue === null ||
      radioValue === '' ||
      selectedIdCustomer === '' ||
      selectedBranchId.length === 0
    ) {
      setOpenAlert(true);
    } else {
      try {
        const result = await createOrder({
          deliveryDate: dateValue,
          type: radioValue,
          deck: selectedBranchId[0],
          templateId: selectedTemplate === null ? null : selectedTemplate,
          products: [],
          linkedDecks: selectedBranchId,
          customerId: selectedIdCustomer,
        }).unwrap();

        toast(t('Added'), 'success');

        setCreateOrderData(result);

        if (type === 'callplan') {
          navigate(`/ordoria/orders/${result.createdOrder.id}?type=callplan`);
          patchStatusCall(result.createdOrder.id);
        } else {
          navigate(`/ordoria/orders/${result.createdOrder.id}`);
        }
      } catch (err) {
        console.error(err);
        toast(t('Something went wrong!'), 'error');
      }
    }
  };

  /**
   * Patch status call with orderId for Ordered status
   * @param orderId - orderId to patch status call
   */
  function patchStatusCall(orderId: string) {
    if (callId && statusOrderedCallId) {
      changeStatus({
        id: callId,
        orderId: orderId,
        statusId: statusOrderedCallId,
        recallBeginTime: null,
        recallEndTime: null,
      }).unwrap();
    }
  }

  ///Multiples Branches
  const [triggerActiveOrder, { data: isOrderActiveData }] =
    useLazyGetOrdersQuery();

  const [orderStatusArray, setOrderStatusArray] = useState<
    { branchId: string; isActive: boolean }[]
  >([]);

  const [filters, setFilters] = useState<{
    end: string | null;
  }>({
    end: null,
  });
  const debouncedFilters = useDebounce(filters, 1000);
  const commonQueryParams = {
    end: debouncedFilters.end || '',
  };
  const fetchQueryParams = useQueryParams({
    ...commonQueryParams,
  });
  const order_ = useGetOrders(fetchQueryParams);
  const [deliveryDates, updateDeliveryDates] = useState<string[]>([]);
  useEffect(() => {
    Promise.all(
      order_?.docs.map(async orderPromise => {
        const order = await orderPromise;
        return order?.deliveryDate;
      }),
    )
      .then(newDeliveryDates => {
        const reformattedDates = newDeliveryDates.map(date =>
          date.substr(0, 10),
        );
        updateDeliveryDates(prevDeliveryDates => [
          ...prevDeliveryDates,
          ...reformattedDates,
        ]);
      })
      .catch(error => {
        console.error('Error fetching delivery dates:', error);
      });
  }, [order_, dateValue]);

  ///Has active order
  useEffect(() => {
    const formattedDate = moment(dateValue?.$d).format('YYYY/MM/DD');

    if (selectedIdCustomer && dateValue) {
      const updatedOrderStatusArray: { branchId: string; isActive: boolean }[] =
        [];

      decksId.forEach(id => {
        triggerActiveOrder(
          `?&beginAt=${formattedDate}&endAt=${formattedDate}&status=${[
            'pending',
            'pending-updates',
            'pending-customer',
            'validated',
            'confirmed',
            'prepared',
          ]}&customerDeck=${id}`,
        )
          .then(res => {
            updatedOrderStatusArray.push({
              branchId: id,
              isActive: res.data.docs.length > 0,
            });

            setOrderStatusArray(updatedOrderStatusArray);
          })
          .catch(error => {
            console.error('Error fetching orders:', error);
          });
      });
    }
  }, [dateValue, selectedIdCustomer]);

  // get selected date formatted like: YYYY MM DD
  formattedDate = `${dateValue?.$d?.getFullYear()} ${String(
    dateValue?.$d?.getMonth() + 1,
  ).padStart(2, '0')} ${String(dateValue?.$d?.getDate()).padStart(2, '0')}`;

  function handleDeleteBranch(e, value) {
    e.preventDefault();
    setSelectedBranchArray(current => _without(current, value));
  }

  const customersFilterOptions = (options, state) => {
    const inputValueLowerCase = state?.inputValue?.toLowerCase();
    return options?.filter(
      option =>
        option?.legalName?.toLowerCase()?.includes(inputValueLowerCase) ||
        option?.code?.toLowerCase()?.includes(inputValueLowerCase),
    );
  };

  const branchesFilterOptions = (options, state) => {
    const inputValueLowerCase = state?.inputValue?.toLowerCase();
    return options?.filter(
      option =>
        option?.location.shortAddress
          ?.toLowerCase()
          ?.includes(inputValueLowerCase) ||
        option?.location.name?.toLowerCase()?.includes(inputValueLowerCase) ||
        option?.code?.toLowerCase()?.includes(inputValueLowerCase),
    );
  };

  return (
    <Wrapper>
      {openAlert ? (
        <Snackbar
          open={openAlert}
          onClose={() => setOpenAlert(false)}
          autoHideDuration={9000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            boxShadow:
              '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)',
          }}
        >
          <Alert
            severity="warning"
            sx={{ width: '506px' }}
            onClose={() => setOpenAlert(false)}
          >
            <AlertTitle>{t('order.typicalDate.title')}</AlertTitle>
            {t('order.typicalDate.subtitle')}
          </Alert>
        </Snackbar>
      ) : null}

      <Grid
        container
        alignItems="center"
        sx={{
          padding: '12px 16px',
          borderBottom: '1px solid rgba(1, 1, 1, 0.12)',
        }}
      >
        <IconButton
          onClick={closeDrawer}
          sx={{
            mr: '8px',
          }}
        >
          <ArrowBackIcon color="action" />
        </IconButton>
        <Typography fontSize="1.429rem" fontWeight="500">
          {t('orders.create_orders')}
        </Typography>
        <IconButton
          onClick={closeDrawer}
          sx={{
            ml: 'auto',
          }}
        >
          <ClearIcon color="action" />
        </IconButton>
      </Grid>

      <ContentWrapper>
        <div className="clientAndBranch">
          <Typography fontWeight="400" fontSize="1.143rem">
            {t('orders.title.clientBranch')}
          </Typography>
          <Grid
            container
            sx={{
              mt: '16px',
              justifyContent: 'space-between',
            }}
          >
            <FormControl sx={{ width: '48%' }}>
              <Autocomplete
                options={
                  !dataCustomers?.docs
                    ? [{ legalName: 'Loading...', id: 0 }]
                    : dataCustomers?.docs
                }
                value={data?.legalName ? data : null}
                onChange={(e, v: { _id: string; legalName: string } | null) => {
                  setSelectedIdCustomer(v?._id);
                }}
                getOptionLabel={option => option.legalName}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={e => {
                      setCustomersSearch(e?.target?.value);
                    }}
                    label={t('orders.title.client')}
                  />
                )}
                filterOptions={customersFilterOptions}
                renderOption={(props, option) => (
                  <MenuItem component="li" sx={{}} {...props} key={option?._id}>
                    {option?.legalName}
                  </MenuItem>
                )}
                noOptionsText={
                  <>
                    {isLoadingCustomers || isFetchingCustomers ? (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <CircularProgress size={20} color="primary" />
                      </div>
                    ) : (
                      t('no_options')
                    )}
                  </>
                }
              ></Autocomplete>
            </FormControl>
            <FormControl size="small" sx={{ width: '48%' }}>
              <Autocomplete
                options={decks || []}
                multiple={true}
                value={selectedBranchArray}
                onChange={(event, value) => {
                  setSelectedBranchArray(value);
                  setOrderDeck(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    onChange={e => {
                      setBranchesSearch(e?.target?.value);
                    }}
                    label={t('orders.branch')}
                    size="small"
                  />
                )}
                filterOptions={branchesFilterOptions}
                getOptionLabel={option =>
                  option?.location.shortAddress || option?.location.name
                }
                renderOption={(props, option) => (
                  <MenuItem
                    // key={option._id}
                    value={option}
                    disabled={orderStatusArray.some(
                      status =>
                        status.branchId === option._id && status.isActive,
                    )}
                    {...props}
                  >
                    <Typography
                      fontSize="1rem"
                      fontWeight="400"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {option?.location.shortAddress || option?.location.name}
                    </Typography>
                    {selectedBranchId.map(branchId =>
                      branchId === option._id &&
                      !orderStatusArray.some(
                        status =>
                          status.branchId === option._id && status.isActive,
                      ) ? (
                        <IconButton
                          key={branchId}
                          sx={{
                            p: 0,
                            ml: 'auto',
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : null,
                    )}
                    {orderStatusArray.some(
                      status =>
                        status.branchId === option._id && status.isActive,
                    ) && (
                      <Typography
                        fontSize="0.75rem"
                        fontWeight={400}
                        sx={{
                          color: '#E65100',
                          fontStyle: 'italic',
                          ml: 'auto',
                        }}
                      >
                        Has an active order
                      </Typography>
                    )}
                  </MenuItem>
                )}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  backgroundColor: '#F5F6F7',
                  '.MuiFormControl-root': {
                    margin: '0 !important',
                  },
                }}
              ></Autocomplete>
            </FormControl>
          </Grid>
        </div>

        <div className="datebranch">
          <Typography fontWeight="400" fontSize="1.143rem">
            {t('common.date')}
          </Typography>
          <Grid
            container
            sx={{
              mt: '16px',
            }}
          >
            <LocalizationProvider
              sx={{ marginBottom: '5px !importnat' }}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                label={t('clients.orderDetails.deliveryDate')}
                inputFormat="MMMM DD, YYYY"
                minDate={moment().format('YYYY/MM/DD')}
                components={{
                  OpenPickerIcon: CalendarTodayIcon,
                }}
                value={dateValue}
                open={datePickerOpen}
                onClose={() => setDatePickerOpen(false)}
                onOpen={() => setDatePickerOpen(true)}
                onChange={handleDateValueChange}
                InputProps={{
                  endAdornment: <CalendarTodayIcon color="action" />,
                }}
                renderInput={params => (
                  <TextField
                    label={t('clients.orderDetails.deliveryDate')}
                    onClick={() => setDatePickerOpen(true)}
                    focused={datePickerOpen}
                    size="small"
                    sx={{
                      width: '48%',
                      mr: 'auto',
                      backgroundColor: '#F5F6F7',
                    }}
                    {...params}
                  />
                )}
                renderDay={(date, _, DayComponentProps) => {
                  let date_: any;
                  const selectedDate = dateValue?.$d?.getTime();
                  date_ = date;
                  const formattedDate_ = `${date_?.$y}-${(date_?.$M + 1)
                    .toString()
                    .padStart(2, '0')}-${date_?.$D
                    .toString()
                    .padStart(2, '0')}`;
                  date_ = date_?.$d?.getTime();
                  const datesAreMatching = date_ == selectedDate ? true : false;
                  const isDateInDeliveryDates =
                    deliveryDates.includes(formattedDate_);

                  return (
                    <CalendarDayContainer>
                      <PickersDay
                        {...DayComponentProps}
                        outsideCurrentMonth={false}
                        selected={datesAreMatching}
                      />
                      {isDateInDeliveryDates ? (
                        <CalenderDayMark
                          color={datesAreMatching ? 'white' : ''}
                        />
                      ) : (
                        ''
                      )}
                    </CalendarDayContainer>
                  );
                }}
              />
            </LocalizationProvider>
            {isNotTypicalDates.map(
              typical =>
                typical === true &&
                !orderOnSameDate && (
                  <Typography
                    fontSize="0.75rem"
                    fontWeight={400}
                    sx={{
                      color: '#E65100',
                    }}
                  >
                    {t('orders.create_orders.not_typical_date')}
                  </Typography>
                ),
            )}
            {orderOnSameDate && (
              <Typography
                fontSize="0.75rem"
                fontWeight={400}
                sx={{
                  color: '#d32f2f',
                }}
              >
                {t('orders.create_orders.same_date_orders')}
              </Typography>
            )}
          </Grid>
        </div>

        <div className="orderType">
          <div
            className="orderType"
            style={{
              marginTop: '30px',
            }}
          >
            <Typography fontWeight="400" fontSize="1.143rem">
              {t('clients.orderDetails.orderType')}
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue="delivery"
                onChange={handleChange}
              >
                <Grid container sx={{ mt: '15px', mb: '10px' }}>
                  <FormControlLabel
                    value="delivery"
                    control={<Radio />}
                    label={t('common.delivery').toUpperCase()}
                  />
                  <FormControlLabel
                    value="collection"
                    control={<Radio />}
                    label={t('roundtrips.tooltip.collection').toUpperCase()}
                    sx={{ ml: '8px' }}
                  />
                  <FormControlLabel
                    value="express"
                    control={<Radio />}
                    label={t('common.express').toUpperCase()}
                    sx={{ ml: '8px' }}
                  />
                  <FormControlLabel
                    value="repair"
                    control={<Radio />}
                    label={t('repair').toUpperCase()}
                    sx={{ ml: '8px' }}
                  />
                </Grid>
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        <div className="template">
          <Grid container alignItems="center">
            <Typography
              fontSize="1.143rem"
              fontWeight="400"
              sx={{
                mb: 2,
              }}
            >
              {t('orders.title.chooseTemplate')}
            </Typography>
            <TextField
              size="small"
              label={t('orders.search_templates')}
              value={searchText}
              onChange={handleSearchTextChange}
              sx={{
                width: '276px',
                ml: 'auto',
                backgroundColor: '#F5F6F7',
              }}
              InputProps={{
                startAdornment: <SearchIcon color="action" />,
              }}
            />
          </Grid>
          <Grid
            container
            sx={{
              overflow: 'auto',
              maxHeight: '320px',
            }}
          >
            <Card
              sx={{
                width: '170px',
                height: '208px',
                mr: '13px',
                border:
                  selectedTemplate === null
                    ? '1px solid #B682FF'
                    : '1px solid transparent',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => handleTemplateClick(null, [])}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#F5EEFF',
                  height: '77px',
                  margin: '0',
                  padding: '0',
                }}
              >
                <AddIcon
                  sx={{
                    height: '46px',
                    width: '46px',
                    color: '#6600F4',
                  }}
                />
              </CardContent>
              <CardContent>
                <Typography fontSize="1rem" fontWeight="400">
                  {t('orders.blank')}
                </Typography>
                <Typography
                  fontSize="0.857rem"
                  fontWeight="400"
                  sx={{
                    color: 'grey',
                    mt: '1px',
                  }}
                >
                  {t('orders.subtitle.create_order_groundUp')}
                </Typography>
              </CardContent>
            </Card>

            {filteredTemplates?.map(template => (
              <div key={template?._id}>
                <TemplatesCards
                  name={template?.name}
                  description={template?.description}
                  weekDays={template?.weekDays}
                  productsNumber={template?.products.length}
                  isSelected={selectedTemplate === template?._id}
                  select={() =>
                    handleTemplateClick(template?._id, template?.products)
                  }
                  createdBy={template?._createdBy?.fullName}
                  type="orders"
                />
              </div>
            ))}
          </Grid>
        </div>
      </ContentWrapper>
      <Grid
        sx={{
          height: '62px',
          width: '100%',
          position: 'absolute',
          bottom: '0',
          borderTop: 'solid 1px rgba(1, 1, 1, 0.12)',
          background: 'white',
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '16px',
            float: 'right',
          }}
        >
          <Button sx={{ color: 'rgba(0, 0, 0, 0.6)' }} onClick={closeDrawer}>
            {t('common.buttons.cancel')}
          </Button>
          {orderOnSameDate ? (
            <Button
              color="primary"
              variant="contained"
              disabled
              sx={{
                borderRadius: '42px',
                ml: '12px',
              }}
            >
              {t('common.buttons.proceed')}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              disabled={createOrderBtnDisabled}
              onClick={handleCreateOrder}
              sx={{
                borderRadius: '42px',
                ml: '12px',
              }}
            >
              {t('common.buttons.proceed')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Wrapper>
  );
}

function useGetOrders(queryParams) {
  const { data, isFetching, isLoading, refetch } = useGetOrdersQuery(
    `${queryParams}&page=${1}&limit=${360}`,
  );
  return data;
}

const Wrapper = styled.div`
  overflow: hidden !important;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  margin: 24px 20px;
`;

const CalendarDayContainer = styled.div`
  display: flex;
  position: relative;
`;

const CalenderDayMark = styled.span`
  height: 4px;
  width: 4px;
  border-radius: 3px;
  z-index: 100;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${props =>
    props.color ? props.color : props.theme.primary};
`;
