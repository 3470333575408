import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'common/store/app';
import { BampteeApplications } from 'common/store/app';
import { selectCurrentApplication } from 'common/store/app/selectors';
import { Application } from 'common/store/app/types';
import { themeActions } from '../styles/theme/slice';
import { selectAuthUser } from 'app/slices/auth/selectors';
import {
  ACCESSORIES,
  CUSTOMER,
  DASHBOARD,
  ORDERS,
  PAYMENTCARDS,
  REPORTS,
  ROLES_PERMISSIONS,
  ROUNDTRIP,
  SCHEDULED_ROUNDTRIPS,
  SETTINGS,
  SETTINGS_COMPANY_PROFILE,
  SETTINGS_CUSTOMIZATION,
  SETTINGS_LANGUAGE,
  SETTINGS_USERS,
  SUPPORTUNIT,
  TOOLS,
  USERS,
  VEHICLES,
  VEHICLES_LOGISTIC,
} from 'utils/routes';
import { useDefineAbilitiesFor } from 'utils/ability';

export const useApplication = (user?: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentApplication: Application = useSelector(selectCurrentApplication);
  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);
  const isAdmin = authUser?.isSuperAdmin;

  const ability = useDefineAbilitiesFor(authUser, currentApplication);

  const biantaModules = useMemo(() => {
    return [
      {
        name: 'dashboard',
        url: DASHBOARD,
      },
      {
        name: 'roundtrips',
        url: `${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`,
      },
      {
        name: 'orders',
        url: ORDERS,
      },
      {
        name: 'customers',
        url: CUSTOMER,
      },
      {
        name: 'reports',
        url: REPORTS,
      },
      {
        name: 'users',
        url: USERS,
      },
      {
        name: 'vehicles',
        url: VEHICLES_LOGISTIC,
      },
      {
        name: 'accessories',
        url: ACCESSORIES,
      },
      {
        name: 'tools',
        url: TOOLS,
      },
      {
        name: 'paymentcards',
        url: PAYMENTCARDS,
      },
      {
        name: 'supportunits',
        url: SUPPORTUNIT,
      },
    ];
  }, []);

  const idmModules = useMemo(() => {
    return [
      {
        name: 'companysettings',
        url: SETTINGS_COMPANY_PROFILE,
      },
      {
        name: 'roles',
        url: ROLES_PERMISSIONS,
      },
      {
        name: 'users',
        url: SETTINGS_USERS,
      },
      {
        name: 'language',
        url: SETTINGS_LANGUAGE,
      },
      {
        name: 'customization',
        url: SETTINGS_CUSTOMIZATION,
      },
    ];
  }, []);

  const isBianta = currentApplication?.id === 'bianta';
  const isIdm = location?.pathname?.includes(SETTINGS);

  useEffect(() => {
    // execute on location change
    const findApp = BampteeApplications.find(
      (app: any) => location.pathname.indexOf(app.url) > -1,
    );

    if (findApp) {
      dispatch(appActions.setCurrentApplication({ application: findApp }));
      // @ts-ignore
      dispatch(themeActions.changeTheme(findApp.id));
    }

    //redirect to first available module
    if (!isAdmin) {
      const appModules = !!isIdm ? idmModules : biantaModules;
      const currentModule = appModules.find(
        module => module.url === location.pathname,
      );
      if (currentModule && !ability.can('canview', currentModule.name)) {
        const defaultModule = appModules.find(module => {
          return ability.can('canview', module.name);
        });

        if (defaultModule?.url) {
          navigate(defaultModule.url);
        }
      }
    }
  }, [
    location.pathname,
    dispatch,
    isBianta,
    isAdmin,
    isIdm,
    currentApplication?.url,
    biantaModules,
    idmModules,
    navigate,
    authUser?.currentAgent,
  ]);

  return currentApplication;
};
