import { Stack } from '@mui/material';
import { Card } from 'app/components/KpiCard';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

interface IStatsCard {
  handleFilter: (status: string) => void;
  facets: any[];
  status: string;
}

enum Status {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  ON_WATCH = 'on-watch',
  IN_REVIEW = 'in-review',
}

export function StatsCards({ facets, status, handleFilter }: IStatsCard) {
  const { t } = useTranslation();

  const STATUSES = {
    [Status.ACTIVE]: {
      thumbColor: themes.default?.progressGreen,
      backgroundColor: themes.default?.chipGreenFont,
      railColor: themes.default?.green100,
      tooltip: t('customer.insight_text.active'),
    },
    [Status.IN_REVIEW]: {
      thumbColor: themes.default?.grey2,
      backgroundColor: `${themes.default?.gainsboro2}40`,
      railColor: themes.default?.gainsboro2,
      tooltip: t('customer.insight_text.in_review'),
    },
    [Status.ON_WATCH]: {
      thumbColor: themes.default?.progressOrange,
      backgroundColor: themes.default?.orange50,
      railColor: themes.default?.orange100,
      tooltip: t('customer.insight_text.on_watch'),
    },
    [Status.BLOCKED]: {
      thumbColor: themes.default?.progressRed,
      backgroundColor: themes.default?.chipRedFont,
      railColor: themes.default?.redRail,
      tooltip: t('customer.insight_text.blocked'),
    },
  };

  const total = useMemo(() => {
    return facets?.[0]?.count?.[0]?.count;
  }, [facets]);

  const getValues = (status: string) => {
    const statusTotal =
      facets?.[0]?.status?.find(s => s._id === status)?.count || 0;
    return {
      total: statusTotal,
      percentage: +((statusTotal / (total || 1)) * 100)?.toFixed(2),
    };
  };

  return (
    <Stack
      direction="row"
      gap="10px"
      paddingX={'14px'}
      position="relative"
      zIndex="2"
    >
      <Card
        displayType={'numbers'}
        cardTitle={t('common.all')}
        grandTotal={total || 0}
        onClick={() => {
          handleFilter('');
        }}
        backgroundColor={themes.default?.indigo50}
        thumbColor={themes.default?.primaryActiveColor}
        railColor={themes.default?.indigo100}
        isActive={!status}
        tooltip={t('common.all')}
      />
      {Object.entries(STATUSES).map(([c, values]) => (
        <Card
          displayType={'chart'}
          cardTitle={t(`customer.status.${c}`)}
          grandTotal={getValues(c)?.total}
          percentage={getValues(c)?.percentage}
          thumbColor={values.thumbColor}
          backgroundColor={values.backgroundColor}
          railColor={values.railColor}
          onClick={() => {
            handleFilter(c);
          }}
          isActive={status === c}
          tooltip={values.tooltip}
        />
      ))}
    </Stack>
  );
}

export default StatsCards;
