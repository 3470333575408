import {
  Button,
  Drawer,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import { usePatchWarehouseMutation } from 'common/services/organizationApi';
import { useForm } from 'react-hook-form';
import { FieldComponent } from 'app/components/FieldComponent';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

import { Location } from '../../../../../../../utils/googlePlace';
import { LocationInput } from '../../../../../../components/LocationInput';
import { themes } from 'styles/theme/themes';

interface OperatingHours {
  openingTime?: string;
  closingTime?: string;
}

interface Warehouse {
  _id?: string;
  name?: string;
  location?: Location;
  capacity?: string;
  manager?: string;
  phone?: string;
  operatingHours?: OperatingHours;
  status?: string;
}

interface WarehouseDrawerProps {
  openModal: boolean;
  closeModal: () => void;
  warehouse?: Warehouse;
}

export function WarehouseDrawer({
  openModal,
  closeModal,
  warehouse,
}: WarehouseDrawerProps) {
  const { dayjs } = window;
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(true);
  const [patchWarehouse] = usePatchWarehouseMutation();
  const [isSwitchOn, setSwitchOn] = React.useState(
    warehouse?.status === 'active' ? true : false,
  );
  const [location, setLocation] = useState<Location | undefined>(
    warehouse?.location,
  );
  const [openingTime, setOpeningTime] = React.useState<Dayjs | null>(
    warehouse?.operatingHours?.openingTime
      ? dayjs(warehouse.operatingHours.openingTime)
      : dayjs().startOf('day'),
  );
  const [closingTime, setClosingTime] = React.useState<Dayjs | null>(
    warehouse?.operatingHours?.closingTime
      ? dayjs(warehouse.operatingHours.closingTime)
      : dayjs().startOf('day'),
  );
  const [capacityNumber, setCapacityNumber] = useState<number>();

  const methods = useForm({
    defaultValues: {
      name: warehouse?.name,
      capacity: warehouse?.capacity,
      manager: warehouse?.manager,
      phone: warehouse?.phone,
    },
  });

  const { control, watch } = methods;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSwitchChange = () => {
    setSwitchOn(prevState => !prevState);
  };

  const name = watch('name');
  const capacity = watch('capacity');
  const manager = watch('manager');
  const contact = watch('phone');

  async function handlePatchWarehouse() {
    const newState = isSwitchOn ? 'active' : 'inactive';
    const formatClosingTime = closingTime?.format('hh:mm');
    const formatOpeningTime = openingTime?.format('hh:mm');

    try {
      await patchWarehouse({
        deckId: warehouse?._id,
        name: name,
        location,
        capacity: capacity,
        manager: manager,
        phone: contact,
        status: newState,
        openingHours: [
          {
            openingTime: formatOpeningTime,
            closingTime: formatClosingTime,
          },
        ],
      });
      closeModal();
    } catch (e) {
      console.log('error on update warehouse' + e);
    }
  }

  return (
    <Drawer
      anchor="right"
      open={openModal}
      onClose={closeModal}
      PaperProps={{
        sx: { width: '473px' },
      }}
    >
      <Typography
        fontSize="1rem"
        fontWeight="400"
        sx={{ padding: '16px 16px 16px 20px' }}
      >
        {warehouse ? 'Modifier un entrepôt' : 'Ajoutez un entrepôt'}
      </Typography>
      <TextFieldWrapper>
        <FieldComponent
          label="Warehouse Name"
          size="small"
          name="name"
          control={control}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
          defaultValue={warehouse ? warehouse.name : ''}
        />

        <LocationInput
          label={t('common.address')}
          defaultValue={warehouse?.location}
          size="small"
          onSelect={loc => {
            setLocation(loc);
          }}
        />

        <FieldComponent
          label="Capacity"
          size="small"
          id="filled-number"
          type="number"
          name="capacity"
          control={control}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
          InputProps={{
            endAdornment: (
              <Typography
                component="span"
                variant="body2"
                sx={{
                  color: 'rgba(0, 0, 0, 0.78)',
                }}
              >
                m<sup>3</sup>
              </Typography>
            ),
          }}
          defaultValue={warehouse ? warehouse.capacity : ''}
        />
        <FieldComponent
          label="Manager"
          size="small"
          name="manager"
          control={control}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
          defaultValue={warehouse ? warehouse.manager : ''}
        />
        <FieldComponent
          label="Contact"
          size="small"
          name="contact"
          control={control}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            width: '100%',
          }}
          defaultValue={warehouse ? warehouse.phone : ''}
        />
      </TextFieldWrapper>
      <div
        className="TextFieldLine"
        style={{
          display: 'flex',
          margin: '0px 20px 0px 20px',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Opening Time"
            value={openingTime}
            onChange={newValue => setOpeningTime(newValue)}
            renderInput={props => (
              <TextField
                {...props}
                sx={{
                  width: '50%',
                  mr: '19px',
                  height: '37px',
                }}
                defaultValue={openingTime}
                size="small"
              />
            )}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            label="Closing Time"
            value={closingTime}
            onChange={newValue => setClosingTime(newValue)}
            inputFormat="HH:mm"
            renderInput={props => (
              <TextField
                {...props}
                sx={{
                  width: '50%',
                  height: '37px',
                }}
                defaultValue={closingTime}
                size="small"
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <FormGroup
        sx={{
          ml: '24px',
        }}
      >
        {warehouse ? (
          <Typography component="div">
            <Switch checked={isSwitchOn} onChange={handleSwitchChange} />
            {isSwitchOn ? 'Active' : 'Non active'}
          </Typography>
        ) : (
          <FormControlLabel
            control={<Switch checked={false} />}
            label="Non active"
          />
        )}
      </FormGroup>
      <FooterWrapper>
        <ButtonWrapper>
          <Button variant="outlined" sx={{ mr: '12px' }} onClick={closeModal}>
            {t('common.buttons.cancel')}
          </Button>
          {warehouse ? (
            <Button
              variant="contained"
              sx={{ mr: '20px' }}
              onClick={handlePatchWarehouse}
            >
              {t('common.buttons.save')}
            </Button>
          ) : (
            <Button variant="contained" sx={{ mr: '20px' }}>
              {t('common.add')}
            </Button>
          )}
        </ButtonWrapper>
      </FooterWrapper>
    </Drawer>
  );
}

const TextFieldWrapper = styled.div`
  margin: 40px 20px 0px 20px;
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow:
    0px 5px 22px 4px ${themes?.default?.lightFill},
    0px 12px 17px 2px rgba(0, 0, 0, 0.14),
    0px 7px 8px -4px rgba(0, 0, 0, 0.2);
  height: 84px;
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;
