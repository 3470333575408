import { useGetCustomerQuery } from 'common/services/customerApi';
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import { LoadingPulse } from 'app/components/LoadingPulse';

interface CustomerDetailsProps {
  customerId: string;
  onCloseDrawer: () => void;
}

export function CustomerDetails({
  customerId,
  onCloseDrawer,
}: CustomerDetailsProps) {
  const { data: customer, isFetching } = useGetCustomerQuery(customerId);

  if (isFetching) {
    return (
      <LoadingWrapper>
        <LoadingPulse />
      </LoadingWrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <HeaderWrapper>
          <ArrowBackIcon
            color="action"
            fontSize="small"
            onClick={onCloseDrawer}
            sx={{ cursor: 'pointer', paddingRight: '10px', width: '30px' }}
          />
          <Typography fontSize="1rem" fontWeight="500">
            {customer?.fullName}
          </Typography>
        </HeaderWrapper>
      </Wrapper>
    </>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
`;

interface WrapperProps {
  currentTabIndex?: number;
}

export const Wrapper = styled.div<WrapperProps>`
  padding: 20px 28px 92px 28px;
  max-height: 100vh;
  overflow: hidden;
  width: ${props => (props.currentTabIndex === 0 ? '1350px' : '850px')};
  min-width: 40vw;
  max-width: 90vw;
  transition: width 0.5s;

  @media (max-width: 1000px) {
    padding: 20px 20px 32px 20px;
  }
  @media (max-width: 700px) {
    overflow: auto;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
