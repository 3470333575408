import { ValidationResponse } from '../types/User';
import api from './api';
import { UserData } from 'common/types/User';
import { getUsersDto } from '../Dtos/mobile/getUsersDto';

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query<UserData, string>({
      query: pagerows => `users?${pagerows}`,
      providesTags: ['Users'],
      // providesTags: (result) =>[{ type: 'Users' }],
      transformResponse: (response: any, meta, arg) => {
        const urlParams = new URLSearchParams(arg);
        const dto = urlParams.get('dto');
        if (dto === 'mobile') return getUsersDto(response);
        return response;
      },
    }),
    getUsersList: builder.query<any, string>({
      query: pagerows => `/users/chat/list?${pagerows}`,
    }),
    getUsersFacets: builder.query<UserData, string>({
      query: pagerows => `users/facets${pagerows}`,
      providesTags: ['Users'],
    }),
    getUser: builder.query<any, string>({
      query: id => `users/${id}`,
      providesTags: ['Users'],
      // providesTags: (result) =>[{ type: 'Users' }],
    }),
    deleteUser: builder.mutation<any, string>({
      query: id => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    checkEmail: builder.query<any, string>({
      query: pagerows => `/organization/check-email?${pagerows}`,
      extraOptions: {
        maxRetries: 0,
      },
      providesTags: ['check-email'],
    }),
    inviteUser: builder.mutation<void, any>({
      query: body => ({
        url: `/users/invite`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users', 'check-email'],
    }),
    addUsers: builder.mutation<any, any>({
      query: data => ({
        url: `/users/invite`,
        method: 'POST',
        body: {
          ...data,
          applications: ['bianta'],
        },
      }),
      invalidatesTags: ['AddUsers', 'Users', 'check-email'],
    }),
    bulkReactivate: builder.mutation<
      void,
      {
        userIds: string[];
        isLocked: boolean;
        action: string;
      }
    >({
      query: data => ({
        url: `/users/bulk`,
        method: 'PATCH',
        body: {
          userIds: data.userIds,
          isLocked: data.isLocked,
        },
      }),
      invalidatesTags: ['Users', 'check-email'],
    }),
    updateUser: builder.mutation<void, any>({
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Users', 'check-email'],
    }),
    cancelInvitation: builder.mutation<void, any>({
      query: id => ({
        url: `/users/${id}/cancel-invitation`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Users', 'check-email'],
    }),
    updateUserStatus: builder.mutation<void, any>({
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData(
            'getUsers',
            'filters=&page=1&limit=10&text=',
            draft => {
              Object.assign(
                draft.docs.find(item => item.id === id),
                patch,
              );
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          dispatch(api.util.invalidateTags(['Post', 'Users']));
        }
      },
      invalidatesTags: ['Users', 'check-email'],
    }),
    addUser: builder.mutation<any, any>({
      query: body => ({
        url: `/users/invite`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Users', 'check-email'],
    }),
    getOptimizeList: builder.query<any, void>({
      query: () => `/reoptimize`,
      providesTags: ['Roundtrips'],
    }),
    deleteUsersBulk: builder.mutation<any, { userIds: string[] }>({
      query: body => ({
        url: '/users/bulk',
        method: 'PATCH',
        body: {
          ...body,
          deleted: true,
        },
      }),
      invalidatesTags: ['UsersDeleteBulk', 'Users', 'check-email'],
    }),
    inviteUsersBulk: builder.mutation<
      {
        message: string;
        messageType: string;
        data: {
          count: number;
          totalUsers: number;
        };
      },
      {
        users: {
          email: string;
          firstName: string;
          lastName: string;
          role: string;
          applications: string[];
        }[];
      }
    >({
      query: body => ({
        url: '/users/bulk',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UsersInivteBulk', 'Users', 'check-email'],
    }),
    importValidate: builder.mutation<ValidationResponse, FormData>({
      query: body => ({
        url: '/users/validate-import',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UsersValidateImport', 'Users', 'check-email'],
    }),
    userImport: builder.mutation<unknown, FormData>({
      query: body => ({
        url: '/users/import',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['UsersImport', 'Users', 'check-email'],
    }),
    getUsersBasic: builder.query<UserData, string>({
      query: queryParams => `users/basic/list${queryParams}`,
      providesTags: ['Users', 'check-email'],
    }),
    getUsersWeb: builder.query<UserData, string>({
      query: queryParams => `users${queryParams}`,
      providesTags: ['Users', 'check-email'],
    }),
    changeUserStatus: builder.mutation<unknown, any>({
      query: body => ({
        url: `/users/change-status`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['UsersImport', 'Users'],
    }),
    updateDocuments: builder.mutation<unknown, any>({
      query: ({ id, ...body }) => ({
        url: `/users/documents/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['UsersImport', 'Users'],
    }),
    updateDocumentIds: builder.mutation<unknown, any>({
      query: ({ id, ...body }) => ({
        url: `/users/ids/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['UsersImport', 'Users'],
    }),
    checkExistingEmail: builder.query<any, string>({
      query: queryParams => `users/check-email?${queryParams}`,
      providesTags: ['Users', 'check-email'],
    }),
    deleteDocument: builder.mutation<unknown, any>({
      query: ({ id, field, ...body }) => ({
        url: `/users/${id}/files/${field}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['UsersImport', 'Users'],
    }),
    validateSSN: builder.mutation<any, any>({
      query: body => ({
        url: '/users/validate-ssn',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useBulkReactivateMutation,
  useLazyCheckEmailQuery,
  useInviteUserMutation,
  useGetUsersQuery,
  useGetUsersListQuery,
  useUpdateUserStatusMutation,
  useLazyGetUsersQuery,
  useDeleteUserMutation,
  useAddUserMutation,
  useUpdateUserMutation,
  useGetOptimizeListQuery,
  useAddUsersMutation,
  useDeleteUsersBulkMutation,
  useInviteUsersBulkMutation,
  useImportValidateMutation,
  useUserImportMutation,
  useGetUserQuery,
  useGetUsersWebQuery,
  useLazyGetUsersWebQuery,
  useCancelInvitationMutation,
  useChangeUserStatusMutation,
  useUpdateDocumentsMutation,
  useUpdateDocumentIdsMutation,
  useLazyCheckExistingEmailQuery,
  useDeleteDocumentMutation,
  useGetUsersFacetsQuery,
  useGetUsersBasicQuery,
  useLazyGetUsersBasicQuery,
  useValidateSSNMutation,
} = userApi;

export default userApi;
