import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectAuthUser,
  selectMainMenuSize,
} from '../../slices/auth/selectors';
import { selectTheme } from '../../../styles/theme/slice/selectors';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';

import SwitchAgentButtonIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import { Avatar, Divider, Select, Tooltip } from '@mui/material';
import styled from 'styled-components/macro';
import { Customization } from '../MainNav/Customizations';
import i18next from 'i18next';
import { LOGOUT, ORDORIA, SETTINGS } from 'utils/routes';
import LogoutIcon from '@mui/icons-material/Logout';
import { handlesetAnchorFunction } from 'functions/setAnchorFunction';
import { themes } from 'styles/theme/themes';
import { useApplication } from 'hooks/useApplication';
import { useModal } from '../Dialog/hooks';
import { stringAvatar } from 'app/helpers/helpers';
import If from '../If';
import { useSwitchAgentMutation } from 'common/services/authApi';
import { getId } from 'common/helpers/document';
import { UserStatusDot } from './components/UserStatusDot';
import { AskAIButton } from './components/AskAIButton';
import { Icon } from 'app/components/Icon';
import { Chat } from 'app/components/Chat';
import {
  CaretDown,
  ShoppingCartSimple,
  VideoCamera,
  ChatTeardropDots,
  Bell,
  MagnifyingGlass,
  UserCircle,
  Gear,
  Info,
  SignOut,
} from '@phosphor-icons/react';
import { useDispatch } from 'react-redux';
import { toolsActions } from 'app/slices/tools';
import { MotivationalQuote } from '../MotivationalQuote';
import { Notifications } from '../NotificationModal';
import { useGetActivitiesQuery } from 'common/services/activityApi';

const LeftActionButtonSx = {
  height: '30px',
  width: '30px',
  padding: '0',
  borderRadius: '4px',
  border: `1px solid ${themes?.default?.gainsboro2}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
};

const LeftActionsIconProps = {
  size: 20,
  color: themes?.default?.iconColor,
};

interface LinkTabProps {
  label?: string;
  href?: string;
}

export function AppHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useSelector(selectTheme);
  let authUser = useSelector(selectAuthUser);
  const mainMenuSize = useSelector(selectMainMenuSize);
  const currentApplication = useApplication(authUser);
  const [search, setSearch] = useState('');
  const [chatOpen, setChatOpen] = useState(false);
  const [notifOpen, setNotifOpen] = useState(false);

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElCustomer, setAnchorElCustomer] =
    React.useState<null | HTMLElement>(null);
  const [settingsDrawerIsOpen, setSettingsDrawerIsOpen] = useState(false);
  const { openModal, closeModal } = useModal();

  const [openMotivationQuote, setOpenMotivationQuote] =
    useState<boolean>(false);

  const [switchAgent] = useSwitchAgentMutation();

  const open = Boolean(anchorEl);
  const openCustomer = Boolean(anchorElCustomer);

  const isCustomer = !isEmpty(authUser?.currentAgent?._customer);

  const handleOpenCustomerMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElCustomer(event.currentTarget);
  };

  const handleSwitchAgent = async agentId => {
    setAnchorElCustomer(null);
    await switchAgent({ agentId }).unwrap();
  };

  const [limit, setLimit] = useState(50);

  const {
    data: activities,
    isLoading: isLoading,
    refetch,
  } = useGetActivitiesQuery(`limit=${limit}`);

  const notificationsCount = React.useMemo(() => {
    return activities?.totalUnread || 0;
  }, [activities?.totalUnread]);

  if (!authUser) {
    return null;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasSeenModal = localStorage.getItem('hasSeenModal');

  if (!hasSeenModal) {
    setOpenMotivationQuote(true);
    localStorage.setItem('hasSeenModal', 'true');
  }

  const customerAgents =
    authUser.agents?.filter(
      (a: any) =>
        getId(a._organization) === getId(authUser?._currentOrganization) &&
        a?._customer,
    ) || [];

  const handleLogout = () => {
    closeModal();
    handleClose();
    dispatch(toolsActions.setZoomIn({ status: false }));
    navigate(LOGOUT);
  };

  const inSettings = window.location.pathname?.includes(SETTINGS);

  return (
    <>
      <Drawer
        anchor="right"
        open={settingsDrawerIsOpen}
        onClose={() => setSettingsDrawerIsOpen(false)}
      >
        <Customization setSettingsDrawerIsOpen={setSettingsDrawerIsOpen} />
      </Drawer>
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          transition: '0.15s padding ease-out',
          height: theme.topBarHeight,
          minHeight: theme.topBarHeight,
          paddingLeft:
            mainMenuSize === 'minify'
              ? theme.mainNavWidthMin
              : theme.mainNavWidth,
          background: themes.default.accordionWhiteBg,
          borderBottom: `1px solid ${themes?.default?.lightFill}`,
          /* grey-shadow-web */
          boxShadow: 'none !important',
          zIndex: '2201',
          width: !!inSettings
            ? `calc(100% - ${themes.default.settingsNavWidth} - 2px)`
            : '100%',
        }}
      >
        <Toolbar
          style={{
            paddingRight: 0,
            paddingLeft: mainMenuSize === 'minify' ? '25px' : '285px',
            height: theme.topBarHeight,
            minHeight: theme.topBarHeight,
          }}
        >
          {/* hide this ai section for now */}
          {/* <If condition={!inSettings && !isCustomer}>
            <LeftActionsWrapper height={theme.topBarHeight}>
              <SearchField
                value={search}
                onChange={e => setSearch(e?.target?.value)}
                sx={{
                  '& input': {
                    // textAlign: !search ? 'center' : 'left',
                    textAlign: 'center',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <div
                      style={{
                        position: 'absolute',
                        top: '6px',
                        left: !search ? '50%' : '10px',
                        transform: !search
                          ? 'translate(-45%, 0)'
                          : 'translate(0, 0)',
                        transitionDuration: '0.2s',
                        transitionTimingFunction: 'ease-out',
                        margin: !search ? 'auto' : '0',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '0.75rem',
                        color: `${themes?.default?.iconColor}cc`,
                        gap: '4px',
                        userSelect: 'none',
                      }}
                    >
                      <div style={{ paddingBottom: '2px' }}>
                        <Icon
                          icon={<MagnifyingGlass weight={'bold'} />}
                          size={14}
                          color={themes?.default?.iconColor}
                        />
                      </div>
                      {!search && t('common.place_holders.search')}
                    </div>
                  ),
                }}
              />
              <AskAIButton />
            </LeftActionsWrapper>
          </If> */}

          {authUser.currentAgent?._customer && (
            <CustomerMenuWrapper>
              <Button
                id="basic-button"
                //aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                //aria-expanded={open ? 'true' : undefined}
                onClick={handleOpenCustomerMenu}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                startIcon={
                  customerAgents.length > 1 ? <SwitchAgentButtonIcon /> : null
                }
              >
                {authUser.currentAgent?._customer.code} -{' '}
                {authUser.currentAgent?._customer.legalName}
              </Button>

              <If condition={customerAgents.length > 1}>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElCustomer}
                  open={openCustomer}
                  onClose={() => setAnchorElCustomer(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {customerAgents.map((agent: any) => (
                    <MenuItem onClick={() => handleSwitchAgent(agent._id)}>
                      {agent?._customer.code} - {agent?._customer.legalName}
                    </MenuItem>
                  ))}
                </Menu>
              </If>
            </CustomerMenuWrapper>
          )}
          <HemeraTabMenu></HemeraTabMenu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <Actions>
            {currentApplication.id === 'ordoria' && false ? (
              <Select
                variant="outlined"
                color="info"
                defaultValue="eur"
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  marginRight: '10px',
                }}
              >
                <MenuItem value="eur">€ EUR</MenuItem>
                <MenuItem value="usd">$ USD</MenuItem>
              </Select>
            ) : (
              <></>
              // <IconButton
              //   size="large"
              //   aria-label="account of current user"
              //   aria-controls="menu-appbar"
              //   aria-haspopup="true"
              //   color="inherit"
              //   style={{ marginRight: '10px', marginBottom: '13px' }}
              // >
              //   <ChatRoundedIcon style={{ color: theme.icon }} />
              // </IconButton>
            )}

            {/* <Tooltip title={t('nav.appHeader.activity')} arrow>
              <IconButton
                size="large"
                aria-label={t('nav.appHeader.activity')}
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                style={{ marginRight: '10px', marginBottom: '13px' }}
              >
                <Notifications style={{ color: theme.icon }} />
              </IconButton>
            </Tooltip> */}
            <RightActionsWrapper>
              <div>
                {!isCustomer && currentApplication.id === 'ordoria' && (
                  <IconButton sx={LeftActionButtonSx}>
                    <Icon
                      {...LeftActionsIconProps}
                      icon={<ShoppingCartSimple />}
                    />
                  </IconButton>
                )}

                {!isCustomer && !inSettings && (
                  <IconButton sx={LeftActionButtonSx}>
                    <Icon {...LeftActionsIconProps} icon={<VideoCamera />} />
                  </IconButton>
                )}

                {!isCustomer && (
                  <>
                    <IconButton
                      sx={LeftActionButtonSx}
                      onClick={() => setChatOpen(true)}
                    >
                      <Icon
                        {...LeftActionsIconProps}
                        icon={<ChatTeardropDots />}
                      />
                      {unreadMessagesCount > 0 && (
                        <NotificationsCountBadge>
                          <div>{unreadMessagesCount}</div>
                        </NotificationsCountBadge>
                      )}
                    </IconButton>
                    <IconButton
                      sx={LeftActionButtonSx}
                      onClick={() => setNotifOpen(true)}
                    >
                      <Icon
                        {...LeftActionsIconProps}
                        icon={
                          <Bell style={{ transform: 'rotate(-14.5deg)' }} />
                        }
                      />
                      {notificationsCount > 0 && (
                        <NotificationsCountBadge>
                          <div>{notificationsCount}</div>
                        </NotificationsCountBadge>
                      )}
                    </IconButton>
                  </>
                )}
              </div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={e => {
                  handlesetAnchorFunction(e, setAnchorEl);
                }}
                color="default"
                disableRipple
                sx={{
                  marginRight: '18px',
                  width: '56px',
                  height: '32px',
                  padding: '4px 8px',
                  gap: '4px',
                  borderRadius: '30px',
                  background: themes?.default?.grey,
                }}
              >
                {/* <UserName>
                {authUser.fullName}
                <UserRole style={{ fontWeight: 'normal' }}>
                  {authUser?.agents?.[0]?.['_role']?.localeName?.[
                    i18next?.resolvedLanguage ? i18next?.resolvedLanguage : 'en'
                  ] || ''}
                </UserRole>
              </UserName> */}
                <Avatar
                  sx={{
                    width: 22,
                    height: 22,
                    background: theme.primary,
                    fontFamily: 'Helvetica !important',
                    fontSize: '0.625rem',
                    color: theme.accordionWhiteBg,
                    position: 'relative',
                    overflow: 'visible',
                  }}
                >
                  {stringAvatar(`${authUser.fullName}`)}
                  <UserStatusDot size={4} status="online" />
                </Avatar>
                <Icon
                  icon={<CaretDown weight="fill" />}
                  color={themes?.default?.iconColor}
                  size={14}
                />
              </IconButton>
            </RightActionsWrapper>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 50,
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                marginTop: '-10px',
                minWidth: '180px',
                '& .MuiList-root': {
                  padding: '0 !important',
                },
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <div
                style={{
                  minHeight: '185px',
                  width: 'max-content',
                  minWidth: '180px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                  padding: '12px',
                }}
              >
                <UserProfileBadge>
                  <Avatar
                    sx={{
                      width: 24,
                      height: 24,
                      background: theme.primary,
                      fontFamily: 'Helvetica !important',
                      fontSize: '0.625rem',
                      color: theme.accordionWhiteBg,
                      position: 'relative',
                      overflow: 'visible',
                    }}
                  >
                    {stringAvatar(`${authUser.fullName}`)}
                    <UserStatusDot size={4} status="online" />
                  </Avatar>
                  <UserInfoContainer>
                    <h4>{authUser.fullName}</h4>
                    <p>
                      {authUser?.currentAgent?.['_role']?.localeName?.[
                        i18next?.resolvedLanguage
                          ? i18next?.resolvedLanguage
                          : 'en'
                      ] || ''}
                    </p>
                  </UserInfoContainer>
                </UserProfileBadge>
                <Divider sx={{ color: themes?.default?.gainsboro2 }} />
                <UserOptionsContainer>
                  <MenuItem sx={{ padding: '4px' }} onClick={() => {}}>
                    <OptionContainer>
                      <Icon
                        icon={<UserCircle />}
                        size={16}
                        color={themes?.default?.iconColor}
                      />
                      <p>{t('drawer.title.profile')}</p>
                    </OptionContainer>
                  </MenuItem>
                  <MenuItem sx={{ padding: '4px' }} onClick={() => {}}>
                    <OptionContainer>
                      <Icon
                        icon={<Gear />}
                        size={16}
                        color={themes?.default?.iconColor}
                      />
                      <p>{t('Add_Client_Settings')}</p>
                    </OptionContainer>
                  </MenuItem>
                  <MenuItem sx={{ padding: '4px' }} onClick={() => {}}>
                    <OptionContainer>
                      <Icon
                        icon={<Info />}
                        size={16}
                        color={themes?.default?.iconColor}
                      />
                      <p>{t('report_a_bug')}</p>
                    </OptionContainer>
                  </MenuItem>
                </UserOptionsContainer>
                <Divider sx={{ color: themes?.default?.gainsboro2 }} />
                <MenuItem
                  sx={{ padding: '4px' }}
                  onClick={() => {
                    openModal({
                      title: t('auth.pages.logout.confirmTitle'),
                      cancel: true,
                      action: {
                        actionCallback: handleLogout,
                        actionText: t('common.buttons.ok'),
                      },
                    });
                  }}
                >
                  <OptionContainer>
                    <Icon
                      icon={<SignOut />}
                      size={16}
                      color={themes?.default?.iconColor}
                    />
                    <p>{t('nav.appHeader.account.logout')}</p>
                  </OptionContainer>
                </MenuItem>
              </div>
            </Menu>
          </Actions>
        </Toolbar>

        {/* <Button
          variant="contained"
          onClick={() => setSettingsDrawerIsOpen(true)}
          disableElevation
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: theme.topBarHeight,
            height: theme.topBarHeight,
            borderRadius: 0,
          }}
        >
          <DashboardCustomize />
        </Button> */}
        <Chat
          open={chatOpen}
          setChatOpen={setChatOpen}
          unreadMessagesCount={unreadMessagesCount}
          setUnreadMessagesCount={setUnreadMessagesCount}
        />

        <MotivationalQuote
          open={openMotivationQuote}
          handleClose={() => setOpenMotivationQuote(false)}
        />

        <Notifications
          open={notifOpen}
          onClose={() => setNotifOpen(false)}
          activities={activities}
          isLoading={isLoading}
          refetch={refetch}
          setLimit={setLimit}
          limit={limit}
        />
      </AppBar>
    </>
  );
}

const UserName = styled.div`
  display: inline-flex;
  font-size: 0.9em;
  text-align: right;
  flex-direction: column;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 0.813rem;
  line-height: 15px;
  letter-spacing: 0.05em;
  padding-right: 8px;
  color: ${p => p.theme.blackPrimary};
  line-height: 1.2em;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomerMenuWrapper = styled.div`
  display: flex;
  padding-left: 24px;
`;

const HemeraTabMenu = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  align-self: end;
  height: 100%;
  .MuiTabs-scroller.MuiTabs-fixed.css-jpln7h-MuiTabs-scroller {
    height: 100%;
  }
`;

const UserRole = styled.span`
  font-weight: 300;
  color: ${p => p.theme.textLight};
`;

const LeftActionsWrapper = styled.div<{ height }>`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 26px;
  height: ${props => props.height};

  .MuiFormControl-root {
    margin: 0 !important;
  }
`;

const SearchField = styled(TextField)({
  margin: '0 !important',
  width: '368px',
  height: '33px',
  gap: '4px',
  borderRadius: '4px',
  background: themes?.default?.grey,
  border: 'transparent',

  '& input': {
    paddingTop: '6.5px',
    paddingLeft: '24px',
  },

  '& input::placeholder': {
    fontSize: '0.75rem',
    color: themes?.default?.nobel,
  },

  '& fieldset': {
    border: 'transparent',
    outline: 'transparent',
  },
});

const RightActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  div {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

const NotificationsCountBadge = styled.div`
  min-width: 17px;
  height: 13px;
  padding: 0.125rem 0.28125rem 0.125rem 0.28125rem;
  border-radius: 50px;
  border: 1px solid ${themes?.default?.accordionWhiteBg};
  background: ${themes?.default?.purpleA700};
  position: absolute;
  top: -6px;
  right: -9px;
  display: flex;
  justify-content: center;

  div {
    height: 7.5px;
    font-size: 0.6rem;
    font-weight: 500;
    line-height: 7.5px;
    text-align: center;
    color: ${themes?.default?.accordionWhiteBg};
  }
`;

const UserProfileBadge = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0 4px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  h4 {
    margin: 0;
    font-size: 0.92rem;
    line-height: 15.23px;
    letter-spacing: 0.05em;
    color: ${themes?.default?.textBlack};
  }
  p {
    margin: 0;
    font-size: 0.78rem;
    line-height: 12.89px;
    letter-spacing: 0.05em;
    color: ${themes?.default?.nobel};
  }
`;

const UserOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    font-size: 0.92rem;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: ${themes?.default?.textColorPrimary};
  }
`;
