import {
  Avatar,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useMediaQuery,
  TextField,
} from '@mui/material';
import If from 'app/components/If';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import styled from 'styled-components';
import { useEffect, useState, useMemo } from 'react';
import { useToaster } from 'hooks/useToaster';
import { Filters } from 'app/components/Filters';
import { productsHeadCellsConst } from 'interfaces/products';
import { filterItems } from 'functions/searchItemInList';
import moment from 'moment';
import { get, groupBy, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { EnhancedTableHead } from '../../../EnhancedTableHead';
import { Order } from 'app/components/TableComponent';
import { ProductNotes } from '../../components/ProductNotes';
import { selectConfiguration } from 'common/store/organization/selectors';
import { updateOrderFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import { ProductTableRow } from '../../components/ProductTableRow';
import SearchField from 'app/components/Search/SearchField';
import { stringAvatar, toggleHeadCellsValue } from 'app/helpers/helpers';
import { SupportUnitsPopover } from 'app/components/SupportUnitsPopover';
import { SupportUnitsTable } from './components/SupportUnitsTable';
import { usePreferredAgent } from 'hooks/PreferredAgent/usePreferredAgent';

interface OrderInformationProps {
  isCustomer: boolean;
  order: any;
  fullOrder: any;
  fullOrderCustomer: any;
  isFetching: boolean;
  setAmountRequested: any;
  amountRequested: any;
  handlePaymentCollectionChange: any;
  triggerGetOrder: Function;
  viewMode?: boolean;
}

const OrderInformationTab = ({
  isCustomer,
  fullOrder,
  fullOrderCustomer,
  isFetching,
  order,
  setAmountRequested,
  amountRequested,
  handlePaymentCollectionChange,
  triggerGetOrder,
  viewMode,
}: OrderInformationProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');
  const toast = useToaster();
  const authUser = useSelector(selectAuthUser);
  const configuration = useSelector(selectConfiguration);

  const { preferredAgent } = usePreferredAgent({ user: fullOrder?._createdBy });

  const [searchText, setSearchText] = useState('');
  const [heads, setHeads] = useState<any[]>(productsHeadCellsConst);
  const [filters, setFilters] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [groupedProducts, setGroupedProducts] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('lastName');
  const [sortOrder, setSortOrder] = useState<Order>('asc');
  const [displayNoteModal, setDisplayNoteModal] = useState({
    isVisible: false,
    productId: null,
    orderId: null,
  });
  const [excessItems, setExcessItems] = useState<any[]>([]);
  const [toCollectValue, setToCollectValue] = useState<any>('€ ');
  //TODO: global usage for filters
  useEffect(() => {
    let orderProducts;
    if (fullOrder) {
      orderProducts = fullOrder?.products?.filter(p => p.quantity > 0) || [];
      setToCollectValue(`€ ${fullOrder?.amountRequested}`);
    } else {
      orderProducts =
        fullOrderCustomer?.products?.filter(p => p.quantity > 0) || [];
    }
    const filteredProducts = orderProducts?.filter(product => {
      for (const filter of filters) {
        const { operator, value, column } = filter;
        let filterBy = get(product, column)?.toString();
        if (column)
          switch (operator) {
            case 'contains':
              if (
                !filterBy ||
                !filterBy?.toLowerCase().includes(value?.toLowerCase())
              ) {
                return false;
              }
              break;
            case 'equal':
              if (
                !filterBy ||
                filterBy?.toLowerCase() != value?.toLowerCase()
              ) {
                return false;
              }
              break;
          }
      }

      return true;
    });

    const searchedProducts = filterItems(filteredProducts, searchText, [
      '_product.code',
      '_product.name',
      '_product._category.name',
      '_product._category._parentCategory.name',
    ]);
    setProducts(searchedProducts);

    setGroupedProducts(
      groupBy(searchedProducts, obj =>
        get(obj, '_product._category._parentCategory.name'),
      ),
    );

    const excessItemsCodes = fullOrder?.deliveryInfo?.excessItems?.map(
      item => item.code,
    );

    setExcessItems(
      searchedProducts.filter(product => {
        return excessItemsCodes?.includes(product._product?.code);
      }),
    );

    toggleHeadCellsValue({
      headCells: [
        {
          id: 'quantity',
          disableRow: !searchedProducts?.find(product => product?.quantity),
        },
        {
          id: 'loadedQuantity',
          disableRow: !searchedProducts?.find(
            product => product?.quantityLoaded,
          ),
        },
        {
          id: 'quantityDelivered',
          disableRow: !searchedProducts?.find(
            product => product?.quantityDelivered,
          ),
        },
      ],
      setHeadCells: setHeads,
    });
  }, [filters, searchText, fullOrder, fullOrderCustomer]);

  const canEdit = useMemo(() => {
    const uneditableStatus = [
      'loaded',
      'on-way',
      'completed',
      'on-dock',
      'unloaded',
      'parked',
      'paused',
      'canceled',
    ];
    if (
      viewMode ||
      uneditableStatus.includes(fullOrder?.roundtrip?.status) ||
      fullOrder?.status === 'delivered'
    ) {
      return false;
    } else {
      return true;
    }
  }, [fullOrder]);

  function handleSaveNewAmout() {
    const newValue = parseFloat(toCollectValue.replace('€ ', ''));
    setAmountRequested(newValue);
    handlePaymentCollectionChange(newValue);
  }

  const handleSearchTextChange = e => {
    const searchText = e.target.value.trim();
    setSearchText(searchText);
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    if (setSortOrder) {
      setSortOrder(isAsc ? 'desc' : 'asc');
    }
    if (property && setOrderBy) {
      setOrderBy(property);
    }
  };

  const handleDisplayNote = productId => {
    setDisplayNoteModal({
      isVisible: true,
      productId,
      orderId: order?.id,
    });
  };

  const getCountNotes = productId => {
    const product = order?.cache?.products?.find(p => p._id === productId);
    return product?.countNotes || 0;
  };

  const checkIfDamaged = productCode => {
    const isDamaged = fullOrder?.deliveryInfo?.damagedItems?.find(
      p => p.code === productCode,
    );
    return isDamaged ? true : false;
  };

  if (isFetching && !(fullOrder || fullOrderCustomer)) {
    return <LoadingPulse style={{ marginTop: '100px' }} />;
  }

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        sx={{ paddingTop: '1vh' }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          sx={{
            borderRight: `1px solid ${themes.default.lightFill}`,
            paddingTop: '10px !important',
            height: '100vh',
          }}
        >
          <HeaderTableWrapper>
            <SearchField
              value={searchText}
              setValue={setSearchText}
              textFieldProps={{
                variant: 'outlined',
                margin: 'none',
                placeholder: t('clients.orderDetails.search'),
                label: '',
              }}
            />
            <Filters
              headCells={heads}
              setFilters={setFilters}
              filters={filters}
              disableTitle={true}
            />
          </HeaderTableWrapper>

          <If
            condition={!isFetching}
            otherwise={<LoadingPulse style={{ marginTop: '50px' }} />}
          >
            <ProductTableWrapper>
              <StyledTableContainer
                isMobile={isMobile}
                sx={{
                  overflow: 'auto',
                  overflowX: 'auto',
                  mb: 0,
                }}
              >
                <Table
                  sx={{ minWidth: 650, border: 'none' }}
                  size="small"
                  aria-label="a dense table"
                >
                  <EnhancedTableHead
                    order={sortOrder ?? 'desc'}
                    orderBy={orderBy ?? ''}
                    onRequestSort={handleSort}
                    isLoading={isFetching}
                    headCells={heads}
                  />
                  <TableBody>
                    {Object.entries(groupedProducts).map(
                      ([family, products]: any) => (
                        <>
                          <TableRow key={family}>
                            <TableCell
                              colSpan={heads?.length}
                              align="left"
                              sx={{
                                padding: '0',
                                border: 'none',
                              }}
                            >
                              <FamilyTitle>
                                {family} ({products?.length})
                              </FamilyTitle>
                            </TableCell>
                          </TableRow>
                          {products?.map(product => (
                            <ProductTableRow
                              product={product}
                              checkIfDamaged={checkIfDamaged}
                              isCustomer={isCustomer}
                              heads={heads}
                              deckId={fullOrder?.cache?.stats?.deckId}
                            />
                          ))}
                        </>
                      ),
                    )}

                    <If condition={!!excessItems?.length}>
                      <TableRow key="excessItems">
                        <TableCell
                          colSpan={heads?.length}
                          align="left"
                          sx={{
                            padding: '0',
                            border: 'none',
                          }}
                        >
                          <ExcessTitle>
                            {t('excess_items')} ({excessItems?.length})
                          </ExcessTitle>
                        </TableCell>
                      </TableRow>

                      {excessItems?.map(product => (
                        <ProductTableRow
                          product={product}
                          checkIfDamaged={checkIfDamaged}
                          isCustomer={isCustomer}
                          heads={heads}
                          deckId={fullOrder?.cache?.stats?.deckId}
                        />
                      ))}
                    </If>
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </ProductTableWrapper>
          </If>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ paddingTop: '12px !important' }}>
          <SummaryTitle>{t('order_summary')}</SummaryTitle>
          <SummaryContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <SummaryTableCell>
                    <SummaryLabel>
                      {t('orders.datatables.filter.createdDate')}
                    </SummaryLabel>
                    <SummaryTableValue>
                      {window
                        .dayjs(order?.createdAt)
                        .format('DD/MM/YYYY, hh:mm A')}
                    </SummaryTableValue>
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <SummaryLabel>
                      {t('clients.orderDetails.deliveryDate')}
                    </SummaryLabel>
                    <SummaryTableValue>
                      {moment.utc(order?.deliveryDate).format('DD/MM/YYYY')}
                    </SummaryTableValue>
                  </SummaryTableCell>
                </TableRow>

                <TableRow>
                  <SummaryTableCell>
                    <SummaryLabel>{t('roundtrip.created_by')}</SummaryLabel>
                    <SummaryTableValue>
                      <If
                        condition={preferredAgent?.fullName}
                        otherwise={'N/A'}
                      >
                        <Avatar
                          sx={{
                            width: '24px',
                            height: '24px',
                            fontSize: '0.75rem',
                            bgcolor: themes.default.primaryActiveColor,
                          }}
                        >
                          {stringAvatar(preferredAgent?.fullName)}
                        </Avatar>
                        <span>{preferredAgent?.fullName}</span>
                      </If>
                    </SummaryTableValue>
                  </SummaryTableCell>
                  <SummaryTableCell>
                    <SummaryLabel>
                      {t('clients.orderDetails.validatedBy')}
                    </SummaryLabel>
                    <SummaryTableValue>
                      <If
                        condition={order?._validatedBy?.fullName}
                        otherwise={
                          <span
                            style={{
                              height: '24px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            N/A
                          </span>
                        }
                      >
                        <Avatar
                          sx={{
                            width: '24px',
                            height: '24px',
                            fontSize: '0.75rem',
                            bgcolor: themes.default.greenStatus,
                          }}
                        >
                          {`${order?._validatedBy?.firstName?.charAt(
                            0,
                          )}${order?._validatedBy?.lastName?.charAt(0)}`}
                        </Avatar>
                        <span>{order?._validatedBy?.fullName}</span>
                      </If>
                    </SummaryTableValue>
                  </SummaryTableCell>
                </TableRow>

                <RowsSeperator />

                <TableRow>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel>
                      {t('clients.orderDetails.numberLines')}
                    </SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue>{order?.countLines}</SummaryValue>
                  </SummaryTableCell>
                </TableRow>
                <TableRow>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel>
                      {t('clients.orderDetails.numberPackages')}
                    </SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue>{order?.countPackages}</SummaryValue>
                  </SummaryTableCell>
                </TableRow>
                <TableRow>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel>
                      {t('clients.orderDetails.weightOnVolume')}
                    </SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue>
                      {order?.totalWeight?.toFixed(2)}kg / {0 /**'volume' */}
                      m³
                    </SummaryValue>
                  </SummaryTableCell>
                </TableRow>

                <TableRow>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel>{t('ca_kg')}</SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue>
                      <If
                        condition={order?.cache?.stats?.amountPerWeight != ''}
                        otherwise="N/A"
                      >
                        {order?.cache?.stats?.amountPerWeight?.toFixed(2)}
                      </If>
                    </SummaryValue>
                  </SummaryTableCell>
                </TableRow>

                <RowsSeperator />

                <If condition={isCustomer === false} otherwise={<></>}>
                  <TableRow>
                    <SummaryTableCell sx={{ border: 'none' }}>
                      <SummaryLabel>
                        {t('clients.orderDetails.RoundtripOnPosition')}
                      </SummaryLabel>
                    </SummaryTableCell>
                    <SummaryTableCell sx={{ border: 'none' }} align="right">
                      <SummaryValue>
                        <If
                          condition={!isEmpty(order?.roundtripCode)}
                          otherwise="N/A"
                        >
                          {order?.roundtripCode} /{' '}
                          {order?.roundtripPosition
                            ? order?.roundtripPosition
                            : 'No Position'}
                        </If>
                      </SummaryValue>
                    </SummaryTableCell>
                  </TableRow>
                </If>

                <If
                  condition={
                    isCustomer === false && !isEmpty(order?.deliveryComment)
                  }
                  otherwise={<></>}
                >
                  <TableRow>
                    <SummaryTableCell sx={{ border: 'none' }}>
                      <SummaryLabel>
                        {t('clients.orderDetails.deliveryComment')}
                      </SummaryLabel>
                    </SummaryTableCell>
                  </TableRow>
                  <SummaryValue style={{ display: 'block', padding: '0 6px' }}>
                    {order?.deliveryComment}
                  </SummaryValue>
                </If>

                <TableRow>
                  <SummaryTableCell
                    colSpan={
                      fullOrder?.status !== 'validated' &&
                      fullOrder?.status !== 'confirmed' &&
                      fullOrder?.status !== 'prepared'
                        ? 2
                        : 1
                    }
                    sx={{
                      border: 'none',
                      verticalAlign: 'middle !important',
                    }}
                  >
                    <SummaryLabel style={{ padding: '6px 0' }}>
                      {t('clients.orderDetails.supportUnit')}
                    </SummaryLabel>
                    <If
                      condition={
                        fullOrder?.supportUnitsList &&
                        fullOrder?.status !== 'validated' &&
                        fullOrder?.status !== 'confirmed' &&
                        fullOrder?.status !== 'prepared'
                      }
                    >
                      <SupportUnitsTable
                        supportUnits={{
                          ...fullOrder?.supportUnitsList,
                          delivered:
                            fullOrder?.status === 'delivered'
                              ? fullOrder?.supportUnitsList?.delivered
                              : [],
                          loaded: fullOrder?.supportUnitListLocations,
                        }}
                      />
                    </If>
                  </SummaryTableCell>
                  <If
                    condition={
                      fullOrder?.supportUnitsList &&
                      (fullOrder?.status === 'validated' ||
                        fullOrder?.status === 'confirmed' ||
                        fullOrder?.status === 'prepared')
                    }
                  >
                    <SummaryTableCell
                      sx={{
                        border: 'none',
                        textAlign: 'right',
                        paddingRight: '0 !important',
                      }}
                    >
                      {order?.supportUnitsRequestedCapacity}
                      <SupportUnitsPopover
                        stopId={order?._id}
                        updateOrderFunction={updateOrderFunction}
                        canEdit={canEdit}
                        widthButton={37}
                        supportUnits={{
                          ...fullOrder?.supportUnitsList,
                          loaded: fullOrder?.supportUnitListLocations,
                        }}
                        refreshData={() => triggerGetOrder(order?.id)}
                      />
                    </SummaryTableCell>
                  </If>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ border: 'none', padding: 0 }}>
                    <Divider sx={{ mt: '20px', mb: '20px' }} />
                  </TableCell>
                </TableRow>
                {/* {authUser || !isCustomer ? (
                    <>
                      <TableRow>
                        <SummaryTableCell sx={{ border: 'none' }}>
                          <SummaryLabel>
                            {t(
                              'clients.orderDetails.paymentCollection',
                            )}
                          </SummaryLabel>
                        </SummaryTableCell>
                        <SummaryTableCell sx={{ border: 'none', textAlign: 'right' }}>
                          <TextField
                            label={`${t('clients.orderDetails.amount')} €`}
                            size="small"
                            value={amountRequested}
                            type="number"
                            onChange={e =>
                              setAmountRequested(parseFloat(e.target.value))
                            }
                            onBlur={handlePaymentCollectionChange}
                            disabled={
                              false === checkUserAcl(authUser, ['manageOrder'])
                            }
                            sx={{
                              width: '75%',
                              marginBottom: 0,
                              marginTop: '10px'
                            }}
                          />
                        </SummaryTableCell>
                      </TableRow>

                      <RowsSeperator />
                    </>

                  ) : null} */}

                <TableRow>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel>{t('order.itemsList.subtotal')}</SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue>
                      {`€ ${order?.totalAmount
                        ?.toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
                    </SummaryValue>
                  </SummaryTableCell>
                </TableRow>
                <TableRow>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel>{t('order.itemsList.shipping')}</SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue>
                      {`€ ${order?.deliveryCost || 0}`}
                    </SummaryValue>
                  </SummaryTableCell>
                </TableRow>
                <TableRow>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel>{t('order.itemsList.tax')} 5%</SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue>
                      {`€ ${(
                        order?.totalAmountTax.toFixed(2) -
                        order?.totalAmount.toFixed(2)
                      ).toFixed(2)}`}
                    </SummaryValue>
                  </SummaryTableCell>
                </TableRow>

                <RowsSeperator />

                <TableRow sx={{ backgroundColor: themes.default.lightGrey }}>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel
                      style={{ color: themes.default.textColorPrimary }}
                    >
                      {t('total_ttc')}
                    </SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue>
                      {`€ ${order?.totalAmountTax
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
                    </SummaryValue>
                  </SummaryTableCell>
                </TableRow>

                <RowsSeperator />

                <SectionTitle>{t('common.collection')}</SectionTitle>

                <TableRow>
                  <SummaryTableCell sx={{ border: 'none', height: '40px' }}>
                    <SummaryLabel
                      style={{
                        color: themes.default.black,
                        // fontSize: '0.6875rem',
                        padding: '2px 0 0 0',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                      }}
                    >
                      {t('payment_to_collect')}
                    </SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell
                    sx={{ border: 'none', height: '40px' }}
                    align="right"
                  >
                    {fullOrder?.status !== 'delivered' && !viewMode ? (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={toCollectValue}
                        sx={{
                          width: '76px',
                          margin: '0 !important',
                          '& input': {
                            padding: '4px 12px !important',
                            height: '24px',
                            fontSize: '0.8125rem',
                            letterSpacing: '0.17000000178813934px',
                          },
                          '& fieldset': {
                            border: `1px solid ${themes?.default?.gainsboro2}`,
                            transitionDuration: '0.15s',
                          },
                          '& fieldset:hover': {
                            border: `1px solid ${themes?.default?.iconColor} !important`,
                          },
                        }}
                        onKeyDown={e => {
                          const isNumberKey = e.key.match(/[0-9.,€$]/);
                          const isNavigationKey = [
                            'Backspace',
                            'Delete',
                            'ArrowLeft',
                            'ArrowRight',
                            'Tab',
                          ].includes(e.key);
                          if (!isNumberKey && !isNavigationKey) {
                            if (!e?.ctrlKey) {
                              e?.preventDefault();
                            }
                          }
                        }}
                        onChange={e => {
                          const value = e?.target?.value;
                          if (!value.startsWith('€ ')) {
                            setToCollectValue('€ ');
                          } else {
                            setToCollectValue(value);
                          }
                        }}
                        onBlur={() => {
                          handleSaveNewAmout();
                        }}
                      />
                    ) : (
                      <span>{toCollectValue}</span>
                    )}
                  </SummaryTableCell>
                </TableRow>

                <TableRow sx={{ backgroundColor: `${themes.default.green}0A` }}>
                  <SummaryTableCell sx={{ border: 'none' }}>
                    <SummaryLabel
                      style={{
                        color: themes.default.black,
                        // fontSize: '0.6875rem',
                        // padding: '2px 0 0 0'
                      }}
                    >
                      {t('amount_collected')}
                    </SummaryLabel>
                  </SummaryTableCell>
                  <SummaryTableCell sx={{ border: 'none' }} align="right">
                    <SummaryValue
                      style={{
                        color: themes.default.textDelivered,
                        fontWeight: '500',
                        // fontSize: '0.875rem'
                      }}
                    >
                      {`€ ${order?.paymentCollection}`}
                    </SummaryValue>
                  </SummaryTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </SummaryContainer>
        </Grid>
      </Grid>

      <ProductNotes
        viewMode={false}
        open={displayNoteModal.isVisible}
        productId={displayNoteModal.productId}
        orderId={displayNoteModal.orderId}
        onCloseModal={() =>
          setDisplayNoteModal({
            isVisible: false,
            productId: null,
            orderId: null,
          })
        }
        refreshData={() => triggerGetOrder(order?.id)}
      />
    </>
  );
};

export default OrderInformationTab;

const ProductTableWrapper = styled.div`
  & td,
  & th {
    font-size: 0.75rem;
  }
  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: none;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: darkgray;
  }
`;

const HeaderTableWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-bottom: 10px;
  padding: 0 16px;
`;

export const SummaryLabel = styled.div`
  color: ${props => props.theme.textColorSecondary};
  font-size: 0.9rem;
`;

export const SummaryValue = styled.div`
  font-size: 1rem;
  display: flex;
  justify-content: end;
  align-items: center;
`;
export const SummaryTableValue = styled.div`
  padding-top: 3px;
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 0.8725rem;
`;
export const SummaryTableCell = styled(TableCell)`
  padding: 5px !important;
  width: 50%;
  vertical-align: top !important;
  border: 1px solid ${props => props.theme.tableBorder};
`;
const FamilyTitle = styled.div`
  color: ${props => props.theme.grey2};
  font-size: 0.75rem;
  font-weight: 600;
  padding: 1rem 0;
`;
const ExcessTitle = styled(FamilyTitle)`
  color: ${props => props.theme.Cinnabar};
`;
interface StyledTableContainerProps {
  isMobile: boolean;
}
const StyledTableContainer = styled(TableContainer)<StyledTableContainerProps>`
  max-height: calc(100vh - 295px);
  white-space: nowrap;

  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: none;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: darkgray;
  }

  & tbody tr:last-child td {
    border-bottom: none;
  }
`;

const RowsSeperator = styled.div`
  height: 11px;
`;

const SummaryContainer = styled.div`
  max-height: calc(100vh - 295px);
  overflow-y: scroll;
  padding-right: 21px;
`;

const SummaryTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 157%;
  letter-spacing: 0.00625rem;
  height: 32px;
  margin-bottom: 10px;
`;

const SectionTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 21.98px;
  letter-spacing: 0.10000000149011612px;
  color: ${themes?.default?.textBlack};
  padding: 24px 0 10px 0;
`;
