import { useEffect, useState, useMemo, useContext } from 'react';
import { ItemOrder } from 'app/pages/AddRoundTrips/components/ItemOrder';
import dayjs from 'dayjs';
import MarkerPinBlue from 'assets/img/map/CustomerMap/MarkerPinBlue.png';
import MarkerPinBlueOutlined from 'assets/img/map/CustomerMap/MarkerPinBlueOutlined.png';
import MarkerPinOrange from 'assets/img/map/CustomerMap/MarkerPinOrange.png';
import MarkerPinOrangeOutlined from 'assets/img/map/CustomerMap/MarkerPinOrangeOutlined.png';
import MarkerPinGreen from 'assets/img/map/CustomerMap/MarkerPinGreen.png';
import MarkerPinGreenHoveredSelected from 'assets/img/map/CustomerMap/MarkerPinGreenHoveredSelected.png';
import MarkerPinRed from 'assets/img/map/CustomerMap/MarkerPinRed.png';
import MarkerPinRedOutlined from 'assets/img/map/CustomerMap/MarkerPinRedOutlined.png';
import { RoundtripDrawerContext } from '../../../RoundtripDrawer';
import {
  MarkerPinContainer,
  PinNumber,
} from 'app/pages/RoundTrips/components/Map/components/ClientOrderMarker';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';

interface ClientOrderMarkerProps {
  position: any;
  order?: any;
  index: any;
  onDisplayOrder: any;
}
export const StopMarker: React.FC<ClientOrderMarkerProps> = ({
  position,
  order,
  index,
  onDisplayOrder,
}) => {
  const { pinnedOrders, setPinnedOrders } = useContext(RoundtripDrawerContext);

  const getPixelPositionOffset = (width: number, height: number) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  const togglePinned = (id: string) => {
    if (pinnedOrders.includes(id)) {
      setPinnedOrders(prev => prev?.filter(p => p !== id));
    } else {
      setPinnedOrders(prev => [...prev, id]);
    }
  };

  const [overlayVisible, setOverlayVisible] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (mouseOver === false && !pinnedOrders?.includes(order?._id)) {
      timeoutId = setTimeout(() => {
        setOverlayVisible(false);
      }, 200);
    } else {
      setOverlayVisible(true);
    }

    return () => clearTimeout(timeoutId);
  }, [mouseOver, pinnedOrders, order?._id]);

  const handleDisplayStopWindow = (display: boolean) => {
    setOverlayVisible(display);
  };

  const isMarkerSelected = useMemo(() => {
    return pinnedOrders?.includes(order?._id) || overlayVisible;
  }, [pinnedOrders, overlayVisible, order?._id]);

  const invalidDeliveryDays = useMemo(() => {
    const deliveryDay = dayjs(order?.getDeliveryDateFormat, 'DD-MM-YYYY')
      .locale('en')
      .format('dddd')
      .toLowerCase();
    return !order?.cache?._deck?.deliveryDays.includes(deliveryDay);
  }, [order?.getDeliveryDateFormat, order?.cache?._deck?.deliveryDays]);

  const markerSrc = useMemo(() => {
    const delivered = order?.status === 'delivered';
    const canceled = order?.status === 'canceled';

    if (delivered) {
      return isMarkerSelected ? MarkerPinGreenHoveredSelected : MarkerPinGreen;
    }

    if (canceled) {
      return isMarkerSelected ? MarkerPinRedOutlined : MarkerPinRed;
    }

    return isMarkerSelected ? MarkerPinBlueOutlined : MarkerPinBlue;
  }, [isMarkerSelected, invalidDeliveryDays]);

  return (
    <>
      <div onMouseLeave={() => setMouseOver(false)}>
        <AdvancedMarker position={position} clickable={true} onClick={() => {}}>
          <MarkerPinContainer
            stopSelected={false}
            viewAssignedOrders={true}
            onClick={() => setMouseOver(isMarkerSelected ? false : !mouseOver)}
            onDoubleClick={() => togglePinned(order?._id)}
          >
            <img src={markerSrc} alt="" width="22px" height="26px" />
            <PinNumber>{index}</PinNumber>
          </MarkerPinContainer>
        </AdvancedMarker>
      </div>
      {overlayVisible && (
        <div
          onMouseEnter={() => setMouseOver(true)}
          onMouseLeave={() => setMouseOver(false)}
        >
          <InfoWindow
            position={position}
            pixelOffset={[0, -20]}
            zIndex={100}
            onCloseClick={() => handleDisplayStopWindow(false)}
          >
            <ItemOrder
              dateValue={dayjs()}
              handleChange2={togglePinned}
              stop={order}
              isAssigned={true}
              index={index}
              data={order}
              key={order?._id}
              onDisplayOrder={onDisplayOrder}
              disableDragAndDrop={true}
              mapVersion={true}
              pinnedLayers={pinnedOrders}
              disableEdit={true}
            />
          </InfoWindow>
        </div>
      )}
    </>
  );
};
