import {
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
  Grid,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { selectTheme } from '../../../../styles/theme/slice/selectors';
import { StatusOrder } from '../../../pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import { themes } from 'styles/theme/themes';

export function OrderItem({ order, onClick }) {
  const theme = useSelector(selectTheme);

  console.log(theme);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:1000px)');

  const chipWidth = isMobile ? 'auto' : '120px';

  return (
    <Card key={order._id} style={{ marginBottom: 16, minHeight: 72 }}>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6}>
            <ContentWrapper>
              <Typography
                sx={{ fontSize: '0.875rem', margin: 0, marginRight: 3 }}
                color="text.secondary"
                gutterBottom
                fontSize="1rem"
              >
                {moment(order.createdAt).format('DD/MM/YYYY')}
              </Typography>

              <Button
                variant="outlined"
                sx={{ width: '125px', marginRight: 5 }}
                onClick={onClick}
              >
                {order.code}
              </Button>

              <StatusOrder status={order.status} context="ordoria" />
            </ContentWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <ChipWrapper>
              <Chip
                label={Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(order.totalAmount)}
                sx={{
                  fontWeight: '500',
                  fontSize: '0.938rem',
                  backgroundColor: theme.primary,
                  color: themes?.default?.accordionWhiteBg,
                  marginLeft: 2,
                  width: chipWidth,
                }}
              />
              <Chip
                label={
                  isMobile
                    ? `${order?.countPackages} colis`
                    : order?.countPackages
                }
                sx={{
                  fontWeight: '500',
                  fontSize: '0.938rem',
                  border: `1px solid  ${theme.primary}`,
                  backgroundColor: themes?.default?.accordionWhiteBg,
                  color: theme.primary,
                  marginLeft: 1,
                  width: chipWidth,
                }}
              />
              <Chip
                label={
                  isMobile ? `${order?.countLines} lignes` : order?.countLines
                }
                sx={{
                  fontWeight: '500',
                  fontSize: '0.938rem',
                  border: `1px solid  ${theme.primary}`,
                  backgroundColor: themes?.default?.accordionWhiteBg,
                  color: theme.primary,
                  marginLeft: 1,
                  maxWidth: chipWidth,
                }}
              />
              <Chip
                label={Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(order.amountRequested)}
                sx={{
                  fontWeight: '500',
                  fontSize: '0.938rem',
                  backgroundColor: themes?.default?.accordionWhiteBg,
                  border: `1px solid  ${theme.primary}`,
                  color: theme.primary,
                  marginLeft: 1,
                  width: chipWidth,
                }}
              />
            </ChipWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

const ContentWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

const ChipWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: end;

  @media (max-width: 1000px) {
    width: 100%;
    justify-content: flex-start;
  }
`;
