import { FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { Checkbox } from 'app/components/CustomCheckbox';

///On click Delete a single file
export function handleDeleteFiles(
  files,
  checkedFiles,
  setFiles,
  setCheckedFiles,
) {
  const remainingFiles = files.filter(
    file => !checkedFiles.includes(file.name),
  );
  setFiles(remainingFiles);
  setCheckedFiles([]);
}

///On click select all the checkbox
export function selectAllCheckbox(areAllChecked, setCheckedFiles, files) {
  if (areAllChecked) {
    setCheckedFiles([]);
  } else {
    const allFileNames = files.map(file => file.name);
    setCheckedFiles(allFileNames);
  }
}

///If the checkbox is selected -> push filename into checkedFiles array
export function handleCheckboxChange(fileName, event, setCheckedFiles, files) {
  const isChecked = event.target.checked;

  if (fileName === 'selectAll') {
    if (isChecked) {
      const allFileNames = files.map(file => file.name);
      setCheckedFiles(allFileNames);
    } else {
      setCheckedFiles([]);
    }
  } else {
    setCheckedFiles(prevCheckedFiles => {
      if (isChecked) {
        return [...prevCheckedFiles, fileName];
      } else {
        return prevCheckedFiles.filter(file => file !== fileName);
      }
    });
  }
}

export function filesList(files, setCheckedFiles, checkedFiles) {
  return files.map(file => (
    <RadioGroup key={file.name}>
      <li
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: '650px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {file.name}
        </Typography>
        <FormControlLabel
          sx={{ marginLeft: 1 }}
          value=""
          control={
            <Checkbox
              onChange={event =>
                handleCheckboxChange(file.name, event, setCheckedFiles, files)
              }
              checked={
                checkedFiles.includes(file.name) ||
                checkedFiles.includes('selectAll')
              }
            />
          }
          label=""
        />
      </li>
    </RadioGroup>
  ));
}
