import React from 'react';
import Tooltip from '@mui/material/Tooltip';
const statSuffixes = ['K', 'M', 'B', 'T', 'Q'];
interface FormatNumberProps {
  number: number;
  unit?: string;
}
const FormatNumber: React.FC<FormatNumberProps> = ({ number, unit }) => {
  let suffixIdx = -1;
  let originalNumber = number;
  while (number >= 1000) {
    number /= 1000;
    suffixIdx += 1;
  }
  const doubleDigits = suffixIdx === -1;
  const roundingAmount = doubleDigits ? 100 : 10;
  number = Math.round(number * roundingAmount) / roundingAmount;
  const formattedNumber = `${number}${
    suffixIdx > -1 ? statSuffixes[suffixIdx] : ''
  }${unit ? ` ${unit}` : ''}`;
  return suffixIdx > -1 ? (
    <Tooltip
      title={`${originalNumber.toLocaleString()}${unit ? ` ${unit}` : ''}`}
    >
      <span>{formattedNumber}</span>
    </Tooltip>
  ) : (
    <span>{formattedNumber}</span>
  );
};
export default FormatNumber;
