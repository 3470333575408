import styled from 'styled-components';
import { FormControlLabel, Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TitleSorting } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusChangePopover';
import { Canceled } from 'app/components/RoundTrips/RoundTripData/statuses';
import FormControlComponent from './Components/FormControlComponent';
import { themes } from 'styles/theme/themes';
import { Checkbox } from 'app/components/CustomCheckbox';

export const Filters = ({
  id2,
  openFilter,
  filterOn,
  handleCloseFilter,
  handlePriorityChange,
  checkedValues,
  handleDeliveryTypeChange,
  handleStatusChange,
  handleTruckTypeChange,
}) => {
  const { t } = useTranslation();
  return (
    <Popover
      id={id2}
      open={openFilter}
      anchorEl={filterOn}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <PopUpContainerFilter width={'288px'}>
        <TitleSorting>{t('common.priority')}</TitleSorting>

        <FormControlComponent
          handleDeliveryTypeChange={handlePriorityChange}
          checkedValues={checkedValues}
          String1={'High'}
          String2={'Medium'}
        />
        <div className="button_icon">
          <FormControlLabel
            control={
              <Checkbox
                onChange={handlePriorityChange}
                checked={checkedValues['Low']}
                value="Low"
              />
            }
            label={t('common.low')}
            sx={{ width: '50%' }}
          />
        </div>

        <TitleSorting>{t('clients.orderDetails.delivery_type')}</TitleSorting>

        <FormControlComponent
          handleDeliveryTypeChange={handleDeliveryTypeChange}
          checkedValues={checkedValues}
          String1={'Delivery'}
          String2={'Collection'}
        />
        <TitleSorting>{t('common.status')}</TitleSorting>
        <FormControlComponent
          handleDeliveryTypeChange={handleStatusChange}
          checkedValues={checkedValues}
          String1={'Loaded'}
          String2={'Canceled'}
        />
        <FormControlComponent
          handleDeliveryTypeChange={handleStatusChange}
          checkedValues={checkedValues}
          String1={'Prepared'}
          String2={'Postponed'}
        />

        <TitleSorting>{t('info.truck_type')}</TitleSorting>
        <FormControlComponent
          handleDeliveryTypeChange={handleTruckTypeChange}
          checkedValues={checkedValues}
          String1={'PL'}
          String2={'VL'}
        />

        <div className="button_icon">
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleTruckTypeChange}
                checked={checkedValues['PL+VL']}
                value="PL+VL"
              />
            }
            label="PL+VL"
            sx={{ width: '50%' }}
          />
        </div>
      </PopUpContainerFilter>
    </Popover>
  );
};

export const PopUpContainerFilter = styled.div<{ width: string }>`
  width: ${props => props.width};
  .seperator {
    border: 0.8px solid ${themes?.default?.textColorSecondary}80;
    width: 171px;
  }
  button {
    width: 100%;
    justify-content: left;
  }
  .button_icon {
    display: flex;
    align-items: center;
  }

  padding: 12px;
  border-radius: 0.25rem;
`;
