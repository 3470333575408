import { TFunction } from 'i18next';
import { AlertColor } from '@mui/material';

export async function handleDeleteDepartments(
  ids: string,
  deleteFunc: (body: { _ids: string }) => any,
  closeModal: () => void,
  toast: (
    duration: number | undefined,
    severity: AlertColor,
    message: string,
    vars?: any,
    loader?: boolean | undefined,
    title?: string | undefined,
  ) => void,
  t: TFunction,
) {
  try {
    const res = await deleteFunc({
      _ids: ids,
    }).unwrap();
    toast(3000, 'success', res.message);
  } catch (err) {
    console.log(err);
  } finally {
    closeModal();
  }
}
