import { Column } from 'app/components/Grid/types';

export const Columns: Column[] = [
  {
    id: 'name',
    label: 'settings.companyProfile.departments.add.textfields.departmentName',
    sortField: 'name',
    cellStyle: {
      width: '200px',
      minWidth: '200px !important',
    },
    sticky: 'left',
  },
  // {
  //   id: 'organization',
  //   label: 'Organization',
  //   canChangeView: true,
  //   sortField: 'deck',
  //   cellStyle: {
  //     width: '150px !important',
  //     minWidth: '150px !important',
  //   },
  // },
  {
    id: 'manager',
    label: 'Add_Client_Manager',
    sortField: 'cache._manager.name',
    canChangeView: true,
    cellStyle: { width: '200px !important', minWidth: '200px !important' },
  },
  // {
  //   id: 'employees',
  //   label: 'Employees',
  //   canChangeView: true,
  //   // sortField: 'ext',
  // },
  // {
  //   id: 'operatingHours',
  //   label: 'Operating Hours',
  //   canChangeView: true,
  //   cellStyle: { width: '200px !important', minWidth: '200px !important' },
  // },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   canChangeView: true,
  //   cellStyle: { width: '200px', minWidth: '200px !important' },
  // },
  {
    id: 'actions',
    disableSort: true,
    label: '',
    cellStyle: {
      width: '100px',
      minWidth: '100px !important',
    },
    sticky: 'right',
  },
];
