import {
  Box,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useGrid } from '../../hooks';
import { visuallyHidden } from '@mui/utils';
import React, {
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { GridRow } from '../styles';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import If from 'app/components/If';
import { Checkbox } from 'app/components/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { CaretDown, CaretUp, Info } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Column } from '../../types';

export const GridHead = () => {
  const {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    columns,
    setColumns,
    selected,
    setSelected,
    totalRows,
    rows,
    withCheckbox,
    columnWidths,
    setColumnWidths,
    withColumnReordering,
  } = useGrid();
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const columnRefs = useRef<React.RefObject<HTMLTableCellElement>[]>([]);

  const [columnOrder, setColumnOrder] = useState(columns);

  useEffect(() => {
    if (!columnOrder?.length) setColumnOrder(columns);
  }, [columns, columnOrder?.length]);

  useEffect(() => {
    columnRefs.current = columnOrder.map(
      (_, i) => columnRefs.current[i] ?? React.createRef(),
    );
  }, [columnOrder]);

  useEffect(() => {
    const newWidths = columnOrder.reduce((acc, col, index) => {
      if (col.disableRow) return acc;

      const headerWidth = columnRefs.current[index]?.current?.offsetWidth || 0;
      const cellWidths = rows.map(row => {
        const cellContent = row[col.id] || '';
        const tempSpan = document.createElement('span');
        tempSpan.innerText = cellContent;
        document.body.appendChild(tempSpan);
        const width = tempSpan.offsetWidth;
        document.body.removeChild(tempSpan);
        return width;
      });
      const maxCellWidth = Math.max(...cellWidths);
      acc[col.id] = Math.max(headerWidth, maxCellWidth);
      return acc;
    }, {});

    setColumnWidths(newWidths);
  }, [columnOrder, rows, setColumnWidths]);

  const getStickyLeft = (index: number) => {
    let offset = 0;
    for (let i = 0; i < index; i++) {
      const col = columnOrder[i];
      if (col?.sticky === 'left') {
        offset += columnWidths[col.id] || 0;
      }
    }
    if (withCheckbox) {
      offset += themes.default.gridCheckboxWidth;
    }
    return offset;
  };

  const handleSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === '';
      setOrder(isAsc ? '-' : '');
      setOrderBy(property);
    },
    [order, orderBy, setOrder, setOrderBy],
  );

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map(n => n._id ?? n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const direction = useMemo(() => {
    return order === '' ? 'asc' : 'desc';
  }, [order]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const reorderedColumns = Array.from(columnOrder);
    const [removed] = reorderedColumns.splice(result.source.index, 1);
    reorderedColumns.splice(result.destination.index, 0, removed);

    // Update both local columnOrder and global columns state
    setColumnOrder(reorderedColumns);
    setColumns(reorderedColumns); // Update global columns state
  };

  const getCellContent = useCallback(
    (column: Column, index: number) => {
      const sortField = column.sortField || column.id;

      return (
        <div ref={columnRefs.current[index]}>
          <If
            condition={!column.disableSort}
            otherwise={
              <>
                <Typography
                  color={theme.grey2}
                  fontSize={'0.9rem'}
                  fontWeight={700}
                  textTransform={'capitalize'}
                >
                  {t(column.label)}
                  {column.hasInfoIcon && (
                    <Tooltip
                      title={column.tooltipText ? t(column.tooltipText) : ''}
                      arrow
                    >
                      <IconButton>
                        <Icon
                          icon={<Info />}
                          weight="bold"
                          size={16}
                          color={`${themes?.default?.iconColor}99`}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>
              </>
            }
          >
            <TableSortLabel
              active={true}
              direction={orderBy === sortField ? direction : 'asc'}
              onClick={() => handleSort(sortField)}
              IconComponent={() => (
                <>
                  <CustomSortIconWrapper>
                    <Icon
                      icon={<CaretUp />}
                      weight="bold"
                      size={12}
                      color={
                        orderBy === sortField && order === ''
                          ? themes?.default?.black
                          : `${themes?.default?.iconColor}99`
                      }
                    />
                    <Icon
                      icon={<CaretDown />}
                      weight="bold"
                      size={12}
                      color={
                        orderBy === sortField && order === '-'
                          ? themes?.default?.black
                          : `${themes?.default?.iconColor}99`
                      }
                    />
                  </CustomSortIconWrapper>
                </>
              )}
            >
              <Typography
                color={theme.grey2}
                fontSize={'0.9rem'}
                fontWeight={700}
                textTransform={'capitalize'}
              >
                {t(column.label)}
                {column.hasInfoIcon && (
                  <Tooltip
                    title={column.tooltipText ? t(column.tooltipText) : ''}
                    placement="top"
                    arrow
                  >
                    <IconButton>
                      <Icon
                        icon={<Info />}
                        weight="bold"
                        size={16}
                        color={`${themes?.default?.iconColor}99`}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
              {orderBy === sortField ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === '-' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </If>
        </div>
      );
    },
    [direction, handleSort, order, orderBy, t, theme],
  );

  if (!totalRows) {
    return <></>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {provided => (
          <Head ref={provided.innerRef} {...provided.droppableProps}>
            <GridRow>
              <If condition={!!withCheckbox}>
                <TableCell padding="none" className="checkbox-cell">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected?.length > 0 && selected?.length < rows?.length
                    }
                    checked={totalRows > 0 && selected?.length === rows?.length}
                    onChange={onSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all',
                    }}
                    sx={{
                      padding: 0,
                    }}
                  />
                </TableCell>
              </If>
              {columns?.map((column, index) => {
                const sortField = column.sortField || column.id;

                if (column.disableRow) {
                  return <></>;
                } else if (!column.sticky) {
                  return (
                    <Draggable
                      key={column.id}
                      draggableId={column.id}
                      index={index}
                      isDragDisabled={!withColumnReordering}
                    >
                      {(provided, snapshot) => (
                        <>
                          {provided.placeholder}

                          <TableCell
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={column.id}
                            sortDirection={
                              orderBy === sortField ? direction : false
                            }
                            className={column.className}
                            sx={{
                              ...provided.draggableProps.style,
                              ...column.cellStyle,
                              width: columnWidths[column.id], // Ensure fixed width
                              // This prevents jumping issues
                              background: snapshot.isDragging
                                ? themes?.default?.primaryLight
                                : '',
                              borderRadius: snapshot.isDragging ? '8px' : '',
                              border: snapshot.isDragging
                                ? `1px solid ${themes?.default?.primary}`
                                : '',
                            }}
                          >
                            {getCellContent(column, index)}
                          </TableCell>
                        </>
                      )}
                    </Draggable>
                  );
                }
                return (
                  <TableCell
                    key={column.id}
                    sortDirection={orderBy === sortField ? direction : false}
                    className={column.className}
                    sx={{
                      ...column.cellStyle,
                      width: columnWidths[column.id],
                      left:
                        column.sticky === 'left'
                          ? `${getStickyLeft(index)}px`
                          : undefined,
                      right: column.sticky === 'right' ? '0' : undefined,
                      zIndex: column.sticky ? '3 !important' : 'auto',
                    }}
                  >
                    {getCellContent(column, index)}
                  </TableCell>
                );
              })}
            </GridRow>
          </Head>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Head = styled(TableHead)`
  position: relative;
  z-index: 4;
`;

const CustomSortIconWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 16px;
  margin: 0 0 0 8px;
  position: relative;

  div {
    height: 6px;
    position: absolute;
    left: 0;
  }

  div:first-child {
    top: -7px;
  }

  div:last-child {
    bottom: 11px;
  }
`;
