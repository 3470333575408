import { Data } from 'interfaces/orders';

export function createData(
  id: { value: number | string },
  customCheck: { value: JSX.Element; display: boolean },
  code: { value: JSX.Element; sort?: string },
  roundtripCode: { value: JSX.Element },
  warehouse: { value: JSX.Element },
  roundtripPosition: { value: JSX.Element },
  customerCode: { value: JSX.Element },
  priority: { value: JSX.Element; sort?: string },
  customer_code: { value: JSX.Element; sort?: string },
  customer_name: { value: JSX.Element; sort?: string },
  address: { value: JSX.Element; sort?: string },
  countLines: { value: JSX.Element; sort?: number },
  createdBy: { value: JSX.Element },
  salesman: { value: JSX.Element },
  quantity: { value: JSX.Element },
  weight: { value: JSX.Element },
  support_unit: { value: JSX.Element },
  supportUnitsRequestedCapacity: { value: JSX.Element; sort?: number },
  totalAmount: { value: JSX.Element; sort?: number },
  totalAmountTax: { value: JSX.Element; sort?: number },
  caKg: { value: JSX.Element; sort?: number },
  delivery_date: { value: JSX.Element },
  amountRequested: { value: JSX.Element },
  outstandingBalance: { value: JSX.Element },
  updatedAt: { value: JSX.Element; sort?: string },
  payment: { value: JSX.Element },
  notes: { value: JSX.Element },
  status: { value: JSX.Element; sort?: string },
  action: { value: JSX.Element },
): Data {
  return {
    id,
    customCheck,
    code,
    roundtripCode,
    roundtripPosition,
    warehouse,
    customerCode,
    priority,
    customer_code,
    customer_name,
    address,
    countLines,
    createdBy,
    salesman,
    quantity,
    weight,
    support_unit,
    supportUnitsRequestedCapacity,
    totalAmount,
    totalAmountTax,
    caKg,
    delivery_date,
    amountRequested,
    outstandingBalance,
    updatedAt,
    payment,
    notes,
    status,
    action,
  };
}
