import { SmileySad, Smiley, SmileyMeh } from '@phosphor-icons/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

interface Props {
  frequency: string;
  size?: number;
}

export const GetCustomerFrequency: React.FC<Props> = ({
  frequency,
  size = 25,
}) => {
  const theme = useSelector(selectTheme);

  switch (frequency) {
    case 'happy':
      return <Smiley size={size} color={theme.green3} />;
    case 'medium':
      return <SmileyMeh size={size} color={theme.iconColor} />;
    case 'sad':
      return <SmileySad size={size} color={theme.progressRed} />;
    default:
      return <SmileySad size={size} color={theme.progressRed} />;
  }
};
