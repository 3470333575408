import React, { useMemo, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Typography,
} from '@mui/material';
import NestedMenuItem from 'mui-nested-menu-item';
import NestedMenu from 'mui-nested-menu-item';
import styled from 'styled-components';
import { PopOverElement, PopoverEl } from '../Popover';
import { PopOverElWrapper } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CaretRight } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { NestedItemsComponent } from './NestedItemsComponent';
import { DotsThree } from '@phosphor-icons/react';
import { Icon } from '../Icon';

interface ActionsProps {
  seperator?: boolean;
  action: any;
  typeNotEdit?: any;
  element?: JSX.Element | null;
  nested?: boolean;
  actions?: any;
  actionNestedComponent?: any;
  actionNestedComponentExit?: any;
  title?: any;
  disableRipple?: any;
  onClick?: Function;
}

interface Props {
  handleAction?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: string,
    id: string | number,
  ) => void;
  id: string | number;
  anchorOriginVertical?: 'center' | 'top' | 'bottom' | number;
  anchorOriginHorizontal?: 'center' | 'left' | 'right' | number;
  transformOriginHorizontal?: 'center' | 'left' | 'right' | number;
  transformOriginVerical?: 'center' | 'top' | 'bottom' | number;
  allowTouchEvents?: boolean;
  actions: ActionsProps[];
  iconAction?: any;
  label?: string;
  handleActionclick?: Function;
  styling?: any;
  iconSx?: any;
  hover?: any;
  nested?: boolean;
  anchor?: any;
  content?: any;
  leftPosition?: boolean;
  setHover?: any;
  iconOpen?: JSX.Element;
  seperatorMargin?: string;
  keepMounted?: boolean;
}

export const NestedMenuComponent: React.FC<Props> = ({
  iconAction,
  id,
  allowTouchEvents,
  handleAction,
  actions,
  label,
  handleActionclick,
  anchorOriginVertical,
  anchorOriginHorizontal,
  transformOriginHorizontal,
  transformOriginVerical,
  styling,
  iconSx,
  hover,
  nested,
  anchor,
  content,
  leftPosition,
  setHover,
  iconOpen,
  seperatorMargin = '4px 10px',
  keepMounted,
}) => {
  const theme: any = useSelector(selectTheme);

  const [menuPosition, setMenuPosition] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | HTMLDivElement | null
  >(anchor);
  const open = Boolean(anchorEl);

  const handleItemClick = (event: React.MouseEvent) => {
    setMenuPosition(null);
  };

  const handleClick = (event: any) => {
    if (event.type === 'touchend') return;

    setAnchorEl(event.currentTarget);
  };
  const handleClose = e => {
    if (setHover) {
      setHover(false);
    }
    setAnchorEl(null);
  };

  const iconElement = useMemo(() => {
    const defaultIcon = (
      <Icon
        icon={<DotsThree weight="bold" />}
        color={open ? themes?.default?.primary : themes?.default?.iconColor}
        size={themes.default?.dotsIconSize}
      />
    );
    return open
      ? iconOpen || iconAction || defaultIcon
      : iconAction
        ? iconAction
        : defaultIcon;
  }, [iconAction, open, iconOpen]);

  return (
    <>
      {!nested && (
        <div
          style={{
            backgroundColor: open && label ? '#EEF2FF' : '',
            cursor: 'pointer',
          }}
          onMouseEnter={e => {
            if (hover) {
              handleClick(e);
            }
          }}
          onClick={e => {
            if (!hover) {
              e?.stopPropagation();
              handleClick(e);
            }
          }}
        >
          <PopoverEl redStatus={theme.redStatus}>{label}</PopoverEl>
          {iconElement}
        </div>
      )}
      <Menu
        open={!!anchorEl}
        anchorOrigin={{
          vertical: anchorOriginVertical ? anchorOriginVertical : 'bottom',
          horizontal: anchorOriginHorizontal ? anchorOriginHorizontal : 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: transformOriginVerical ? transformOriginVerical : 'top',
          horizontal: transformOriginHorizontal
            ? transformOriginHorizontal
            : 'right',
        }}
        anchorEl={anchorEl}
        onClose={e => handleClose(e)}
      >
        {actions.map((action, index) => {
          if (action?.element) {
            return (
              <div
                key={index}
                style={{ width: '100% !important' }}
                onClick={() => {
                  action.onClick && action.onClick();
                }}
              >
                {action?.nested ? (
                  // @ts-ignore
                  <MenuWrapper
                    label={action.element}
                    parentMenuOpen={Boolean(anchorEl)}
                    placeholder={''}
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                    sx={{
                      paddingLeft: '0 !important',
                      paddingRight: '32px !important',
                      width: 'fit-content',
                    }}
                    rightIcon={
                      <CaretRight
                        color={themes.default.iconColor}
                        size={14}
                        weight="bold"
                      />
                    }
                    disableRipple={action?.disableRipple ? true : false}
                  >
                    <NestedItemsComponent
                      action={action}
                      handleAction={handleAction}
                      handleActionclick={handleActionclick}
                      handleClose={keepMounted ? () => {} : e => handleClose(e)}
                      handleItemClick={handleItemClick}
                      id={id}
                      leftPosition={leftPosition}
                    />
                  </MenuWrapper>
                ) : (
                  <>
                    {action?.seperator ? (
                      <div style={{ margin: seperatorMargin }}>
                        {action.element}
                      </div>
                    ) : (
                      <MenuItem
                        onClick={handleItemClick}
                        sx={{ padding: '0 !important' }}
                        disableRipple={action?.seperator ? true : false}
                      >
                        <PopOverElement
                          primaryActiveColor={theme.primaryActiveColor}
                          className={
                            action?.seperator
                              ? 'seperator'
                              : action?.action === 'delete'
                                ? 'delete'
                                : ''
                          }
                          style={{ height: action?.seperator ? 'auto' : '' }}
                          onClick={e => {
                            //
                            // setAnchorEl(null);
                            if (handleAction)
                              handleAction(e, action.action, id);
                            if (handleActionclick)
                              handleActionclick(e, handleClose, action.action);
                            // if (action.functionCall) action.functionCall(id);
                            handleClose(e);
                          }}
                        >
                          <PopOverElWrapper>{action.element}</PopOverElWrapper>
                        </PopOverElement>
                      </MenuItem>
                    )}
                  </>
                )}
              </div>
            );
          }
        })}
      </Menu>
    </>
  );
};

const MenuWrapper = styled(NestedMenuItem)(props => ({
  width: '100% !important',

  '& li': {
    width: '100% !important',
    padding: '0 !important',
  },
  '& div': {
    width: '100% !important',
  },
  '&:hover': { background: themes.default.grey },
  '&:focus': { background: themes.default.grey },
}));
