import React from 'react';
import ManagedContext from '../../../../../../common/UtilityComponents/ManagedContext';
import FunctionalDepartments from './Components/FunctionalDepartments';
import { GridProvider } from '../../../../../components/Grid/context';

export const Departments = () => {
  return (
    <GridProvider>
      <ManagedContext
        contextName="departmentsContext"
        defaultValue={{
          openDrawer: false,
          selectedDepartment: null,
          editMode: false,
        }}
      >
        <FunctionalDepartments />
      </ManagedContext>
    </GridProvider>
  );
};
