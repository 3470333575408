import styled from 'styled-components';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { themes } from 'styles/theme/themes';
import Arrow from 'assets/img/arrow-right.svg';

const Position = styled.div<{ color: string }>`
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${props => props.color};
  border-radius: 50px;
  width: 27px;
  height: 20px;
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const PositionNumber = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem;
`;

export function StopPosition({ stop }: { stop: any }) {
  const color =
    stop.planningPosition !== stop.deliveryPosition ? '#EF9A9A' : '#A5D6A7';
  const isDelivered = !!stop.deliveryPosition;

  return (
    <Wrapper>
      <Position color={themes.default.gainsboro2}>
        <PositionNumber>{stop.planningPosition}</PositionNumber>
      </Position>
      {isDelivered && (
        <>
          <img src={Arrow} alt="Arrow" />
          <Position color={color}>
            <PositionNumber>{stop.deliveryPosition}</PositionNumber>
          </Position>
        </>
      )}
    </Wrapper>
  );
}

export default StopPosition;
