import { useContext, useState } from 'react';
import { useDeleteAreaMutation } from 'common/services/storageLocationApi';
import { useApiCalls } from '../../hooks/useApiCalls';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StorageLocationContext } from '../../../StorageLocationProvider';
import { useTranslation } from 'react-i18next';
import { useToaster } from 'hooks/useToaster';
import styled from 'styled-components';

export const DeleteArea = ({ currentTabAction }) => {
  const { refetchAreas } = useApiCalls();

  const { closeModal } = useContext(StorageLocationContext);
  const { t } = useTranslation();
  const [deleteAreaMutation] = useDeleteAreaMutation();
  const toast = useToaster();

  const deleteArea = async () => {
    try {
      await deleteAreaMutation({
        areaId: currentTabAction._id,
      }).unwrap();
      toast(
        5000,
        'success',
        `${
          currentTabAction.name +
          ' ' +
          t('common.dialog.delete.toaster_success')
        }`,
      );
    } catch (e) {}
    closeModal();
    refetchAreas();
  };

  return (
    <Dialog
      open={true}
      fullWidth
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <strong>
            {t('common.buttons.dialogue_delete') + ' ' + currentTabAction?.name}
          </strong>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </>
      <DialogContent dividers>
        <>
          {`${
            t('common.dialog.delete.initial_title') +
            ' ' +
            currentTabAction?.name +
            '? ' +
            t('storage.areas.delete_initial_title')
          }`}
        </>
      </DialogContent>
      <DialogActions style={{ marginBottom: '22px', marginRight: '14px' }}>
        <Button onClick={closeModal} color="inherit">
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={deleteArea}
          disabled={false}
          variant="contained"
          autoFocus
          sx={{
            height: '33px',
            width: '88px',
          }}
          color="error"
        >
          {t('common.buttons.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
