import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.tools || initialState;

export const selectIsZoomedIn = createSelector(
  [selectSlice],
  state => state.zoomIn,
);

export const selectMode = createSelector([selectSlice], state => state.mode);

export const selectAuth = createSelector([selectSlice], state => state);
