import React, { useMemo } from 'react';
import { themes } from 'styles/theme/themes';
import { ActionPopover, PopoverEl } from 'app/components/Popover';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ActionsHandleProps } from 'app/components/Chat/components/ChatWindow';

interface Props {
  messageUuid: string;
  messageContent: string;
  handleMessageActions: (action: ActionsHandleProps) => void;
  canEdit: boolean;
  canDownload?: boolean;
  canView?: boolean;
  canReply?: boolean;
  canSelect?: boolean;
  fileName?: string;
  fileType?: string;
}

export const MessageOptionsMenu: React.FC<Props> = ({
  messageUuid,
  messageContent,
  handleMessageActions,
  canEdit,
  canDownload,
  canView,
  canReply,
  canSelect,
  fileName = 'Untitled',
  fileType = '',
}) => {
  const { t } = useTranslation();

  const actions = useMemo(() => {
    const result: any = [];

    if (canEdit) {
      result.push({
        action: 'edit',
        onClick: () => {},
        element: (
          <PopoverEl redStatus="" style={{ width: '100%' }}>
            <div className="popover-item">{t('common.edit')}</div>
          </PopoverEl>
        ),
      });
    }

    if (canReply) {
      result.push({
        action: 'reply',
        onClick: () => {},
        element: (
          <PopoverEl redStatus="" style={{ width: '100%' }}>
            <div className="popover-item">{t('chat.message.reply')}</div>
          </PopoverEl>
        ),
      });
    }

    // canCopy
    if (true) {
      result.push({
        action: 'copy',
        onClick: () => {},
        element: (
          <PopoverEl redStatus="" style={{ width: '100%' }}>
            <div className="popover-item">{t('common.copy')}</div>
          </PopoverEl>
        ),
      });
    }

    if (canSelect) {
      result.push({
        action: 'select',
        onClick: () => {},
        element: (
          <PopoverEl redStatus="" style={{ width: '100%' }}>
            <div className="popover-item">{t('shortcuts.select')}</div>
          </PopoverEl>
        ),
      });
    }

    if (canView) {
      result.push({
        action: 'view',
        onClick: () => {},
        element: (
          <PopoverEl redStatus="" style={{ width: '100%' }}>
            <div className="popover-item">{t('view')}</div>
          </PopoverEl>
        ),
      });
    }

    if (canDownload) {
      result.push({
        action: 'download',
        onClick: () => {},
        element: (
          <PopoverEl redStatus="" style={{ width: '100%' }}>
            <div className="popover-item">{t('common.download')}</div>
          </PopoverEl>
        ),
      });
    }

    // separator
    if (result.length > 0) {
      result.push({
        action: 'divider',
        seperator: true,
        element: (
          <PopoverEl redStatus="" style={{ width: '100%' }}>
            <Divider />
          </PopoverEl>
        ),
      });
    }

    // delete
    result.push({
      action: 'delete',
      onClick: () => {},
      element: (
        <PopoverEl
          redStatus={themes.default.redStatus}
          style={{ width: '100%' }}
        >
          <div className="delete popover-item">{t('delete')}</div>
        </PopoverEl>
      ),
    });

    return result;
  }, [canEdit, canDownload]);

  return (
    <>
      <ActionPopover
        id={messageUuid}
        handleAction={async (e, action, id) =>
          handleMessageActions({
            actionType: action,
            messageUuid: `${id}`,
            messageContent: messageContent,
            fileName: fileName,
            fileType: fileType,
            canEdit: canEdit,
          })
        }
        styling={{ justifyContent: 'end' }}
        iconSize={24}
        actions={actions}
      />
    </>
  );
};
