import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import ImageBackground from '../../../assets/img/login/background-image.svg';

export const Wrapper = styled.div(props => ({
  height: 'calc(100vh - 5px)',
  width: '100%',
  background: `url(${ImageBackground})`,
  backgroundSize: 'cover',
  marginTop: `-${props.theme.topBarHeight}`,
  backgroundPosition: `35%`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const LoginText = styled.div`
  font-weight: 700;
  font-size: 2.25rem;
  color: ${themes?.default?.accordionWhiteBg};
`;

export const LoginDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px;
  img {
    max-height: 325px;
  }
  a {
    text-decoration: none;
    color: ${themes?.default?.textColorPrimary};
  }
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;
export const EmailText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 19px;
  color: ${themes?.default?.greyDisabled};
  position: absolute;
  right: 10px;
`;
export const LoginDescription = styled.div`
  color: ${themes?.default?.accordionWhiteBg};
  font-size: 1.125rem;
  font-weight: 700;
  width: 477px;
`;
export const LoginDescriptionOption = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  color: ${themes?.default?.textColorPrimary};
  text-align: center;
  margin-right: 1%;
`;

export const SingupText = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  color: ${themes?.default?.accordionWhiteBg};
`;

export const LoginContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 10%;
  margin-top: 20px;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    margin: 0 10%;
    .signupName {
      margin-bottom: 150px !important;
      position: relative;
      bottom: 51px;
    }
    .displayNone {
      display: none;
    }
  }
`;

export const Singup = styled.div`
  background-color: ${themes?.default?.accordionWhiteBg};
  font-size: 1rem;
  font-weight: 700;
  padding: 10px;
  width: fit-content;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
`;

export const LoginTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const InputFieldWrapper = styled.div`
  .hidden {
    height: 0px;
    margin-top: 0px;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
  }
  .expanded {
    height: 64px;
    transform-origin: 50% 0px;
    transition-property: visibility, height, margin-top, opacity, transform;
    transition-duration: 0s, 0.2s, 0.2s, 0.2s;
    transition-timing-function: ease-in-out;
  }
  p {
    margin-top: 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 14px;
    color: ${themes?.default?.black};
    margin-bottom: 0;
  }
  a {
    font-weight: bold;
    color: black;
  }
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  & input {
    background-color: ${p => p.theme.greyBgInput};
    border: unset;
    height: 40px;
  }
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 0.75rem;
    line-height: 14px;
    color: ${themes?.default?.black};
  }
  a {
    font-weight: bold;
    color: black;
  }
  label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 299;
    font-size: 1rem;
    line-height: 19px;
    color: ${themes?.default?.textColorPrimary};
  }
`;
export const MobileLoginDescription = styled.div`
  display: none;
  @media only screen and (max-width: 1000px) {
    display: block;
    color: black;
    img {
      width: 100%;
      margin: 0px auto;
    }
    .CreatePresence {
      position: relative;
      z-index: 10;
      bottom: 65px;
      background: ${themes?.default?.accordionWhiteBg};
      margin: 10px auto;
      width: 100%;
      padding: 15px;
      max-width: 420px;
    }
    div {
      color: black !important;
    }
    .title {
      font-weight: 700;
      font-size: 1.063rem;
      line-height: 20px;
      color: ${themes?.default?.textColorPrimary};
      margin-bottom: 10px;
    }
    .text {
      margin-bottom: 10px;
      font-weight: 300;
      font-size: 1rem;
      line-height: 19px;
      color: ${themes?.default?.textColorPrimary};
      width: 100%;
    }
  }
`;
export const LoginFormWrapper = styled.div`
  padding-bottom: 50px;
  background-color: ${themes?.default?.accordionWhiteBg};
  height: fit-content;
  max-width: 500px;
  width: 438px;
  box-shadow: 0px 0.125rem 15px rgba(139, 139, 139, 0.1);
  @media only screen and (max-width: 1000px) {
    margin-top: 60px;
    margin: auto;
    width: 100%;
  }
`;

export const ActionButton = styled.div`
  display: none;
  @media only screen and (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 15px auto;
    div {
      color: black;
    }
    .text {
      color: black;
    }
    a {
      text-decoration: none;
      color: black;
    }
  }
`;

export const CustomButton = styled.button<{ theme: any }>`
  cursor: pointer;
  background: ${props => props?.theme?.primaryActiveColor};
  border-radius: 6px;
  width: 100%;
  height: 35px;
  border: none;
  margin-top: 30px;
  color: ${themes?.default?.accordionWhiteBg};
  ::before,
  ::after {
    box-sizing: none;
  }
`;

export const LoginFormContent = styled.div`
  padding: 0 40px;
  .disabled_button {
    background: ${themes?.default?.greyDisabled};
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BreakingLine = styled.div`
  position: relative;
  border-bottom: 0.019rem solid ${p => p.theme.textColorSecondary};
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .text {
    position: absolute;
    bottom: -11px;
    background-color: ${themes?.default?.accordionWhiteBg};
    width: 30px;
    display: flex;
    justify-content: center;
  }
`;

export const SocialMediaLoginField = styled.div`
  height: 40px;
  border: 0.063rem solid ${p => p.theme.textColorSecondary};
  border-radius: 6px;
  display: flex;
  align-items: center;
  column-gap: 33px;
  img {
    padding-left: 40px;
  }
`;

export const SocialMediaLoginFieldWrapper = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const SocialMediaText = styled.div`
  font-size: 1rem;
  @media only screen and (max-width: 1000px) {
    font-size: 0.7rem;
  }
`;

export const Input = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  input {
    flex: 1;
    padding-right: 40px;
    width: 100%;
    padding-left: 14px;
    height: 49px;
    border-radius: 0 !important;
  }
  .true_code {
    border: 1px solid ${themes.dark.green} !important;
  }
  input:focus {
    outline: none;
  }
  input.true:focus {
    border-bottom: 0.125rem solid ${themes?.default.primary};
  }
`;

export const TimerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 1000px) {
    display: flex;
    align-items: center;
    width: 50%;
    margin: 40px auto;
    div {
      width: 50%;
      margin: 40px auto;
      color: black;
    }
  }
`;
