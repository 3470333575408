import api from './api';
import {
  AddRoundtrip,
  AddRoundtripApi,
  AddRoundtripUpdate,
  RoundtripInitOrderRequest,
  RoundtripInitRequest,
  RoundtripInitResponse,
  RoundtripUpdate,
  RoundtripUpdateStatusRequest,
  orderDelete,
} from '../types/Roundtrip';
import { timeZoneDate } from '../utils/dates';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { getRoundtripByAgentDto } from '../Dtos/mobile/getRoundtripByAgentDto';
import { getRoundTripListDto } from '../Dtos/mobile/getRoundTripListDto';
import { getRoundTripsClusterDto } from '../Dtos/mobile/getRoundTripsClusterDto';

const MAX_RETRY = 1200;

export interface CalendarApiResponse {
  [key: string]: { active: number; inactive: number };
}

const createStopUpdateApiEndpoint = () => ({
  query: ({ id, stopId, date, ...body }: any) => {
    return { url: `/roundtrips/${id}/stop/${stopId}`, method: 'PATCH', body };
  },
  extraOptions: { maxRetries: MAX_RETRY },
  invalidatesTags: ['RoundtripsStats'],
  onQueryStarted: async (
    { id, stopId, date, ...patch }: any,
    { dispatch, queryFulfilled }: any,
  ) => {
    let patchResult;
    patchResult = dispatch(
      roundtripApis.util.updateQueryData(
        'getRoundTripsCluster',
        patch.urlQuery,
        draft => {
          draft.roundtrips = draft.roundtrips.map(rt => {
            if (rt._id == id) {
              rt.stops = rt.stops.map(stop => {
                if (stop._id == stopId) {
                  return {
                    ...stop,
                    status: patch.status,
                    _order: { ...stop._order, status: patch.status },
                  };
                }
                return stop;
              });
              return rt;
            }
            return rt;
          });
        },
      ),
    );
    try {
      const { data: updatedPost } = await queryFulfilled;
      patchResult = dispatch(
        roundtripApis.util.updateQueryData(
          'getRoundtripByAgent',
          // '2023-07-24',
          timeZoneDate(date),
          draft => {
            const updatedData = Object.assign({}, draft, updatedPost);
            const updatedData1 = {
              ...draft,
              ...updatedPost,
            };
            return updatedData1;
          },
        ),
      );
      dispatch(
        roundtripApis.util.updateQueryData(
          'getRoundTripDetails',
          // '2023-07-24',
          { roundtripId: id },
          draft => {
            const updatedData = Object.assign({}, draft, updatedPost);
            const updatedData1 = {
              ...draft,
              ...updatedPost,
            };
            return updatedData1;
          },
        ),
      );
    } catch {
      patchResult?.undo();
    }
  },
});

const createStopUpdateMobileApiEndpoint = () => ({
  query: ({ id, stopId, date, ...body }: any) => {
    return { url: `/roundtrips/${id}/stop/${stopId}`, method: 'PATCH', body };
  },
  invalidatesTags: ['RoundtripsStats'],
  onQueryStarted: async (
    { id, stopId, date, status }: any,
    { dispatch, queryFulfilled }: any,
  ) => {
    let patchResult;
    patchResult = dispatch(
      roundtripApis.util.updateQueryData('getRoundTripList', {}, draft => {
        draft?.docs?.map(rt => {
          if (rt._id == id) {
            rt.stops = rt.stops.map(stop => {
              if (stop._id == stopId) {
                console.log('status update ', status);
                return {
                  ...stop,
                  status: status,
                };
              }
              return stop;
            });
            return rt;
          }
          return rt;
        });
      }),
    );
    try {
      const { data: updatedPost } = await queryFulfilled;
      patchResult = dispatch(
        roundtripApis.util.updateQueryData(
          'getRoundTripDetails',
          { roundtripId: id },
          draft => {
            draft.stops = draft.stops.map(stop => {
              if (stop._id === stopId) {
                return {
                  ...stop,
                  status: status,
                };
              }
              return stop;
            });
            return draft;
          },
        ),
      );
    } catch {
      patchResult?.undo();
    }
  },
});

const createUncancelStopApiEndpoint = () => ({
  query: ({ id, stopId, date, ...body }: any) => {
    return {
      url: `/roundtrips/${id}/stop/${stopId}/uncancelStop`,
      method: 'PATCH',
      body,
    };
  },
  invalidatesTags: ['RoundtripsStats'],
  onQueryStarted: async (
    { id, stopId, date, status }: any,
    { dispatch, queryFulfilled }: any,
  ) => {
    let patchResult;
    patchResult = dispatch(
      roundtripApis.util.updateQueryData('getRoundTripList', {}, draft => {
        draft?.docs?.map(rt => {
          if (rt._id == id) {
            rt.stops = rt.stops.map(stop => {
              if (stop._id == stopId) {
                return {
                  ...stop,
                  status: status,
                };
              }
              return stop;
            });
            return rt;
          }
          return rt;
        });
      }),
    );
    try {
      const { data: updatedPost } = await queryFulfilled;
      patchResult = dispatch(
        roundtripApis.util.updateQueryData(
          'getRoundTripDetails',
          { roundtripId: id },
          draft => {
            draft.stops = draft.stops.map(stop => {
              if (stop._id === stopId) {
                return {
                  ...stop,
                  status: status,
                };
              }
              return stop;
            });
            return draft;
          },
        ),
      );
    } catch {
      patchResult?.undo();
    }
  },
});

const addAccessItemApiEndpoint = () => ({
  query: ({ id, stopId, itemType, data, ...body }: any) => {
    return {
      url: `/roundtrips/${id}/stop/${stopId}/${itemType}`,
      method: 'PATCH',
      body: data,
    };
  },
  invalidatesTags: [],
});

export const roundtripApis = api.injectEndpoints({
  endpoints: builder => ({
    postInit: builder.mutation<RoundtripInitResponse, RoundtripInitRequest>({
      query: body => ({
        url: '/roundtrips/init',
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    postInitOrder: builder.mutation<any, RoundtripInitOrderRequest>({
      query: body => ({
        url: `/roundtrips/init-orders${body?.query}`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    getRoundTrips: builder.query<any, string>({
      query: pagerows => `roundtrips?${pagerows}`,
      providesTags: ['Roundtrips'],
      // providesTags: (result) =>[{ type: 'Users' }],
    }),
    getRoundTripsClusterPost: builder.mutation<
      any,
      { pagerows: any; excludeIds: any }
    >({
      query: ({ pagerows, excludeIds }) => ({
        url: `/roundtrips/clusters${pagerows}`,
        method: 'POST',
        body: { excludeIds: excludeIds },
      }),
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    getRoundTripsCluster: builder.query<any, string>({
      query: pagerows => `/roundtrips/clusters${pagerows}`,
      providesTags: ['Roundtrips'],
      // providesTags: (result) =>[{ type: 'Users' }],
      transformResponse: (response: any, meta, arg) => {
        const urlParams = new URLSearchParams(arg);
        const dto = urlParams.get('dto');
        if (dto === 'mobile') return getRoundTripsClusterDto(response);
        return response;
      },
    }),
    getRoundTripsClusterStats: builder.query<any, any>({
      query: ({ clusterId }) => `/roundtrips/clusters/${clusterId}/stats`,
      providesTags: ['Roundtrips'],
      // providesTags: (result) =>[{ type: 'Users' }],
    }),
    roundtripUpdateStatus: builder.mutation<any, RoundtripUpdateStatusRequest>({
      query: body => ({
        url: `/roundtrips/status/${body?.status}`,
        method: 'POST',
        body,
      }),
      extraOptions: { maxRetries: MAX_RETRY },
      onQueryStarted: async (
        { roundtripIds, pageRows, ...patch }: any,
        { dispatch, queryFulfilled }: any,
      ) => {
        let patchResult;
        patchResult = dispatch(
          roundtripApis.util.updateQueryData(
            'getRoundTripsCluster',
            pageRows,
            draft => {
              draft.roundtrips = draft.roundtrips.map(rt => {
                if (rt._id == roundtripIds || roundtripIds?.includes(rt._id)) {
                  return { ...rt, status: patch.status };
                }
                return rt;
              });
            },
          ),
        );
        let patchResult1 = dispatch(
          roundtripApis.util.updateQueryData('getRoundTripList', {}, draft => {
            if (!draft) return;
            draft.docs = draft.docs.map(rt => {
              if (rt._id == roundtripIds || roundtripIds?.includes(rt._id)) {
                return {
                  ...rt,
                  status: patch.status,
                  _updatedStatusBy: patch._updatedStatusBy,
                };
              }
              return rt;
            });
          }),
        );

        let patchResult2 = dispatch(
          roundtripApis.util.updateQueryData(
            'getRoundTripDetails',
            {
              roundtripId: roundtripIds,
            },
            draft => {
              draft.status = patch.status;
              draft._updatedStatusBy = patch._updatedStatusBy;
              return draft;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
          patchResult1?.undo();
          patchResult2?.undo();
        }
      },
    }),
    updateRoundTripsCluster: builder.mutation<any, AddRoundtripUpdate>({
      query: body => ({
        url: `/roundtrips/clusters/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Roundtrips'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    deleteOrderCluster: builder.mutation<any, orderDelete>({
      query: body => ({
        url: `/roundtrips/clusters/${body.id}/unlink-orders`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Roundtrips', 'OrderFacets'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    addOrderCluster: builder.mutation<any, orderDelete>({
      query: body => ({
        url: `/roundtrips/clusters/${body.id}/link-orders`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Roundtrips', 'OrderFacets'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    deleteCluster: builder.mutation<any, { id: string }>({
      query: id => ({
        url: `roundtrips/clusters/${id.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Roundtrips'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    updateRoundTrip: builder.mutation<any, RoundtripUpdate>({
      query: body => ({
        url: `/roundtrips/${body.id}`,
        method: 'PATCH',
        body: body.data,
      }),
      invalidatesTags: ['Roundtrips', 'InitOrders'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    addStopRoundtrip: builder.mutation<any, RoundtripUpdate>({
      query: body => ({
        url: `/roundtrips/${body.id}/stops`,
        method: 'POST',
        body: body.data,
      }),
      extraOptions: { maxRetries: MAX_RETRY },
      onQueryStarted: async (
        { id, pageRows, ...patch }: any,
        { dispatch, queryFulfilled }: any,
      ) => {
        let patchResult;
        try {
          const { data: updatedPost } = await queryFulfilled;
          patchResult = dispatch(
            roundtripApis.util.updateQueryData(
              'getRoundTripsCluster',
              pageRows,
              draft => {
                draft.roundtrips = draft.roundtrips.map(rt => {
                  if (rt._id == id) {
                    return { ...updatedPost };
                  }
                  return rt;
                });
              },
            ),
          );
        } catch {
          patchResult?.undo();
        }
      },
    }),
    deleteRoundTrip: builder.mutation<any, { id: string }>({
      query: id => ({
        url: `/roundtrips/${id.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Roundtrips', 'InitOrders'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    getRoundtripByAgent: builder.query<any, string>({
      query: date => `roundtrips/by-agent?date=${date}`,
      providesTags: ['Roundtrips'],
      transformResponse: (response: any, meta, arg) => {
        return getRoundtripByAgentDto(response);
      },
    }),
    getRoundtripByAgentStats: builder.query<any, string>({
      query: date => `roundtrips/by-agent/stats?date=${date}`,
      providesTags: ['Roundtrips', 'RoundtripsStats'],
    }),
    addRoundTrips: builder.mutation<any, AddRoundtrip>({
      query: body => ({
        url: '/roundtrips',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Roundtrips'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    roundtripAddApi: builder.mutation<any, AddRoundtripApi>({
      query: body => ({
        url: '/roundtrips/api',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Roundtrips'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    roundtripUpdateApi: builder.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `/roundtrips/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['RoundtripsStats'],
    }),
    roundtripAddStopApi: builder.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `/roundtrips/${id}/stops`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RoundtripsStats'],
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    getRoundtripsNotes: builder.query<
      any,
      { roundtripId: string; stopId: string }
    >({
      query: ({ roundtripId, stopId }) => ({
        url: `roundtrips/${roundtripId}/notes?stopId=${stopId}`,
        method: 'GET',
        providesTags: ['Roundtrips'],
      }),
    }),
    createRoundtripsNote: builder.mutation<
      void,
      { roundtripId: string; content: any; stopId: string }
    >({
      query: ({ roundtripId, content, stopId }) => ({
        url: `roundtrips/${roundtripId}/notes`,
        method: 'POST',
        body: { content, stopId },
      }),
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    reorderStops: builder.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `/roundtrips/${id}/stops/reorder`,
        method: 'PATCH',
        body,
      }),
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    onWay: builder.mutation<any, any>({
      query: ({ id, date, ...body }) => ({
        url: `/roundtrips/status/on-way`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
      async onQueryStarted({ date, ...patch }, { dispatch, queryFulfilled }) {
        if (patch.disableCacheUpdate) {
          return;
        }
        try {
          const { data: updatedPost } = await queryFulfilled;
          const patchResult = dispatch(
            roundtripApis.util.updateQueryData(
              'getRoundtripByAgent',
              // '2023-07-24',
              timeZoneDate(date),
              draft => {
                const updatedData = Object.assign({}, draft, updatedPost[0]);
                const updatedData1 = {
                  ...draft,
                  ...updatedPost[0],
                };
                return updatedData1;
              },
            ),
          );
        } catch {}
      },
    }),
    paused: builder.mutation<any, any>({
      query: ({ id, date, ...body }) => ({
        url: `/roundtrips/status/paused`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
      async onQueryStarted({ date, ...patch }, { dispatch, queryFulfilled }) {
        if (patch.disableCacheUpdate) {
          return;
        }
        try {
          const { data: updatedPost } = await queryFulfilled;
          const patchResult = dispatch(
            roundtripApis.util.updateQueryData(
              'getRoundtripByAgent',
              // '2023-07-24',
              timeZoneDate(date),
              draft => {
                const updatedData = Object.assign({}, draft, updatedPost[0]);
                const updatedData1 = {
                  ...draft,
                  ...updatedPost[0],
                };
                return updatedData1;
              },
            ),
          );
        } catch {}
      },
    }),
    deleteRoundtripsNote: builder.mutation<
      any,
      { roundtripId: string; noteId: string }
    >({
      query: ({ roundtripId, noteId }) => ({
        url: `roundtrips/${roundtripId}/notes/${noteId}`,
        method: 'DELETE',
      }),
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    patchRoundtripsNote: builder.mutation<
      any,
      { roundtripId: string; noteId: string; content: any }
    >({
      query: ({ roundtripId, noteId, content }) => ({
        url: `roundtrips/${roundtripId}/notes/${noteId}`,
        method: 'PATCH',
        body: { content },
      }),
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    stopUpdateApi: builder.mutation<any, any>(createStopUpdateApiEndpoint()),
    stopUpdateMobileApi: builder.mutation<any, any>(
      createStopUpdateMobileApiEndpoint(),
    ),
    UncancelStop: builder.mutation<any, any>(createUncancelStopApiEndpoint()),

    addAccessItemApiEndpoint: builder.mutation<any, any>(
      addAccessItemApiEndpoint(),
    ),

    updateCancelationReasonApi: builder.mutation<{}, any>({
      query: ({ id, stopId, ...body }) => ({
        url: `/roundtrips/${id}/stop/${stopId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [],
      async onQueryStarted(
        { id, date, ...patch },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          const patchResult = dispatch(
            roundtripApis.util.updateQueryData(
              'getRoundtripByAgent',
              // '2023-07-24',
              timeZoneDate(date),
              draft => {
                const updatedData = Object.assign({}, draft, updatedPost);
                const updatedData1 = {
                  ...draft,
                  ...updatedPost,
                };
                return updatedData1;
              },
            ),
          );
        } catch {}
      },
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    endTripApi: builder.mutation<{}, any>({
      query: ({ date, ...body }) => ({
        url: `/roundtrips/status/parked`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RoundtripsStats'],
      async onQueryStarted({ date, ...patch }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          const patchResult = dispatch(
            roundtripApis.util.updateQueryData(
              'getRoundtripByAgent',
              // '2023-07-24',
              timeZoneDate(date),
              draft => {
                const updatedData = Object.assign({}, draft, updatedPost[0]);
                const updatedData1 = {
                  ...draft,
                  ...updatedPost[0],
                };
                return updatedData1;
              },
            ),
          );
        } catch {}
      },
      extraOptions: { maxRetries: MAX_RETRY },
    }),
    getWarehousesRoundtrip: builder.query<any, string>({
      query: pagerows => `/roundtrips/decks${pagerows}`,
      providesTags: ['Roundtrips'],
      // providesTags: (result) =>[{ type: 'Users' }],
    }),

    deleteStop: builder.mutation<any, { roundtripId: string; stopId: string }>({
      query: ({ roundtripId, stopId }) => ({
        url: `roundtrips/${roundtripId}/stop/${stopId}`,
        method: 'DELETE',
      }),
      extraOptions: { maxRetries: MAX_RETRY },
      invalidatesTags: ['InitOrders', 'OrderFacets'],
    }),
    getRoundTripDetails: builder.query<
      any,
      { roundtripId?: string; clusterId?: string; context?: string }
    >({
      query: ({ roundtripId, clusterId, context }) => ({
        url: `roundtrips/${roundtripId}?_cluster=${clusterId}&limit=1000&context=${context}`,
        method: 'GET',
      }),
      providesTags: ['Roundtrips', 'RoundtripDetails'],
    }),

    getRoundTripList: builder.query<
      any,
      { clusterId?: string; context?: string }
    >({
      query: ({ clusterId, context }) => ({
        url: `roundtrips?_cluster=${clusterId}&limit=1000&context=${context}`,
        method: 'GET',
      }),
      providesTags: ['Roundtrips', 'RoundtripDetails'],
      transformResponse: (response: any, meta, arg) => {
        return getRoundTripListDto(response);
      },
    }),

    getInitOrder: builder.query<any, { query: string }>({
      query: ({ query }) => ({
        url: `roundtrips/init-orders${query}`,
        method: 'GET',
      }),
      providesTags: ['InitOrders'],
    }),
    getRoundtripsActivities: builder.query<any, void>({
      query: () => ({
        url: `roundtrips/activities`,
        method: 'GET',
      }),
      providesTags: ['RoundtripsStats', 'Roundtrips'],
    }),
    getEmployeeRoundtripsForCalendar: builder.query<
      CalendarApiResponse,
      { query: string }
    >({
      query: ({ query }) => ({
        url: `roundtrips/clusters/employeeCalendar${query}`,
        method: 'GET',
      }),
      providesTags: ['RoundtripsCalendar'],
    }),
    getRoundtripsForCalendar: builder.query<
      CalendarApiResponse,
      { query: string }
    >({
      query: ({ query }) => ({
        url: `roundtrips/clusters/calendar${query}`,
        method: 'GET',
      }),
      providesTags: ['RoundtripsCalendar'],
    }),
    calculateRoundtripRoute: builder.mutation<void, { roundtripId: string }>({
      query: ({ roundtripId }) => ({
        url: `roundtrips/${roundtripId}/calculate-route`,
        method: 'POST',
        body: {},
      }),
      extraOptions: { maxRetries: 0 },
    }),
    checkConfirmationCode: builder.query<
      any,
      { orderId: string; code: string }
    >({
      query: ({ orderId, code }) => ({
        url: `orders/${orderId}/check-confirmation-code?code=${code}`,
        method: 'GET',
      }),
    }),
    postTemplate: builder.mutation<any, any>({
      query: body => ({
        url: 'roundtriptemplates',
        method: 'POST',
        body,
      }),
    }),
    getSupportUnit: builder.query<
      any,
      { roundtripId?: string; stopId?: string }
    >({
      query: ({ roundtripId, stopId }) => ({
        url: `roundtrips/${roundtripId}/${stopId}/support-unit-locations`,
        method: 'GET',
      }),
      providesTags: ['Roundtrips', 'RoundtripDetails'],
    }),
    getSupportUnitRoundtrip: builder.query<
      any,
      { roundtripId?: string; stopId?: string }
    >({
      query: ({ roundtripId, stopId }) => ({
        url: `roundtrips/${roundtripId}/support-unit-locations`,
        method: 'GET',
      }),
      providesTags: ['Roundtrips', 'RoundtripDetails'],
    }),
    getDockerNotifications: builder.query<any, { lastIdReceived?: string }>({
      query: ({ lastIdReceived }) => ({
        url: `roundtrips/dockerNotifications?id=${lastIdReceived}`,
        method: 'GET',
      }),
      providesTags: ['Roundtrips', 'RoundtripDetails'],
    }),

    getDockerCountNotifications: builder.query<any, any>({
      query: () => ({
        url: `/roundtrips/notificationsCount`,
        method: 'GET',
      }),
      providesTags: ['Roundtrips', 'RoundtripDetails'],
    }),

    getAgentActivities: builder.query<any, { date: any }>({
      query: ({ date }) => ({
        url: `/roundtrips/by-agent/stats?date=${date}&agentcategory=logistics`,
        method: 'GET',
      }),
      providesTags: ['Roundtrips', 'RoundtripDetails'],
    }),

    getManagerActivities: builder.query<
      any,
      { salesCluster: string; logisticCluster: string }
    >({
      query: ({ salesCluster, logisticCluster }) => ({
        url: `/roundtrips/clustersCount?salesCluster=${salesCluster}&logisticCluster=${logisticCluster}`,
        method: 'GET',
      }),
      providesTags: ['Roundtrips', 'RoundtripDetails'],
    }),
    getDockerActivities: builder.query<
      any,
      { salesCluster: string; logisticCluster: string }
    >({
      query: ({ salesCluster, logisticCluster }) => ({
        url: `/roundtrips/clusterDockerCount?salesCluster=${salesCluster}&logisticCluster=${logisticCluster}`,
        method: 'GET',
      }),
      extraOptions: {
        refetchOnMountOrArgChange: true,
      },
      providesTags: ['Roundtrips', 'RoundtripDetails'],
    }),
    postDockerReadNotifications: builder.mutation<any, any>({
      query: body => ({
        url: '/roundtrips/readNotification',
        method: 'POST',
        body,
      }),
    }),

    postAddSupportUnitsCollected: builder.mutation<any, any>({
      query: body => ({
        url: `/roundtrips/${body.roundtripId}/confirm-collected`,
        method: 'POST',
        body,
      }),
    }),
    postSupportUnit: builder.mutation<any, any>({
      query: body => ({
        url: `roundtrips/${body.roundtripId}/stops/${body.stopsId}/support-unit-location`,
        method: 'POST',
        body,
      }),
    }),
    deleteSupportUnit: builder.mutation<any, any>({
      query: body => {
        return {
          url: `orders/${body.orderId}/delete-support-unit`,
          method: 'POST',
          body,
        };
      },
    }),
    postRoundtripMoveTruck: builder.mutation<any, any>({
      query: body => ({
        url: `roundtrips/moveTruck/${body.roundtripId}?authOrganization=${body.authOrganization}`,
        method: 'POST',
        body,
      }),
      onQueryStarted: async (body: any, { dispatch, queryFulfilled }: any) => {
        let patchAllRoundtrips = dispatch(
          roundtripApis.util.updateQueryData(
            'getRoundTripList',
            { clusterId: body.clusterId },
            draft => {
              if (!draft) return;
              draft.docs = draft.docs.map(rt => {
                if (
                  rt._id == body.roundtripId ||
                  body?.roundtripId?.includes(rt._id)
                ) {
                  return {
                    ...rt,
                    truckMoved: true,
                  };
                }
                return rt;
              });
            },
          ),
        );

        let patchRoundtripDetails = dispatch(
          roundtripApis.util.updateQueryData(
            'getRoundTripDetails',
            {
              roundtripId: body.roundtripId,
            },
            draft => {
              draft.truckMoved = true;
              return draft;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchAllRoundtrips?.undo();
          patchRoundtripDetails?.undo();
        }
      },
    }),

    getTemplatesRoundtrips: builder.query<
      any,
      { _deck: string; agentCategory: AgentCategories }
    >({
      query: ({ _deck, agentCategory }) => ({
        url: `roundtriptemplates?_deck=${_deck}&agentCategory=${agentCategory}`,
        method: 'GET',
      }),
    }),
    createRoundtripFromTemplate: builder.mutation<any, any>({
      query: body => ({
        url: 'roundtriptemplates/createroundtrips',
        method: 'POST',
        body,
      }),
    }),
    getClientsOrders: builder.query<any, { query: string }>({
      query: ({ query }) => ({
        url: `roundtrips/all-orders/${query}`,
        method: 'GET',
      }),
    }),

    deleteMultiStops: builder.mutation<any, any>({
      query: ({ roundtripIds, stopIds }) => ({
        url: `roundtrips/${roundtripIds}/delete`,
        method: 'POST',
        body: {
          stopIds,
        },
      }),
    }),
    importFileValidate: builder.mutation<any, FormData>({
      query: body => ({
        url: 'roundtrips/upload/validate-import',
        method: 'POST',
        body,
      }),
    }),
    fileImport: builder.mutation<unknown, FormData>({
      query: body => ({
        url: 'roundtrips/import',
        method: 'POST',
        body,
      }),
    }),
    getDriverLocations: builder.query<any, { tripId: string }>({
      query: ({ tripId }) => ({
        url: `roundtrips/${tripId}/driver-locations`,
        method: 'GET',
      }),
    }),
    getWarehouseInfo: builder.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `roundtrips/clusters/${id}/stats`,
        method: 'GET',
      }),
    }),
    getMapMarkersData: builder.query<any, { id: string; params: any }>({
      query: ({ id, params }) => ({
        url: `roundtrips/clusters/${id}/map${params}`,
        method: 'GET',
      }),
    }),
    validateAddress: builder.mutation<any, any>({
      query: ({ id, stopId, ...body }) => ({
        url: `roundtrips/${id}/stop/${stopId}/validateLocation`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});
export const {
  useGetWarehousesRoundtripQuery,
  useLazyGetWarehousesRoundtripQuery,
  useAddRoundTripsMutation,
  useDeleteRoundTripMutation,
  useUpdateRoundTripsClusterMutation,
  useUpdateRoundTripMutation,
  usePostInitMutation,
  usePostInitOrderMutation,
  useLazyGetRoundTripsQuery,
  useGetRoundtripByAgentQuery,
  useLazyGetRoundtripByAgentQuery,
  useGetRoundtripByAgentStatsQuery,
  useRoundtripUpdateStatusMutation,
  useRoundtripAddApiMutation,
  useGetRoundTripsClusterPostMutation,
  useLazyGetRoundTripsClusterQuery,
  useGetRoundTripsClusterQuery,
  useLazyGetRoundtripsNotesQuery,
  useGetRoundtripsNotesQuery,
  useCreateRoundtripsNoteMutation,
  useDeleteRoundtripsNoteMutation,
  usePatchRoundtripsNoteMutation,
  useRoundtripUpdateApiMutation,
  useReorderStopsMutation,
  useStopUpdateApiMutation,
  useStopUpdateMobileApiMutation,
  useUncancelStopMutation,
  useEndTripApiMutation,
  useOnWayMutation,
  usePausedMutation,
  useDeleteStopMutation,
  useAddStopRoundtripMutation,
  useCalculateRoundtripRouteMutation,
  useRoundtripAddStopApiMutation,
  useDeleteClusterMutation,
  useDeleteOrderClusterMutation,
  useAddOrderClusterMutation,
  useUpdateCancelationReasonApiMutation,
  useGetRoundTripDetailsQuery,
  useLazyGetRoundTripDetailsQuery,
  useGetInitOrderQuery,
  useLazyGetInitOrderQuery,
  useGetRoundtripsActivitiesQuery,
  useGetEmployeeRoundtripsForCalendarQuery,
  useGetRoundtripsForCalendarQuery,
  useLazyGetRoundtripsForCalendarQuery,
  useCheckConfirmationCodeQuery,
  usePostTemplateMutation,
  useGetSupportUnitQuery,
  useGetSupportUnitRoundtripQuery,
  useGetDockerNotificationsQuery,
  useGetAgentActivitiesQuery,
  useGetManagerActivitiesQuery,
  useGetDockerActivitiesQuery,
  useGetDockerCountNotificationsQuery,
  usePostDockerReadNotificationsMutation,
  usePostSupportUnitMutation,
  usePostAddSupportUnitsCollectedMutation,
  useDeleteSupportUnitMutation,
  usePostRoundtripMoveTruckMutation,
  useGetTemplatesRoundtripsQuery,
  useLazyGetTemplatesRoundtripsQuery,
  useCreateRoundtripFromTemplateMutation,
  useAddAccessItemApiEndpointMutation,
  useGetClientsOrdersQuery,
  useDeleteMultiStopsMutation,
  useImportFileValidateMutation,
  useFileImportMutation,
  useGetDriverLocationsQuery,
  useLazyGetDriverLocationsQuery,
  useGetWarehouseInfoQuery,
  useGetMapMarkersDataQuery,
  useGetRoundTripListQuery,
  useGetRoundTripsClusterStatsQuery,
  useValidateAddressMutation,
} = roundtripApis;

export default roundtripApis;
