import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { GridHead } from './components/GridHead';
import { GridPagination } from './components/GridPagination';
import { Stack, SxProps } from '@mui/material';
import { useGrid } from './hooks';
import { FilterChips } from '../FilterChips';
import If from '../If';
import styled from 'styled-components';
import React from 'react';

interface GridProps {
  children: React.ReactNode;
  toolbar?: JSX.Element;
  middleSection?: JSX.Element;
  tableSx?: SxProps;
}

export const Grid = ({
  children,
  toolbar,
  middleSection,
  tableSx,
}: GridProps) => {
  const { filters, setFilters, totalRows } = useGrid();
  return (
    <Stack width={'100%'} height={'100%'}>
      {toolbar}

      {middleSection}

      <FilterChipsWrapper>
        <FilterChips filters={filters} setFilters={setFilters} />
      </FilterChipsWrapper>

      <TableWrapper>
        <TableContainer sx={{ flex: 1 }}>
          <Table stickyHeader sx={tableSx}>
            <GridHead />
            <If condition={!!totalRows}>
              <GridBody>{children}</GridBody>
            </If>
          </Table>
        </TableContainer>
        <GridPagination />
      </TableWrapper>
    </Stack>
  );
};

const TableWrapper = styled(Paper)({
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  boxShadow: 'none !important',
  padding: '0 2px',
});

const GridBody = styled(TableBody)({
  overflow: 'scroll',
  flex: 1,
});

const FilterChipsWrapper = styled('div')({
  paddingTop: '5px',
  paddingBottom: '5px',
});
