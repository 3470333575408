import React, { createContext, useCallback, useState } from 'react';
import { StateSetter } from 'types';

interface ContextProps {
  drawerOpen: boolean;
  setDrawerOpen: StateSetter<boolean>;
  selectedUser: string;
  setSelectedUser: StateSetter<string>;
  handleCloseDrawer: () => void;
}

export const UserContext = createContext<ContextProps | undefined>(undefined);

export const UserProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedUser('');
  }, [setDrawerOpen, setSelectedUser]);

  return (
    <>
      <UserContext.Provider
        value={{
          drawerOpen,
          setDrawerOpen,
          selectedUser,
          setSelectedUser,
          handleCloseDrawer,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};
