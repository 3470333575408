import CheckboxGrid from 'app/components/FilterComponents/components/CheckboxGrid';
import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { useCheckValues } from 'app/components/FilterComponents/hooks/useCheckValues';
import {
  FilterContainer,
  FilterSection,
} from 'app/components/FilterComponents/styles';
import {
  handleCheckboxFilterChange,
  handleSelectFilterChange,
} from 'app/helpers/helpers';
import { useTranslation } from 'react-i18next';
import { useGrid } from 'app/components/Grid/hooks';
import { useMemo } from 'react';
import { useLazyGetPlacesQuery } from 'common/services/placeApi';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { useQueryParams } from 'common/hooks/useQueryParams';
import { Popover } from '@material-ui/core';
import { CustomerStatus } from 'common/types/Customer';

interface PlacesFilterProps {
  filterOn: HTMLButtonElement | null;
  handleCloseFilter: () => void;
}
export const PlacesFilter = ({
  filterOn,
  handleCloseFilter,
}: PlacesFilterProps) => {
  const { filters, setFilters } = useGrid();
  const { t } = useTranslation();

  const [triggerStatus, { data: places }] = useLazyGetPlacesQuery();
  const { checkedValues, setCheckedValues } = useCheckValues(filters);
  const placesQueryParams = useQueryParams({
    context: 'places',
    page: 1,
    limit: 20,
  });

  const deliveryTypes = useMemo(() => {
    return [
      { label: t('roundtrips.tooltip.collection'), value: 'collection' },
      { label: t('common.delivery'), value: 'delivery' },
    ];
  }, []);

  const vehicleCondition = useMemo(() => {
    return [
      { label: 'PL', value: 'pl' },
      { label: 'VL', value: 'vl' },
    ];
  }, []);

  const status = useMemo(() => {
    return [
      {
        label: t('customer.status.in-review'),
        value: CustomerStatus.IN_REVIEW,
        color: '#7850bf',
      },
      {
        label: t('user.status.active'),
        value: CustomerStatus.ACTIVE,
        color: '#1b5e20',
      },
      {
        label: t('customer.status.on-watch'),
        value: CustomerStatus.ON_WATCH,
        color: '#5f2b01',
      },
      {
        label: t('customer.status.blocked'),
        value: CustomerStatus.BLOCKED,
        color: '#71431d',
      },
      {
        label: t('customer.status.archived'),
        value: CustomerStatus.ARCHIVED,
        color: '#1f1f1f',
      },
    ];
  }, []);

  const handleClearAll = (e: React.MouseEvent) => {
    setCheckedValues({});
    setFilters({
      deliveryTypes: [],
      vehicleCondition: [],
      status: [],
    });
  };

  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    handleSelectFilterChange(filterKey, options, setFilters);
  };

  const searchStatus = (search: any) => {
    triggerStatus(`${placesQueryParams}&text=${search}`);
  };

  return (
    <Popover
      open={Boolean(filterOn)}
      onClose={handleCloseFilter}
      anchorEl={filterOn}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <FilterContainer>
        <FilterHeader onClearAll={handleClearAll} />
        <FilterSection>
          <CheckboxGrid
            filterName={t('Delivery Type')}
            checkedValues={checkedValues}
            openByDefault={true}
            handleFilterChange={(e, label) => {
              handleCheckboxFilterChange(
                e,
                label,
                'deliveryTypes',
                checkedValues,
                setCheckedValues,
                setFilters,
              );
            }}
            filterItems={deliveryTypes?.map(cond => {
              return {
                value: cond?.value,
                label: cond?.label,
              };
            })}
          />
          <CheckboxGrid
            filterName={t('Access Conditions')}
            checkedValues={checkedValues}
            openByDefault={true}
            handleFilterChange={(e, label) => {
              handleCheckboxFilterChange(
                e,
                label,
                'vehicleCondition',
                checkedValues,
                setCheckedValues,
                setFilters,
              );
            }}
            filterItems={vehicleCondition?.map(vehicle => {
              return {
                value: vehicle?.value,
                label: vehicle?.label,
              };
            })}
          />
          <MultiSelect
            filterName={t('Status')}
            selectedOptions={filters?.status}
            handleFilterChange={options => {
              handleOptionsChange('status', options);
            }}
            filterItems={status}
            onSearch={searchStatus}
            openByDefault={true}
          />
        </FilterSection>
      </FilterContainer>
    </Popover>
  );
};
