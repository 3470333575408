import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Truck from 'assets/img/roundTrips/truck.svg';
import styled, { css } from 'styled-components';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';

interface RoundtripTruckIconProps {
  vehicle: boolean;
  driver: boolean;
  running: boolean;
  style?: React.CSSProperties;
  iconStyle?: {
    icon: React.CSSProperties;
    img: React.CSSProperties;
  };
  notTracked?: boolean;
  mapOpen?: boolean;
  status?: string;
}

// TODO: deprecated this component
export default function RoundtripTruckIcon({
  vehicle,
  driver,
  running,
  style,
  iconStyle,
  notTracked,
  mapOpen,
  status,
}: RoundtripTruckIconProps) {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  let truckIcon = <></>;
  let title = '';

  const hideTooltip = notTracked;

  if (vehicle || driver) {
    truckIcon = <RedPulse />;
    title = t('roundtrips.tooltip.truck.warning');
  } else if (running) {
    truckIcon = (
      <GreenPulse style={iconStyle?.icon}>
        <img src={Truck} style={iconStyle?.img} />
      </GreenPulse>
    );
    title = t('roundtrips.tooltip.truck.running');
  } else {
    truckIcon = (
      <GreyIcon style={iconStyle?.icon}>
        <img src={Truck} style={iconStyle?.img} />
        {/* <Slash /> */}
      </GreyIcon>
    );
    title = t('roundtrips.tooltip.truck.not_running');
  }

  if (mapOpen) {
    truckIcon = (
      <TruckIconWrapper
        style={{ border: `2px solid ${theme.primaryActiveColor}` }}
      >
        {truckIcon}
      </TruckIconWrapper>
    );
  } else {
    truckIcon = <TruckIconWrapper>{truckIcon}</TruckIconWrapper>;
  }

  let trackedIcon = <></>;
  if (notTracked) {
    trackedIcon = (
      <div>
        <PriorityHighIcon />
      </div>
    );
  }

  return (
    <TruckIcons style={style}>
      <ManagedTooltip disabled={hideTooltip} title={title}>
        {truckIcon}
      </ManagedTooltip>
      <Tooltip title={t('roundtrips.tooltip.truck.not_tracked')}>
        {trackedIcon}
      </Tooltip>
    </TruckIcons>
  );
}

const sharedStyles = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* left: 10px; */
  border-radius: 50%;
  width: 14px;
  height: 14px;

  img {
    width: 7.39px;
    height: 5.91px;
  }
`;

export const Slash = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  transform: rotate(125deg);
`;

export const RedPulse = styled.div`
  background: #df0000;
  box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
  animation: redPulse 1.5s infinite;

  @keyframes redPulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.15);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(255, 0, 0, 0.15);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.15);
    }
  }

  ${sharedStyles}

  width: 0.6rem;
  height: 0.6rem;
`;

export const GreenPulse = styled.div`
  background: rgba(4, 188, 133, 1);
  box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.5);
  animation: greenPulse 1.5s infinite;
  cursor: pointer;

  @keyframes greenPulse {
    0% {
      box-shadow: 0 0 0 0 rgba(4, 188, 133, 0.2);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(4, 188, 133, 0.2);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(4, 188, 133, 0.2);
    }
  }

  ${sharedStyles}
`;

export const BluePulse = styled.div`
  background: #29b6f6;
  box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.5);
  animation: bluePulse 1.5s infinite;
  cursor: pointer;

  @keyframes bluePulse {
    0% {
      box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.2);
    }
    50% {
      box-shadow: 0 0 0 5px rgba(41, 182, 246, 0.2);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(41, 182, 246, 0.2);
    }
  }

  ${sharedStyles}
`;

export const GreyIcon = styled.div`
  background: rgba(206, 204, 204, 1);
  cursor: pointer;
  ${sharedStyles}
`;

export const BlackIcon = styled.div`
  background: #2a2d33;
  cursor: pointer;
  ${sharedStyles}
`;

export const BlueIcon = styled.div`
  background: #29b6f6;
  cursor: pointer;
  ${sharedStyles}
`;

const TruckIcons = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  svg {
    width: 12px;
    height: 12px;
  }
`;

const TruckIconWrapper = styled.div(props => ({
  borderRadius: '50%',
  padding: '3px',
}));
